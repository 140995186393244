import React from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Autocomplete, FormFieldControl } from "../pages/FormFields";
import { NewInstanceModal } from "./NewInstanceModal";
import { useAddCompanyAdminMutation, ConstructionCompany } from "../../graphql";
import { errorUrl } from "../../helpers/navigation";
import { defaultHelperText, siteInputParams } from "../pages/inputConstants";
import { isEmpty } from "lodash";

export const AddUserCompanyModal: React.FC<{
  isOpened: boolean;
  email: string;
  companies: ConstructionCompany[];
  close: () => void;
  onSave: () => void;
}> = (props) => {
  const navigate = useNavigate();

  const [addCompanyAdmin, { error }] = useAddCompanyAdminMutation();

  const companyOptions = React.useMemo(
    () =>
      props.companies.map((company) => ({
        label: company.name,
        value: company.id,
      })),
    [props.companies],
  );

  const companyIds = React.useMemo(
    () => companyOptions.map(({ value }) => value),
    [companyOptions],
  );

  const ValidationSchema = Yup.object().shape({
    companyId: Yup.number().oneOf(companyIds, defaultHelperText.required),
  });

  const initialValues = {
    companyId: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit: () => {
      null;
    },
  });

  React.useEffect(() => {
    if (error) navigate(errorUrl);
  }, [error]);

  const close = () => {
    formik.resetForm({ values: initialValues });
    props.close();
  };

  const onSave = () => {
    const input = {
      email: props.email,
      companyId: formik.values.companyId,
    };
    void addCompanyAdmin({ variables: { input } }).then(props.onSave);
    close();
  };

  return (
    <NewInstanceModal
      title="Add Company"
      canBeSaved={formik.isValid && !isEmpty(formik.touched)}
      onSave={onSave}
      onCancel={close}
      isOpened={props.isOpened}
    >
      <FormFieldControl mt={2}>
        <Autocomplete<number>
          name="companyId"
          options={companyOptions}
          onChange={(value?: number | null) => {
            void formik
              .setFieldValue("companyId", value || 0)
              .then(() => void formik.setTouched({ companyId: true }));
          }}
          value={formik.values.companyId}
          {...siteInputParams.company}
        />
      </FormFieldControl>
    </NewInstanceModal>
  );
};
