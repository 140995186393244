import { HeadCell } from "../../../components/tables/types";
import { SiteNavigationPath, getSiteUrl } from "../../../helpers/navigation";
import { GateReport } from "./types";
import { Link } from "react-router-dom";

interface GetHeadCellsArgs {
  handleSort?: (key: string) => void;
  hadnleImageLoad?: () => void;
}

const IMG_STYLES = {
  minWidth: "170px",
  maxWidth: "250px",
  margin: "0 auto",
};

export const getHeadCells = ({
  handleSort,
  hadnleImageLoad,
}: GetHeadCellsArgs): Array<HeadCell<GateReport>> => [
  {
    key: "cameraName",
    label: "Camera Name",
    alignment: "left",
    width: "23%",
    handleSort,
    render: (gateReport) => gateReport.cameraId,
  },
  {
    key: "eventType",
    label: "Event Type",
    alignment: "center",
    width: "18%",
    handleSort,
    render: (gateReport) => gateReport.eventType,
  },
  {
    key: "eventObject",
    label: "Object",
    alignment: "center",
    width: "18%",
    handleSort,
    render: (gateReport) => gateReport.eventObject,
  },
  // {
  //   key: "eventObject",
  //   label: "Event Object",
  //   alignment: "center",
  //   width: "15%",
  //   handleSort,
  //   render: (gateReport) => gateReport.cameraId,
  // },
  {
    key: "parsedTimestamp",
    label: "Time",
    alignment: "center",
    width: "15%",
    handleSort,
    render: (gateReport) => <>{gateReport.parsedTimestamp}</>,
  },
  {
    key: "preview",
    label: "Camera Preview",
    alignment: "center",
    width: "15%",
    handleSort,
    render: (gateReport) => (
      <img
        className="table-image"
        crossOrigin="anonymous"
        src={gateReport.snapshot_s3 || ""}
        onLoad={hadnleImageLoad}
        style={IMG_STYLES}
      />
    ),
  },
  {
    key: "actions",
    label: "Actions",
    alignment: "right",
    width: "15%",
    render: (gateReport) => (
      <Link
        to={getSiteUrl(
          SiteNavigationPath.GateReportActiveItem,
          gateReport.siteId!,
        ).replace(":gateReportId", `${gateReport.id}`)}
      >
        Go to details
      </Link>
    ),
  },
];
