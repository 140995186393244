import { omit, pick } from "lodash";
import {
  ISO_TIMEZONE_SOURCE,
  PartialPatternChoices,
  UNNESTED_FIELDS,
  UNNESTED_FIELDS_SOURCE,
} from "./constants";
import { CameraConfigurationForm } from "./types";
import { CameraConfigurationCreateUpdateInput } from "../../../graphql";

export function convertConfigurationFormToAPIData(
  values: CameraConfigurationForm,
) {
  const nestedValues: CameraConfigurationCreateUpdateInput = omit(
    {
      ...values,
      timezone:
        values.timezone === ISO_TIMEZONE_SOURCE ? null : values.timezone,
      [UNNESTED_FIELDS_SOURCE]: Object.fromEntries(
        UNNESTED_FIELDS.map((field) => [field, values[field]]),
      ),
    },
    UNNESTED_FIELDS,
  );

  if (nestedValues.timeParsingRules.partialPatterns)
    nestedValues.timeParsingRules.partialPatterns = pick(
      nestedValues.timeParsingRules.partialPatterns,
      Object.values(PartialPatternChoices),
    );

  return nestedValues;
}
