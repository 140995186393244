import React, { useEffect } from "react";

import {
  saveToLocalStorage,
  loadFromLocalStorage,
} from "../helpers/localStorage";
import { THEMES } from "../constants";
import { ThemeVariant } from "./types";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useTheme } from "./hooks";
import { createTheme } from ".";

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: (theme: ThemeVariant) => saveToLocalStorage("theme", theme),
};

const ThemeContext = React.createContext(initialState);

type ThemeProviderProps = {
  children: React.ReactNode;
};

function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, _setTheme] = React.useState<string>(initialState.theme);
  useEffect(() => _setTheme(loadFromLocalStorage("theme", THEMES.DEFAULT)), []);

  const setThemeHook = (theme: ThemeVariant) => {
    saveToLocalStorage("theme", theme);
    _setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: setThemeHook }}>
      {children}
    </ThemeContext.Provider>
  );
}

const ThemeContextProvider: React.FC = (props) => {
  const { theme } = useTheme();
  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      {props.children}
    </MuiThemeProvider>
  );
};

export { ThemeProvider, ThemeContext, ThemeContextProvider };
