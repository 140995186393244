import { UserRole } from "@ocilex/api";
import { SiteNavigationPath } from "./helpers/navigation";
import { useCurrentUser } from "./auth/hooks";
import { useMemo } from "react";

const project = {
  name: "Ocilex",
  creationYear: "2022",
  contactEmail: "support@ocilex.com",
};

export const useProject = () => {
  const user = useCurrentUser();
  const name = user?.permissionsFromCompany?.name || project.name;
  const dynamicProject = useMemo(() => ({ ...project, name }), [user]);
  return dynamicProject;
};

export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export enum TimelapseStatusLabel {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  NO_CONNECTION = "No connection",
}

// TODO: finalize timelapse frequencies requirements with clients
export enum TimelapseFrequencyLabel {
  S10 = "10s",
  S20 = "20s",
  S60 = "60s",
  M5 = "5m",
  M10 = "10m",
  M15 = "15m",
  H1 = "1h",
}

export enum LiveViewUpdateFrequency {
  M1 = "M1",
  M5 = "M5",
  M10 = "M10",
}

export enum CameraTypes {
  Milesight = "milesight",
  Hanwha = "hanwha",
}

export enum WorkingDayLabel {
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
  SUNDAY = "Sunday",
}

export enum UserStatusLabel {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  INVITED = "Invited",
}

export enum SiteStatusLabel {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  DRAFT = "Draft",
}

export enum UserRoleLabel {
  GUEST = "Guest",
  CORE_ONLY = "Core User",
  GENERAL = "Standard User",
  MANAGER = "Manager",
}

export enum CompanyStatusLabel {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  DRAFT = "Draft",
}

export enum CameraReportScheduleStatusLabel {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  DELETED = "Removed",
}

export const emDash = "—";
export const timeFormat = "HH:mm:ss";
export const dateFormat = "yyyy-MM-dd";
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const allSitePages = Object.values(SiteNavigationPath);
// Previously implemented via testing substrings below in the current path.
// This means there's no support for dynamic paths.
// Have no time to reimplement it in react-router compatible way, so keep it in mind.
const publicSitePages = [
  SiteNavigationPath.LiveViewList,
  SiteNavigationPath.CompareSnapshotsBaseURI,
];

// Determines list of Site pages basically may be shown in the sidebar.
export const sitePagesForShowInSideBar: SiteNavigationPath[] =
  allSitePages.filter((page) => page != SiteNavigationPath.UserEdit);

// Determines list of Site pages allowed for site user.
export const roleBasedAccess = {
  [UserRole.Guest]: [...publicSitePages],
  [UserRole.CoreOnly]: [...publicSitePages, SiteNavigationPath.Timelapse],
  [UserRole.General]: [
    ...publicSitePages,
    SiteNavigationPath.BimView,
    SiteNavigationPath.BimCompare,
    SiteNavigationPath.Timelapse,
    SiteNavigationPath.EditFrame,
    SiteNavigationPath.CompareFrames,
    SiteNavigationPath.GateReport,
  ],
  [UserRole.Manager]: allSitePages,
};

export const permissionsFromCompanyMapping: Record<
  SiteNavigationPath,
  string | undefined
> = {
  [SiteNavigationPath.BimCompare]: "canViewBIMCompare",
  [SiteNavigationPath.BimView]: "canViewBIMView",
  [SiteNavigationPath.EditFrame]: "canViewEditFrame",
  [SiteNavigationPath.GateReport]: "canViewGateReport",
  [SiteNavigationPath.GateReportActiveItem]: "canViewGateReport",
  [SiteNavigationPath.LiveViewDetail]: "canViewLiveView",
  [SiteNavigationPath.LiveViewList]: "canViewLiveView",
  [SiteNavigationPath.CompareSnapshotsBaseURI]: "canViewSiteProgress",
  [SiteNavigationPath.Timelapse]: "canViewTimelapse",
  [SiteNavigationPath.UserEdit]: "canViewUserManagement",
  [SiteNavigationPath.UserList]: "canViewUserManagement",
  [SiteNavigationPath.CompareSnapshots]: undefined,
  [SiteNavigationPath.CompareFrames]: undefined,
};
