import React, { useCallback, useMemo, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import { pick } from "lodash";
import { Button } from "../pages/Common";
import MUIFieldSet from "../MUIFieldSet";
import { applyClientTimezoneOffset } from "../../helpers/date";
import { ConfirmationModal } from "./ConfirmationModal";
import { format } from "date-fns";

interface DownloadTimelapseModalProps {
  siteName: string;
  disabled?: boolean;
  onSubmit: (values: { dateFrom: Date | null; dateTo: Date | null }) => void;
}

enum DownloadModeChoices {
  FullVideo = "fullVideo",
  DateRange = "dateRange",
}

const DownloadModeSelectChoices = [
  { label: "Full video", value: DownloadModeChoices.FullVideo },
  { label: "Select date range", value: DownloadModeChoices.DateRange },
];

const FormValidationSchema = Yup.object({
  downloadMode: Yup.string()
    .oneOf(Object.values(DownloadModeChoices))
    .required(),
  dateFrom: Yup.date().when("downloadMode", {
    is: DownloadModeChoices.DateRange,
    then: (schema) => schema.required(),
  }),
  dateTo: Yup.date().when("downloadMode", {
    is: DownloadModeChoices.DateRange,
    then: (schema) => schema.required(),
  }),
});

const Container = styled("div")({
  marginTop: "-8px",
});

const StyledRadioButton = styled(Radio)({
  padding: "2px",
  margin: "0 7px",
});

export default function DownloadTimelapseWidget({
  siteName,
  disabled = false,
  onSubmit,
}: DownloadTimelapseModalProps) {
  const emptyForm = useMemo(function getEmptyForm() {
    const stripTime = (date: Date) => {
      date.setTime(
        Math.floor(date.getTime() / 1000 / 60 / 60 / 24) * 24 * 60 * 60 * 1000,
      );
      return date;
    };
    const now = stripTime(new Date());
    const pastMonth = stripTime(new Date());
    pastMonth.setMonth(now.getMonth() - 1);
    now.setUTCHours(23);
    now.setUTCMinutes(59);

    return {
      downloadMode: DownloadModeChoices.FullVideo,
      dateFrom: pastMonth,
      dateTo: now,
    };
  }, []);

  const [isConfirmationModalVisible, setConfirmationModalVisibility] =
    useState(false);

  const formik = useFormik({
    initialValues: emptyForm,
    validationSchema: FormValidationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      onSubmit(
        values.downloadMode === DownloadModeChoices.DateRange
          ? pick(values, ["dateFrom", "dateTo"])
          : { dateFrom: null, dateTo: null },
      );
    },
  });

  const handleConfirmationModalOpen = useCallback(
    function handleConfirmationModalOpen() {
      setConfirmationModalVisibility(true);
    },
    [setConfirmationModalVisibility],
  );

  const handleConfirmationModalClose = useCallback(
    function handleConfirmationModalClose() {
      setConfirmationModalVisibility(false);
    },
    [setConfirmationModalVisibility],
  );

  const handleDownloadConfirmation = useCallback(
    function handleDownloadConfirmation() {
      handleConfirmationModalClose();
      void formik.submitForm();
    },
    [formik.submitForm, handleConfirmationModalClose],
  );

  const handleDateFromChange = useCallback(
    function handleDateFromChange(value: Date | null) {
      void formik.setFieldValue(
        "dateFrom",
        applyClientTimezoneOffset(value ?? new Date(), -1),
      );
    },
    [formik.setFieldValue],
  );

  const handleDateToChange = useCallback(
    function handleDateToChange(value: Date | null) {
      void formik.setFieldValue(
        "dateTo",
        applyClientTimezoneOffset(value ?? new Date(), -1),
      );
    },
    [formik.setFieldValue],
  );

  const displayDateFrom = applyClientTimezoneOffset(formik.values.dateFrom);
  const displayDateTo = applyClientTimezoneOffset(formik.values.dateTo);

  const dateFormat = "MMM do, yyyy";

  return (
    <Container>
      <ConfirmationModal
        isOpened={isConfirmationModalVisible}
        onConfirm={handleDownloadConfirmation}
        close={handleConfirmationModalClose}
      >
        <Typography>
          You're requesting a timelapse download from the {siteName}{" "}
          construction site.
        </Typography>
        {formik.values.downloadMode === DownloadModeChoices.DateRange && (
          <Typography>
            We will generate a timelapse starting at{" "}
            {format(displayDateFrom, dateFormat)} and ending at{" "}
            {format(displayDateTo, dateFormat)}.
          </Typography>
        )}
        <Typography>Are you sure?</Typography>
      </ConfirmationModal>
      <MUIFieldSet legend="Download Timelapse">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={3}>
            <RadioGroup
              name="downloadMode"
              value={formik.values.downloadMode}
              onChange={formik.handleChange}
            >
              {DownloadModeSelectChoices.map(({ label, value }) => (
                <FormControlLabel
                  key={value}
                  label={label}
                  value={value}
                  disabled={disabled}
                  control={<StyledRadioButton />}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={6} md={3.5}>
            <DatePicker
              label="Start Date"
              disabled={
                disabled ||
                formik.values.downloadMode === DownloadModeChoices.FullVideo
              }
              value={displayDateFrom}
              onAccept={handleDateFromChange}
              maxDate={formik.values.dateTo}
            />
          </Grid>
          <Grid item xs={6} md={3.5}>
            <DatePicker
              label="End Date"
              disabled={
                disabled ||
                formik.values.downloadMode === DownloadModeChoices.FullVideo
              }
              value={displayDateTo}
              onAccept={handleDateToChange}
              minDate={formik.values.dateFrom}
              maxDate={emptyForm.dateTo}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              sx={{
                display: "block",
                margin: "auto",
              }}
              onClick={handleConfirmationModalOpen}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </MUIFieldSet>
    </Container>
  );
}
