import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllowedSitePages,
  getAllowedAdminPages,
} from "../helpers/permissions";
import { useCurrentUser } from "../auth/hooks";
import {
  getSiteUrl,
  isSiteMenuPage,
  notFoundUrl,
  getAdminUrl,
} from "../helpers/navigation";
import { User } from "@ocilex/api";

export const RoutesGuard: React.FC = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const { siteId: maybeSiteId } = useParams();
  const siteId = parseInt(maybeSiteId || "");

  if (!user) return null;
  if (user.isAdmin) return <Outlet />;

  let pageIsAllowed = false;

  if (isSiteMenuPage(path)) {
    pageIsAllowed = canGetSitePage(user, path, siteId);
  } else {
    pageIsAllowed = canGetAdminPage(user, path);
  }

  if (!pageIsAllowed) {
    navigate(notFoundUrl);
    return null;
  }

  return <Outlet />;
};

const canGetAdminPage = (user: User, path: string) => {
  return !!getAllowedAdminPages(user).find((navPath) => {
    return path.includes(getAdminUrl(navPath));
  });
};

const canGetSitePage = (user: User, path: string, siteId: number) => {
  return !!getAllowedSitePages(user, siteId).find((navPath) => {
    const allowedPrefix = getSiteUrl(navPath, siteId);
    return path.includes(allowedPrefix);
  });
};
