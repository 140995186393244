import React from "react";
import { PaginatedTable } from "../../components/tables/PaginatedTable";
import {
  Button,
  Divider,
  PageTitle,
  PlusButton,
} from "../../components/pages/Common";
import { useDebounce } from "use-debounce";
import { filterObjectsByString } from "../../components/tables/filterByString";
import { dateToString } from "../../helpers/toString";
import { useNavigate } from "react-router-dom";
import {
  User as FullUser,
  UserStatus,
  useGetSiteUsersQuery,
  UserRole,
} from "../../graphql";
import { emDash, UserRoleLabel, UserStatusLabel } from "../../constants";
import { uniqBy, flatten } from "lodash";
import { InviteSiteUserModal } from "../../components/modals/InviteSiteUserModal";
import { getSiteUrl, SiteNavigationPath } from "../../helpers/navigation";
import { enumToChoices } from "../../helpers/enumToChoices";
import {
  roleChoicesSortFn,
  SingleSelect,
} from "../../components/pages/FormFields";
import { useCurrentSite } from "./context/SiteContext";
import { HeadCell, Sorting } from "../../components/tables/types";
import { sortReducer, sortRows } from "../../components/tables/sorting";
import { useLocalStorage } from "../../helpers/localStorage";
import { SearchInput } from "../../components/tables/components/SearchInput";
import { TableFilterToolbar } from "../../components/tables/components/Toolbar";

const EditButton: React.FC<{
  siteId: number;
  email: string;
}> = ({ email, siteId }) => {
  const navigate = useNavigate();

  return (
    <Button
      mr={2}
      variant="outlined"
      onClick={() =>
        navigate(getSiteUrl(SiteNavigationPath.UserEdit, siteId, email))
      }
      margin="0"
    >
      Edit
    </Button>
  );
};
type User = Pick<FullUser, "name" | "joiningAt" | "phone" | "status">;

type SiteUser = User & {
  siteId: number;
  role: UserRole;
  email: string;
};

type Column = HeadCell<SiteUser>;

const defaultSorting: Sorting = {
  key: "joiningAt",
  order: "desc",
};

const filterByStatus = (user: SiteUser, status: UserStatus | "") => {
  if (status === "") return true;
  return user.status === status;
};

const filterByRole = (user: SiteUser, role: UserRole | "") => {
  if (role === "") return true;
  return user.role === role;
};

type FiltersType = {
  role: UserRole | "";
  status: string;
};

const initialFilters: FiltersType = {
  role: "",
  status: "",
};

const filtersReducer = (
  state: FiltersType,
  action: Partial<FiltersType>,
): FiltersType => {
  return { ...state, ...action };
};

const searchUsers = (users: SiteUser[], pattern: string): SiteUser[] => {
  const byName = filterObjectsByString(users, "name", pattern);
  const byEmail = filterObjectsByString(users, "email", pattern);
  return uniqBy(flatten([byName, byEmail]), "email");
};

const Page: React.FC = () => {
  const site = useCurrentSite();
  const { data: siteUsersQuery } = useGetSiteUsersQuery({
    variables: { constructionSiteId: site.id },
  });
  const settingsLocalStorageKey = "site-users-list";
  const [initialSorting, saveSorting] = useLocalStorage<Sorting>(
    `${settingsLocalStorageKey}-sorting`,
    defaultSorting,
  );
  const [sorting, setSortingKey] = React.useReducer(
    sortReducer,
    initialSorting,
  );

  const [modalIsOpened, setModalIsOpened] = React.useState<boolean>(false);

  const users: SiteUser[] = React.useMemo(
    () =>
      (siteUsersQuery?.constructionSite?.users || []).map((row) => ({
        ...row?.user,
        siteId: row?.siteId || 0,
        email: row?.user.email || "",
        joiningAt: row?.user.joiningAt || "",
        role: row?.role || UserRole.General,
      })),
    [siteUsersQuery?.constructionSite],
  );

  const [searchPattern, setSearchPattern] = React.useState<string>("");
  const [filtersState, setFiltersState] = React.useReducer(
    filtersReducer,
    initialFilters,
  );
  const [debouncedSearchPattern] = useDebounce(searchPattern, 200);

  const matchedUsers = React.useMemo(() => {
    return searchUsers(users, debouncedSearchPattern);
  }, [debouncedSearchPattern, users]);

  const filteredUsers = React.useMemo(() => {
    return matchedUsers.filter((user) => {
      return (
        filterByStatus(user, filtersState.status as UserStatus) &&
        filterByRole(user, filtersState.role)
      );
    });
  }, [matchedUsers, filtersState]);

  const sortedRows = React.useMemo(() => {
    return sortRows(filteredUsers, sorting);
  }, [sorting, filteredUsers]);

  React.useEffect(() => saveSorting(sorting), [sorting]);

  if (!site) return null;

  const headCells: Array<Column> = [
    {
      key: "name",
      label: "Name",
      alignment: "left",
      width: "25%",
      handleSort: setSortingKey,
      render: (user) => user.name || emDash,
    },
    {
      key: "email",
      label: "Email",
      alignment: "left",
      width: "25%",
      handleSort: setSortingKey,
      render: (user) => user.email,
    },
    {
      key: "phone",
      label: "Phone",
      alignment: "center",
      width: "10%",
      render: (user) => user.phone || emDash,
    },
    {
      key: "role",
      label: "Role",
      alignment: "center",
      width: "10%",
      handleSort: setSortingKey,
      render: (user) => UserRoleLabel[user.role],
    },
    {
      key: "status",
      label: "Status",
      alignment: "center",
      width: "10%",
      handleSort: setSortingKey,
      render: (user) => UserStatusLabel[user.status || UserStatus.Inactive],
    },
    {
      key: "joiningAt",
      label: "Joining Date",
      alignment: "center",
      width: "10%",
      handleSort: setSortingKey,
      render: (user) =>
        user.joiningAt ? dateToString(new Date(user.joiningAt)) : emDash,
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "10%",
      render: (user) => <EditButton email={user.email} siteId={user.siteId} />,
    },
  ];

  const filters = [
    <SingleSelect
      label="Role"
      minWidth="150px"
      placeholder="Select role"
      value={filtersState.role}
      allowEmpty={true}
      options={enumToChoices(UserRoleLabel, { reversed: true })}
      choicesSortFn={roleChoicesSortFn}
      onChange={(value: string) => setFiltersState({ role: value as UserRole })}
    />,
    <SingleSelect
      label="Status"
      placeholder="Select status"
      value={filtersState.status}
      allowEmpty={true}
      options={enumToChoices(UserStatusLabel, { reversed: true })}
      onChange={(value: string) => setFiltersState({ status: value })}
    />,
  ];

  const search = (
    <SearchInput
      placeholder="Search by name or email"
      onChange={setSearchPattern}
    />
  );
  const toolbar = <TableFilterToolbar search={search} filters={filters} />;

  return (
    <>
      <PageTitle text={`${site.name}: User Management`}>
        <InviteSiteUserModal
          siteId={site.id}
          isOpened={modalIsOpened}
          close={() => setModalIsOpened(false)}
        />
        <PlusButton
          onClick={() => setModalIsOpened(true)}
          tooltip="Invite a User"
        />
      </PageTitle>
      <Divider my={6} />
      <PaginatedTable
        headCells={headCells}
        rows={sortedRows}
        sorting={sorting}
        settingsLocalStorageKey={settingsLocalStorageKey}
        toolbar={toolbar}
      />
    </>
  );
};

export const SiteUsersListPage = React.memo(Page);
