import { User as FullUser, UserRole } from "@ocilex/api";
import { flatten } from "lodash";
import {
  allSitePages,
  permissionsFromCompanyMapping,
  roleBasedAccess,
  sitePagesForShowInSideBar,
} from "../constants";
import { SiteNavigationPath, AdminNavigationPath } from "./navigation";

type User = Pick<
  FullUser,
  "email" | "isAdmin" | "sites" | "companies" | "permissionsFromCompany"
>;

export const getAllowedAdminPages = (user: User) => {
  if (user.isAdmin) return Object.values(AdminNavigationPath);
  if (isCompanyAdmin(user))
    return [
      AdminNavigationPath.CompanyEdit,
      AdminNavigationPath.UserList,
      AdminNavigationPath.UserEdit,
      AdminNavigationPath.SiteList,
      AdminNavigationPath.SiteEdit,
    ];
  return [];
};

/* Returns all site pages can be accessed by user */
export const getAllowedSitePages = (
  user: User,
  siteId: number,
): SiteNavigationPath[] => {
  if (
    user.isAdmin ||
    (isCompanyAdminForSite(user, siteId) && !user.permissionsFromCompany)
  )
    return allSitePages;
  const role = getSiteUserRole(user, siteId);
  const pages = role ? roleBasedAccess[role] : [];
  return pages.filter((page) => {
    const pagePerms = permissionsFromCompanyMapping[page];
    if (typeof pagePerms === "string" && user.permissionsFromCompany) {
      return !!user.permissionsFromCompany[
        pagePerms as keyof typeof user.permissionsFromCompany
      ];
    }
    return true;
  });
};

/* Returns all (allowed and forbidden) site pages
  to be shown for user in the sidebar;
*/
export const getSitePagesForShowInSidebar = (
  user: User,
  siteId: number,
): { path: SiteNavigationPath; isAllowed: boolean }[] => {
  const allowedPages = getAllowedSitePages(user, siteId).filter((page) =>
    sitePagesForShowInSideBar.includes(page),
  );

  const allowedPagesMap = allowedPages.map((path) => ({
    path,
    isAllowed: true,
  }));

  if (user.isAdmin || isCompanyAdminForSite(user, siteId))
    return allowedPagesMap;

  const role = getSiteUserRole(user, siteId);

  switch (role) {
    case UserRole.CoreOnly: {
      return roleBasedAccess[UserRole.General].map((path) => {
        return {
          path,
          isAllowed: allowedPages.includes(path),
        };
      });
    }
    default: {
      return allowedPagesMap;
    }
  }
};

export enum EditableUserField {
  name = "name",
  isAdmin = "isAdmin",
  phone = "phone",
  status = "status",
  permissionsFromCompany = "permissionsFromCompany",
}

const userFields = Object.values(EditableUserField) as EditableUserField[];

export const getUserFieldsAllowedForEdit = (
  user: User,
  userToBeUpdated: User,
): EditableUserField[] => {
  if (user.isAdmin) return userFields;

  if (user.email === userToBeUpdated.email)
    return [EditableUserField.name, EditableUserField.phone];

  // Not an admin cannot edit admin
  if (userToBeUpdated.isAdmin) return [];

  const sites = userToBeUpdated.sites || [];
  const companies = userToBeUpdated.companies || [];
  const companiesIds = companies.map((companyId) => companyId);

  const asCompanyCoAdmin = companiesIds.find(({ companyId }) =>
    canAdminCompany(user, companyId),
  );

  const asCompanyAdmin = sites.find(({ site }) =>
    canAdminCompany(user, site.companyId),
  );

  if (asCompanyCoAdmin || asCompanyAdmin)
    return [
      EditableUserField.name,
      EditableUserField.phone,
      EditableUserField.status,
    ];

  return [];
};

export enum EditableSiteUserField {
  name = "name",
  role = "role",
  phone = "phone",
  status = "status",
}

const siteUserFields = Object.values(
  EditableSiteUserField,
) as EditableSiteUserField[];

export const getSiteUserFieldsAllowedForEdit = (
  user: User,
  userToBeUpdated: User,
  site: { id: number; companyId: number },
): EditableSiteUserField[] => {
  if (user.email === userToBeUpdated.email)
    return [EditableSiteUserField.name, EditableSiteUserField.phone];
  if (user.isAdmin) return siteUserFields;

  // Not an admin cannot edit admin
  if (userToBeUpdated.isAdmin) return [];

  // As company admin
  if (canAdminCompany(user, site.companyId)) return siteUserFields;

  // As manager
  const userRole = getSiteUserRole(user, site.id);
  const opposedRole = getSiteUserRole(userToBeUpdated, site.id);
  if (!userRole || !opposedRole) return [];
  if (userRole === UserRole.Manager) return siteUserFields;
  return [];
};

export const canAddRemoveUserCompany = (
  user: User,
  userToUpdate: { email: string },
  companyId: number,
) => {
  if (user.email === userToUpdate.email) return false;
  return canAdminCompany(user, companyId);
};

const isCompanyAdminForSite = (user: User, siteId: number) => {
  const sites = flatten(
    user.companies?.map(({ company }) => company?.sites || []),
  );
  return !!sites.find(({ id }) => id === siteId);
};

export const canAdminSite = (user: User, siteId: number) =>
  user.isAdmin || isCompanyAdminForSite(user, siteId);

export const canAdminCompany = (user: User, companyId: number) => {
  if (user.isAdmin) return true;
  return !!user.companies?.find(({ companyId: id }) => id === companyId);
};

const getSiteUserRole = (user: User, siteId: number): UserRole | undefined =>
  user.sites?.find((site) => site.siteId === siteId)?.role;

export const isCompanyAdmin = (user: User) => (user.companies || []).length > 0;
