import React from "react";
import * as Yup from "yup";
import { NewInstanceModal } from "./NewInstanceModal";

import { useNavigate } from "react-router-dom";
import {
  defaultHelperText,
  siteInputParams,
  userInputParams,
} from "../pages/inputConstants";
import {
  useCreateCompAdminMutation,
  useGetCompaniesQuery,
} from "../../graphql";

import { useFormik } from "formik";
import { TextInput, Autocomplete } from "../pages/FormFields";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
} from "../../helpers/navigation";
import { isEmpty } from "lodash";
import { canAdminCompany } from "../../helpers/permissions";
import { useCurrentUser } from "../../auth/hooks";

export const InviteCompAdminModal: React.FC<{
  isOpened: boolean;
  close: () => void;
}> = (props) => {
  const navigate = useNavigate();
  const loggedUser = useCurrentUser();
  const [createCompAdmin] = useCreateCompAdminMutation();
  const { data: companiesData } = useGetCompaniesQuery();

  const companies = companiesData?.constructionCompanies || [];
  const companyIds = React.useMemo(
    () => companies.map(({ id }) => id),
    [companies],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string()
      .email(defaultHelperText.invalidEmail)
      .required(defaultHelperText.required),
    company: Yup.number().oneOf(companyIds, defaultHelperText.required),
  });

  const initialValues = {
    name: "",
    email: "",
    company: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      null;
    },
  });

  const redirect = (email: string) => {
    const url = getAdminUrl(AdminNavigationPath.UserEdit, email);
    navigate(url);
  };

  const onSave = () => {
    void formik.validateForm().then((errors) => {
      if (!isEmpty(errors)) return;
      createCompAdmin({
        variables: {
          input: formik.values,
        },
      })
        .then((result) => {
          const email = result.data?.createCompanyAdmin.email || "";
          close();
          redirect(email);
        })
        .catch(() => navigate(errorUrl));
    });
  };

  const close = () => {
    formik.resetForm({ values: initialValues });
    props.close();
  };

  const companySelectProps = React.useMemo(() => {
    return {
      ...siteInputParams.company,
      placeholderValue: "0",
      name: "site",
      initialValue: formik.values.company,
      options: !loggedUser
        ? []
        : companies
            .filter(({ id }) => canAdminCompany(loggedUser, id))
            .map(({ id, name }) => ({
              value: id,
              label: name,
            })),
      onChange: (value?: number) => formik.setFieldValue("company", value),
      onBlur: async (e: React.SyntheticEvent) => {
        formik.handleBlur(e);
        await formik.setFieldTouched("company");
      },
    };
  }, [companies, loggedUser]);

  return (
    <NewInstanceModal
      title="Invite a Company Admin"
      canBeSaved={formik.isValid && !isEmpty(formik.touched)}
      onSave={onSave}
      onCancel={close}
      saveButtonLabel="Invite"
      isOpened={props.isOpened}
    >
      <form>
        <Grid item xs={12} my={2}>
          <TextInput
            {...userInputParams.name}
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            helperText={formik.errors.name}
            showError={!!formik.errors.name && formik.touched.name}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={12} mb={2}>
          <TextInput
            {...userInputParams.email}
            value={formik.values.email}
            name="email"
            onChange={formik.handleChange}
            helperText={formik.errors.email}
            showError={!!formik.errors.email && formik.touched.email}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid container spacing={6} mb={2}>
          <Grid item xs={12}>
            <FormControl
              fullWidth={true}
              error={!!formik.errors.company && formik.touched.company}
            >
              <Autocomplete
                {...companySelectProps}
                value={formik.values.company}
              />
              {!!formik.errors.company && formik.touched.company && (
                <FormHelperText>{formik.errors.company}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </NewInstanceModal>
  );
};
