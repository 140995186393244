import React, { useMemo, useState } from "react";
import { utcToZonedTime, format } from "date-fns-tz";
import {
  PageTitle,
  Divider,
  ZonedClock,
  Button,
  // Button,
} from "../../../components/pages/Common";
import { useCurrentSite } from "../context/SiteContext";
import "vis-timeline/styles/vis-timeline-graph2d.css";

import {
  useGetGateReportsQuery,
  useGateReportTypesQuery,
  useGetSiteCamerasQuery,
  useGateReportObjectsQuery,
  InputMaybe,
  GateReportEventType,
  GateReportEventObject,
  useExportGateReportsMutation,
} from "../../../graphql";
import "react-calendar-timeline/lib/Timeline.css";
import {
  DatePicker,
  DatetimePicker,
  SingleSelect,
} from "../../../components/pages/FormFields";
import {
  dateUtcToLocalStartOfDay,
  getUtcNow,
  offsetDateByTz,
} from "../../../helpers/date";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  // CircularProgress,
  Grid,
  Box,
} from "@mui/material";
// import { GateReportItem } from "./GateReportTimeline";
import { GateReportTable } from "./GateReportTable";
import { GateReport } from "./types";
// import { GateReportActiveItem } from "./GateReportActiveItem";

function compensateDST(dt: Date): Date {
  const janOffset = new Date(dt.getFullYear(), 0, 1).getTimezoneOffset();
  const julOffset = new Date(dt.getFullYear(), 6, 1).getTimezoneOffset();
  const dstMinutes = dt.getTimezoneOffset() - Math.max(janOffset, julOffset);
  dt = new Date(dt);
  dt.setMinutes(dt.getMinutes() + dstMinutes);
  return dt;
}

export const GateReportPage: React.FC = () => {
  const site = useCurrentSite();

  const { data: camerasData } = useGetSiteCamerasQuery({
    variables: { constructionSiteId: site.id },
  });

  const [selectedCameraId, setSelectedCameraId] = useState<string>();
  const [eventType, setEventType] = useState<InputMaybe<GateReportEventType>>();
  const [eventObject, setEventObject] =
    useState<InputMaybe<GateReportEventObject>>();

  const siteTz = site.timezone || "";
  const utcNow = getUtcNow();
  const siteNow = offsetDateByTz(utcNow, siteTz);
  const initialTime = dateUtcToLocalStartOfDay(utcNow, siteTz);
  const [startDate, setStartDate] = useState<Date>(initialTime);
  const [endDate, setEndDate] = useState<Date>(siteNow);
  // const [startDateTs, setStartDateTs] = useState<number>();
  // const [endDateTs, setEndDateTs] = useState<number>();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportGateReport] = useExportGateReportsMutation();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const startDateTs = useMemo(
    () => Math.round(startDate.getTime() / 1000),
    [startDate],
  );
  const endDateTs = useMemo(
    () => Math.round(endDate.getTime() / 1000),
    [endDate],
  );

  // Get gate report type
  const { data: siteGateReportTypeQuery } = useGateReportTypesQuery({});

  const { data: siteGateReportObjectsQuery } = useGateReportObjectsQuery({});

  // Gate report query to server
  const { data: siteGateReportsQuery } = useGetGateReportsQuery({
    variables: {
      filters: {
        siteId: site.id,
        eventType: eventType,
        eventObject: eventObject,
        cameraId: selectedCameraId,
        startDate: startDateTs,
        endDate: endDateTs,
      },
      page,
      pageSize: rowsPerPage,
    },
  });

  const cameraOptions = React.useMemo(() => {
    const cameras = camerasData?.constructionSite?.cameras || [];
    return [
      {
        value: "",
        label: "--",
      },
      ...cameras.map(({ name }) => ({
        value: name,
        label: name,
      })),
    ];
  }, [camerasData]);

  const eventTypeOptions = React.useMemo(() => {
    const gateReportTypes = siteGateReportTypeQuery?.gateReportTypes || [];

    return [
      {
        value: undefined,
        label: "--",
      },
      ...gateReportTypes.map(({ name }) => ({
        label: name || "",
        value: name || undefined,
      })),
    ];
  }, [siteGateReportTypeQuery]);

  const eventObjectOptions = React.useMemo(() => {
    const gateReportObjects =
      siteGateReportObjectsQuery?.gateReportObjects || [];

    return [
      {
        value: undefined,
        label: "--",
      },
      ...gateReportObjects.map(({ name }) => ({
        label: name || "",
        value: name || undefined,
      })),
    ];
  }, [siteGateReportObjectsQuery]);

  // TIMELINE
  // const [activeTimelineItem, setActiveTimelineItem] =
  //   React.useState<GateReportItem | null>();

  // const [toggleState, setToggleState] = React.useState<ToggleState>("timeline");

  const gateReports: GateReport[] = useMemo(
    () =>
      (siteGateReportsQuery?.gateReports?.gateReports || []).map(
        (gateReport) => ({
          ...gateReport,
          parsedTimestamp:
            gateReport.eventTimestamp && site.timezone
              ? format(
                  // cameras neglect DST, so we need to compensate
                  compensateDST(
                    utcToZonedTime(
                      gateReport.eventTimestamp * 1000,
                      site.timezone,
                    ),
                  ),
                  "yyyy-MM-dd HH:mm:ss",
                )
              : null,
        }),
      ),
    [siteGateReportsQuery?.gateReports],
  );

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: Gate Report`}>
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>

      {/*{activeTimelineItem && (
        <GateReportActiveItem
          item={activeTimelineItem.gateReport}
          timezone={site.timezone}
          siteId={site.id}
        />
      )}*/}

      <Divider my={6} />
      <Grid
        container
        mb={6}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={12}>
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            alignItems={{ md: "center" }}
            spacing={2}
          >
            <Grid item md={2} xs={12}>
              <SingleSelect
                label="Camera"
                placeholder="Select Camera"
                value={selectedCameraId || ""}
                onChange={(value: string) => setSelectedCameraId(value)}
                options={cameraOptions}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <SingleSelect
                label="Event Type"
                placeholder="Select Event Type"
                value={eventType || ""}
                onChange={(value: string | undefined) =>
                  setEventType(value as InputMaybe<GateReportEventType>)
                }
                options={eventTypeOptions}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <SingleSelect
                label="Event Object"
                placeholder="Select Event Object"
                value={eventObject || ""}
                onChange={(value: string | undefined) =>
                  setEventObject(value as InputMaybe<GateReportEventObject>)
                }
                options={eventObjectOptions}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <DatetimePicker
                value={startDate}
                label="Select start date/time"
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                maxDate={siteNow}
                onChange={(value: Date | null) => {
                  if (value) {
                    setStartDate(value);
                  }
                }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <DatetimePicker
                value={endDate}
                maxDate={siteNow}
                label="Select end date/time"
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                onChange={(value: Date | null) => {
                  if (value) {
                    setEndDate(value);
                  }
                }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color="warning"
                variant="contained"
                onClick={() => {
                  setIsExportModalOpen(true);
                }}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <GateReportTable
        siteId={site.id}
        gateReports={gateReports}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
        count={siteGateReportsQuery?.gateReports?.count}
      />
      <ExportModal
        open={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        onExport={(date) => {
          void exportGateReport({
            variables: {
              filters: {
                siteId: site.id,
                date: Math.round(date.getTime() / 1000),
                cameraId: selectedCameraId,
                eventObject,
                eventType,
              },
            },
          });
        }}
      />
    </React.Fragment>
  );
};

const ExportModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onExport: (date: Date) => void;
}> = ({ open, onExport, onClose }) => {
  const [date, setDate] = useState(new Date());
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  return (
    <>
      <Dialog open={open} fullWidth maxWidth={"sm"} onClose={onClose}>
        <DialogTitle>Export Gate Report</DialogTitle>
        <DialogContent>
          You are about to export a gate report. As soon as it is generated, it
          will be sent to your email. Please pick a single date to generate the
          report. The rest of the filters will be taken from the table.
          <Box marginTop={5}>
            <DatePicker
              value={date}
              label="Select date"
              inputFormat="yyyy-MM-dd"
              mask="____-__-__"
              onChange={(value: Date | null) => {
                if (value) {
                  setDate(value);
                }
              }}
            />
          </Box>
          <Box marginTop={5} display="flex" justifyContent="flex-end" gap={5}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onExport(date);
                onClose();
                setIsConfirmOpen(true);
              }}
            >
              Export
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <ExportConfirmModal
        open={isConfirmOpen}
        onClose={() => {
          onClose();
          setIsConfirmOpen(false);
        }}
      />
    </>
  );
};

const ExportConfirmModal: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"sm"} onClose={onClose}>
      <DialogTitle>Please wait a bit...</DialogTitle>
      <DialogContent>
        Your export is in progress. It will be sent to your email soon.
        <Box marginTop={5} display="flex" justifyContent="flex-end" gap={5}>
          <Button color="primary" variant="outlined" onClick={() => onClose()}>
            OK
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
