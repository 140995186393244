import React from "react";
import { Button } from "../pages/Common";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { TimelapseSetting } from "../../graphql";

interface ModalProps {
  isOpened: boolean;
  onConfirm: (timelapse: TimelapseSetting | null) => void;
  close: () => void;
  timelapse: TimelapseSetting | null;
}

export const TimelapseDisactivationModal: React.FC<ModalProps> = (props) => {
  const modalId = React.useMemo(() => uuid(), []);
  return (
    <Dialog
      open={props.isOpened}
      onClose={props.close}
      aria-labelledby={modalId}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id={modalId}>Are You Sure?</DialogTitle>
      <DialogContent>
        Are you sure you want to deactivate this timelapse? Timelapse video
        recording will be stopped.
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.onConfirm(props.timelapse)}
        >
          Yes
        </Button>
        <Button color="primary" variant="outlined" onClick={props.close}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
