export const enumToChoices = <T extends Record<string, string>>(
  obj: T,
  options?: { reversed?: boolean },
) =>
  Object.entries(obj).map(([key, value]) => {
    return {
      label: options?.reversed ? value : key,
      value: options?.reversed ? key : value,
    };
  });
