import React from "react";
import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Tooltip as MuiTooltip,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Button as MuiButton,
  Fab as MuiFab,
  Chip as MuiChip,
  Grid,
  IconButton as MuiIconButton,
  Snackbar as MuiSnackbar,
  SnackbarOrigin,
  TooltipProps,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  AccessTime as ClockIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
import { useProject } from "../../constants";
import Clock from "react-live-clock";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

export const Divider = styled(MuiDivider)(spacing);
export const Paper = styled(MuiPaper)(spacing);
export const Card = styled(MuiCard)(spacing);
export const CardContent = styled(MuiCardContent)(spacing);
export const FormControl = styled(MuiFormControl)(spacing);
export const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
export const Alert = styled(MuiAlert)(spacing);
export const Button = styled(MuiButton)(spacing);
export const IconButton = styled(MuiIconButton)(spacing);
export const Fab = styled(MuiFab)(spacing);
export const Chip = styled(MuiChip)(spacing);

export const PageTitle: React.FC<{ text: string; paddingTop?: string }> = ({
  text,
  children,
  paddingTop,
}) => {
  const { name } = useProject();
  return (
    <>
      <Helmet>
        <title>
          {name} - {text}
        </title>
      </Helmet>
      <Grid container spacing={6} justifyContent="space-between">
        <Grid item paddingTop={paddingTop}>
          <Typography variant="h3" gutterBottom display="inline">
            {text}
          </Typography>
        </Grid>

        <Grid item paddingTop={paddingTop}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export const PlusButton: React.FC<{
  tooltip: string;
  onClick: () => void;
}> = (props) => {
  return (
    <MuiTooltip title={props.tooltip} placement="top-start">
      <Fab size="medium" color="primary" onClick={props.onClick}>
        <AddIcon />
      </Fab>
    </MuiTooltip>
  );
};

const StyledClockIcon = styled(ClockIcon)`
  width: 22px;
  height: 22px;
  margin-bottom: 3px;
  margin-right: 5px;
`;

const ClockContainer = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ZonedClock: React.FC<{ timezone: string }> = ({ timezone }) => {
  return (
    <ClockContainer variant="h3" gutterBottom display="inline">
      <StyledClockIcon style={{ marginBottom: "-1px" }} /> Site local time:
      <Clock
        format={"HH:mm"}
        ticking={true}
        timezone={timezone}
        style={{
          marginLeft: "5px",
        }}
      />
    </ClockContainer>
  );
};

export const Snackbar: React.FC<{
  anchorOrigin: SnackbarOrigin;
  iconElement: React.ReactNode;
  content: React.ReactNode;
  displayDuration: number;
}> = (props) => {
  const [open, setOpened] = React.useState(false);

  const handleClose = React.useCallback(() => {
    setOpened(false);
  }, []);

  const handlepOpen = React.useCallback(() => {
    setOpened(true);
  }, []);

  return (
    <>
      <div
        onClick={handlepOpen}
        style={{ display: "inline-block", verticalAlign: "middle" }}
      >
        {props.iconElement}
      </div>
      {ReactDOM.createPortal(
        <MuiSnackbar
          anchorOrigin={props.anchorOrigin}
          open={open}
          autoHideDuration={props.displayDuration}
          onClose={handleClose}
          message={<div style={{ paddingRight: "15px" }}>{props.content}</div>}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />,
        document.body,
      )}
    </>
  );
};

const TooltipLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

const TooltipTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;

export const Tooltip: React.FC<TooltipProps> = (props) => (
  <MuiTooltip {...props} title={<TooltipTitle>{props.title}</TooltipTitle>} />
);

export const UpgradePlanIcon: React.FC<{ iconElement: React.ReactElement }> = (
  props,
) => {
  const { contactEmail } = useProject();
  const onEmailClick = React.useCallback((e: React.SyntheticEvent) => {
    window.location.href = "mailto:" + contactEmail;
    e.preventDefault();
  }, []);

  const tooltipContent = React.useMemo(
    () => (
      <>
        Contact support at {""}
        <TooltipLink to="#" onClick={onEmailClick}>
          {contactEmail}
        </TooltipLink>
        <br /> to upgrade your plan.
      </>
    ),
    [],
  );

  if (!isMobile) {
    return (
      <Tooltip
        placement="right"
        title={tooltipContent}
        children={props.iconElement}
      />
    );
  }

  return (
    <Snackbar
      iconElement={props.iconElement}
      content={tooltipContent}
      displayDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    />
  );
};
