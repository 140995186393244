export const dateToString = (date: Date): string =>
  date.toISOString().slice(0, 10);

export const dateTo24HoursString = (date: Date): string => {
  const hour = padTo2Digits(date.getHours());
  const minute = padTo2Digits(date.getMinutes());
  return `${hour}:${minute}`;
};

const padTo2Digits = (value: number): string => {
  return value.toString().padStart(2, "0");
};
