import React from "react";
import { CircularProgress } from "@mui/material";

import styled from "@emotion/styled";

const Page = styled.div`
  width: 100%;
  height: 100%;
`;

const Loader = styled(CircularProgress)`
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
`;

export const LoaderPage: React.FC = () => {
  return (
    <Page>
      <Loader size="200px" />
    </Page>
  );
};
