import React from "react";
import { MutationResult } from "@apollo/client";
import { Alert, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  CameraConfigurationTestResultResponseType,
  TestCameraConfigurationMutation,
} from "../../../../graphql";

interface RenderConfigurationTestingResponseProps {
  testingResponse: MutationResult<TestCameraConfigurationMutation>;
}

export function RenderConfigurationTestingResponse({
  testingResponse: { data, error },
}: RenderConfigurationTestingResponseProps) {
  if (error)
    return (
      <Alert severity="error">
        Unable to send a request due to server error!
      </Alert>
    );
  else if (data) {
    if (
      data.testCameraConfiguration.timestampResponseType ===
      CameraConfigurationTestResultResponseType.Error
    ) {
      return (
        <Alert severity="error">
          {data.testCameraConfiguration.timestampResponse}
        </Alert>
      );
    } else {
      return (
        <Grid container>
          {Boolean(data.testCameraConfiguration.snapshotResponse) && (
            <Grid item mb={2} xs={12}>
              <Alert
                severity={
                  data.testCameraConfiguration.snapshotResponseType ===
                  CameraConfigurationTestResultResponseType.Error
                    ? "error"
                    : "success"
                }
              >
                {data.testCameraConfiguration.snapshotResponse}
              </Alert>
            </Grid>
          )}
          {Boolean(data.testCameraConfiguration.parsedDate) && (
            <Grid item mb={2} xs={12}>
              <Alert severity="success">
                Successfully parsed date:{" "}
                {data.testCameraConfiguration.parsedDate}
              </Alert>
            </Grid>
          )}
          {Boolean(data.testCameraConfiguration.warnings.length) &&
            data.testCameraConfiguration.warnings.map((line) => (
              <Grid item mb={2} xs={12} key={line}>
                <Alert severity="warning">{line}</Alert>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" color="text.primary" gutterBottom>
                  Response
                </Typography>
                {data.testCameraConfiguration.timestampResponse
                  .split("\n")
                  .map((line) => (
                    <Typography color="text.secondary" key={line}>
                      {line}
                    </Typography>
                  ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    }
  }

  return null;
}
