import React from "react";
import { Grid, Slider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormCard } from "../../components/FormCard";
import {
  DatetimePicker,
  SingleSelect,
  Switcher,
} from "../../components/pages/FormFields";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import {
  Alert,
  Divider,
  PageTitle,
  ZonedClock,
} from "../../components/pages/Common";

import {
  useGetSiteCamerasLazyQuery,
  Camera,
  useGetForgeAuthTokenQuery,
} from "../../graphql";
import {
  toUtcDateTimeString,
  getUtcNow,
  isToday,
  offsetDateByTz,
  roundMinutes,
} from "../../helpers/date";
import { useCurrentSite } from "./context/SiteContext";
import { dateTimeFormat } from "../../constants";
import { errorUrl } from "../../helpers/navigation";
import styled from "@emotion/styled";
import { ForgeViewer } from "../../components/ForgeViewer";
import { tokenExprationPeriod } from "../../forge-client/client";

const tokenPollingInterval = (tokenExprationPeriod - 60) * 1000;

enum PageState {
  noCameras,
  paramsNotSet,
  readyToRender,
}

export const BimViewPage: React.FC = () => {
  const site = useCurrentSite();

  const siteTz = site.timezone || "";
  const nowUtc = getUtcNow();
  const nowLocal = offsetDateByTz(nowUtc, siteTz);

  const navigate = useNavigate();
  const [getLazySiteCameras, { error }] = useGetSiteCamerasLazyQuery();
  const [cameras, setCameras] = React.useState<Camera[]>();
  const [cameraId, setCameraId] = React.useState<number>();
  const [snapshotUrl, setSnapshotUrl] = React.useState("");
  const [opacity, setOpacity] = React.useState(50);
  const [show3DModel, setShow3DModel] = React.useState<boolean>(true);
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
  const [show3DViewer, setShow3DViewer] = React.useState(false);

  const { data: tokenData } = useGetForgeAuthTokenQuery({
    pollInterval: tokenPollingInterval,
  });
  const token = tokenData?.forgeAuthToken;

  const [snapshotTime, setSnapshotTime] = React.useState<Date | null>(
    roundMinutes(nowLocal),
  );

  const getCameras = async (time?: Date) => {
    const { data } = await getLazySiteCameras({
      variables: {
        constructionSiteId: site.id,
        snapshotTime: time
          ? toUtcDateTimeString(time, siteTz, dateTimeFormat)
          : "",
      },
    });
    const cameras = data?.constructionSite.cameras || [];
    return cameras.filter((camera) => !!camera.bimFile);
  };

  const getSnapshot = async (time?: Date) => {
    const cameras = await getCameras(time);
    const camera = cameras.find((camera) => camera.id === cameraId);
    return camera?.snapshot || "";
  };

  React.useEffect(() => {
    setCameraId(undefined);
    void getCameras().then((cameras) => setCameras(cameras));
  }, [site.id]);

  const camera = React.useMemo(
    () => (cameras ? cameras.find((cam) => cam.id === cameraId) : null),
    [cameraId],
  );

  React.useEffect(() => {
    setSnapshotUrl("");
    setImageLoaded(false);
  }, [cameraId]);

  React.useEffect(() => {
    setImageLoaded(false);
    void getSnapshot(snapshotTime || undefined).then((url) =>
      setSnapshotUrl(url),
    );
  }, [cameraId, snapshotTime]);

  React.useEffect(() => {
    if (!imageLoaded || show3DViewer) return;
    setTimeout(() => setShow3DViewer(true));
  }, [imageLoaded, show3DViewer]);

  React.useEffect(() => {
    if (error) navigate(errorUrl);
  }, [error]);

  React.useEffect(() => {
    setShow3DViewer(false);
  }, [cameraId]);

  const cameraChoices = React.useMemo(() => {
    if (!cameras) return [];
    return cameras.map((camera) => ({
      value: camera.id,
      label: camera?.name || "",
    }));
  }, [cameras]);

  let pageState = PageState.readyToRender;
  if (Array.isArray(cameras) && !cameras.length) {
    pageState = PageState.noCameras;
  } else if (!camera || !snapshotTime) {
    pageState = PageState.paramsNotSet;
  }

  const { bimFile } = camera || {};

  const showOpacitySlider = [
    show3DViewer,
    show3DModel,
    pageState === PageState.readyToRender,
  ].every(Boolean);

  const render3Dmodel = [show3DViewer, show3DModel].every(Boolean);

  const snapshot = snapshotUrl ? (
    <SnapshotContainer src={snapshotUrl} onLoad={() => setImageLoaded(true)} />
  ) : null;

  const onOpacityChange = React.useCallback(
    (val: number | number[]) => setOpacity(Array.isArray(val) ? val[0] : val),
    [],
  );

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: BIM View`}>
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>
      <Divider my={6} />
      <FormCard>
        <Grid
          container
          mb={6}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={9}>
            <Grid
              container
              direction={{ xs: "column", md: "row" }}
              alignItems={{ md: "center" }}
              spacing={2}
            >
              <Grid item md={3} xs={12}>
                <SingleSelect
                  label="Camera"
                  placeholder="Select Camera"
                  value={cameraId || 0}
                  options={cameraChoices}
                  onChange={(value: number) => setCameraId(value)}
                  disabled={pageState === PageState.noCameras}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <DatetimePicker
                  value={snapshotTime}
                  label="Date"
                  inputFormat="yyyy-MM-dd HH:mm"
                  maxDate={nowLocal}
                  maxTime={
                    snapshotTime && isToday(snapshotTime, siteTz)
                      ? nowLocal
                      : undefined
                  }
                  mask="____-__-__ __:__"
                  onChange={(value: Date | null) => setSnapshotTime(value)}
                  disabled={pageState === PageState.noCameras}
                />
              </Grid>
              <Grid item xs={12} md={6} style={switcherStyle}>
                <Switcher
                  label="Show 3D model"
                  disabled={!camera || !bimFile}
                  checked={bimFile ? show3DModel : false}
                  onChange={(value: boolean) => setShow3DModel(value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {pageState === PageState.readyToRender && (
          <Grid
            container
            justifyContent="center"
            direction="column"
            spacing={4}
          >
            <Grid item style={showOpacitySlider ? {} : hiddenStyle}>
              BIM Opacity
              <Slider
                value={opacity}
                onChange={(e, val) => onOpacityChange(val)}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item>
              {!render3Dmodel || !token ? (
                snapshot
              ) : (
                <ForgeViewer
                  objectId={bimFile?.forgeObjectId || ""}
                  token={token}
                  sceneState={bimFile?.sceneState || ""}
                  opacity={opacity}
                  edit={false}
                  show={true}
                >
                  {snapshot}
                </ForgeViewer>
              )}
            </Grid>
          </Grid>
        )}

        {pageState === PageState.paramsNotSet && (
          <Alert my={2} severity="warning">
            Please select a camera and specify date and time of the frame
          </Alert>
        )}

        {pageState === PageState.noCameras && (
          <Alert my={2} severity="error">
            We're sorry, but there are no 3D files uploaded for the cameras on
            this site. Please contact admins.
          </Alert>
        )}
      </FormCard>
    </React.Fragment>
  );
};

const SnapshotContainer = styled.img`
  width: 100%;
  height: auto;
`;

const hiddenStyle: React.CSSProperties = {
  opacity: 0,
  pointerEvents: "none",
};

const switcherStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  height: "52px",
};
