import React from "react";
import {
  Button,
  Divider,
  PageTitle,
  PlusButton,
} from "../../components/pages/Common";
import { useDebounce } from "use-debounce";
import { filterObjectsByString } from "../../components/tables/filterByString";
import { dateToString } from "../../helpers/toString";
import { useNavigate } from "react-router-dom";
import { useGetCompaniesQuery, ConstructionCompany } from "../../graphql";
import { enumToChoices } from "../../helpers/enumToChoices";
import { CompanyStatusLabel } from "../../constants";
import { getAdminUrl, AdminNavigationPath } from "../../helpers/navigation";
import { SingleSelect } from "../../components/pages/FormFields";
import { PaginatedTable } from "../../components/tables/PaginatedTable";
import { HeadCell, Sorting } from "../../components/tables/types";
import { useLocalStorage } from "../../helpers/localStorage";
import { sortReducer, sortRows } from "../../components/tables/sorting";
import { TableFilterToolbar } from "../../components/tables/components/Toolbar";
import { SearchInput } from "../../components/tables/components/SearchInput";
import { NewCompanyModal } from "../../components/modals/NewCompanyModal";

const EditButton: React.FC<{ companyId: number }> = ({ companyId }) => {
  const navigate = useNavigate();

  return (
    <Button
      mr={2}
      variant="outlined"
      onClick={() =>
        navigate(getAdminUrl(AdminNavigationPath.CompanyEdit, companyId))
      }
      margin="0"
    >
      Edit
    </Button>
  );
};

type Company = { sitesN: number } & Pick<
  ConstructionCompany,
  "id" | "name" | "status" | "createdAt" | "sites"
>;

const defaultSorting: Sorting = {
  key: "createdAt",
  order: "desc",
};

const Page: React.FC = () => {
  const { data } = useGetCompaniesQuery();
  const [nameFilter, setNameFilter] = React.useState<string>("");
  const [statusFilter, setStatusFilter] = React.useState<string>("");
  const [debouncedNameFilter] = useDebounce(nameFilter, 200);
  const settingsLocalStorageKey = "admin-companies-list";
  const [initialSorting, saveSorting] = useLocalStorage<Sorting>(
    `${settingsLocalStorageKey}-sorting`,
    defaultSorting,
  );
  const [sorting, setSortingKey] = React.useReducer(
    sortReducer,
    initialSorting,
  );

  const [modalIsOpened, setModalIsOpened] = React.useState<boolean>(false);

  const companies = React.useMemo(
    () =>
      (data?.constructionCompanies || []).map((company) => ({
        ...company,
        sitesN: (company.activeSites || []).length,
      })),
    [data?.constructionCompanies],
  );

  const filteredRows = React.useMemo(() => {
    const filteredByName = filterObjectsByString(
      companies,
      "name",
      debouncedNameFilter,
    );
    return filterObjectsByString(filteredByName, "status", statusFilter, {
      exact: true,
    });
  }, [debouncedNameFilter, statusFilter, companies]);

  const sortedRows = React.useMemo(() => {
    return sortRows(filteredRows, sorting);
  }, [sorting, filteredRows]);

  React.useEffect(() => saveSorting(sorting), [sorting]);

  const headCells: Array<HeadCell<Company>> = [
    {
      key: "name",
      label: "Company",
      alignment: "left",
      width: "30%",
      handleSort: setSortingKey,
      render: (company) => company.name,
    },
    {
      key: "sitesN",
      label: "Active Sites",
      alignment: "center",
      width: "25%",
      handleSort: setSortingKey,
      render: (company) => company.sitesN,
    },
    {
      key: "status",
      label: "Status",
      alignment: "center",
      width: "15%",
      handleSort: setSortingKey,
      render: (company) => CompanyStatusLabel[company.status],
    },
    {
      key: "createdAt",
      label: "Creation Date",
      alignment: "center",
      width: "15%",
      handleSort: setSortingKey,
      render: (company) => dateToString(new Date(company.createdAt)),
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "15%",
      render: (company) => <EditButton companyId={company.id} />,
    },
  ];

  const filters = [
    <SingleSelect
      label="Status"
      placeholder="Select status"
      value={statusFilter}
      allowEmpty={true}
      options={enumToChoices(CompanyStatusLabel, { reversed: true })}
      onChange={setStatusFilter}
    />,
  ];

  const search = (
    <SearchInput
      placeholder="Search by company names"
      onChange={setNameFilter}
    />
  );
  const toolbar = <TableFilterToolbar search={search} filters={filters} />;

  return (
    <>
      <PageTitle text="Construction Companies Management">
        <NewCompanyModal
          isOpened={modalIsOpened}
          close={() => setModalIsOpened(false)}
        />
        <PlusButton
          onClick={() => setModalIsOpened(true)}
          tooltip="Add new construction company"
        />
      </PageTitle>
      <Divider my={6} />
      <PaginatedTable
        headCells={headCells}
        rows={sortedRows}
        sorting={sorting}
        settingsLocalStorageKey={settingsLocalStorageKey}
        toolbar={toolbar}
      />
    </>
  );
};

export const CompaniesListAdmin = React.memo(Page);
