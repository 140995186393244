import React from "react";
import styled from "@emotion/styled";

import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";

import { InputBase, Grid } from "@mui/material";

const SearchContainer = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: block;
  position: relative;
  width: 100%;
  min-width: 220px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;

const SearchIconWrapper = styled.div`
  color: ${(props) => props.theme.header.color};
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
  }
`;

export interface SearchProps {
  placeholder: string;
  onChange: (value: string) => void;
}

export const SearchInput: React.FC<SearchProps> = ({
  placeholder,
  onChange,
}) => {
  return (
    <Grid item>
      <SearchContainer>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Input
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
        />
      </SearchContainer>
    </Grid>
  );
};
