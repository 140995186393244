import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

import "./App.css";
import "./i18n";
import "./vendor/perfect-scrollbar.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { createEmotionCache } from "./utils/createEmotionCache";

import { CacheProvider } from "@emotion/react";

import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo-client";
import { getProjectRoutes } from "./ProjectRoutes";
import { ThemeContextProvider, ThemeProvider } from "./theme/context";
import { HelmetProvider } from "react-helmet-async";

const clientSideEmotionCache = createEmotionCache();

const App = () => {
  const projectRoutes = React.useMemo(getProjectRoutes, []);

  return (
    <div className="App">
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <ApolloProvider client={apolloClient}>
          <CacheProvider value={clientSideEmotionCache}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider>
                <ThemeContextProvider>
                  <HelmetProvider>{projectRoutes}</HelmetProvider>
                </ThemeContextProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </CacheProvider>
        </ApolloProvider>
      </Auth0Provider>
    </div>
  );
};

export default App;
