import React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  defaultHelperText,
  timelapseInputParams,
} from "../pages/inputConstants";
import {
  SingleSelect,
  MultipleSelect,
  TextInput,
  TimePicker,
  frequencyChoicesSortFn,
} from "../pages/FormFields";
import { useFormik } from "formik";

import * as Yup from "yup";
import { TimelapseFrequencyLabel, WorkingDayLabel } from "../../constants";
import { Button } from "../pages/Common";
import {
  MutationCreateTimelapseSettingArgs,
  Camera,
  TimelapseSetting,
  MutationUpdateTimelapseSettingArgs,
  Frequency,
} from "../../graphql";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { dateTo24HoursString } from "../../helpers/toString";
import { frequencyChoices } from "../../helpers/getChoices";
import { getDateFromWorkingHours } from "../../helpers/date";

interface ModalProps {
  siteId: number;
  timelapse: TimelapseSetting | null | undefined;
  save: (value: MutationCreateTimelapseSettingArgs) => void;
  update: (value: MutationUpdateTimelapseSettingArgs) => void;
  close: () => void;
  isOpened: boolean;
  cameras: Omit<Camera, "configuration">[];
}

const TimelapseValidationSchema = Yup.object().shape({
  name: Yup.string().required(defaultHelperText.required),
  camera: Yup.string().required(),
  frameFrequency: Yup.string().required(),
  workingDays: Yup.array().min(1, defaultHelperText.required),
  workingHoursStart: Yup.date().required(),
  workingHoursEnd: Yup.date().required(),
});

export const AddEditTimelapseModal: React.FC<ModalProps> = (props) => {
  const modalId = React.useMemo(() => uuid(), []);

  const initialValues = props.timelapse
    ? {
        name: props.timelapse.name,
        frameFrequency: props.timelapse.frameFrequency,
        workingDays: props.timelapse.workingDays || [],
        workingHoursStart: getDateFromWorkingHours(
          props.timelapse.workingHoursStart,
          "10",
        ),
        workingHoursEnd: getDateFromWorkingHours(
          props.timelapse.workingHoursEnd,
          "19",
        ),
        camera: props.timelapse.cameraId,
      }
    : {
        name: "",
        camera: props.cameras[0].id,
        frameFrequency: Object.keys(TimelapseFrequencyLabel)[0],
        workingDays: [],
        workingHoursStart: new Date(0, 0, 0, 10, 0, 0),
        workingHoursEnd: new Date(0, 0, 0, 19, 0, 0),
      };

  const formik = useFormik({
    initialValues,
    validationSchema: TimelapseValidationSchema,
    validateOnBlur: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  React.useEffect(
    () => formik.resetForm({ values: initialValues }),
    [props.isOpened],
  );

  const save = () => {
    if (props.timelapse) {
      const timelapse = {
        id: props.timelapse.id,
        name: formik.values.name,
        frameFrequency: formik.values.frameFrequency as Frequency,
        workingDays: formik.values.workingDays,
        workingHoursStart: dateTo24HoursString(formik.values.workingHoursStart),
        workingHoursEnd: dateTo24HoursString(formik.values.workingHoursEnd),
      };
      return props.update(timelapse);
    }
    const timelapse = {
      name: formik.values.name,
      cameraId: formik.values.camera,
      siteId: props.siteId,
      frameFrequency: formik.values.frameFrequency,
      workingDays: formik.values.workingDays,
      workingHoursStart: dateTo24HoursString(formik.values.workingHoursStart),
      workingHoursEnd: dateTo24HoursString(formik.values.workingHoursEnd),
    };
    props.save(timelapse as MutationCreateTimelapseSettingArgs);
  };

  const cameraChoices = props.cameras.map((camera) => {
    return {
      label: camera.name,
      value: camera.id,
    };
  });

  const cameraSelectProps = React.useMemo(() => {
    return {
      label: "Camera",
      placeholder: "Select Camera",
      initialValue: formik.values.camera,
      options: cameraChoices,
      onChange: (value: number) => formik.setFieldValue("camera", value),
    };
  }, []);

  const frequencySelectProps = React.useMemo(() => {
    return {
      label: "Frame Frequency",
      placeholder: "Select Frequency",
      initialValue: formik.values.frameFrequency,
      options: frequencyChoices,
      choicesSortFn: frequencyChoicesSortFn,
      onChange: (value: string) =>
        formik.setFieldValue("frameFrequency", value),
    };
  }, []);

  const daysSelectProps = React.useMemo(() => {
    return {
      label: "Working Days",
      placeholder: "Select Working Days",
      name: "workingDays",
      options: Object.entries(WorkingDayLabel).map(([value, label]) => {
        return { value, label };
      }),
      onChange: (value: string[]) => formik.setFieldValue("workingDays", value),
    };
  }, []);

  const workingFromPickerProps = React.useMemo(() => {
    return {
      label: "Working hours, from",
      onChange: (value: Date | null) =>
        formik.setFieldValue("workingHoursStart", value),
    };
  }, []);

  const workingToPickerProps = React.useMemo(() => {
    return {
      label: "Working hours, to",
      onChange: (value: Date | null) =>
        formik.setFieldValue("workingHoursEnd", value),
    };
  }, []);

  return (
    <Dialog
      open={props.isOpened}
      onClose={props.close}
      aria-labelledby={modalId}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id={modalId}>
        {props.timelapse ? "Edit Timelapse" : "Add Timelapse"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            mt={6}
            columnSpacing={2}
            justifyContent="space-between"
          >
            <Grid item mb={2} xs={12}>
              <TextInput
                name="name"
                value={formik.values.name}
                {...timelapseInputParams.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.name}
                showError={!!formik.errors.name && formik.touched.name}
              />
            </Grid>

            {!props.timelapse && (
              <Grid item mb={2} xs={12}>
                <SingleSelect
                  {...cameraSelectProps}
                  value={formik.values.camera}
                />
              </Grid>
            )}

            <Grid item mb={2} xs={12}>
              <SingleSelect
                {...frequencySelectProps}
                value={formik.values.frameFrequency}
              />
            </Grid>

            <Grid item mb={2} xs={12}>
              <MultipleSelect
                {...daysSelectProps}
                value={formik.values.workingDays}
                errors={
                  formik.touched.workingDays ? formik.errors.workingDays : []
                }
                onBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item mb={2} xs={6}>
              <TimePicker
                value={formik.values.workingHoursStart}
                {...workingFromPickerProps}
              />
            </Grid>

            <Grid item mb={2} xs={6}>
              <TimePicker
                value={formik.values.workingHoursEnd}
                {...workingToPickerProps}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!isEmpty(formik.errors) || !formik.dirty}
          onClick={save}
        >
          Save
        </Button>
        <Button color="primary" variant="outlined" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
