import React from "react";
import { Grid } from "@mui/material";
import { SingleSelect } from "../../components/pages/FormFields";
import {
  Alert,
  Divider,
  PageTitle,
  ZonedClock,
} from "../../components/pages/Common";
import {
  useGetSiteTimelapsesQuery,
  useGetTimelapsePlaylistLazyQuery,
  useRequestTimelapseDownloadMutation,
} from "../../graphql/generated";
import ReactPlayer from "react-player";
import { useCurrentSite } from "./context/SiteContext";
import { FormCard } from "../../components/FormCard";
import Hls from "hls.js";
import DownloadTimelapseWidget from "../../components/modals/DownloadTimelapseWidget";

export const TimelapsePage: React.FC = () => {
  const site = useCurrentSite();
  const [reload, setReload] = React.useState(false);
  const [selectedTimelapse, setSelectedTimelapse] = React.useState<number>();
  const [playlist, setPlaylist] = React.useState<string>();
  const [getPlaylist, { error }] = useGetTimelapsePlaylistLazyQuery();
  const [requestTimelapseDownload] = useRequestTimelapseDownloadMutation();

  React.useEffect(() => {
    if (error) setPlaylist(undefined);
  }, [error]);

  React.useEffect(() => {
    setSelectedTimelapse(undefined);
  }, [site.id]);

  const forceHLS = React.useMemo(() => Hls.isSupported(), []);

  const { data: timelapse } = useGetSiteTimelapsesQuery({
    variables: { constructionSiteId: site.id },
  });

  const timelapses = timelapse?.constructionSite.timelapse || [];

  const isActive = !!selectedTimelapse && !reload;

  const options = timelapses.map((timelapse) => {
    return {
      value: timelapse.id,
      label: timelapse.name || "",
    };
  });

  const handleChange = (value: number | string) => {
    if (typeof value === "string") {
      setSelectedTimelapse(undefined);
      return;
    }

    setTimeout(() => {
      setReload(true);
      setSelectedTimelapse(value);

      void getPlaylist({
        variables: {
          timelapseId: value,
        },
      }).then((result) => {
        setPlaylist(result?.data?.getTimelapsePlaylist || "");
      });
      setReload(false);
    });
  };

  const handleDownloadModalSubmit = React.useCallback(
    function handleDownloadModalSubmit({
      dateFrom,
      dateTo,
    }: {
      dateFrom: Date | null;
      dateTo: Date | null;
    }) {
      if (selectedTimelapse)
        void requestTimelapseDownload({
          variables: {
            id: selectedTimelapse,
            dateFrom: dateFrom?.toISOString(),
            dateTo: dateTo?.toISOString(),
          },
        });
    },
    [selectedTimelapse],
  );

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: Timelapse`}>
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>
      <Divider my={6} />
      <FormCard>
        <Grid
          container
          justifyContent="left"
          rowSpacing={2}
          columnSpacing={2}
          mb={5}
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            <SingleSelect
              label="Timelapse"
              placeholder="Select Timelapse"
              value={selectedTimelapse || ""}
              allowEmpty={true}
              options={options}
              onChange={(value: number | string) => handleChange(value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={8}>
            <DownloadTimelapseWidget
              siteName={site.name}
              disabled={!isActive}
              onSubmit={handleDownloadModalSubmit}
            />
          </Grid>
        </Grid>
        {isActive && (
          <Grid
            item
            style={{
              maxHeight: "70vh",
              width: "auto",
              height: "100%",
              display: "inline-block",
            }}
          >
            <ReactPlayer
              width="100%"
              height="70vh"
              controls={true}
              config={{
                file: {
                  forceHLS,
                  hlsVersion: "1.2.0",
                  hlsOptions: {
                    enableWorker: true,
                    lowLatencyMode: true,
                  },
                  attributes: {
                    controlsList: "nofullscreen",
                  },
                },
              }}
              muted={true}
              playing={true}
              playsinline={true}
              url={playlist}
            />
          </Grid>
        )}
        {!isActive && (
          <Alert my={2} severity="warning">
            Please select a Timelapse to see the video.
          </Alert>
        )}
      </FormCard>
    </React.Fragment>
  );
};
