import React from "react";

export enum ImageStatus {
  success = "success",
  loading = "loading",
  error = "error",
}

const defaultState: {
  src: undefined | string;
  status: ImageStatus;
} = { src: undefined, status: ImageStatus.loading };

export function useImage(url: string) {
  const [image, setImage] = React.useState<typeof defaultState>(defaultState);

  React.useEffect(() => {
    setImage(defaultState);
    if (!url) return;
    const img = document.createElement("img");

    const onLoad = () =>
      setImage({ src: img.src, status: ImageStatus.success });
    const onError = () =>
      setImage({ src: undefined, status: ImageStatus.error });

    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    img.src = url;

    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
      setImage(defaultState);
    };
  }, [url]);

  return {
    ...image,
  };
}
