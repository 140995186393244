import React from "react";
import styled from "@emotion/styled";
import { CompanyStatus, ConstructionSiteStatus } from "@ocilex/api";
import { orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../auth/hooks";
import { UserMeQuery } from "../graphql";
import {
  getAdminUrl,
  AdminNavigationPath,
  getSiteUrl,
  SiteNavigationPath,
} from "../helpers/navigation";
import { isCompanyAdmin } from "../helpers/permissions";
import { ErrorPage } from "./ErrorPage";
import { FindInPage } from "@mui/icons-material";

export const IndexPage: React.FC = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const [navigateTo, setNavigateTo] = React.useState<string | null>();

  React.useEffect(() => {
    if (!navigateTo) return;
    navigate(navigateTo);
  }, [navigateTo]);

  React.useEffect(() => {
    if (!user) return;
    setNavigateTo(getRedirectUrl(user));
  }, [user]);

  if (navigateTo === null)
    return (
      <ErrorPage title={<FindIcon color="primary" />} details={description} />
    );

  return null;
};

const description = (
  <>
    <div>
      We're sorry, but you don't have access to any active construction sites.
    </div>
    <div>Probably, your sites weren't set up yet or have been deactivated.</div>
  </>
);

const getRedirectUrl = (user: UserMeQuery["me"]): string | null => {
  const isAdmin = user.isAdmin;
  const isCompAdmin = isCompanyAdmin(user);

  if (isAdmin) return getAdminUrl(AdminNavigationPath.CompanyList);

  if (isCompAdmin) return getAdminUrl(AdminNavigationPath.SiteList);

  const { siteId } = getActiveSite(user) || {};

  if (!siteId) return null;

  return getSiteUrl(SiteNavigationPath.LiveViewList, siteId);
};

const getActiveSite = (user: UserMeQuery["me"]) => {
  const sites = orderBy(user?.sites || [], [
    (row) => row.site.company?.name.toLowerCase(),
    (row) => row.site.name.toLowerCase(),
  ]);

  return sites.find(
    ({ site }) =>
      (site.status as string) === ConstructionSiteStatus.Active &&
      (site.company?.status as string) === CompanyStatus.Active,
  );
};

const FindIcon = styled(FindInPage)`
  width: 100px;
  height: 100px;
`;
