import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Divider,
  PageTitle,
  PlusButton,
} from "../../../components/pages/Common";
import { HeadCell } from "../../../components/tables/types";
import { dateToString } from "../../../helpers/toString";
import {
  CameraConfiguration,
  useDeleteCameraConfigurationMutation,
  useGetCameraConfigurationsQuery,
} from "../../../graphql";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import AddEditCameraConfigurationModal from "../../../components/modals/AddEditCameraConfigurationModal";
import { useDebounce } from "use-debounce";
import { SearchInput } from "../../../components/tables/components/SearchInput";
import { TableFilterToolbar } from "../../../components/tables/components/Toolbar";
import { Grid, Tooltip } from "@mui/material";
import { pluralize } from "../../../utils/spelling";
import { ConfirmationModal } from "../../../components/modals/ConfirmationModal";

const SETTINGS_LOCAL_STORAGE_KEY = "CAMERA_CONFIGURATION_LIST_SETTINGS";

export default function CameraConfigurationList() {
  const [isCreateUpdateModalOpened, setCreateUpdateModalStatus] =
    useState(false);
  const [updatedConfigurationId, setUpdatedConfigurationId] = useState<
    number | undefined
  >();
  const [
    isDeletionConfirmationModalOpened,
    setDeletionConfirmationModalStatus,
  ] = useState(false);
  const [deletedConfigurationData, setDeletedConfigurationData] = useState<
    { id: number; name: string } | undefined
  >();
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 250);
  const { data: cameraConfigurations, refetch } =
    useGetCameraConfigurationsQuery({
      variables: {
        search: debouncedSearch,
      },
    });
  const [deleteCameraConfiguration] = useDeleteCameraConfigurationMutation();

  const handleCreateModalOpen = useCallback(
    function handleCreateModalOpen() {
      setCreateUpdateModalStatus(true);
    },
    [setCreateUpdateModalStatus],
  );

  const handleCreateUpdateModalClose = useCallback(
    function handleCreateUpdateModalClose(isUpdated) {
      setUpdatedConfigurationId(undefined);
      setCreateUpdateModalStatus(false);
      if (isUpdated) void refetch();
    },
    [setCreateUpdateModalStatus, setUpdatedConfigurationId, refetch],
  );

  const handleDeletionConfirmationModalClose = useCallback(
    function handleDeletionConfirmationModalClose() {
      setDeletedConfigurationData(undefined);
      setDeletionConfirmationModalStatus(false);
    },
    [setDeletedConfigurationData, setDeletionConfirmationModalStatus],
  );

  const handleConfigurationDeletion = useCallback(
    function handleConfigurationDeletion() {
      if (deletedConfigurationData)
        deleteCameraConfiguration({
          variables: {
            id: deletedConfigurationData.id,
          },
        })
          .then(() => refetch())
          // eslint-disable-next-line no-console
          .catch((error) => console.error(error))
          .finally(handleDeletionConfirmationModalClose);
    },
    [deletedConfigurationData, handleDeletionConfirmationModalClose],
  );

  const tableHeader: HeadCell<
    Pick<
      CameraConfiguration,
      | "id"
      | "name"
      | "timeFormat"
      | "statusUri"
      | "snapshotUri"
      | "createdAt"
      | "updatedAt"
      | "assignedCamerasCount"
    >
  >[] = useMemo(function generateTableHeader() {
    return [
      {
        key: "id",
        label: "Id",
        alignment: "left",
        width: "10px",
        render: (configuration) => configuration.id,
      },
      {
        key: "name",
        label: "Configuration name",
        alignment: "left",
        width: "150px",
        render: (configuration) => configuration.name,
      },
      {
        key: "timeFormat",
        label: "Time Format",
        alignment: "center",
        width: "100px",
        render: (configuration) => configuration.timeFormat,
      },
      {
        key: "statusUri",
        label: "Timestamp URI",
        alignment: "center",
        width: "30%",
        render: (configuration) => configuration.statusUri,
      },
      {
        key: "snapshotUri",
        label: "Snapshot URI",
        alignment: "center",
        width: "30%",
        render: (configuration) => configuration.snapshotUri,
      },
      {
        key: "createdAt",
        label: "Creation Date",
        alignment: "center",
        width: "110px",
        render: (configuration) =>
          dateToString(new Date(configuration.createdAt)),
      },
      {
        key: "updatedAt",
        label: "Last Update Date",
        alignment: "center",
        width: "110px",
        render: (configuration) =>
          dateToString(new Date(configuration.updatedAt)),
      },
      {
        key: "actions",
        label: "Actions",
        alignment: "right",
        width: "100px",
        render: (configuration) => (
          <Grid
            container
            mt={6}
            columnSpacing={2}
            justifyContent="space-between"
          >
            <Grid item mb={2} xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  setUpdatedConfigurationId(configuration.id);
                  setCreateUpdateModalStatus(true);
                }}
                margin="0"
              >
                Edit
              </Button>
            </Grid>
            <Grid item mb={2} xs={12}>
              <Tooltip
                title={
                  configuration.assignedCamerasCount &&
                  configuration.assignedCamerasCount > 0
                    ? `Unable to delete this configuration. It is used by ${
                        configuration.assignedCamerasCount
                      } ${pluralize(
                        configuration.assignedCamerasCount,
                        "camera",
                      )}.`
                    : "Delete this configuration"
                }
                placement="left"
              >
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={Boolean(
                      configuration.assignedCamerasCount &&
                        configuration.assignedCamerasCount > 0,
                    )}
                    onClick={() => {
                      setDeletedConfigurationData({
                        id: configuration.id,
                        name: configuration.name,
                      });
                      setDeletionConfirmationModalStatus(true);
                    }}
                    margin="0"
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
    ];
  }, []);

  const searchInput = (
    <SearchInput placeholder="Search by name" onChange={setSearch} />
  );
  const toolbar = <TableFilterToolbar search={searchInput} filters={[]} />;

  return (
    <>
      <AddEditCameraConfigurationModal
        id={updatedConfigurationId}
        isOpened={isCreateUpdateModalOpened}
        onClose={handleCreateUpdateModalClose}
      />
      <ConfirmationModal
        isOpened={isDeletionConfirmationModalOpened}
        onConfirm={handleConfigurationDeletion}
        close={handleDeletionConfirmationModalClose}
      >
        Are you sure you want to delete camera configuration
        {deletedConfigurationData?.name
          ? ` "${deletedConfigurationData.name}"`
          : ""}
        ?
      </ConfirmationModal>
      <PageTitle text="Camera Configurations">
        <PlusButton
          onClick={handleCreateModalOpen}
          tooltip="Add camera configuration"
        />
      </PageTitle>
      <Divider my={6} />
      <PaginatedTable
        headCells={tableHeader}
        rows={
          (cameraConfigurations?.getCameraConfigurations as CameraConfiguration[]) ??
          []
        }
        settingsLocalStorageKey={SETTINGS_LOCAL_STORAGE_KEY}
        toolbar={toolbar}
      />
    </>
  );
}
