import React from "react";
import styled from "@emotion/styled";

import { defaultMaxLeght } from "./inputConstants";
import { TextField, FormControl } from "./Common";
import FormHelperText from "@mui/material/FormHelperText";
import {
  MenuItem,
  Select,
  InputLabel,
  Autocomplete as MuiAutocomplete,
  ListItem,
  Switch,
  FormControlLabel,
  Grid,
  Typography,
  Button as MuiButton,
  Tooltip,
} from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input";
import { SelectChangeEvent } from "@mui/material/Select";
import { v4 as uuid } from "uuid";
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
  DateTimePicker as MuiDatetimePicker,
  TimeView,
} from "@mui/x-date-pickers";
import { UserRoleLabel } from "../../constants";
import { frequencyChoices } from "../../helpers/getChoices";
import { noop } from "lodash";

type ChoiceObject<T> = { label: string; value: T };
type ChoicesSortFn<T = unknown> = (
  a: ChoiceObject<T>,
  b: ChoiceObject<T>,
) => number;

const choicesAlphabeticSortFn: ChoicesSortFn = (
  { label: label1 },
  { label: label2 },
) => label1.localeCompare(label2);

const getConstantChoicesSortFn = (
  orderedConstant: Record<string, string> | ChoiceObject<unknown>[],
): ChoicesSortFn => {
  let order: string[];

  if (Array.isArray(orderedConstant)) {
    order = orderedConstant.map(({ label }) => label);
  } else {
    order = Object.values(orderedConstant);
  }

  return ({ label: label1 }, { label: label2 }) => {
    const a = order.indexOf(label1) || 0;
    const b = order.indexOf(label2) || 0;
    return a - b;
  };
};

export const roleChoicesSortFn: ChoicesSortFn =
  getConstantChoicesSortFn(UserRoleLabel);

export const frequencyChoicesSortFn: ChoicesSortFn =
  getConstantChoicesSortFn(frequencyChoices);

export type TextInputProps = {
  label: string;
  name?: string;
  margin?: "dense" | "normal" | "none";
  helperText?: string;
  placeholder?: string;
  maxLength?: number;
  value?: string | null;
  showError?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  type?: string;
  onChange?: StandardInputProps["onChange"];
  onBlur?: StandardInputProps["onBlur"];
};

export const TextInput: React.FC<TextInputProps> = ({
  value,
  label,
  maxLength,
  helperText,
  showError,
  placeholder,
  autoComplete,
  ...rest
}) => {
  const propsWithDefaults = {
    value: value || "",
    placeholder: placeholder || `Enter ${label}`,
    maxLength: maxLength || defaultMaxLeght,
    helperText: showError && (helperText || "Is not valid"),
    autoComplete: autoComplete || "off",
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      label={label}
      error={showError}
      {...rest}
      {...propsWithDefaults}
      InputProps={{
        inputProps: { maxLength },
      }}
    />
  );
};

export const FormFieldControl = styled(FormControl)<{
  minwidth?: string;
}>`
  width: 100%;
  min-width: ${(props) => props.minwidth || "100px"};
`;

export interface SingleSelectProps<T> {
  label: string;
  name?: string;
  placeholder?: string;
  placeholderValue?: string;
  options: { value: T; label: string }[];
  value: T;
  allowEmpty?: boolean;
  minWidth?: string;
  disabled?: boolean;
  choicesSortFn?: ChoicesSortFn;
  onChange?: (value: T) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
}
export function SingleSelect<T extends string | number | undefined>(
  props: SingleSelectProps<T>,
) {
  const fieldId = React.useMemo(uuid, []);
  const { choicesSortFn = choicesAlphabeticSortFn } = props;

  const handleChange = (event: SelectChangeEvent<T>) => {
    if (!props.onChange) return;
    props.onChange(event.target.value as T);
  };

  const choices: React.ReactNode[] = React.useMemo(
    () =>
      [...props.options].sort(choicesSortFn).map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      )),
    [props.options],
  );
  return (
    <FormFieldControl minwidth={props.minWidth}>
      <InputLabel id={fieldId}>{props.label}</InputLabel>
      <Select<T>
        name={props.name}
        labelId={fieldId}
        value={props.value}
        onChange={handleChange}
        onBlur={props.onBlur}
        label={props.label}
        disabled={props.disabled}
        fullWidth
      >
        <MenuItem value={props.placeholderValue || ""} disabled>
          {props.placeholder}
        </MenuItem>
        {props.allowEmpty && (
          <MenuItem value="">
            <em>---</em>
          </MenuItem>
        )}
        {choices}
      </Select>
    </FormFieldControl>
  );
}

export interface AutocompleteOption<T> {
  value: T;
  label: string;
}

export interface AutocompleteProps<T> {
  label: string;
  name?: string;
  placeholder: string;
  placeholderValue?: string;
  options: AutocompleteOption<T>[];
  value: T | null;
  allowEmpty?: boolean;
  error?: string;
  onChange: (value?: T) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
}

export function Autocomplete<T extends string | number>(
  props: AutocompleteProps<T>,
) {
  const handleChange = (
    event: React.SyntheticEvent,
    option: AutocompleteOption<T> | null,
  ) => {
    if (!!option || props.allowEmpty) props.onChange(option?.value);
  };

  const options: AutocompleteOption<T>[] = React.useMemo(
    () =>
      props.options
        .sort(choicesAlphabeticSortFn)
        .map(({ value, label }) => ({ value, label })),
    [props.options],
  );

  return (
    <MuiAutocomplete<AutocompleteOption<T>, undefined, boolean>
      renderInput={(params) => (
        <FormControl style={{ width: "100%" }} error={!!props.error}>
          <TextField
            {...params}
            label={props.label}
            placeholder={props.placeholder}
            error={!!props.error}
          />
          {props.error && <FormHelperText>{props.error}</FormHelperText>}
        </FormControl>
      )}
      renderOption={(props, option, state) => (
        <ListItem
          {...props}
          key={option.value}
          value={option.value}
          selected={state.selected}
        >
          {option.label}
        </ListItem>
      )}
      options={options}
      value={options.find((option) => option.value === props.value)}
      onChange={handleChange}
      onBlur={props.onBlur}
      disableClearable={!props.allowEmpty}
      fullWidth
      role="listbox"
      aria-label={props.label}
    />
  );
}

export interface MultipleSelectProps {
  name: string;
  label: string;
  placeholder: string;
  options: { value: string; label: string }[];
  value: string[];
  errors?: string | string[] | null;
  minWidth?: string;
  onBlur?: (event: React.SyntheticEvent) => void;
  onChange: (value: string[]) => void;
}

export const MultipleSelect: React.FC<MultipleSelectProps> = (props) => {
  const fieldId = React.useMemo(uuid, []);
  const errors = props.errors || [];
  const errorsArray = Array.isArray(errors) ? errors : [errors];

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = Array.isArray(event.target.value)
      ? event.target.value
      : [event.target.value];
    props.onChange(value);
  };

  const choices: React.ReactNode[] = React.useMemo(
    () =>
      props.options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      )),
    [],
  );

  return (
    <FormFieldControl error={errorsArray.length > 0} minwidth={props.minWidth}>
      <InputLabel id={fieldId}>{props.label}</InputLabel>
      <Select
        labelId={fieldId}
        name={props.name}
        value={props.value}
        onChange={handleChange}
        label={props.label}
        onBlur={props.onBlur}
        fullWidth
        multiple
      >
        <MenuItem value={[]} disabled>
          {props.placeholder}
        </MenuItem>
        {choices}
      </Select>
      {errorsArray.length > 0 ? (
        <FormHelperText>{errorsArray[0]}</FormHelperText>
      ) : null}
    </FormFieldControl>
  );
};

export const DatePicker: React.FC<{
  label: string;
  value: Date | null;
  inputFormat?: string;
  maxDate?: Date;
  mask?: string;
  onChange: (value: Date | null) => void;
}> = ({ onChange, ...rest }) => {
  const [hasErrors, setHasErrors] = React.useState(false);

  const onAccept = (date: Date | null) => {
    const isValid = !!date && !isNaN(date.getTime());
    onChange(isValid ? date : null);
    setHasErrors(!isValid);
  };

  return (
    <FormFieldControl>
      <MuiDatePicker
        {...rest}
        slotProps={{ textField: { error: hasErrors } }}
        onChange={() => {
          null;
        }}
        onAccept={onAccept}
      />
    </FormFieldControl>
  );
};

export const TimePicker: React.FC<{
  label: string;
  value: Date | null;
  maxTime?: Date;
  views?: TimeView[];
  inputFormat?: string;
  mask?: string;
  disabled?: boolean;
  onChange: (value: Date | null) => void;
}> = ({ onChange, ...rest }) => {
  const [hasErrors, setHasErrors] = React.useState(false);

  const onAccept = (date: Date | null) => {
    const isValid = !!date && !isNaN(date.getTime());
    onChange(isValid ? date : null);
    setHasErrors(!isValid);
  };

  return (
    <FormFieldControl>
      <MuiTimePicker
        {...rest}
        ampm={false}
        slotProps={{ textField: { error: hasErrors } }}
        onChange={() => {
          null;
        }}
        onAccept={onAccept}
      />
    </FormFieldControl>
  );
};

export const DatetimePicker: React.FC<{
  label: string;
  value: Date | null;
  inputFormat?: string;
  mask?: string;
  disabled?: boolean;
  maxTime?: Date;
  maxDate?: Date;
  onChange: (value: Date | null) => void;
  onOpen?: () => void;
  onClose?: () => void;
}> = ({ onChange, ...rest }) => {
  const [hasErrors, setHasErrors] = React.useState(false);

  const onAccept = (date: Date | null) => {
    const isValid = !!date && !isNaN(date.getTime());
    onChange(isValid ? date : null);
    setHasErrors(!isValid);
  };

  return (
    <FormFieldControl>
      <MuiDatetimePicker
        {...rest}
        ampm={false}
        slotProps={{ textField: { error: hasErrors } }}
        onChange={noop}
        onAccept={onAccept}
      />
    </FormFieldControl>
  );
};

interface SwitcherProps {
  label?: string;
  checked: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  name?: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const Switcher: React.FC<SwitcherProps> = (props) => {
  return (
    <FormControlLabel
      label={props.label}
      labelPlacement={props.labelPlacement || "start"}
      control={
        <Switch
          checked={props.checked}
          onChange={(event, checked) => props.onChange(checked)}
          name={props.name}
          disabled={props.disabled}
          size="medium"
        />
      }
    />
  );
};

interface TextAreaProps {
  label: string;
  name?: string;
  value?: string;
  placeholder?: string;
  rows: number;
  maxLength?: number;
  onChange?: (event: React.SyntheticEvent) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  value = "",
  placeholder = `Enter ${label}`,
  maxLength = defaultMaxLeght,
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      label={label}
      value={value}
      placeholder={placeholder}
      variant="outlined"
      multiline
      fullWidth
      InputProps={{
        inputProps: { maxLength },
      }}
    />
  );
};

interface FileInputProps {
  label: string;
  onChange: (files: FileList | null) => void;
  name?: string;
  fileName?: string;
  error?: string;
  disabled?: boolean;
  accept?: string;
}

const FileNameContainer = styled(Typography)`
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileInput = React.forwardRef(
  (props: FileInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const onChange = (event: React.SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      props.onChange(target.files);
    };

    return (
      <Grid container spacing={4} justifyContent="flex-start">
        <Grid item>
          <FormControl error={!!props.error}>
            <MuiButton
              variant="contained"
              component="label"
              disabled={props.disabled}
            >
              {props.label}
              <input
                type="file"
                name={props.name}
                onChange={onChange}
                accept={props.accept}
                hidden
                ref={ref}
              />
            </MuiButton>
            {!!props.error && <FormHelperText>{props.error}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item>
          {!!props.fileName && (
            <Tooltip title={props.fileName} placement="top-start">
              <FileNameContainer mt={2}>{props.fileName}</FileNameContainer>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  },
);
