import { useParams, useNavigate, Link } from "react-router-dom";
import { GateReport, useGetGateReportQuery } from "../../../graphql";
import { useCurrentSite } from "../context/SiteContext";
import { GateReportActiveItem } from "./GateReportActiveItem";
import { getSiteUrl, SiteNavigationPath } from "../../../helpers/navigation";

// TODO: Refactor, I had not much time to write this

export const GateReportActiveItemPage = () => {
  const navigate = useNavigate();
  const currentSite = useCurrentSite();
  const { gateReportId } = useParams();
  if (!gateReportId) {
    navigate(-1);
    return <div />;
  }
  const { data } = useGetGateReportQuery({
    variables: { id: parseInt(gateReportId) },
  });
  const gateReport = data?.gateReport;

  if (!gateReport) {
    navigate(-1);
    return <div />;
  }
  return (
    <>
      <Link to={getSiteUrl(SiteNavigationPath.GateReport, gateReport.siteId!)}>
        Back
      </Link>
      <GateReportActiveItem
        item={gateReport as GateReport}
        timezone={currentSite.timezone}
        siteId={currentSite.id}
      />
    </>
  );
};
