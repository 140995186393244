import React from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const ForgeViewerRoot = styled(Root)`
  position: absolute;
  width: 100%;
`;

export function Loader() {
  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  );
}

export function ForgeViewerLoader() {
  return (
    <ForgeViewerRoot>
      <CircularProgress
        style={{
          color: "black",
        }}
        size="70px"
        thickness={2}
      />
    </ForgeViewerRoot>
  );
}
