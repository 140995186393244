import styled from "@emotion/styled";
import { Avatar, Grid, Typography, Switch } from "@mui/material";
import { useFormik } from "formik";
import { isEmpty, pick } from "lodash";
import React from "react";
import * as Yup from "yup";
import { FormCard } from "../../components/FormCard";
import {
  Button,
  Divider,
  PageTitle,
  UpgradePlanIcon,
  Snackbar,
  Tooltip,
} from "../../components/pages/Common";
import { SingleSelect, TextInput } from "../../components/pages/FormFields";
import { userInputParams } from "../../components/pages/inputConstants";

import NorthIcon from "@mui/icons-material/North";
import { User, UserRole, UserStatus } from "@ocilex/api";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../auth/hooks";
import { SimpleTable } from "../../components/tables/SimpleTable";
import { sortRows } from "../../components/tables/sorting";
import { HeadCell } from "../../components/tables/types";
import { emDash, UserRoleLabel, UserStatusLabel } from "../../constants";
import { useUpdateMeMutation } from "../../graphql";
import { errorUrl } from "../../helpers/navigation";
import InfoIcon from "@mui/icons-material/Info";
import { isMobile } from "react-device-detect";

export const EditProfile = () => {
  const user = useCurrentUser();
  const sites = React.useMemo(() => {
    const userSites = (user?.sites || []).map((row) => ({
      name: row.site.name,
      role: row.role,
    }));
    return sortRows(userSites, {
      key: "name",
      order: "asc",
    });
  }, [user?.sites]);

  const companies = React.useMemo(() => {
    const userCompanies = (user?.companies || []).map((row) => ({
      name: row.company?.name || emDash,
    }));
    return sortRows(userCompanies, {
      key: "name",
      order: "asc",
    });
  }, [user?.companies]);

  if (!user) return null;

  return (
    <React.Fragment>
      <PageTitle text="Edit profile" />
      <Divider my={6} />
      <FormCard size="sm">
        <EditForm user={user} />
      </FormCard>
      <CompaniesTable companies={companies} />
      <SitesTable sites={sites} user={user} />
    </React.Fragment>
  );
};

const FormValidationSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  acceptsExtraEmails: Yup.boolean().nullable(),
});

const EditForm: React.FC<{ user: User }> = ({ user }) => {
  const navigate = useNavigate();
  const [updateMe] = useUpdateMeMutation();

  const initialValues = pick(user, ["phone", "name", "acceptsExtraEmails"]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: FormValidationSchema,
    validateOnBlur: true,
    onSubmit: () => {
      null;
    },
  });

  if (!user) return null;

  const userStatus = user.status || UserStatus.Inactive;
  const redirect = () => navigate("/");

  const save = () => {
    updateMe({
      variables: {
        input: {
          ...formik.values,
          acceptsExtraEmails: formik.values.acceptsExtraEmails || false,
        },
      },
    })
      .then(redirect)
      .catch(() => navigate(errorUrl));
  };

  const tooltipContent = (
    <div>
      Snapshots from cameras used for timelapses on your sites will be sent to
      your email.
      <br />
      Guest users won't receive these emails.
    </div>
  );

  const infoIcon = (
    <InfoIcon
      style={{
        marginTop: "2px",
      }}
    />
  );

  return (
    <form>
      <Typography variant="h6" gutterBottom mb={8}>
        General info
      </Typography>

      <Grid container spacing={2} justifyContent="left">
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...userInputParams.name}
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.name}
            showError={!isEmpty(formik.errors.name) && formik.touched.name}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Status"
            options={[
              {
                label: UserStatusLabel[userStatus],
                value: userStatus,
              },
            ]}
            value={userStatus}
            disabled
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput {...userInputParams.email} value={user.email} disabled />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            name="phone"
            autoComplete="new-password"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.phone}
            showError={!isEmpty(formik.errors.phone) && formik.touched.phone}
            {...userInputParams.phone}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Permission level"
            options={[
              { label: "Admin", value: 1 },
              { label: "User", value: 0 },
            ]}
            value={+user.isAdmin}
            disabled
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom my={4}>
        Subscription preferences
      </Typography>

      <Grid
        container
        spacing={6}
        mb={4}
        justifyContent="space-between"
        alignItems="left"
        direction="row"
      >
        <Grid item xs={12}>
          <div style={{ marginLeft: "-10px", display: "inline-block" }}>
            <Switch
              checked={formik.values.acceptsExtraEmails || false}
              onChange={(event, checked) =>
                void formik.setFieldValue("acceptsExtraEmails", checked)
              }
              size="medium"
            />
          </div>

          <div style={{ display: "inline-block", verticalAlign: "middle" }}>
            <div
              style={{
                display: "inline-block",
                marginRight: "5px",
              }}
            >
              Subscribe to weekly snapshot emails
            </div>
            {isMobile ? (
              <Snackbar
                iconElement={infoIcon}
                content={tooltipContent}
                displayDuration={5000}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              />
            ) : (
              <Tooltip
                title={tooltipContent}
                placement="right"
                children={
                  <div
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    {infoIcon}
                  </div>
                }
              />
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={6} justifyContent="left">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            my={2}
            mr={2}
            onClick={save}
            disabled={!isEmpty(formik.errors)}
          >
            Save
          </Button>
          <Button color="primary" variant="outlined" my={2} onClick={redirect}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

type CompanyRow = { name: string };

const companyHeadCells: Array<HeadCell<CompanyRow>> = [
  {
    key: "name",
    label: "Company",
    alignment: "left",
    width: "50%",
    render: (row) => row.name || emDash,
  },
  {
    key: "role",
    label: "Role",
    alignment: "left",
    width: "50%",
    render: () => "Company Admin",
  },
];

const CompaniesTable: React.FC<{ companies: CompanyRow[] }> = ({
  companies,
}) => {
  if (companies.length === 0) return null;

  return (
    <FormCard size="sm">
      <Typography variant="h6" gutterBottom mb={4}>
        Managed companies
      </Typography>
      <SimpleTable headCells={companyHeadCells} rows={companies} />
    </FormCard>
  );
};

const UpgradeIconContainer = styled(Avatar)`
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  margin-left: 5px;
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

type SiteRow = { name: string; role: UserRole };

const SitesTable: React.FC<{ user: User; sites: SiteRow[] }> = (props) => {
  const { sites, user } = props;
  if (sites.length === 0) return null;

  const siteHeadCells: Array<HeadCell<SiteRow>> = [
    {
      key: "site",
      label: "Site",
      alignment: "left",
      width: "50%",
      render: (row) => row.name || emDash,
    },
    {
      key: "role",
      label: "Role",
      alignment: "left",
      width: "50%",
      render: (row) => {
        const showUpgradeIcon =
          !user.isAdmin &&
          ([UserRole.Guest, UserRole.CoreOnly] as string[]).includes(row.role);

        return (
          <div style={{ display: "flex" }}>
            {UserRoleLabel[row.role]}
            {showUpgradeIcon && (
              <UpgradePlanIcon
                iconElement={
                  <UpgradeIconContainer>
                    <NorthIcon style={{ height: "16px" }} />
                  </UpgradeIconContainer>
                }
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <FormCard size="sm">
      <Typography variant="h6" gutterBottom mb={4}>
        Available sites
      </Typography>
      <SimpleTable headCells={siteHeadCells} rows={sites} />
    </FormCard>
  );
};
