import React from "react";
import {
  Button,
  Divider,
  PageTitle,
  PlusButton,
} from "../../components/pages/Common";
import { useDebounce } from "use-debounce";
import { filterObjectsByString } from "../../components/tables/filterByString";
import { NewSiteModal } from "../../components/modals/NewSiteModal";
import { dateToString } from "../../helpers/toString";
import { useNavigate } from "react-router-dom";
import {
  ConstructionSite,
  ConstructionSiteStatus,
  useGetSitesQuery,
} from "../../graphql";
import { enumToChoices } from "../../helpers/enumToChoices";
import { SiteStatusLabel } from "../../constants";
import { getAdminUrl, AdminNavigationPath } from "../../helpers/navigation";
import { SingleSelect } from "../../components/pages/FormFields";
import { PaginatedTable } from "../../components/tables/PaginatedTable";
import { HeadCell, Sorting } from "../../components/tables/types";
import { useLocalStorage } from "../../helpers/localStorage";
import { sortReducer, sortRows } from "../../components/tables/sorting";
import { TableFilterToolbar } from "../../components/tables/components/Toolbar";
import { SearchInput } from "../../components/tables/components/SearchInput";
import { canAdminSite } from "../../helpers/permissions";
import { useCurrentUser } from "../../auth/hooks";
import { Grid } from "@mui/material";

const EditButton: React.FC<{ siteId: number }> = ({ siteId }) => {
  const navigate = useNavigate();

  return (
    <Button
      mr={2}
      variant="outlined"
      onClick={() =>
        navigate(getAdminUrl(AdminNavigationPath.SiteEdit, siteId))
      }
      margin="0"
    >
      Edit
    </Button>
  );
};

const defaultSorting: Sorting = {
  key: "createdAt",
  order: "desc",
};

type Site = { companyName: string } & Pick<
  ConstructionSite,
  "id" | "name" | "status" | "createdAt"
>;

const Page: React.FC = () => {
  const { data } = useGetSitesQuery();
  const loggedUser = useCurrentUser();
  const [nameFilter, setNameFilter] = React.useState<string>("");
  const [modalIsOpened, setModalIsOpened] = React.useState<boolean>(false);
  const [statusFilter, setStatusFilter] = React.useState<string>("");
  const [debouncedNameFilter] = useDebounce(nameFilter, 200);
  const settingsLocalStorageKey = "admin-sites-list";
  const [initialSorting, saveSorting] = useLocalStorage<Sorting>(
    `${settingsLocalStorageKey}-sorting`,
    defaultSorting,
  );
  const [sorting, setSortingKey] = React.useReducer(
    sortReducer,
    initialSorting,
  );

  const sites = React.useMemo(() => {
    if (!loggedUser) return [];
    const rows = data?.constructionSites || [];

    return rows
      .filter(({ id }) => canAdminSite(loggedUser, id))
      .map((site) => ({
        ...site,
        companyName: site.company?.name || "",
      }));
  }, [loggedUser, data?.constructionSites]);

  const filteredRows = React.useMemo(() => {
    const filteredByName = filterObjectsByString(
      sites,
      "name",
      debouncedNameFilter,
    );
    return filterObjectsByString(filteredByName, "status", statusFilter, {
      exact: true,
    });
  }, [debouncedNameFilter, statusFilter, sites]);

  const sortedRows = React.useMemo(() => {
    return sortRows(filteredRows, sorting);
  }, [sorting, filteredRows]);

  React.useEffect(() => saveSorting(sorting), [sorting]);

  const headCells: Array<HeadCell<Site>> = [
    {
      key: "name",
      label: "Site",
      alignment: "left",
      width: "25%",
      handleSort: setSortingKey,
      render: (site) => site.name,
    },
    {
      key: "companyName",
      label: "Company",
      alignment: "center",
      width: "20%",
      handleSort: setSortingKey,
      render: (site) => site.companyName,
    },
    {
      key: "status",
      label: "Status",
      alignment: "center",
      width: "15%",
      handleSort: setSortingKey,
      render: (site) => SiteStatusLabel[site.status],
    },
    {
      key: "createdAt",
      label: "Creation Date",
      alignment: "center",
      width: "15%",
      handleSort: setSortingKey,
      render: (site) => dateToString(new Date(site.createdAt)),
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "15%",
      render: (site) => (
        <Grid container direction="column" gap={2}>
          <Grid item>
            <EditButton siteId={site.id} />
          </Grid>
        </Grid>
      ),
    },
  ];

  const filters = [
    <SingleSelect
      label="Status"
      placeholder="Select status"
      value={statusFilter}
      allowEmpty={true}
      options={enumToChoices(ConstructionSiteStatus)}
      onChange={setStatusFilter}
    />,
  ];

  const search = (
    <SearchInput placeholder="Search by site names" onChange={setNameFilter} />
  );
  const toolbar = <TableFilterToolbar search={search} filters={filters} />;

  return (
    <>
      <PageTitle text="Construction Sites Management">
        <NewSiteModal
          isOpened={modalIsOpened}
          close={() => setModalIsOpened(false)}
        />
        <PlusButton
          onClick={() => setModalIsOpened(true)}
          tooltip="Add construction site"
        />
      </PageTitle>
      <Divider my={6} />
      <PaginatedTable
        headCells={headCells}
        rows={sortedRows}
        sorting={sorting}
        settingsLocalStorageKey={settingsLocalStorageKey}
        toolbar={toolbar}
      />
    </>
  );
};

export const SitesListAdmin = React.memo(Page);
