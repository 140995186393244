import "@mui/lab/themeAugmentation";

import {
  createTheme as createMuiTheme,
  ThemeOptions,
} from "@mui/material/styles";
import { variants } from "./variants";
import { typography } from "./typography";
import { breakpoints } from "./breakpoints";
import { components } from "./components";
import { shadows } from "./shadows";

export const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      components: components as ThemeOptions["components"],
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
  );
};
