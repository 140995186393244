import React from "react";
import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SidebarItemsType } from "../../types/sidebar";
import { SidebarNavSection } from "./SidebarNavSection";

const baseScrollbar = (props: { theme: Theme }) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
  svg {
    min-width: 20px;
  }
`;

export type NavSectionProps = {
  title: string | JSX.Element;
  pages: SidebarItemsType[];
  uniqKey: string;
};

interface SidebarNavProps {
  showMenu?: boolean;
  items: NavSectionProps[];
}

export const SidebarNav: React.FC<SidebarNavProps> = ({ items, showMenu }) => {
  const theme = useTheme();
  const [scrollbarRef, setScrollbarRef] =
    React.useState<ReactPerfectScrollbar>();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  const updateScrollbar = () => {
    setTimeout(() => scrollbarRef?.updateScroll?.(), 200);
  };

  return (
    // @ts-expect-error: wrong typing
    <ScrollbarComponent ref={setScrollbarRef}>
      <ScrollbarContext.Provider
        value={{
          updateScrollbar,
        }}
      >
        <List disablePadding>
          {showMenu && items.length > 0 && (
            <Items>
              {items.map((item) => (
                <SidebarNavSection
                  component="div"
                  key={item.uniqKey}
                  pages={item.pages}
                  title={item.title}
                />
              ))}
            </Items>
          )}
        </List>

        {showMenu && items.length == 0 && (
          <Placeholder>No construction sites are available</Placeholder>
        )}
      </ScrollbarContext.Provider>
    </ScrollbarComponent>
  );
};

const Placeholder = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.8;
  text-transform: uppercase;
`;

const initialScrollbarContext = {
  updateScrollbar: () => {
    null;
  },
};

const ScrollbarContext = React.createContext(initialScrollbarContext);

export function useScrollbarContext() {
  return React.useContext(ScrollbarContext);
}
