import React from "react";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { NewInstanceModal } from "./NewInstanceModal";

import { useFormik } from "formik";
import { useAddCompanyMutation } from "../../graphql";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
} from "../../helpers/navigation";
import { TextInput } from "../pages/FormFields";
import { companyInputParams, defaultHelperText } from "../pages/inputConstants";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(defaultHelperText.required),
});

export const NewCompanyModal: React.FC<{
  isOpened: boolean;
  close: () => void;
}> = (props) => {
  const navigate = useNavigate();
  const initialValues = { name: "" };
  const formik = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      null;
    },
  });

  const [addCompany, { data, loading, error }] = useAddCompanyMutation();

  React.useEffect(() => {
    const companyId = data?.addCompany.id;
    if (error) navigate(errorUrl);
    if (companyId) {
      navigate(getAdminUrl(AdminNavigationPath.CompanyEdit, companyId));
    }
  }, [data, error]);

  const close = () => {
    formik.resetForm({ values: initialValues });
    props.close();
  };

  const onSave = () => {
    void addCompany({ variables: { input: { name: formik.values.name } } });
    close();
  };

  return (
    <NewInstanceModal
      title="Add New Construction Company"
      canBeSaved={formik.isValid && !loading}
      onSave={onSave}
      onCancel={close}
      isOpened={props.isOpened}
    >
      <TextInput
        autoFocus
        name="name"
        margin="dense"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        helperText={formik.errors.name}
        showError={!!formik.errors.name && formik.touched.name}
        {...companyInputParams.name}
      />
    </NewInstanceModal>
  );
};
