import { FC } from "react";
import styled from "@emotion/styled";
import { GateReport } from "@ocilex/api";
import { utcToZonedTime, format } from "date-fns-tz";

interface GateReportActiveItemProps {
  item: GateReport;
  timezone: string | null | undefined;
  siteId: number;
}

const LiveView = styled.img`
  max-width: 100%;
`;

export const GateReportActiveItem: FC<GateReportActiveItemProps> = ({
  item,
  timezone,
  siteId,
}) => {
  const parsedTimestamp =
    item.eventTimestamp &&
    timezone &&
    format(
      utcToZonedTime(item.eventTimestamp * 1000, timezone),
      "yyyy-MM-dd HH:mm:ss",
    );
  return (
    <div>
      <h2>
        {item.eventType} on site #{siteId}
      </h2>
      <p>
        <b>Time</b>: {item.eventTimestamp && timezone ? parsedTimestamp : "N/A"}
      </p>
      <p>
        <b>Camera ID</b>: {item.cameraId}
      </p>
      <p>
        <b>Event Source</b>: {item.eventSource}
      </p>
      <LiveView src={item.snapshot_s3 || ""} />
    </div>
  );
};
