import styled from "@emotion/styled";
import React from "react";
import { Divider } from "../../components/pages/Common";

import {
  GetGateReportsQuery,
  useGateReportTypesQuery,
  useGetGateReportsQuery,
  useGetSiteQuery,
  GateReportEventType,
  InputMaybe,
} from "../../graphql";

import { HighlightOff as CloseIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { FormCard } from "../../components/FormCard";
import {
  DatetimePicker,
  SingleSelect,
} from "../../components/pages/FormFields";
import { PaginatedTable } from "../../components/tables/PaginatedTable";
import { HeadCell } from "../../components/tables/types";
import { emDash } from "../../constants";
import { getUtcNow, offsetDateByTz, roundMinutes } from "../../helpers/date";
import { getServerUri } from "./helpers";
import { iframeTokenError } from "./Error";

type GateReportRow = GetGateReportsQuery["gateReports"]["gateReports"][number];

interface SnapshotProps {
  show: boolean;
  src: string;
  close: () => void;
}

const updateSnapshotProps = (
  prev: SnapshotProps,
  next: Partial<SnapshotProps>,
) => ({ ...prev, ...next });

export const IframeGateReport: React.FC = () => {
  const params = useParams();
  const siteId = parseInt(params.siteId || "");
  const token = params.token || "";

  const queriesContext = React.useMemo(() => {
    return { uri: getServerUri(token) };
  }, [token]);

  const { data, error } = useGetSiteQuery({
    variables: { constructionSiteId: siteId },
    context: queriesContext,
  });

  const site = data?.constructionSite || null;

  const [selectedCameraName, setSelectedCameraName] = React.useState<string>();

  const InitialSnapshotProps: SnapshotProps = React.useMemo(
    () => ({
      show: false,
      src: "",
      close: () => setSnapshotProps({ show: false }),
    }),
    [],
  );

  const [snapshotProps, setSnapshotProps] = React.useReducer(
    updateSnapshotProps,
    InitialSnapshotProps,
  );
  const [eventType, setEventType] =
    React.useState<InputMaybe<GateReportEventType>>();
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [startDateTs, setStartDateTs] = React.useState<number>();
  const [endDateTs, setEndDateTs] = React.useState<number>();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const { data: siteGateReportTypeQuery } = useGateReportTypesQuery({
    context: queriesContext,
  });

  const { data: siteGateReportsQuery } = useGetGateReportsQuery({
    context: queriesContext,
    variables: {
      filters: {
        siteId: siteId,
        eventType: eventType,
        cameraId: selectedCameraName || "",
        startDate: startDateTs,
        endDate: endDateTs,
      },
      page,
      pageSize: rowsPerPage,
    },
  });

  const { timezone } = site || {};

  const siteNow = React.useMemo(() => {
    if (!timezone) return new Date();
    return offsetDateByTz(getUtcNow(), timezone);
  }, [timezone]);

  React.useEffect(() => {
    const initialTime = roundMinutes(siteNow);
    setStartDate(initialTime);
    setEndDate(siteNow);
  }, [siteNow]);

  const cameraOptions = React.useMemo(() => {
    return [
      {
        value: "",
        label: "--",
      },
      ...(site?.cameras || []).map(({ name }) => ({
        value: name,
        label: name,
      })),
    ];
  }, [site]);

  const eventTypeOptions = React.useMemo(() => {
    const gateReportTypes = siteGateReportTypeQuery?.gateReportTypes || [];

    return [
      {
        value: "",
        label: "--",
      },
      ...gateReportTypes.map(({ name }) => ({
        label: name || "",
        value: name || "",
      })),
    ];
  }, [siteGateReportTypeQuery]);

  const showImage = React.useCallback((row: GateReportRow) => {
    setSnapshotProps({ src: row.snapshot_s3 || undefined, show: true });
  }, []);

  const headCells: Array<HeadCell<GateReportRow>> = React.useMemo(
    () => [
      {
        key: "eventTimestamp",
        label: "Date",
        alignment: "left",
        width: "30%",
        render: (row) => {
          if (!row.eventTimestamp) return emDash;
          const label = zonedTimestampToDateString(row.eventTimestamp);
          return (
            <a href="#" onClick={() => showImage(row)}>
              {label}
            </a>
          );
        },
      },
      {
        label: "Event Type",
        key: "eventType",
        alignment: "center",
        width: "35%",
        render: ({ eventType }) => eventType || emDash,
      },
      {
        label: "Camera",
        key: "cameraId",
        alignment: "center",
        width: "35%",
        render: ({ cameraId }) => cameraId || emDash,
      },
    ],
    [site],
  );

  const rows = React.useMemo(() => {
    const rows = [...(siteGateReportsQuery?.gateReports?.gateReports || [])];

    return rows.sort((row1, row2) => {
      return (row2.eventTimestamp || 0) - (row1.eventTimestamp || 0);
    });
  }, [siteGateReportsQuery]);

  if (!site) return null;
  if (error) return iframeTokenError;

  return (
    <FormCard nopadding>
      <Typography variant="h3" gutterBottom display="inline">
        {site.name}
      </Typography>

      <Divider mb={5} mt={2} />
      <Grid
        container
        mb={6}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={9}>
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            alignItems={{ md: "center" }}
            rowSpacing={3}
            columnSpacing={2}
          >
            <Grid item md={3} xs={12}>
              <SingleSelect
                label="Camera"
                placeholder="Select Camera"
                value={selectedCameraName || ""}
                onChange={(value: string) => setSelectedCameraName(value)}
                options={cameraOptions}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <SingleSelect
                label="Event Type"
                placeholder="Select Event Type"
                value={eventType || ""}
                onChange={(value: string | undefined) =>
                  setEventType(value as InputMaybe<GateReportEventType>)
                }
                options={eventTypeOptions}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <DatetimePicker
                value={startDate}
                label="Select start date/time"
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                maxDate={siteNow}
                onChange={(value: Date | null) => {
                  if (value) {
                    setStartDate(value);
                    setStartDateTs(value.getTime() / 1000);
                  }
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <DatetimePicker
                value={endDate}
                maxDate={siteNow}
                label="Select end date/time"
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                onChange={(value: Date | null) => {
                  if (value) {
                    setEndDate(value);
                    setEndDateTs(value.getTime() / 1000);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ display: snapshotProps.show ? "none" : "block" }}>
        <PaginatedTable
          headCells={headCells}
          rows={rows}
          settingsLocalStorageKey="iframe-gate-report"
          size="small"
          rowHeight={32}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
        />
      </div>
      <Snapshot {...snapshotProps} />
    </FormCard>
  );
};

const SnapshotContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  z-index: 1;

  img {
    object-fit: contain;
    max-height: 100vh;
    max-width: 100vw;
  }
`;

const CloseButton = styled(CloseIcon)`
  position: fixed;
  top: 10px;
  right: 10px;
  display: block;
  margin: 10px;
  color: white;
  background-color: black;
  cursor: pointer;
  font-size: 40px;
  border-radius: 50%;

  &:hover {
    transform: scale(1.05);
  }
`;

const Snapshot: React.FC<SnapshotProps> = ({ show, src, close }) => {
  if (!show) return null;

  return (
    <SnapshotContainer>
      <div>
        <CloseButton onClick={close} />
      </div>
      <img src={src} />
    </SnapshotContainer>
  );
};

const zonedTimestampToDateString = (timestamp: number) => {
  return new Date(timestamp * 1000)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
};
