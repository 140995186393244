import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FormCard } from "../../components/FormCard";
import {
  DatePicker,
  SingleSelect,
  Switcher,
  TimePicker,
} from "../../components/pages/FormFields";
import {
  Alert,
  Button,
  Divider,
  PageTitle,
  ZonedClock,
} from "../../components/pages/Common";
import { downloadFile } from "../../helpers/file";
import { useGetSiteCamerasQuery } from "../../graphql";
import {
  getUtcFromLocalDate,
  getUtcNow,
  isToday,
  offsetDateByTz,
  roundMinutes,
  toUtcDateTimeString,
} from "../../helpers/date";
import { useCurrentSite } from "./context/SiteContext";
import { format as dateToString } from "date-fns";
import { errorUrl } from "../../helpers/navigation";
import { addOrUpdateWatermark } from "../../helpers/updateWatermark";
import { dateFormat, dateTimeFormat, timeFormat } from "../../constants";
import { ImageEditor, ImageEditorRef } from "../../components/ImageEditor";

export const EditFramePage: React.FC = () => {
  const navigate = useNavigate();
  const site = useCurrentSite();
  const [snapshotUrl, setSnapshotUrl] = React.useState("");
  const [selectedCamera, setSelectedCamera] = React.useState<number>();
  const [switchChecked, setSwitchChecked] = React.useState(false);

  const [editFrameObjId, setEditFrameObjId] = React.useState<number | null>(
    null,
  );

  const siteTz = site.timezone || "";

  const utcNow = getUtcNow();
  const siteNow = offsetDateByTz(utcNow, siteTz);

  const initialTime = roundMinutes(siteNow);

  const [time, setTime] = React.useState<Date>(siteNow);
  const [date, setDate] = React.useState<Date>(initialTime);

  React.useEffect(() => {
    setTime(initialTime);
    setDate(siteNow);
    setSelectedCamera(undefined);
  }, [site.id]);

  const dateFilter = new Date(date);
  dateFilter.setHours(time.getHours());
  dateFilter.setMinutes(time.getMinutes());
  dateFilter.setSeconds(time.getSeconds());

  const utcDTimeString = React.useMemo(
    () => toUtcDateTimeString(dateFilter, siteTz, dateTimeFormat),
    [dateFilter, site.timezone],
  );

  const snapshotDate = React.useMemo(() => {
    if (!snapshotUrl) return null;
    const url = new URL(snapshotUrl);
    const fname = url.pathname.split("/").pop() || "";
    const utcEpoch = parseInt(fname.split(".").shift() || "0");
    if (!utcEpoch) return null;
    const utcDate = getUtcFromLocalDate(new Date(utcEpoch * 1000));
    return offsetDateByTz(utcDate, siteTz);
  }, [snapshotUrl]);

  const { data: siteData } = useGetSiteCamerasQuery({
    variables: {
      constructionSiteId: site.id,
      snapshotTime: utcDTimeString,
    },
  });

  const cameras = React.useMemo(() => {
    return siteData?.constructionSite.cameras || [];
  }, [siteData]);

  const imageEditor = React.useRef<ImageEditorRef>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const wrapper = wrapperRef.current || { offsetHeight: 0, offsetWidth: 0 };

  const imageRatio = 1.33;

  const editorSize = {
    width: "100%",
    height: `${wrapper.offsetWidth / imageRatio + 180}px`,
  };

  const imageSize = {
    width: wrapper.offsetWidth,
    height: wrapper.offsetWidth * 3,
  };

  React.useEffect(() => {
    if (!imageEditor.current) return;
    setTimeout(() => {
      void imageEditor.current?.imageEditorInst.resizeCanvasDimension(
        imageSize,
      );
    });
  }, [imageEditor.current, snapshotUrl, imageSize.width]);

  const isActive = [date, time, selectedCamera].every(Boolean);

  React.useEffect(() => {
    if (!isActive) return;
    const camera = cameras.find((camera) => camera.id === selectedCamera);
    setSwitchChecked(false);
    setSnapshotUrl(camera?.snapshot || "");
    void imageEditor.current?.imageEditorInst.loadImageFromURL(
      camera?.snapshot || "",
      "CameraSnapshot",
    );
  }, [cameras, selectedCamera]);

  const handleDownload = () => {
    const camera = cameras.find((camera) => camera.id === selectedCamera);
    const cameraName = camera?.name || "";
    const format = "jpeg";
    const imageBase64 =
      imageEditor?.current?.imageEditorInst.toDataURL({
        format,
      }) || "";

    downloadFile(
      imageBase64,
      `Snapshot ${cameraName} ${dateToString(
        snapshotDate || dateFilter,
        dateTimeFormat,
      )}.${format}`,
    );
  };

  const camerasChoices = React.useMemo(
    () =>
      cameras.map((camera) => {
        return {
          value: camera.id,
          label: camera.name,
        };
      }),
    [cameras],
  );

  const removeWatermark = () => {
    if (!imageEditor.current) return;
    return imageEditor.current?.imageEditorInst
      .removeObject(editFrameObjId || 0)
      .then((objectProps) => objectProps)
      .catch(() => navigate(errorUrl));
  };

  const updateWatermark = () => {
    if (imageEditor.current && snapshotDate) {
      addOrUpdateWatermark(imageEditor.current, snapshotDate, dateTimeFormat)
        .then((objectProps) => setEditFrameObjId(objectProps.id))
        .catch(() => navigate(errorUrl));
    }
  };

  const onSwitcherChange = (value: boolean) => {
    if (value) {
      updateWatermark();
    } else {
      removeWatermark()
        ?.then((obj) => obj)
        .catch(() => navigate(errorUrl));
    }
    setSwitchChecked(value);
  };

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: Edit frame`}>
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>

      <Divider my={6} />
      <FormCard>
        <Grid
          container
          mb={6}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={9}>
            <Grid
              container
              direction={{ xs: "column", md: "row" }}
              alignItems={{ md: "center" }}
              spacing={2}
            >
              <Grid item md={3} xs={12}>
                <SingleSelect
                  label="Camera"
                  placeholder="Select Camera"
                  value={selectedCamera || 0}
                  options={camerasChoices}
                  onChange={(value: number) => setSelectedCamera(value)}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <DatePicker
                  value={date}
                  label="Date picker"
                  inputFormat={dateFormat}
                  mask="____-__-__"
                  maxDate={siteNow}
                  onChange={(value: Date | null) => {
                    if (value) setDate(value);
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TimePicker
                  value={time}
                  maxTime={isToday(date, siteTz) ? siteNow : undefined}
                  views={["hours", "minutes", "seconds"]}
                  label="Time picker"
                  inputFormat={timeFormat}
                  mask="__:__:__"
                  onChange={(value: Date | null) => {
                    if (value) setTime(value);
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Switcher
                  label="Show date and time"
                  disabled={!isActive || !snapshotUrl}
                  checked={switchChecked}
                  onChange={onSwitcherChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} my={2}>
            <Button
              color="primary"
              variant="contained"
              disabled={!isActive}
              onClick={() => handleDownload()}
              fullWidth
            >
              Download
            </Button>
          </Grid>
        </Grid>

        {isActive && (
          <Grid ref={wrapperRef}>
            <ImageEditor
              editorRef={imageEditor}
              menu={["crop", "draw", "shape", "text", "icon", "filter"]}
              editorSize={editorSize}
              imageSize={imageSize}
              show={wrapper.offsetWidth > 0}
              image={{
                path: snapshotUrl,
                name: "CameraSnapshot",
              }}
            />
          </Grid>
        )}

        {!isActive && (
          <Alert my={2} severity="warning">
            Please select a camera and specify date and time of the frame
          </Alert>
        )}
      </FormCard>
    </React.Fragment>
  );
};
