import React from "react";
import { Button } from "../pages/Common";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogTitle,
} from "@mui/material";
import { v4 as uuid } from "uuid";

interface ModalProps {
  isOpened: boolean;
  onConfirm: () => void;
  close: () => void;
  title?: string;
  children: DialogContentProps;
}

export const ConfirmationModal: React.FC<ModalProps> = (props) => {
  const modalId = React.useMemo(() => uuid(), []);

  return (
    <Dialog
      open={props.isOpened}
      onClose={props.close}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id={modalId}>{props.title || "Are You Sure?"}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={props.onConfirm}>
          Yes
        </Button>
        <Button color="primary" variant="outlined" onClick={props.close}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
