import { FC } from "react";
import { ImageStatus } from "../../../hooks/use-image";
import Magnifier from "../../../components/ImageMagnifier";

interface LiveViewImageProps {
  snapshotSrc?: string;
  snapshotStatus: ImageStatus;
  isZoomEnabled: boolean;
  onIsZoomedChanged: (isZoomed: boolean) => void;
}

export const LiveViewImage: FC<LiveViewImageProps> = ({
  snapshotSrc,
  snapshotStatus,
  isZoomEnabled,
  onIsZoomedChanged,
}) => {
  return (
    (Boolean(snapshotSrc) &&
      snapshotStatus === ImageStatus.success &&
      (isZoomEnabled ? (
        <Magnifier
          src={snapshotSrc!}
          mgWidth={300}
          mgHeight={300}
          zoomFactor={isZoomEnabled ? 4 : 1}
          onZoomStart={() => onIsZoomedChanged(true)}
          onZoomEnd={() => onIsZoomedChanged(false)}
        />
      ) : (
        <img
          className="snapshot"
          src={snapshotSrc}
          width="100%"
          height="100%"
        />
      ))) ||
    null
  );
};
