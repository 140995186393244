import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
};

export type AddCompanyAdminInput = {
  companyId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
};

export type AddUpdateUserSiteInput = {
  email: Scalars['String']['input'];
  role: UserRole;
  site: Scalars['Int']['input'];
};

export type AdminCreateInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Camera = {
  __typename?: 'Camera';
  address?: Maybe<Scalars['String']['output']>;
  bimFile?: Maybe<CameraBimFile>;
  configuration?: Maybe<CameraConfiguration>;
  enableHealthCheck?: Maybe<Scalars['Boolean']['output']>;
  health?: Maybe<CameraHealth>;
  healthCheckEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['Int']['output'];
  lastSeen?: Maybe<Scalars['DateTime']['output']>;
  lastStatus?: Maybe<CameraStatus>;
  name: Scalars['String']['output'];
  reportSchedules?: Maybe<Array<CameraReportSchedule>>;
  siteId: Scalars['Int']['output'];
  snapshot?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updateFrequency?: Maybe<LiveViewUpdateFrequency>;
  workingDays?: Maybe<Array<Maybe<WorkingDay>>>;
  workingHoursEnd?: Maybe<Scalars['String']['output']>;
  workingHoursStart?: Maybe<Scalars['String']['output']>;
};


export type CameraSnapshotArgs = {
  time?: InputMaybe<Scalars['String']['input']>;
};

export enum CameraAuthenticationMethod {
  Basic = 'BASIC',
  Digest = 'DIGEST'
}

export type CameraBimFile = CreatedUpdated & {
  __typename?: 'CameraBimFile';
  cameraId: Scalars['Int']['output'];
  conversionStatus: ConversionStatus;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']['output']>;
  fname: Scalars['String']['output'];
  forgeJobStatus?: Maybe<ForgeJobStatus>;
  forgeLocation: Scalars['String']['output'];
  forgeObjectId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  opacity: Scalars['Int']['output'];
  sceneState: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['Int']['output']>;
};

export type CameraConfiguration = CreatedUpdated & {
  __typename?: 'CameraConfiguration';
  assignedCamerasCount?: Maybe<Scalars['Int']['output']>;
  authenticationMethod: CameraAuthenticationMethod;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isAvailableForUsers: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  snapshotUri: Scalars['String']['output'];
  statusUri: Scalars['String']['output'];
  timeFormat: CameraTimeFormat;
  timeParsingRules: CameraTimeParsingRules;
  timezone?: Maybe<CameraTimezoneSource>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CameraConfigurationCreateUpdateInput = {
  authenticationMethod: CameraAuthenticationMethod;
  isAvailableForUsers?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  snapshotUri: Scalars['String']['input'];
  statusUri: Scalars['String']['input'];
  timeFormat: CameraTimeFormat;
  timeParsingRules: CameraTimeParsingRulesInput;
  timezone?: InputMaybe<CameraTimezoneSource>;
};

export type CameraConfigurationTestInput = {
  authenticationMethod: CameraAuthenticationMethod;
  snapshotUri?: InputMaybe<Scalars['String']['input']>;
  statusUri: Scalars['String']['input'];
  timeFormat?: InputMaybe<CameraTimeFormat>;
  timeParsingRules?: InputMaybe<CameraTimeParsingRulesInput>;
  timezone?: InputMaybe<CameraTimezoneSource>;
};

export type CameraConfigurationTestResult = {
  __typename?: 'CameraConfigurationTestResult';
  parsedDate?: Maybe<Scalars['DateTime']['output']>;
  snapshotResponse?: Maybe<Scalars['String']['output']>;
  snapshotResponseType?: Maybe<CameraConfigurationTestResultResponseType>;
  timestampResponse: Scalars['String']['output'];
  timestampResponseType: CameraConfigurationTestResultResponseType;
  warnings: Array<Scalars['String']['output']>;
};

export enum CameraConfigurationTestResultResponseType {
  Error = 'ERROR',
  Success = 'SUCCESS'
}

export type CameraCreateInput = {
  address: Scalars['String']['input'];
  configuration: Scalars['Int']['input'];
  enableHealthCheck: Scalars['Boolean']['input'];
  healthCheckEmails: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  updateFrequency: LiveViewUpdateFrequency;
  username: Scalars['String']['input'];
  workingDays: Array<WorkingDay>;
  workingHoursEnd: Scalars['String']['input'];
  workingHoursStart: Scalars['String']['input'];
};

export type CameraCredentialsInput = {
  address: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CameraHealth = {
  __typename?: 'CameraHealth';
  lastSeen?: Maybe<Scalars['DateTime']['output']>;
  status: CameraStatus;
};

export type CameraReportSchedule = {
  __typename?: 'CameraReportSchedule';
  camera?: Maybe<Camera>;
  cameraId: Scalars['Int']['output'];
  days: Array<WorkingDay>;
  id: Scalars['Int']['output'];
  status: CameraReportScheduleStatus;
  time: Array<Scalars['String']['output']>;
};

export enum CameraReportScheduleStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type CameraSmtpCreds = {
  __typename?: 'CameraSmtpCreds';
  email?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
};

export type CameraSnapshotFilterInput = {
  cameraId?: InputMaybe<Scalars['Int']['input']>;
  creationDay: Scalars['DateTime']['input'];
};

export type CameraSnapshotItem = {
  __typename?: 'CameraSnapshotItem';
  cameraId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  snapshotUrl: Scalars['String']['output'];
};

export type CameraSnapshotResult = {
  __typename?: 'CameraSnapshotResult';
  currentItemIndex?: Maybe<Scalars['Int']['output']>;
  results: Array<CameraSnapshotItem>;
};

export enum CameraStatus {
  Available = 'available',
  Unavailable = 'unavailable'
}

export enum CameraTimeFormat {
  Custom = 'CUSTOM',
  Iso = 'ISO',
  Partial = 'PARTIAL',
  Unix = 'UNIX'
}

export type CameraTimeParsingRules = {
  __typename?: 'CameraTimeParsingRules';
  customTimeFormat?: Maybe<Scalars['String']['output']>;
  partialPatterns?: Maybe<PartialTimePatterns>;
  pattern?: Maybe<Scalars['String']['output']>;
};

export type CameraTimeParsingRulesInput = {
  customTimeFormat?: InputMaybe<Scalars['String']['input']>;
  partialPatterns?: InputMaybe<PartialTimePatternsInput>;
  pattern?: InputMaybe<Scalars['String']['input']>;
};

export enum CameraTimezoneSource {
  Localtime = 'LOCALTIME',
  Utc = 'UTC'
}

export type CameraUpdateInput = {
  address: Scalars['String']['input'];
  configuration: Scalars['Int']['input'];
  enableHealthCheck: Scalars['Boolean']['input'];
  healthCheckEmails: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  updateFrequency: LiveViewUpdateFrequency;
  username?: InputMaybe<Scalars['String']['input']>;
  workingDays: Array<WorkingDay>;
  workingHoursEnd: Scalars['String']['input'];
  workingHoursStart: Scalars['String']['input'];
};

export type CompanyAdmin = CreatedUpdated & {
  __typename?: 'CompanyAdmin';
  company?: Maybe<ConstructionCompany>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
};

export type CompanyAdminCreateInput = {
  company: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyFiltersInput = {
  status: CompanyStatus;
};

export enum CompanyStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

export type ConstructionCompany = CreatedUpdated & {
  __typename?: 'ConstructionCompany';
  activeSites?: Maybe<Array<ConstructionSite>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<ConstructionSite>>;
  smtpFrom?: Maybe<Scalars['String']['output']>;
  smtpHost?: Maybe<Scalars['String']['output']>;
  smtpPass?: Maybe<Scalars['String']['output']>;
  smtpPort?: Maybe<Scalars['Int']['output']>;
  smtpUser?: Maybe<Scalars['String']['output']>;
  status: CompanyStatus;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['Int']['output']>;
};

export type ConstructionCompanyPermission = {
  __typename?: 'ConstructionCompanyPermission';
  canViewBIMCompare?: Maybe<Scalars['Boolean']['output']>;
  canViewBIMView?: Maybe<Scalars['Boolean']['output']>;
  canViewEditFrame?: Maybe<Scalars['Boolean']['output']>;
  canViewGateReport?: Maybe<Scalars['Boolean']['output']>;
  canViewLiveView?: Maybe<Scalars['Boolean']['output']>;
  canViewSiteProgress?: Maybe<Scalars['Boolean']['output']>;
  canViewTimelapse?: Maybe<Scalars['Boolean']['output']>;
  canViewUserManagement?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo_s3?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ConstructionCompanyWithPerms = {
  __typename?: 'ConstructionCompanyWithPerms';
  activeSites?: Maybe<Array<ConstructionSite>>;
  canViewBIMCompare: Scalars['Boolean']['output'];
  canViewBIMView: Scalars['Boolean']['output'];
  canViewEditFrame: Scalars['Boolean']['output'];
  canViewGateReport: Scalars['Boolean']['output'];
  canViewLiveView: Scalars['Boolean']['output'];
  canViewSiteProgress: Scalars['Boolean']['output'];
  canViewTimelapse: Scalars['Boolean']['output'];
  canViewUserManagement: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<ConstructionSite>>;
  smtpFrom?: Maybe<Scalars['String']['output']>;
  smtpHost?: Maybe<Scalars['String']['output']>;
  smtpPass?: Maybe<Scalars['String']['output']>;
  smtpPort?: Maybe<Scalars['Int']['output']>;
  smtpUser?: Maybe<Scalars['String']['output']>;
  status: CompanyStatus;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['Int']['output']>;
};

export type ConstructionSite = CreatedUpdated & {
  __typename?: 'ConstructionSite';
  cameraEmail?: Maybe<Scalars['String']['output']>;
  cameraEmailPassword?: Maybe<Scalars['String']['output']>;
  cameras?: Maybe<Array<Camera>>;
  company?: Maybe<ConstructionCompany>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  gatereports?: Maybe<Array<SiteGateReport>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  procoreConnectionParam?: Maybe<ProcoreConnectionParamsType>;
  status: ConstructionSiteStatus;
  timelapse?: Maybe<Array<TimelapseSetting>>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  users?: Maybe<Array<Maybe<ConstructionSiteUser>>>;
};


export type ConstructionSiteCamerasArgs = {
  snapshotTime?: InputMaybe<Scalars['String']['input']>;
};

export enum ConstructionSiteStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

export type ConstructionSiteUser = CreatedUpdated & {
  __typename?: 'ConstructionSiteUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  role: UserRole;
  site: ConstructionSite;
  siteId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type ConstructionSiteUserCreateUpdateInput = {
  email: Scalars['String']['input'];
  role: UserRole;
};

export type ConstructionSiteUserInput = {
  create?: InputMaybe<Array<ConstructionSiteUserCreateUpdateInput>>;
  delete?: InputMaybe<Array<ConstructionSiteUserWhereUniqueInput>>;
  update?: InputMaybe<Array<ConstructionSiteUserCreateUpdateInput>>;
};

export type ConstructionSiteUserWhereUniqueInput = {
  email: Scalars['String']['input'];
};

export type ConstructionSitesFilterInput = {
  status: ConstructionSiteStatus;
};

export enum ConversionStatus {
  Converted = 'CONVERTED',
  Error = 'ERROR',
  NotConverted = 'NOT_CONVERTED'
}

export type ConvertPlaylistTask = {
  __typename?: 'ConvertPlaylistTask';
  destinationEmail: Scalars['String']['output'];
  downloadId: Scalars['Int']['output'];
  epochFrom?: Maybe<Scalars['Int']['output']>;
  epochTo?: Maybe<Scalars['Int']['output']>;
  outputDir: Scalars['String']['output'];
  outputExt: VideoFormat;
  outputFname: Scalars['String']['output'];
  playlistDir: Scalars['String']['output'];
};

export type CreateCameraReportScheduleInput = {
  camera: Scalars['Int']['input'];
  days: Array<WorkingDay>;
  time: Array<Scalars['String']['input']>;
};

export type CreateConstructionCompanyInput = {
  name: Scalars['String']['input'];
};

export type CreateGateReportInput = {
  boxId: Scalars['String']['input'];
  cameraId: Scalars['String']['input'];
  eventObject: GateReportEventObject;
  eventSource: Scalars['String']['input'];
  eventTimestamp: Scalars['Int']['input'];
  eventType: GateReportEventType;
  orgId: Scalars['String']['input'];
  payload: Scalars['String']['input'];
  siteId: Scalars['Int']['input'];
  snapshot_s3: Scalars['String']['input'];
};

export type CreateUpdateBimFileInput = {
  camera: Scalars['Int']['input'];
  fname: Scalars['String']['input'];
  forgeLocation: Scalars['String']['input'];
  forgeObjectId: Scalars['String']['input'];
};

export type CreatedUpdated = {
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DownloadPartialTimelapseInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
};

export type ForgeAuthToken = {
  __typename?: 'ForgeAuthToken';
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  refresh_token?: Maybe<Scalars['String']['output']>;
  token_type: Scalars['String']['output'];
};

export enum ForgeJobStatus {
  Failed = 'failed',
  Inprogress = 'inprogress',
  Pending = 'pending',
  Success = 'success',
  Timeout = 'timeout'
}

export enum Frequency {
  H1 = 'H1',
  M5 = 'M5',
  M10 = 'M10',
  M15 = 'M15',
  S10 = 'S10',
  S20 = 'S20',
  S60 = 'S60'
}

export type GateReport = {
  __typename?: 'GateReport';
  boxId?: Maybe<Scalars['String']['output']>;
  cameraId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventObject?: Maybe<GateReportEventObject>;
  eventSource?: Maybe<Scalars['String']['output']>;
  eventTimestamp?: Maybe<Scalars['Int']['output']>;
  eventType?: Maybe<GateReportEventType>;
  id: Scalars['Int']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['Int']['output']>;
  snapshot_s3?: Maybe<Scalars['String']['output']>;
};

export enum GateReportEventObject {
  Car = 'CAR',
  NotSupported = 'NOT_SUPPORTED',
  Person = 'PERSON',
  Unidentified = 'UNIDENTIFIED'
}

export enum GateReportEventType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export type GateReportObject = {
  __typename?: 'GateReportObject';
  name?: Maybe<Scalars['String']['output']>;
};

export type GateReportType = {
  __typename?: 'GateReportType';
  name?: Maybe<Scalars['String']['output']>;
};

export type GateReportsExportFilterInput = {
  cameraId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Int']['input']>;
  eventObject?: InputMaybe<GateReportEventObject>;
  eventType?: InputMaybe<GateReportEventType>;
  siteId: Scalars['Int']['input'];
};

export type GateReportsExportResponse = {
  __typename?: 'GateReportsExportResponse';
  eta: Scalars['Int']['output'];
  recipientEmail: Scalars['String']['output'];
};

export type GateReportsFilterInput = {
  cameraId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Int']['input']>;
  eventObject?: InputMaybe<GateReportEventObject>;
  eventType?: InputMaybe<GateReportEventType>;
  siteId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Int']['input']>;
};

export type GateReportsResponse = {
  __typename?: 'GateReportsResponse';
  count: Scalars['Int']['output'];
  gateReports: Array<GateReport>;
  hasMore?: Maybe<Scalars['Boolean']['output']>;
};

export type IdRequiredInput = {
  id: Scalars['Int']['input'];
};

export enum LiveViewUpdateFrequency {
  M1 = 'M1',
  M5 = 'M5',
  M10 = 'M10'
}

export type MeUpdateInput = {
  acceptsExtraEmails: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCompany: ConstructionCompany;
  addCompanyAdmin: CompanyAdmin;
  addGateReport: GateReport;
  addUserSite: ConstructionSiteUser;
  changeTimelapseStatus: TimelapseSetting;
  createAdmin: User;
  createCamera?: Maybe<Camera>;
  createCameraReportSchedule: CameraReportSchedule;
  createCompanyAdmin: User;
  createConstructionSite: ConstructionSite;
  createTimelapseSetting?: Maybe<TimelapseSetting>;
  createTokenBasedAccess?: Maybe<TokenBasedAccess>;
  createUpdateBimFile: CameraBimFile;
  createUpdateCameraConfiguration: CameraConfiguration;
  createUser: User;
  deleteCamera: Scalars['Int']['output'];
  deleteCameraConfiguration: Scalars['Int']['output'];
  deleteCompany: Scalars['Int']['output'];
  deleteConstructionSite: Scalars['Int']['output'];
  downloadTimelapse?: Maybe<Scalars['Int']['output']>;
  exportGateReports: GateReportsExportResponse;
  regenerateCameraSmtpCreds?: Maybe<CameraSmtpCreds>;
  removeCompanyAdmin: CompanyAdmin;
  removeUserSite: ConstructionSiteUser;
  requestUpdateCompanyLogo: UploadUrl;
  restoreTimelapse: TimelapseSetting;
  testCameraConfiguration: CameraConfigurationTestResult;
  updateBimFileSceneState: CameraBimFile;
  updateCamera?: Maybe<Camera>;
  updateCameraReportSchedule: CameraReportSchedule;
  updateCompany: ConstructionCompanyWithPerms;
  updateConstructionSite: ConstructionSite;
  updateMe: User;
  updateTimelapseSetting?: Maybe<TimelapseSetting>;
  updateUser: User;
  updateUserSite: ConstructionSiteUser;
};


export type MutationAddCompanyArgs = {
  input: CreateConstructionCompanyInput;
};


export type MutationAddCompanyAdminArgs = {
  input: AddCompanyAdminInput;
};


export type MutationAddGateReportArgs = {
  input: CreateGateReportInput;
};


export type MutationAddUserSiteArgs = {
  input: AddUpdateUserSiteInput;
};


export type MutationChangeTimelapseStatusArgs = {
  id: Scalars['Int']['input'];
  status: TimelapseStatus;
};


export type MutationCreateAdminArgs = {
  input: AdminCreateInput;
};


export type MutationCreateCameraArgs = {
  input: CameraCreateInput;
  siteId: Scalars['Int']['input'];
};


export type MutationCreateCameraReportScheduleArgs = {
  input: CreateCameraReportScheduleInput;
};


export type MutationCreateCompanyAdminArgs = {
  input: CompanyAdminCreateInput;
};


export type MutationCreateConstructionSiteArgs = {
  companyId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateTimelapseSettingArgs = {
  cameraId: Scalars['Int']['input'];
  frameFrequency: Frequency;
  name: Scalars['String']['input'];
  siteId: Scalars['Int']['input'];
  watermark?: InputMaybe<Scalars['String']['input']>;
  workingDays: Array<WorkingDay>;
  workingHoursEnd: Scalars['String']['input'];
  workingHoursStart: Scalars['String']['input'];
};


export type MutationCreateTokenBasedAccessArgs = {
  input: TokenBasedAccessInput;
};


export type MutationCreateUpdateBimFileArgs = {
  input: CreateUpdateBimFileInput;
};


export type MutationCreateUpdateCameraConfigurationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  input: CameraConfigurationCreateUpdateInput;
};


export type MutationCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationDeleteCameraArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCameraConfigurationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteConstructionSiteArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDownloadTimelapseArgs = {
  input: DownloadPartialTimelapseInput;
};


export type MutationExportGateReportsArgs = {
  filters?: InputMaybe<GateReportsExportFilterInput>;
};


export type MutationRegenerateCameraSmtpCredsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveCompanyAdminArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveUserSiteArgs = {
  input: IdRequiredInput;
};


export type MutationRequestUpdateCompanyLogoArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRestoreTimelapseArgs = {
  input: RestoreTimelapseInput;
};


export type MutationTestCameraConfigurationArgs = {
  configuration: CameraConfigurationTestInput;
  credentials: CameraCredentialsInput;
};


export type MutationUpdateBimFileSceneStateArgs = {
  input: UpdateBimFileSceneState;
};


export type MutationUpdateCameraArgs = {
  id: Scalars['Int']['input'];
  input: CameraUpdateInput;
};


export type MutationUpdateCameraReportScheduleArgs = {
  input: UpdateCameraReportScheduleInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateConstructionCompanyInput;
};


export type MutationUpdateConstructionSiteArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  procoreConnectionParam?: InputMaybe<ProcoreConnectionParams>;
  status?: InputMaybe<ConstructionSiteStatus>;
  timezone: Scalars['String']['input'];
  users?: InputMaybe<ConstructionSiteUserInput>;
};


export type MutationUpdateMeArgs = {
  input: MeUpdateInput;
};


export type MutationUpdateTimelapseSettingArgs = {
  frameFrequency?: InputMaybe<Frequency>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  watermark?: InputMaybe<Scalars['String']['input']>;
  workingDays?: InputMaybe<Array<WorkingDay>>;
  workingHoursEnd?: InputMaybe<Scalars['String']['input']>;
  workingHoursStart?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
};


export type MutationUpdateUserSiteArgs = {
  input: AddUpdateUserSiteInput;
};

export enum ObjectType {
  Camera = 'CAMERA',
  Site = 'SITE'
}

export type PartialTimePatterns = {
  __typename?: 'PartialTimePatterns';
  day: Scalars['String']['output'];
  hour: Scalars['String']['output'];
  minute: Scalars['String']['output'];
  month: Scalars['String']['output'];
  year: Scalars['String']['output'];
};

export type PartialTimePatternsInput = {
  day: Scalars['String']['input'];
  hour: Scalars['String']['input'];
  minute: Scalars['String']['input'];
  month: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export type ProcoreConnectionParams = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
};

export type ProcoreConnectionParamsType = {
  __typename?: 'ProcoreConnectionParamsType';
  companyId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  constructionCompainesForUser: Array<ConstructionCompany>;
  constructionCompanies: Array<ConstructionCompany>;
  constructionCompany: ConstructionCompanyWithPerms;
  constructionSite: ConstructionSite;
  constructionSites: Array<ConstructionSite>;
  forgeAuthToken: ForgeAuthToken;
  gateReport: GateReport;
  gateReportObjects: Array<GateReportObject>;
  gateReportTypes: Array<GateReportType>;
  gateReports: GateReportsResponse;
  getCameraConfiguration: CameraConfiguration;
  getCameraConfigurations: Array<CameraConfiguration>;
  getCameraSmtpCreds?: Maybe<CameraSmtpCreds>;
  getCameraSnapshots: CameraSnapshotResult;
  getSiteCamera: Camera;
  getTimelapseFragmentUrl: Scalars['String']['output'];
  getTimelapseMp4Url: Scalars['String']['output'];
  getTimelapsePlaylist: Scalars['String']['output'];
  getTimelapseUrl: Scalars['String']['output'];
  me: User;
  mediaPermissions?: Maybe<Scalars['String']['output']>;
  user: User;
  users: Array<User>;
};


export type QueryConstructionCompainesForUserArgs = {
  userEmail: Scalars['String']['input'];
};


export type QueryConstructionCompaniesArgs = {
  filters?: InputMaybe<CompanyFiltersInput>;
};


export type QueryConstructionCompanyArgs = {
  id: Scalars['Int']['input'];
};


export type QueryConstructionSiteArgs = {
  id: Scalars['Int']['input'];
};


export type QueryConstructionSitesArgs = {
  filters?: InputMaybe<ConstructionSitesFilterInput>;
};


export type QueryGateReportArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGateReportsArgs = {
  filters?: InputMaybe<GateReportsFilterInput>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryGetCameraConfigurationArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCameraConfigurationsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCameraSmtpCredsArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCameraSnapshotsArgs = {
  filters: CameraSnapshotFilterInput;
};


export type QueryGetSiteCameraArgs = {
  id: Scalars['Int']['input'];
  snapshotTime?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTimelapseFragmentUrlArgs = {
  id: Scalars['Int']['input'];
  url: Scalars['String']['input'];
};


export type QueryGetTimelapseMp4UrlArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetTimelapsePlaylistArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetTimelapseUrlArgs = {
  id: Scalars['Int']['input'];
};


export type QueryMediaPermissionsArgs = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryUserArgs = {
  email: Scalars['String']['input'];
};

export type RestoreTimelapseInput = {
  id: Scalars['Int']['input'];
  utcDateFrom: Scalars['String']['input'];
  utcDateTo: Scalars['String']['input'];
};

export enum RestoreTimelapseTaskExitCode {
  Error = 'ERROR',
  NoNewFragments = 'NO_NEW_FRAGMENTS',
  Success = 'SUCCESS'
}

export type RestoreTimelapseTaskInput = {
  __typename?: 'RestoreTimelapseTaskInput';
  cameraName: Scalars['String']['output'];
  epochFrom: Scalars['Int']['output'];
  epochTo: Scalars['Int']['output'];
  frameFrequency: Frequency;
  initiatorEmail?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['Int']['output'];
  timeFrom: Scalars['String']['output'];
  timeTo: Scalars['String']['output'];
  timelapseId: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
  weekDays: Array<WorkingDay>;
};

export type RestoreTimelapseTaskResult = {
  __typename?: 'RestoreTimelapseTaskResult';
  cameraName: Scalars['String']['output'];
  epochFrom: Scalars['Int']['output'];
  epochTo: Scalars['Int']['output'];
  exitCode: RestoreTimelapseTaskExitCode;
  frameFrequency: Frequency;
  initiatorEmail?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['Int']['output'];
  timeFrom: Scalars['String']['output'];
  timeTo: Scalars['String']['output'];
  timelapseId: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
  weekDays: Array<WorkingDay>;
};

export type SiteGateReport = {
  __typename?: 'SiteGateReport';
  boxId?: Maybe<Scalars['String']['output']>;
  cameraId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventSource?: Maybe<Scalars['String']['output']>;
  eventTimestamp?: Maybe<Scalars['Int']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['Int']['output']>;
  snapshot_s3?: Maybe<Scalars['String']['output']>;
};

export enum TaskError {
  MetaNotFound = 'META_NOT_FOUND',
  NoFragmentsFound = 'NO_FRAGMENTS_FOUND'
}

/** Defines types and constants of Tasks that are shared between services. */
export enum TaskType {
  ConvertPlaylist = 'CONVERT_PLAYLIST',
  RestoreTimelapse = 'RESTORE_TIMELAPSE'
}

export type TimelapseSetting = CreatedUpdated & {
  __typename?: 'TimelapseSetting';
  camera?: Maybe<Camera>;
  cameraId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  frameFrequency: Frequency;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  siteId: Scalars['Int']['output'];
  status?: Maybe<TimelapseStatus>;
  updatedAt: Scalars['DateTime']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
  watermark?: Maybe<Scalars['String']['output']>;
  workingDays?: Maybe<Array<WorkingDay>>;
  workingHoursEnd?: Maybe<Scalars['String']['output']>;
  workingHoursStart?: Maybe<Scalars['String']['output']>;
};

export enum TimelapseStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  NoConnection = 'NO_CONNECTION'
}

export type TokenBasedAccess = {
  __typename?: 'TokenBasedAccess';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  objectId: Scalars['Int']['output'];
  objectType: ObjectType;
  token: Scalars['String']['output'];
};

export type TokenBasedAccessInput = {
  objectId: Scalars['Int']['input'];
  objectType: ObjectType;
};

export type UpdateBimFileSceneState = {
  id: Scalars['Int']['input'];
  opacity: Scalars['Int']['input'];
  sceneState: Scalars['String']['input'];
};

export type UpdateCameraReportScheduleInput = {
  camera: Scalars['Int']['input'];
  days?: InputMaybe<Array<WorkingDay>>;
  id: Scalars['Int']['input'];
  status?: InputMaybe<CameraReportScheduleStatus>;
  time?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateConstructionCompanyInput = {
  canViewBIMCompare?: InputMaybe<Scalars['Boolean']['input']>;
  canViewBIMView?: InputMaybe<Scalars['Boolean']['input']>;
  canViewEditFrame?: InputMaybe<Scalars['Boolean']['input']>;
  canViewGateReport?: InputMaybe<Scalars['Boolean']['input']>;
  canViewLiveView?: InputMaybe<Scalars['Boolean']['input']>;
  canViewSiteProgress?: InputMaybe<Scalars['Boolean']['input']>;
  canViewTimelapse?: InputMaybe<Scalars['Boolean']['input']>;
  canViewUserManagement?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  smtpFrom?: InputMaybe<Scalars['String']['input']>;
  smtpHost?: InputMaybe<Scalars['String']['input']>;
  smtpPass?: InputMaybe<Scalars['String']['input']>;
  smtpPort?: InputMaybe<Scalars['Int']['input']>;
  smtpUser?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CompanyStatus>;
  withLogo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  uploadUrl: Scalars['String']['output'];
};

export type User = CreatedUpdated & {
  __typename?: 'User';
  acceptsExtraEmails?: Maybe<Scalars['Boolean']['output']>;
  companies?: Maybe<Array<CompanyAdmin>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  email: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  joiningAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissionsFromCompany?: Maybe<ConstructionCompanyPermission>;
  phone?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<ConstructionSiteUser>>;
  status?: Maybe<UserStatus>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
};

export type UserCreateInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  role: UserRole;
  site: Scalars['Int']['input'];
};

export enum UserRole {
  CoreOnly = 'CORE_ONLY',
  General = 'GENERAL',
  Guest = 'GUEST',
  Manager = 'MANAGER'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED'
}

export type UserUpdateInput = {
  email: Scalars['String']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissionsFromCompany?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
};

export enum VideoFormat {
  Mp4 = 'mp4'
}

export enum WorkingDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type AddCameraReportScheduleMutationVariables = Exact<{
  input: CreateCameraReportScheduleInput;
}>;


export type AddCameraReportScheduleMutation = { __typename?: 'Mutation', createCameraReportSchedule: { __typename?: 'CameraReportSchedule', id: number, days: Array<WorkingDay>, time: Array<string>, status: CameraReportScheduleStatus } };

export type AddCompanyAdminMutationVariables = Exact<{
  input: AddCompanyAdminInput;
}>;


export type AddCompanyAdminMutation = { __typename?: 'Mutation', addCompanyAdmin: { __typename?: 'CompanyAdmin', id: number } };

export type AddCompanyMutationVariables = Exact<{
  input: CreateConstructionCompanyInput;
}>;


export type AddCompanyMutation = { __typename?: 'Mutation', addCompany: { __typename?: 'ConstructionCompany', id: number } };

export type CreateSiteMutationVariables = Exact<{
  name: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
}>;


export type CreateSiteMutation = { __typename?: 'Mutation', createConstructionSite: { __typename?: 'ConstructionSite', id: number } };

export type CreateUpdateBimFileMutationVariables = Exact<{
  input: CreateUpdateBimFileInput;
}>;


export type CreateUpdateBimFileMutation = { __typename?: 'Mutation', createUpdateBimFile: { __typename?: 'CameraBimFile', id: number } };

export type AddUserSiteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  role: UserRole;
  site: Scalars['Int']['input'];
}>;


export type AddUserSiteMutation = { __typename?: 'Mutation', addUserSite: { __typename?: 'ConstructionSiteUser', id: number, role: UserRole, site: { __typename?: 'ConstructionSite', id: number, name: string } } };

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', email: string } };

export type CreateAdminMutationVariables = Exact<{
  input: AdminCreateInput;
}>;


export type CreateAdminMutation = { __typename?: 'Mutation', createAdmin: { __typename?: 'User', email: string } };

export type CreateCompAdminMutationVariables = Exact<{
  input: CompanyAdminCreateInput;
}>;


export type CreateCompAdminMutation = { __typename?: 'Mutation', createCompanyAdmin: { __typename?: 'User', email: string } };

export type CreateAccessTokenMutationVariables = Exact<{
  input: TokenBasedAccessInput;
}>;


export type CreateAccessTokenMutation = { __typename?: 'Mutation', createTokenBasedAccess?: { __typename?: 'TokenBasedAccess', id: number, createdAt: string, objectId: number, objectType: ObjectType, token: string } | null };

export type CreateCameraMutationVariables = Exact<{
  siteId: Scalars['Int']['input'];
  input: CameraCreateInput;
}>;


export type CreateCameraMutation = { __typename?: 'Mutation', createCamera?: { __typename?: 'Camera', id: number } | null };

export type CreateUpdateCameraConfigurationMutationVariables = Exact<{
  cameraId?: InputMaybe<Scalars['Int']['input']>;
  input: CameraConfigurationCreateUpdateInput;
}>;


export type CreateUpdateCameraConfigurationMutation = { __typename?: 'Mutation', createUpdateCameraConfiguration: { __typename?: 'CameraConfiguration', id: number } };

export type DeleteCameraConfigurationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteCameraConfigurationMutation = { __typename?: 'Mutation', deleteCameraConfiguration: number };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: number };

export type DeleteConstructionSiteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteConstructionSiteMutation = { __typename?: 'Mutation', deleteConstructionSite: number };

export type DeleteCameraMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteCameraMutation = { __typename?: 'Mutation', deleteCamera: number };

export type ExportGateReportsMutationVariables = Exact<{
  filters?: InputMaybe<GateReportsExportFilterInput>;
}>;


export type ExportGateReportsMutation = { __typename?: 'Mutation', exportGateReports: { __typename?: 'GateReportsExportResponse', eta: number, recipientEmail: string } };

export type RegenerateCameraSmtpCredsMutationVariables = Exact<{
  cameraId: Scalars['Int']['input'];
}>;


export type RegenerateCameraSmtpCredsMutation = { __typename?: 'Mutation', regenerateCameraSmtpCreds?: { __typename?: 'CameraSmtpCreds', email?: string | null, password?: string | null } | null };

export type RemoveCompanyAdminMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveCompanyAdminMutation = { __typename?: 'Mutation', removeCompanyAdmin: { __typename?: 'CompanyAdmin', id: number } };

export type RemoveUserSiteMutationVariables = Exact<{
  userSiteId: Scalars['Int']['input'];
}>;


export type RemoveUserSiteMutation = { __typename?: 'Mutation', removeUserSite: { __typename?: 'ConstructionSiteUser', id: number } };

export type RequestTimelapseDownloadMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type RequestTimelapseDownloadMutation = { __typename?: 'Mutation', downloadTimelapse?: number | null };

export type RequestUpdateCompanyLogoMutationVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type RequestUpdateCompanyLogoMutation = { __typename?: 'Mutation', requestUpdateCompanyLogo: { __typename?: 'UploadUrl', uploadUrl: string } };

export type RestoreTimelapseMutationVariables = Exact<{
  input: RestoreTimelapseInput;
}>;


export type RestoreTimelapseMutation = { __typename?: 'Mutation', restoreTimelapse: { __typename?: 'TimelapseSetting', name: string, id: number } };

export type TestCameraConfigurationMutationVariables = Exact<{
  credentials: CameraCredentialsInput;
  configuration: CameraConfigurationTestInput;
}>;


export type TestCameraConfigurationMutation = { __typename?: 'Mutation', testCameraConfiguration: { __typename?: 'CameraConfigurationTestResult', timestampResponseType: CameraConfigurationTestResultResponseType, timestampResponse: string, snapshotResponseType?: CameraConfigurationTestResultResponseType | null, snapshotResponse?: string | null, parsedDate?: string | null, warnings: Array<string> } };

export type CreateTimelapseSettingMutationVariables = Exact<{
  siteId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  cameraId: Scalars['Int']['input'];
  frameFrequency: Frequency;
  workingDays: Array<WorkingDay> | WorkingDay;
  workingHoursStart: Scalars['String']['input'];
  workingHoursEnd: Scalars['String']['input'];
  watermark?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateTimelapseSettingMutation = { __typename?: 'Mutation', createTimelapseSetting?: { __typename?: 'TimelapseSetting', id: number, status?: TimelapseStatus | null } | null };

export type UpdateTimelapseSettingMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  frameFrequency?: InputMaybe<Frequency>;
  workingDays?: InputMaybe<Array<WorkingDay> | WorkingDay>;
  workingHoursStart?: InputMaybe<Scalars['String']['input']>;
  workingHoursEnd?: InputMaybe<Scalars['String']['input']>;
  watermark?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateTimelapseSettingMutation = { __typename?: 'Mutation', updateTimelapseSetting?: { __typename?: 'TimelapseSetting', id: number, status?: TimelapseStatus | null } | null };

export type UpdateCameraReportScheduleMutationVariables = Exact<{
  input: UpdateCameraReportScheduleInput;
}>;


export type UpdateCameraReportScheduleMutation = { __typename?: 'Mutation', updateCameraReportSchedule: { __typename?: 'CameraReportSchedule', id: number, status: CameraReportScheduleStatus, days: Array<WorkingDay>, time: Array<string> } };

export type UpdateCameraMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: CameraUpdateInput;
}>;


export type UpdateCameraMutation = { __typename?: 'Mutation', updateCamera?: { __typename?: 'Camera', id: number } | null };

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateConstructionCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'ConstructionCompanyWithPerms', id: number } };

export type UpdateMeMutationVariables = Exact<{
  input: MeUpdateInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'User', email: string, phone?: string | null, name?: string | null, acceptsExtraEmails?: boolean | null } };

export type UpdateSiteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConstructionSiteStatus>;
  timezone: Scalars['String']['input'];
  procoreConnectionParam?: InputMaybe<ProcoreConnectionParams>;
  users?: InputMaybe<ConstructionSiteUserInput>;
}>;


export type UpdateSiteMutation = { __typename?: 'Mutation', updateConstructionSite: { __typename?: 'ConstructionSite', id: number, name: string, status: ConstructionSiteStatus, procoreConnectionParam?: { __typename?: 'ProcoreConnectionParamsType', companyId?: string | null, projectId?: string | null } | null } };

export type UpdateBimFileSceneStateMutationVariables = Exact<{
  input: UpdateBimFileSceneState;
}>;


export type UpdateBimFileSceneStateMutation = { __typename?: 'Mutation', updateBimFileSceneState: { __typename?: 'CameraBimFile', id: number, sceneState: string, opacity: number } };

export type UpdateTimelapseStatusMutationVariables = Exact<{
  changeTimelapseStatusId: Scalars['Int']['input'];
  status: TimelapseStatus;
}>;


export type UpdateTimelapseStatusMutation = { __typename?: 'Mutation', changeTimelapseStatus: { __typename?: 'TimelapseSetting', id: number, status?: TimelapseStatus | null } };

export type UpdateUserSiteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  role: UserRole;
  site: Scalars['Int']['input'];
}>;


export type UpdateUserSiteMutation = { __typename?: 'Mutation', updateUserSite: { __typename?: 'ConstructionSiteUser', id: number, role: UserRole, site: { __typename?: 'ConstructionSite', id: number, name: string } } };

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  permissionsFromCompany?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', email: string, isAdmin: boolean, name?: string | null, phone?: string | null, status?: UserStatus | null } };

export type GetCameraChoicesQueryVariables = Exact<{
  constructionSiteId: Scalars['Int']['input'];
}>;


export type GetCameraChoicesQuery = { __typename?: 'Query', constructionSite: { __typename?: 'ConstructionSite', id: number, timezone?: string | null, cameras?: Array<{ __typename?: 'Camera', id: number, name: string, snapshot?: string | null, health?: { __typename?: 'CameraHealth', status: CameraStatus, lastSeen?: string | null } | null }> | null } };

export type GetSnapshotQueryVariables = Exact<{
  cameraId: Scalars['Int']['input'];
  snapshotTime?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSnapshotQuery = { __typename?: 'Query', getSiteCamera: { __typename?: 'Camera', snapshot?: string | null } };

export type GetCameraConfigurationsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCameraConfigurationsQuery = { __typename?: 'Query', getCameraConfigurations: Array<{ __typename?: 'CameraConfiguration', id: number, name: string, statusUri: string, snapshotUri: string, timeFormat: CameraTimeFormat, createdAt: string, updatedAt: string, assignedCamerasCount?: number | null }> };

export type GetCameraConfigurationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetCameraConfigurationQuery = { __typename?: 'Query', getCameraConfiguration: { __typename?: 'CameraConfiguration', id: number, name: string, statusUri: string, snapshotUri: string, timeFormat: CameraTimeFormat, authenticationMethod: CameraAuthenticationMethod, timezone?: CameraTimezoneSource | null, isAvailableForUsers: boolean, createdAt: string, updatedAt: string, timeParsingRules: { __typename?: 'CameraTimeParsingRules', pattern?: string | null, customTimeFormat?: string | null, partialPatterns?: { __typename?: 'PartialTimePatterns', year: string, month: string, day: string, hour: string, minute: string } | null } } };

export type GetCameraSnapshotsQueryVariables = Exact<{
  filters: CameraSnapshotFilterInput;
}>;


export type GetCameraSnapshotsQuery = { __typename?: 'Query', getCameraSnapshots: { __typename?: 'CameraSnapshotResult', currentItemIndex?: number | null, results: Array<{ __typename?: 'CameraSnapshotItem', id: number, cameraId: number, snapshotUrl: string, createdAt: string }> } };

export type GetSiteCamerasQueryVariables = Exact<{
  constructionSiteId: Scalars['Int']['input'];
  snapshotTime?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSiteCamerasQuery = { __typename?: 'Query', constructionSite: { __typename?: 'ConstructionSite', id: number, cameras?: Array<{ __typename?: 'Camera', id: number, name: string, siteId: number, snapshot?: string | null, health?: { __typename?: 'CameraHealth', status: CameraStatus, lastSeen?: string | null } | null, bimFile?: { __typename?: 'CameraBimFile', id: number, fname: string, cameraId: number, conversionStatus: ConversionStatus, forgeLocation: string, forgeObjectId: string, sceneState: string, opacity: number, createdAt: string, updatedAt: string } | null }> | null } };

export type GetSiteCameraQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  snapshotTime?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSiteCameraQuery = { __typename?: 'Query', getSiteCamera: { __typename?: 'Camera', id: number, timezone?: string | null, snapshot?: string | null } };

export type GetFullCameraQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetFullCameraQuery = { __typename?: 'Query', getSiteCamera: { __typename?: 'Camera', id: number, name: string, address?: string | null, enableHealthCheck?: boolean | null, healthCheckEmails?: Array<string | null> | null, workingDays?: Array<WorkingDay | null> | null, workingHoursStart?: string | null, workingHoursEnd?: string | null } };

export type GetCompaniesForUserQueryVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type GetCompaniesForUserQuery = { __typename?: 'Query', constructionCompainesForUser: Array<{ __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string }> };

export type GetCompaniesQueryVariables = Exact<{
  filters?: InputMaybe<CompanyFiltersInput>;
}>;


export type GetCompaniesQuery = { __typename?: 'Query', constructionCompanies: Array<{ __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string, activeSites?: Array<{ __typename?: 'ConstructionSite', id: number, name: string, status: ConstructionSiteStatus, createdAt: string, updatedAt: string, companyId: number }> | null }> };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', constructionCompany: { __typename?: 'ConstructionCompanyWithPerms', id: number, name: string, description?: string | null, status: CompanyStatus, createdAt: string, updatedAt: string, createdById?: number | null, updatedById?: number | null, canViewUserManagement: boolean, canViewLiveView: boolean, canViewSiteProgress: boolean, canViewTimelapse: boolean, canViewGateReport: boolean, canViewBIMCompare: boolean, canViewBIMView: boolean, canViewEditFrame: boolean, smtpHost?: string | null, smtpPort?: number | null, smtpUser?: string | null, smtpPass?: string | null, smtpFrom?: string | null } };

export type GetForgeAuthTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetForgeAuthTokenQuery = { __typename?: 'Query', forgeAuthToken: { __typename?: 'ForgeAuthToken', access_token: string, expires_in: number, refresh_token?: string | null, token_type: string } };

export type GetGateReportQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetGateReportQuery = { __typename?: 'Query', gateReport: { __typename?: 'GateReport', id: number, orgId?: string | null, siteId?: number | null, boxId?: string | null, cameraId?: string | null, eventTimestamp?: number | null, eventSource?: string | null, eventObject?: GateReportEventObject | null, eventType?: GateReportEventType | null, snapshot_s3?: string | null, payload?: string | null } };

export type GetGateReportsQueryVariables = Exact<{
  filters?: InputMaybe<GateReportsFilterInput>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;


export type GetGateReportsQuery = { __typename?: 'Query', gateReports: { __typename?: 'GateReportsResponse', count: number, hasMore?: boolean | null, gateReports: Array<{ __typename?: 'GateReport', id: number, orgId?: string | null, siteId?: number | null, boxId?: string | null, cameraId?: string | null, eventTimestamp?: number | null, eventSource?: string | null, eventObject?: GateReportEventObject | null, eventType?: GateReportEventType | null, snapshot_s3?: string | null, payload?: string | null }> } };

export type GateReportTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GateReportTypesQuery = { __typename?: 'Query', gateReportTypes: Array<{ __typename?: 'GateReportType', name?: string | null }> };

export type GateReportObjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GateReportObjectsQuery = { __typename?: 'Query', gateReportObjects: Array<{ __typename?: 'GateReportObject', name?: string | null }> };

export type GetMinimalCameraConfigurationsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMinimalCameraConfigurationsQuery = { __typename?: 'Query', getCameraConfigurations: Array<{ __typename?: 'CameraConfiguration', id: number, name: string }> };

export type GetBaseSiteQueryVariables = Exact<{
  constructionSiteId: Scalars['Int']['input'];
}>;


export type GetBaseSiteQuery = { __typename?: 'Query', constructionSite: { __typename?: 'ConstructionSite', id: number, name: string, status: ConstructionSiteStatus, updatedAt: string, createdAt: string, companyId: number, timezone?: string | null } };

export type GetSiteGateReportsQueryVariables = Exact<{
  constructionSiteId: Scalars['Int']['input'];
}>;


export type GetSiteGateReportsQuery = { __typename?: 'Query', constructionSite: { __typename?: 'ConstructionSite', id: number, gatereports?: Array<{ __typename?: 'SiteGateReport', id: number, orgId?: string | null, siteId?: number | null, boxId?: string | null, cameraId?: string | null, eventTimestamp?: number | null, eventSource?: string | null, eventType?: string | null, snapshot_s3?: string | null, payload?: string | null }> | null } };

export type GetCameraSmtpCredsQueryVariables = Exact<{
  cameraId: Scalars['Int']['input'];
}>;


export type GetCameraSmtpCredsQuery = { __typename?: 'Query', getCameraSmtpCreds?: { __typename?: 'CameraSmtpCreds', email?: string | null, password?: string | null } | null };

export type GetSiteUsersQueryVariables = Exact<{
  constructionSiteId: Scalars['Int']['input'];
}>;


export type GetSiteUsersQuery = { __typename?: 'Query', constructionSite: { __typename?: 'ConstructionSite', id: number, users?: Array<{ __typename?: 'ConstructionSiteUser', id: number, role: UserRole, siteId: number, user: { __typename?: 'User', email: string, name?: string | null, phone?: string | null, status?: UserStatus | null, joiningAt?: string | null, isAdmin: boolean } } | null> | null } };

export type GetSiteQueryVariables = Exact<{
  constructionSiteId: Scalars['Int']['input'];
}>;


export type GetSiteQuery = { __typename?: 'Query', constructionSite: { __typename?: 'ConstructionSite', id: number, name: string, status: ConstructionSiteStatus, timezone?: string | null, updatedAt: string, createdAt: string, companyId: number, company?: { __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string } | null, timelapse?: Array<{ __typename?: 'TimelapseSetting', id: number, name: string, status?: TimelapseStatus | null, frameFrequency: Frequency, createdAt: string, updatedAt: string, workingHoursStart?: string | null, workingHoursEnd?: string | null, workingDays?: Array<WorkingDay> | null, siteId: number, cameraId: number, camera?: { __typename?: 'Camera', id: number, name: string, siteId: number, snapshot?: string | null } | null }> | null, cameras?: Array<{ __typename?: 'Camera', id: number, name: string, address?: string | null, enableHealthCheck?: boolean | null, healthCheckEmails?: Array<string | null> | null, workingDays?: Array<WorkingDay | null> | null, workingHoursStart?: string | null, workingHoursEnd?: string | null, snapshot?: string | null, siteId: number, updateFrequency?: LiveViewUpdateFrequency | null, configuration?: { __typename?: 'CameraConfiguration', id: number, name: string } | null, bimFile?: { __typename?: 'CameraBimFile', id: number, fname: string, cameraId: number, conversionStatus: ConversionStatus, forgeLocation: string, forgeObjectId: string, sceneState: string, opacity: number, createdAt: string, updatedAt: string } | null, reportSchedules?: Array<{ __typename?: 'CameraReportSchedule', id: number, cameraId: number, days: Array<WorkingDay>, time: Array<string>, status: CameraReportScheduleStatus }> | null }> | null, procoreConnectionParam?: { __typename?: 'ProcoreConnectionParamsType', companyId?: string | null, projectId?: string | null } | null } };

export type GetSitesQueryVariables = Exact<{
  filters?: InputMaybe<ConstructionSitesFilterInput>;
}>;


export type GetSitesQuery = { __typename?: 'Query', constructionSites: Array<{ __typename?: 'ConstructionSite', id: number, name: string, status: ConstructionSiteStatus, createdAt: string, updatedAt: string, companyId: number, company?: { __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string, createdById?: number | null, updatedById?: number | null } | null }> };

export type GetSiteTimelapsesQueryVariables = Exact<{
  constructionSiteId: Scalars['Int']['input'];
}>;


export type GetSiteTimelapsesQuery = { __typename?: 'Query', constructionSite: { __typename?: 'ConstructionSite', id: number, name: string, timelapse?: Array<{ __typename?: 'TimelapseSetting', id: number, name: string, status?: TimelapseStatus | null, createdAt: string, updatedAt: string, camera?: { __typename?: 'Camera', id: number, bimFile?: { __typename?: 'CameraBimFile', id: number, forgeLocation: string, fname: string, conversionStatus: ConversionStatus, cameraId: number, createdAt: string, updatedAt: string, forgeObjectId: string, sceneState: string, opacity: number } | null } | null }> | null } };

export type GetTimelapsePlaylistQueryVariables = Exact<{
  timelapseId: Scalars['Int']['input'];
}>;


export type GetTimelapsePlaylistQuery = { __typename?: 'Query', getTimelapsePlaylist: string };

export type GetTimelapseMp4UrlQueryVariables = Exact<{
  timelapseId: Scalars['Int']['input'];
}>;


export type GetTimelapseMp4UrlQuery = { __typename?: 'Query', getTimelapseMp4Url: string };

export type UserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type UserMeQuery = { __typename?: 'Query', me: { __typename?: 'User', email: string, name?: string | null, phone?: string | null, status?: UserStatus | null, isAdmin: boolean, createdAt: string, updatedAt: string, acceptsExtraEmails?: boolean | null, permissionsFromCompany?: { __typename?: 'ConstructionCompanyPermission', id?: number | null, name?: string | null, canViewUserManagement?: boolean | null, canViewLiveView?: boolean | null, canViewSiteProgress?: boolean | null, canViewTimelapse?: boolean | null, canViewGateReport?: boolean | null, canViewBIMCompare?: boolean | null, canViewBIMView?: boolean | null, canViewEditFrame?: boolean | null, logo_s3?: string | null } | null, companies?: Array<{ __typename?: 'CompanyAdmin', id: number, companyId: number, userId: number, createdAt: string, updatedAt: string, company?: { __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string, sites?: Array<{ __typename?: 'ConstructionSite', id: number, createdAt: string, updatedAt: string, companyId: number, status: ConstructionSiteStatus, name: string }> | null } | null }> | null, sites?: Array<{ __typename?: 'ConstructionSiteUser', id: number, role: UserRole, siteId: number, userId: number, createdAt: string, updatedAt: string, user: { __typename?: 'User', createdAt: string, updatedAt: string, email: string, isAdmin: boolean }, site: { __typename?: 'ConstructionSite', id: number, createdAt: string, updatedAt: string, companyId: number, status: ConstructionSiteStatus, name: string, company?: { __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string } | null } }> | null } };

export type GetUserQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', email: string, name?: string | null, phone?: string | null, status?: UserStatus | null, isAdmin: boolean, updatedAt: string, createdAt: string, permissionsFromCompany?: { __typename?: 'ConstructionCompanyPermission', id?: number | null, canViewUserManagement?: boolean | null, canViewLiveView?: boolean | null, canViewSiteProgress?: boolean | null, canViewTimelapse?: boolean | null, canViewGateReport?: boolean | null, canViewBIMCompare?: boolean | null, canViewBIMView?: boolean | null, canViewEditFrame?: boolean | null } | null, sites?: Array<{ __typename?: 'ConstructionSiteUser', id: number, role: UserRole, siteId: number, userId: number, createdAt: string, updatedAt: string, site: { __typename?: 'ConstructionSite', id: number, name: string, companyId: number, status: ConstructionSiteStatus, createdAt: string, updatedAt: string }, user: { __typename?: 'User', email: string, isAdmin: boolean, createdAt: string, updatedAt: string } }> | null, companies?: Array<{ __typename?: 'CompanyAdmin', id: number, companyId: number, userId: number, createdAt: string, updatedAt: string, company?: { __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string } | null }> | null } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', name?: string | null, email: string, isAdmin: boolean, phone?: string | null, status?: UserStatus | null, createdAt: string, updatedAt: string, joiningAt?: string | null, sites?: Array<{ __typename?: 'ConstructionSiteUser', id: number, role: UserRole, siteId: number, userId: number, createdAt: string, updatedAt: string, user: { __typename?: 'User', createdAt: string, updatedAt: string, email: string, isAdmin: boolean }, site: { __typename?: 'ConstructionSite', id: number, createdAt: string, updatedAt: string, companyId: number, status: ConstructionSiteStatus, name: string } }> | null, companies?: Array<{ __typename?: 'CompanyAdmin', id: number, companyId: number, userId: number, createdAt: string, updatedAt: string, company?: { __typename?: 'ConstructionCompany', id: number, name: string, status: CompanyStatus, createdAt: string, updatedAt: string } | null }> | null }> };


export const AddCameraReportScheduleDocument = gql`
    mutation AddCameraReportSchedule($input: CreateCameraReportScheduleInput!) {
  createCameraReportSchedule(input: $input) {
    id
    days
    time
    status
  }
}
    `;
export type AddCameraReportScheduleMutationFn = Apollo.MutationFunction<AddCameraReportScheduleMutation, AddCameraReportScheduleMutationVariables>;

/**
 * __useAddCameraReportScheduleMutation__
 *
 * To run a mutation, you first call `useAddCameraReportScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCameraReportScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCameraReportScheduleMutation, { data, loading, error }] = useAddCameraReportScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCameraReportScheduleMutation(baseOptions?: Apollo.MutationHookOptions<AddCameraReportScheduleMutation, AddCameraReportScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCameraReportScheduleMutation, AddCameraReportScheduleMutationVariables>(AddCameraReportScheduleDocument, options);
      }
export type AddCameraReportScheduleMutationHookResult = ReturnType<typeof useAddCameraReportScheduleMutation>;
export type AddCameraReportScheduleMutationResult = Apollo.MutationResult<AddCameraReportScheduleMutation>;
export type AddCameraReportScheduleMutationOptions = Apollo.BaseMutationOptions<AddCameraReportScheduleMutation, AddCameraReportScheduleMutationVariables>;
export const AddCompanyAdminDocument = gql`
    mutation AddCompanyAdmin($input: AddCompanyAdminInput!) {
  addCompanyAdmin(input: $input) {
    id
  }
}
    `;
export type AddCompanyAdminMutationFn = Apollo.MutationFunction<AddCompanyAdminMutation, AddCompanyAdminMutationVariables>;

/**
 * __useAddCompanyAdminMutation__
 *
 * To run a mutation, you first call `useAddCompanyAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyAdminMutation, { data, loading, error }] = useAddCompanyAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyAdminMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyAdminMutation, AddCompanyAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyAdminMutation, AddCompanyAdminMutationVariables>(AddCompanyAdminDocument, options);
      }
export type AddCompanyAdminMutationHookResult = ReturnType<typeof useAddCompanyAdminMutation>;
export type AddCompanyAdminMutationResult = Apollo.MutationResult<AddCompanyAdminMutation>;
export type AddCompanyAdminMutationOptions = Apollo.BaseMutationOptions<AddCompanyAdminMutation, AddCompanyAdminMutationVariables>;
export const AddCompanyDocument = gql`
    mutation AddCompany($input: CreateConstructionCompanyInput!) {
  addCompany(input: $input) {
    id
  }
}
    `;
export type AddCompanyMutationFn = Apollo.MutationFunction<AddCompanyMutation, AddCompanyMutationVariables>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyMutation, AddCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(AddCompanyDocument, options);
      }
export type AddCompanyMutationHookResult = ReturnType<typeof useAddCompanyMutation>;
export type AddCompanyMutationResult = Apollo.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<AddCompanyMutation, AddCompanyMutationVariables>;
export const CreateSiteDocument = gql`
    mutation CreateSite($name: String!, $companyId: Int!) {
  createConstructionSite(name: $name, companyId: $companyId) {
    id
  }
}
    `;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const CreateUpdateBimFileDocument = gql`
    mutation CreateUpdateBimFile($input: CreateUpdateBimFileInput!) {
  createUpdateBimFile(input: $input) {
    id
  }
}
    `;
export type CreateUpdateBimFileMutationFn = Apollo.MutationFunction<CreateUpdateBimFileMutation, CreateUpdateBimFileMutationVariables>;

/**
 * __useCreateUpdateBimFileMutation__
 *
 * To run a mutation, you first call `useCreateUpdateBimFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateBimFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateBimFileMutation, { data, loading, error }] = useCreateUpdateBimFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateBimFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateBimFileMutation, CreateUpdateBimFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateBimFileMutation, CreateUpdateBimFileMutationVariables>(CreateUpdateBimFileDocument, options);
      }
export type CreateUpdateBimFileMutationHookResult = ReturnType<typeof useCreateUpdateBimFileMutation>;
export type CreateUpdateBimFileMutationResult = Apollo.MutationResult<CreateUpdateBimFileMutation>;
export type CreateUpdateBimFileMutationOptions = Apollo.BaseMutationOptions<CreateUpdateBimFileMutation, CreateUpdateBimFileMutationVariables>;
export const AddUserSiteDocument = gql`
    mutation AddUserSite($email: String!, $role: UserRole!, $site: Int!) {
  addUserSite(input: {email: $email, role: $role, site: $site}) {
    id
    role
    site {
      id
      name
    }
  }
}
    `;
export type AddUserSiteMutationFn = Apollo.MutationFunction<AddUserSiteMutation, AddUserSiteMutationVariables>;

/**
 * __useAddUserSiteMutation__
 *
 * To run a mutation, you first call `useAddUserSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSiteMutation, { data, loading, error }] = useAddUserSiteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useAddUserSiteMutation(baseOptions?: Apollo.MutationHookOptions<AddUserSiteMutation, AddUserSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserSiteMutation, AddUserSiteMutationVariables>(AddUserSiteDocument, options);
      }
export type AddUserSiteMutationHookResult = ReturnType<typeof useAddUserSiteMutation>;
export type AddUserSiteMutationResult = Apollo.MutationResult<AddUserSiteMutation>;
export type AddUserSiteMutationOptions = Apollo.BaseMutationOptions<AddUserSiteMutation, AddUserSiteMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: UserCreateInput!) {
  createUser(input: $input) {
    email
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateAdminDocument = gql`
    mutation CreateAdmin($input: AdminCreateInput!) {
  createAdmin(input: $input) {
    email
  }
}
    `;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const CreateCompAdminDocument = gql`
    mutation CreateCompAdmin($input: CompanyAdminCreateInput!) {
  createCompanyAdmin(input: $input) {
    email
  }
}
    `;
export type CreateCompAdminMutationFn = Apollo.MutationFunction<CreateCompAdminMutation, CreateCompAdminMutationVariables>;

/**
 * __useCreateCompAdminMutation__
 *
 * To run a mutation, you first call `useCreateCompAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompAdminMutation, { data, loading, error }] = useCreateCompAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompAdminMutation, CreateCompAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompAdminMutation, CreateCompAdminMutationVariables>(CreateCompAdminDocument, options);
      }
export type CreateCompAdminMutationHookResult = ReturnType<typeof useCreateCompAdminMutation>;
export type CreateCompAdminMutationResult = Apollo.MutationResult<CreateCompAdminMutation>;
export type CreateCompAdminMutationOptions = Apollo.BaseMutationOptions<CreateCompAdminMutation, CreateCompAdminMutationVariables>;
export const CreateAccessTokenDocument = gql`
    mutation CreateAccessToken($input: TokenBasedAccessInput!) {
  createTokenBasedAccess(input: $input) {
    id
    createdAt
    objectId
    objectType
    token
  }
}
    `;
export type CreateAccessTokenMutationFn = Apollo.MutationFunction<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;

/**
 * __useCreateAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessTokenMutation, { data, loading, error }] = useCreateAccessTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>(CreateAccessTokenDocument, options);
      }
export type CreateAccessTokenMutationHookResult = ReturnType<typeof useCreateAccessTokenMutation>;
export type CreateAccessTokenMutationResult = Apollo.MutationResult<CreateAccessTokenMutation>;
export type CreateAccessTokenMutationOptions = Apollo.BaseMutationOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;
export const CreateCameraDocument = gql`
    mutation CreateCamera($siteId: Int!, $input: CameraCreateInput!) {
  createCamera(siteId: $siteId, input: $input) {
    id
  }
}
    `;
export type CreateCameraMutationFn = Apollo.MutationFunction<CreateCameraMutation, CreateCameraMutationVariables>;

/**
 * __useCreateCameraMutation__
 *
 * To run a mutation, you first call `useCreateCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCameraMutation, { data, loading, error }] = useCreateCameraMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCameraMutation(baseOptions?: Apollo.MutationHookOptions<CreateCameraMutation, CreateCameraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCameraMutation, CreateCameraMutationVariables>(CreateCameraDocument, options);
      }
export type CreateCameraMutationHookResult = ReturnType<typeof useCreateCameraMutation>;
export type CreateCameraMutationResult = Apollo.MutationResult<CreateCameraMutation>;
export type CreateCameraMutationOptions = Apollo.BaseMutationOptions<CreateCameraMutation, CreateCameraMutationVariables>;
export const CreateUpdateCameraConfigurationDocument = gql`
    mutation CreateUpdateCameraConfiguration($cameraId: Int, $input: CameraConfigurationCreateUpdateInput!) {
  createUpdateCameraConfiguration(id: $cameraId, input: $input) {
    id
  }
}
    `;
export type CreateUpdateCameraConfigurationMutationFn = Apollo.MutationFunction<CreateUpdateCameraConfigurationMutation, CreateUpdateCameraConfigurationMutationVariables>;

/**
 * __useCreateUpdateCameraConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateUpdateCameraConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateCameraConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateCameraConfigurationMutation, { data, loading, error }] = useCreateUpdateCameraConfigurationMutation({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateCameraConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateCameraConfigurationMutation, CreateUpdateCameraConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateCameraConfigurationMutation, CreateUpdateCameraConfigurationMutationVariables>(CreateUpdateCameraConfigurationDocument, options);
      }
export type CreateUpdateCameraConfigurationMutationHookResult = ReturnType<typeof useCreateUpdateCameraConfigurationMutation>;
export type CreateUpdateCameraConfigurationMutationResult = Apollo.MutationResult<CreateUpdateCameraConfigurationMutation>;
export type CreateUpdateCameraConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateUpdateCameraConfigurationMutation, CreateUpdateCameraConfigurationMutationVariables>;
export const DeleteCameraConfigurationDocument = gql`
    mutation DeleteCameraConfiguration($id: Int!) {
  deleteCameraConfiguration(id: $id)
}
    `;
export type DeleteCameraConfigurationMutationFn = Apollo.MutationFunction<DeleteCameraConfigurationMutation, DeleteCameraConfigurationMutationVariables>;

/**
 * __useDeleteCameraConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteCameraConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCameraConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCameraConfigurationMutation, { data, loading, error }] = useDeleteCameraConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCameraConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCameraConfigurationMutation, DeleteCameraConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCameraConfigurationMutation, DeleteCameraConfigurationMutationVariables>(DeleteCameraConfigurationDocument, options);
      }
export type DeleteCameraConfigurationMutationHookResult = ReturnType<typeof useDeleteCameraConfigurationMutation>;
export type DeleteCameraConfigurationMutationResult = Apollo.MutationResult<DeleteCameraConfigurationMutation>;
export type DeleteCameraConfigurationMutationOptions = Apollo.BaseMutationOptions<DeleteCameraConfigurationMutation, DeleteCameraConfigurationMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: Int!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const DeleteConstructionSiteDocument = gql`
    mutation DeleteConstructionSite($id: Int!) {
  deleteConstructionSite(id: $id)
}
    `;
export type DeleteConstructionSiteMutationFn = Apollo.MutationFunction<DeleteConstructionSiteMutation, DeleteConstructionSiteMutationVariables>;

/**
 * __useDeleteConstructionSiteMutation__
 *
 * To run a mutation, you first call `useDeleteConstructionSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConstructionSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConstructionSiteMutation, { data, loading, error }] = useDeleteConstructionSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConstructionSiteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConstructionSiteMutation, DeleteConstructionSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConstructionSiteMutation, DeleteConstructionSiteMutationVariables>(DeleteConstructionSiteDocument, options);
      }
export type DeleteConstructionSiteMutationHookResult = ReturnType<typeof useDeleteConstructionSiteMutation>;
export type DeleteConstructionSiteMutationResult = Apollo.MutationResult<DeleteConstructionSiteMutation>;
export type DeleteConstructionSiteMutationOptions = Apollo.BaseMutationOptions<DeleteConstructionSiteMutation, DeleteConstructionSiteMutationVariables>;
export const DeleteCameraDocument = gql`
    mutation DeleteCamera($id: Int!) {
  deleteCamera(id: $id)
}
    `;
export type DeleteCameraMutationFn = Apollo.MutationFunction<DeleteCameraMutation, DeleteCameraMutationVariables>;

/**
 * __useDeleteCameraMutation__
 *
 * To run a mutation, you first call `useDeleteCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCameraMutation, { data, loading, error }] = useDeleteCameraMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCameraMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCameraMutation, DeleteCameraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCameraMutation, DeleteCameraMutationVariables>(DeleteCameraDocument, options);
      }
export type DeleteCameraMutationHookResult = ReturnType<typeof useDeleteCameraMutation>;
export type DeleteCameraMutationResult = Apollo.MutationResult<DeleteCameraMutation>;
export type DeleteCameraMutationOptions = Apollo.BaseMutationOptions<DeleteCameraMutation, DeleteCameraMutationVariables>;
export const ExportGateReportsDocument = gql`
    mutation ExportGateReports($filters: GateReportsExportFilterInput) {
  exportGateReports(filters: $filters) {
    eta
    recipientEmail
  }
}
    `;
export type ExportGateReportsMutationFn = Apollo.MutationFunction<ExportGateReportsMutation, ExportGateReportsMutationVariables>;

/**
 * __useExportGateReportsMutation__
 *
 * To run a mutation, you first call `useExportGateReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportGateReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportGateReportsMutation, { data, loading, error }] = useExportGateReportsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExportGateReportsMutation(baseOptions?: Apollo.MutationHookOptions<ExportGateReportsMutation, ExportGateReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportGateReportsMutation, ExportGateReportsMutationVariables>(ExportGateReportsDocument, options);
      }
export type ExportGateReportsMutationHookResult = ReturnType<typeof useExportGateReportsMutation>;
export type ExportGateReportsMutationResult = Apollo.MutationResult<ExportGateReportsMutation>;
export type ExportGateReportsMutationOptions = Apollo.BaseMutationOptions<ExportGateReportsMutation, ExportGateReportsMutationVariables>;
export const RegenerateCameraSmtpCredsDocument = gql`
    mutation RegenerateCameraSmtpCreds($cameraId: Int!) {
  regenerateCameraSmtpCreds(id: $cameraId) {
    email
    password
  }
}
    `;
export type RegenerateCameraSmtpCredsMutationFn = Apollo.MutationFunction<RegenerateCameraSmtpCredsMutation, RegenerateCameraSmtpCredsMutationVariables>;

/**
 * __useRegenerateCameraSmtpCredsMutation__
 *
 * To run a mutation, you first call `useRegenerateCameraSmtpCredsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateCameraSmtpCredsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateCameraSmtpCredsMutation, { data, loading, error }] = useRegenerateCameraSmtpCredsMutation({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *   },
 * });
 */
export function useRegenerateCameraSmtpCredsMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateCameraSmtpCredsMutation, RegenerateCameraSmtpCredsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateCameraSmtpCredsMutation, RegenerateCameraSmtpCredsMutationVariables>(RegenerateCameraSmtpCredsDocument, options);
      }
export type RegenerateCameraSmtpCredsMutationHookResult = ReturnType<typeof useRegenerateCameraSmtpCredsMutation>;
export type RegenerateCameraSmtpCredsMutationResult = Apollo.MutationResult<RegenerateCameraSmtpCredsMutation>;
export type RegenerateCameraSmtpCredsMutationOptions = Apollo.BaseMutationOptions<RegenerateCameraSmtpCredsMutation, RegenerateCameraSmtpCredsMutationVariables>;
export const RemoveCompanyAdminDocument = gql`
    mutation RemoveCompanyAdmin($id: Int!) {
  removeCompanyAdmin(id: $id) {
    id
  }
}
    `;
export type RemoveCompanyAdminMutationFn = Apollo.MutationFunction<RemoveCompanyAdminMutation, RemoveCompanyAdminMutationVariables>;

/**
 * __useRemoveCompanyAdminMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyAdminMutation, { data, loading, error }] = useRemoveCompanyAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanyAdminMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyAdminMutation, RemoveCompanyAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyAdminMutation, RemoveCompanyAdminMutationVariables>(RemoveCompanyAdminDocument, options);
      }
export type RemoveCompanyAdminMutationHookResult = ReturnType<typeof useRemoveCompanyAdminMutation>;
export type RemoveCompanyAdminMutationResult = Apollo.MutationResult<RemoveCompanyAdminMutation>;
export type RemoveCompanyAdminMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyAdminMutation, RemoveCompanyAdminMutationVariables>;
export const RemoveUserSiteDocument = gql`
    mutation RemoveUserSite($userSiteId: Int!) {
  removeUserSite(input: {id: $userSiteId}) {
    id
  }
}
    `;
export type RemoveUserSiteMutationFn = Apollo.MutationFunction<RemoveUserSiteMutation, RemoveUserSiteMutationVariables>;

/**
 * __useRemoveUserSiteMutation__
 *
 * To run a mutation, you first call `useRemoveUserSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserSiteMutation, { data, loading, error }] = useRemoveUserSiteMutation({
 *   variables: {
 *      userSiteId: // value for 'userSiteId'
 *   },
 * });
 */
export function useRemoveUserSiteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserSiteMutation, RemoveUserSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserSiteMutation, RemoveUserSiteMutationVariables>(RemoveUserSiteDocument, options);
      }
export type RemoveUserSiteMutationHookResult = ReturnType<typeof useRemoveUserSiteMutation>;
export type RemoveUserSiteMutationResult = Apollo.MutationResult<RemoveUserSiteMutation>;
export type RemoveUserSiteMutationOptions = Apollo.BaseMutationOptions<RemoveUserSiteMutation, RemoveUserSiteMutationVariables>;
export const RequestTimelapseDownloadDocument = gql`
    mutation RequestTimelapseDownload($id: Int!, $dateFrom: DateTime, $dateTo: DateTime) {
  downloadTimelapse(input: {id: $id, dateFrom: $dateFrom, dateTo: $dateTo})
}
    `;
export type RequestTimelapseDownloadMutationFn = Apollo.MutationFunction<RequestTimelapseDownloadMutation, RequestTimelapseDownloadMutationVariables>;

/**
 * __useRequestTimelapseDownloadMutation__
 *
 * To run a mutation, you first call `useRequestTimelapseDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTimelapseDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTimelapseDownloadMutation, { data, loading, error }] = useRequestTimelapseDownloadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useRequestTimelapseDownloadMutation(baseOptions?: Apollo.MutationHookOptions<RequestTimelapseDownloadMutation, RequestTimelapseDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestTimelapseDownloadMutation, RequestTimelapseDownloadMutationVariables>(RequestTimelapseDownloadDocument, options);
      }
export type RequestTimelapseDownloadMutationHookResult = ReturnType<typeof useRequestTimelapseDownloadMutation>;
export type RequestTimelapseDownloadMutationResult = Apollo.MutationResult<RequestTimelapseDownloadMutation>;
export type RequestTimelapseDownloadMutationOptions = Apollo.BaseMutationOptions<RequestTimelapseDownloadMutation, RequestTimelapseDownloadMutationVariables>;
export const RequestUpdateCompanyLogoDocument = gql`
    mutation RequestUpdateCompanyLogo($companyId: Int!) {
  requestUpdateCompanyLogo(id: $companyId) {
    uploadUrl
  }
}
    `;
export type RequestUpdateCompanyLogoMutationFn = Apollo.MutationFunction<RequestUpdateCompanyLogoMutation, RequestUpdateCompanyLogoMutationVariables>;

/**
 * __useRequestUpdateCompanyLogoMutation__
 *
 * To run a mutation, you first call `useRequestUpdateCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUpdateCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUpdateCompanyLogoMutation, { data, loading, error }] = useRequestUpdateCompanyLogoMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRequestUpdateCompanyLogoMutation(baseOptions?: Apollo.MutationHookOptions<RequestUpdateCompanyLogoMutation, RequestUpdateCompanyLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestUpdateCompanyLogoMutation, RequestUpdateCompanyLogoMutationVariables>(RequestUpdateCompanyLogoDocument, options);
      }
export type RequestUpdateCompanyLogoMutationHookResult = ReturnType<typeof useRequestUpdateCompanyLogoMutation>;
export type RequestUpdateCompanyLogoMutationResult = Apollo.MutationResult<RequestUpdateCompanyLogoMutation>;
export type RequestUpdateCompanyLogoMutationOptions = Apollo.BaseMutationOptions<RequestUpdateCompanyLogoMutation, RequestUpdateCompanyLogoMutationVariables>;
export const RestoreTimelapseDocument = gql`
    mutation RestoreTimelapse($input: RestoreTimelapseInput!) {
  restoreTimelapse(input: $input) {
    name
    id
  }
}
    `;
export type RestoreTimelapseMutationFn = Apollo.MutationFunction<RestoreTimelapseMutation, RestoreTimelapseMutationVariables>;

/**
 * __useRestoreTimelapseMutation__
 *
 * To run a mutation, you first call `useRestoreTimelapseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTimelapseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTimelapseMutation, { data, loading, error }] = useRestoreTimelapseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreTimelapseMutation(baseOptions?: Apollo.MutationHookOptions<RestoreTimelapseMutation, RestoreTimelapseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreTimelapseMutation, RestoreTimelapseMutationVariables>(RestoreTimelapseDocument, options);
      }
export type RestoreTimelapseMutationHookResult = ReturnType<typeof useRestoreTimelapseMutation>;
export type RestoreTimelapseMutationResult = Apollo.MutationResult<RestoreTimelapseMutation>;
export type RestoreTimelapseMutationOptions = Apollo.BaseMutationOptions<RestoreTimelapseMutation, RestoreTimelapseMutationVariables>;
export const TestCameraConfigurationDocument = gql`
    mutation TestCameraConfiguration($credentials: CameraCredentialsInput!, $configuration: CameraConfigurationTestInput!) {
  testCameraConfiguration(
    credentials: $credentials
    configuration: $configuration
  ) {
    timestampResponseType
    timestampResponse
    snapshotResponseType
    snapshotResponse
    parsedDate
    warnings
  }
}
    `;
export type TestCameraConfigurationMutationFn = Apollo.MutationFunction<TestCameraConfigurationMutation, TestCameraConfigurationMutationVariables>;

/**
 * __useTestCameraConfigurationMutation__
 *
 * To run a mutation, you first call `useTestCameraConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCameraConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCameraConfigurationMutation, { data, loading, error }] = useTestCameraConfigurationMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *      configuration: // value for 'configuration'
 *   },
 * });
 */
export function useTestCameraConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<TestCameraConfigurationMutation, TestCameraConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCameraConfigurationMutation, TestCameraConfigurationMutationVariables>(TestCameraConfigurationDocument, options);
      }
export type TestCameraConfigurationMutationHookResult = ReturnType<typeof useTestCameraConfigurationMutation>;
export type TestCameraConfigurationMutationResult = Apollo.MutationResult<TestCameraConfigurationMutation>;
export type TestCameraConfigurationMutationOptions = Apollo.BaseMutationOptions<TestCameraConfigurationMutation, TestCameraConfigurationMutationVariables>;
export const CreateTimelapseSettingDocument = gql`
    mutation CreateTimelapseSetting($siteId: Int!, $name: String!, $cameraId: Int!, $frameFrequency: Frequency!, $workingDays: [WorkingDay!]!, $workingHoursStart: String!, $workingHoursEnd: String!, $watermark: String) {
  createTimelapseSetting(
    siteId: $siteId
    name: $name
    cameraId: $cameraId
    frameFrequency: $frameFrequency
    workingDays: $workingDays
    workingHoursStart: $workingHoursStart
    workingHoursEnd: $workingHoursEnd
    watermark: $watermark
  ) {
    id
    status
  }
}
    `;
export type CreateTimelapseSettingMutationFn = Apollo.MutationFunction<CreateTimelapseSettingMutation, CreateTimelapseSettingMutationVariables>;

/**
 * __useCreateTimelapseSettingMutation__
 *
 * To run a mutation, you first call `useCreateTimelapseSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimelapseSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimelapseSettingMutation, { data, loading, error }] = useCreateTimelapseSettingMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      name: // value for 'name'
 *      cameraId: // value for 'cameraId'
 *      frameFrequency: // value for 'frameFrequency'
 *      workingDays: // value for 'workingDays'
 *      workingHoursStart: // value for 'workingHoursStart'
 *      workingHoursEnd: // value for 'workingHoursEnd'
 *      watermark: // value for 'watermark'
 *   },
 * });
 */
export function useCreateTimelapseSettingMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimelapseSettingMutation, CreateTimelapseSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimelapseSettingMutation, CreateTimelapseSettingMutationVariables>(CreateTimelapseSettingDocument, options);
      }
export type CreateTimelapseSettingMutationHookResult = ReturnType<typeof useCreateTimelapseSettingMutation>;
export type CreateTimelapseSettingMutationResult = Apollo.MutationResult<CreateTimelapseSettingMutation>;
export type CreateTimelapseSettingMutationOptions = Apollo.BaseMutationOptions<CreateTimelapseSettingMutation, CreateTimelapseSettingMutationVariables>;
export const UpdateTimelapseSettingDocument = gql`
    mutation UpdateTimelapseSetting($id: Int!, $name: String, $frameFrequency: Frequency, $workingDays: [WorkingDay!], $workingHoursStart: String, $workingHoursEnd: String, $watermark: String) {
  updateTimelapseSetting(
    id: $id
    name: $name
    frameFrequency: $frameFrequency
    workingDays: $workingDays
    workingHoursStart: $workingHoursStart
    workingHoursEnd: $workingHoursEnd
    watermark: $watermark
  ) {
    id
    status
  }
}
    `;
export type UpdateTimelapseSettingMutationFn = Apollo.MutationFunction<UpdateTimelapseSettingMutation, UpdateTimelapseSettingMutationVariables>;

/**
 * __useUpdateTimelapseSettingMutation__
 *
 * To run a mutation, you first call `useUpdateTimelapseSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelapseSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelapseSettingMutation, { data, loading, error }] = useUpdateTimelapseSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      frameFrequency: // value for 'frameFrequency'
 *      workingDays: // value for 'workingDays'
 *      workingHoursStart: // value for 'workingHoursStart'
 *      workingHoursEnd: // value for 'workingHoursEnd'
 *      watermark: // value for 'watermark'
 *   },
 * });
 */
export function useUpdateTimelapseSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelapseSettingMutation, UpdateTimelapseSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimelapseSettingMutation, UpdateTimelapseSettingMutationVariables>(UpdateTimelapseSettingDocument, options);
      }
export type UpdateTimelapseSettingMutationHookResult = ReturnType<typeof useUpdateTimelapseSettingMutation>;
export type UpdateTimelapseSettingMutationResult = Apollo.MutationResult<UpdateTimelapseSettingMutation>;
export type UpdateTimelapseSettingMutationOptions = Apollo.BaseMutationOptions<UpdateTimelapseSettingMutation, UpdateTimelapseSettingMutationVariables>;
export const UpdateCameraReportScheduleDocument = gql`
    mutation UpdateCameraReportSchedule($input: UpdateCameraReportScheduleInput!) {
  updateCameraReportSchedule(input: $input) {
    id
    status
    days
    time
  }
}
    `;
export type UpdateCameraReportScheduleMutationFn = Apollo.MutationFunction<UpdateCameraReportScheduleMutation, UpdateCameraReportScheduleMutationVariables>;

/**
 * __useUpdateCameraReportScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateCameraReportScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCameraReportScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCameraReportScheduleMutation, { data, loading, error }] = useUpdateCameraReportScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCameraReportScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCameraReportScheduleMutation, UpdateCameraReportScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCameraReportScheduleMutation, UpdateCameraReportScheduleMutationVariables>(UpdateCameraReportScheduleDocument, options);
      }
export type UpdateCameraReportScheduleMutationHookResult = ReturnType<typeof useUpdateCameraReportScheduleMutation>;
export type UpdateCameraReportScheduleMutationResult = Apollo.MutationResult<UpdateCameraReportScheduleMutation>;
export type UpdateCameraReportScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateCameraReportScheduleMutation, UpdateCameraReportScheduleMutationVariables>;
export const UpdateCameraDocument = gql`
    mutation UpdateCamera($id: Int!, $input: CameraUpdateInput!) {
  updateCamera(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateCameraMutationFn = Apollo.MutationFunction<UpdateCameraMutation, UpdateCameraMutationVariables>;

/**
 * __useUpdateCameraMutation__
 *
 * To run a mutation, you first call `useUpdateCameraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCameraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCameraMutation, { data, loading, error }] = useUpdateCameraMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCameraMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCameraMutation, UpdateCameraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCameraMutation, UpdateCameraMutationVariables>(UpdateCameraDocument, options);
      }
export type UpdateCameraMutationHookResult = ReturnType<typeof useUpdateCameraMutation>;
export type UpdateCameraMutationResult = Apollo.MutationResult<UpdateCameraMutation>;
export type UpdateCameraMutationOptions = Apollo.BaseMutationOptions<UpdateCameraMutation, UpdateCameraMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($input: UpdateConstructionCompanyInput!) {
  updateCompany(input: $input) {
    id
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($input: MeUpdateInput!) {
  updateMe(input: $input) {
    email
    phone
    name
    acceptsExtraEmails
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const UpdateSiteDocument = gql`
    mutation UpdateSite($id: Int!, $name: String, $status: ConstructionSiteStatus, $timezone: String!, $procoreConnectionParam: ProcoreConnectionParams, $users: ConstructionSiteUserInput) {
  updateConstructionSite(
    id: $id
    name: $name
    status: $status
    timezone: $timezone
    procoreConnectionParam: $procoreConnectionParam
    users: $users
  ) {
    id
    name
    status
    procoreConnectionParam {
      companyId
      projectId
    }
  }
}
    `;
export type UpdateSiteMutationFn = Apollo.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      timezone: // value for 'timezone'
 *      procoreConnectionParam: // value for 'procoreConnectionParam'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useUpdateSiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
      }
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = Apollo.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const UpdateBimFileSceneStateDocument = gql`
    mutation UpdateBimFileSceneState($input: UpdateBimFileSceneState!) {
  updateBimFileSceneState(input: $input) {
    id
    sceneState
    opacity
  }
}
    `;
export type UpdateBimFileSceneStateMutationFn = Apollo.MutationFunction<UpdateBimFileSceneStateMutation, UpdateBimFileSceneStateMutationVariables>;

/**
 * __useUpdateBimFileSceneStateMutation__
 *
 * To run a mutation, you first call `useUpdateBimFileSceneStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBimFileSceneStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBimFileSceneStateMutation, { data, loading, error }] = useUpdateBimFileSceneStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBimFileSceneStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBimFileSceneStateMutation, UpdateBimFileSceneStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBimFileSceneStateMutation, UpdateBimFileSceneStateMutationVariables>(UpdateBimFileSceneStateDocument, options);
      }
export type UpdateBimFileSceneStateMutationHookResult = ReturnType<typeof useUpdateBimFileSceneStateMutation>;
export type UpdateBimFileSceneStateMutationResult = Apollo.MutationResult<UpdateBimFileSceneStateMutation>;
export type UpdateBimFileSceneStateMutationOptions = Apollo.BaseMutationOptions<UpdateBimFileSceneStateMutation, UpdateBimFileSceneStateMutationVariables>;
export const UpdateTimelapseStatusDocument = gql`
    mutation UpdateTimelapseStatus($changeTimelapseStatusId: Int!, $status: TimelapseStatus!) {
  changeTimelapseStatus(id: $changeTimelapseStatusId, status: $status) {
    id
    status
  }
}
    `;
export type UpdateTimelapseStatusMutationFn = Apollo.MutationFunction<UpdateTimelapseStatusMutation, UpdateTimelapseStatusMutationVariables>;

/**
 * __useUpdateTimelapseStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTimelapseStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelapseStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelapseStatusMutation, { data, loading, error }] = useUpdateTimelapseStatusMutation({
 *   variables: {
 *      changeTimelapseStatusId: // value for 'changeTimelapseStatusId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTimelapseStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelapseStatusMutation, UpdateTimelapseStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimelapseStatusMutation, UpdateTimelapseStatusMutationVariables>(UpdateTimelapseStatusDocument, options);
      }
export type UpdateTimelapseStatusMutationHookResult = ReturnType<typeof useUpdateTimelapseStatusMutation>;
export type UpdateTimelapseStatusMutationResult = Apollo.MutationResult<UpdateTimelapseStatusMutation>;
export type UpdateTimelapseStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTimelapseStatusMutation, UpdateTimelapseStatusMutationVariables>;
export const UpdateUserSiteDocument = gql`
    mutation UpdateUserSite($email: String!, $role: UserRole!, $site: Int!) {
  updateUserSite(input: {email: $email, role: $role, site: $site}) {
    id
    role
    site {
      id
      name
    }
  }
}
    `;
export type UpdateUserSiteMutationFn = Apollo.MutationFunction<UpdateUserSiteMutation, UpdateUserSiteMutationVariables>;

/**
 * __useUpdateUserSiteMutation__
 *
 * To run a mutation, you first call `useUpdateUserSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSiteMutation, { data, loading, error }] = useUpdateUserSiteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useUpdateUserSiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSiteMutation, UpdateUserSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSiteMutation, UpdateUserSiteMutationVariables>(UpdateUserSiteDocument, options);
      }
export type UpdateUserSiteMutationHookResult = ReturnType<typeof useUpdateUserSiteMutation>;
export type UpdateUserSiteMutationResult = Apollo.MutationResult<UpdateUserSiteMutation>;
export type UpdateUserSiteMutationOptions = Apollo.BaseMutationOptions<UpdateUserSiteMutation, UpdateUserSiteMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($email: String!, $name: String, $isAdmin: Boolean, $phone: String, $status: UserStatus, $permissionsFromCompany: Int) {
  updateUser(
    input: {email: $email, name: $name, isAdmin: $isAdmin, phone: $phone, status: $status, permissionsFromCompany: $permissionsFromCompany}
  ) {
    email
    isAdmin
    name
    phone
    status
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      isAdmin: // value for 'isAdmin'
 *      phone: // value for 'phone'
 *      status: // value for 'status'
 *      permissionsFromCompany: // value for 'permissionsFromCompany'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetCameraChoicesDocument = gql`
    query GetCameraChoices($constructionSiteId: Int!) {
  constructionSite(id: $constructionSiteId) {
    id
    timezone
    cameras {
      id
      name
      snapshot
      health {
        status
        lastSeen
      }
    }
  }
}
    `;

/**
 * __useGetCameraChoicesQuery__
 *
 * To run a query within a React component, call `useGetCameraChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraChoicesQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *   },
 * });
 */
export function useGetCameraChoicesQuery(baseOptions: Apollo.QueryHookOptions<GetCameraChoicesQuery, GetCameraChoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraChoicesQuery, GetCameraChoicesQueryVariables>(GetCameraChoicesDocument, options);
      }
export function useGetCameraChoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraChoicesQuery, GetCameraChoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraChoicesQuery, GetCameraChoicesQueryVariables>(GetCameraChoicesDocument, options);
        }
export type GetCameraChoicesQueryHookResult = ReturnType<typeof useGetCameraChoicesQuery>;
export type GetCameraChoicesLazyQueryHookResult = ReturnType<typeof useGetCameraChoicesLazyQuery>;
export type GetCameraChoicesQueryResult = Apollo.QueryResult<GetCameraChoicesQuery, GetCameraChoicesQueryVariables>;
export const GetSnapshotDocument = gql`
    query GetSnapshot($cameraId: Int!, $snapshotTime: String) {
  getSiteCamera(id: $cameraId, snapshotTime: $snapshotTime) {
    snapshot
  }
}
    `;

/**
 * __useGetSnapshotQuery__
 *
 * To run a query within a React component, call `useGetSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnapshotQuery({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *      snapshotTime: // value for 'snapshotTime'
 *   },
 * });
 */
export function useGetSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetSnapshotQuery, GetSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSnapshotQuery, GetSnapshotQueryVariables>(GetSnapshotDocument, options);
      }
export function useGetSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSnapshotQuery, GetSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSnapshotQuery, GetSnapshotQueryVariables>(GetSnapshotDocument, options);
        }
export type GetSnapshotQueryHookResult = ReturnType<typeof useGetSnapshotQuery>;
export type GetSnapshotLazyQueryHookResult = ReturnType<typeof useGetSnapshotLazyQuery>;
export type GetSnapshotQueryResult = Apollo.QueryResult<GetSnapshotQuery, GetSnapshotQueryVariables>;
export const GetCameraConfigurationsDocument = gql`
    query GetCameraConfigurations($search: String) {
  getCameraConfigurations(search: $search) {
    id
    name
    statusUri
    snapshotUri
    timeFormat
    createdAt
    updatedAt
    assignedCamerasCount
  }
}
    `;

/**
 * __useGetCameraConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetCameraConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraConfigurationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCameraConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCameraConfigurationsQuery, GetCameraConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraConfigurationsQuery, GetCameraConfigurationsQueryVariables>(GetCameraConfigurationsDocument, options);
      }
export function useGetCameraConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraConfigurationsQuery, GetCameraConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraConfigurationsQuery, GetCameraConfigurationsQueryVariables>(GetCameraConfigurationsDocument, options);
        }
export type GetCameraConfigurationsQueryHookResult = ReturnType<typeof useGetCameraConfigurationsQuery>;
export type GetCameraConfigurationsLazyQueryHookResult = ReturnType<typeof useGetCameraConfigurationsLazyQuery>;
export type GetCameraConfigurationsQueryResult = Apollo.QueryResult<GetCameraConfigurationsQuery, GetCameraConfigurationsQueryVariables>;
export const GetCameraConfigurationDocument = gql`
    query GetCameraConfiguration($id: Int!) {
  getCameraConfiguration(id: $id) {
    id
    name
    statusUri
    snapshotUri
    timeFormat
    authenticationMethod
    timezone
    isAvailableForUsers
    createdAt
    updatedAt
    timeParsingRules {
      pattern
      partialPatterns {
        year
        month
        day
        hour
        minute
      }
      customTimeFormat
    }
  }
}
    `;

/**
 * __useGetCameraConfigurationQuery__
 *
 * To run a query within a React component, call `useGetCameraConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCameraConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetCameraConfigurationQuery, GetCameraConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraConfigurationQuery, GetCameraConfigurationQueryVariables>(GetCameraConfigurationDocument, options);
      }
export function useGetCameraConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraConfigurationQuery, GetCameraConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraConfigurationQuery, GetCameraConfigurationQueryVariables>(GetCameraConfigurationDocument, options);
        }
export type GetCameraConfigurationQueryHookResult = ReturnType<typeof useGetCameraConfigurationQuery>;
export type GetCameraConfigurationLazyQueryHookResult = ReturnType<typeof useGetCameraConfigurationLazyQuery>;
export type GetCameraConfigurationQueryResult = Apollo.QueryResult<GetCameraConfigurationQuery, GetCameraConfigurationQueryVariables>;
export const GetCameraSnapshotsDocument = gql`
    query GetCameraSnapshots($filters: CameraSnapshotFilterInput!) {
  getCameraSnapshots(filters: $filters) {
    currentItemIndex
    results {
      id
      cameraId
      snapshotUrl
      createdAt
    }
  }
}
    `;

/**
 * __useGetCameraSnapshotsQuery__
 *
 * To run a query within a React component, call `useGetCameraSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraSnapshotsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCameraSnapshotsQuery(baseOptions: Apollo.QueryHookOptions<GetCameraSnapshotsQuery, GetCameraSnapshotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraSnapshotsQuery, GetCameraSnapshotsQueryVariables>(GetCameraSnapshotsDocument, options);
      }
export function useGetCameraSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraSnapshotsQuery, GetCameraSnapshotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraSnapshotsQuery, GetCameraSnapshotsQueryVariables>(GetCameraSnapshotsDocument, options);
        }
export type GetCameraSnapshotsQueryHookResult = ReturnType<typeof useGetCameraSnapshotsQuery>;
export type GetCameraSnapshotsLazyQueryHookResult = ReturnType<typeof useGetCameraSnapshotsLazyQuery>;
export type GetCameraSnapshotsQueryResult = Apollo.QueryResult<GetCameraSnapshotsQuery, GetCameraSnapshotsQueryVariables>;
export const GetSiteCamerasDocument = gql`
    query GetSiteCameras($constructionSiteId: Int!, $snapshotTime: String) {
  constructionSite(id: $constructionSiteId) {
    id
    cameras(snapshotTime: $snapshotTime) {
      id
      name
      siteId
      snapshot
      health {
        status
        lastSeen
      }
      bimFile {
        id
        fname
        cameraId
        conversionStatus
        forgeLocation
        forgeObjectId
        sceneState
        opacity
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetSiteCamerasQuery__
 *
 * To run a query within a React component, call `useGetSiteCamerasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteCamerasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteCamerasQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *      snapshotTime: // value for 'snapshotTime'
 *   },
 * });
 */
export function useGetSiteCamerasQuery(baseOptions: Apollo.QueryHookOptions<GetSiteCamerasQuery, GetSiteCamerasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteCamerasQuery, GetSiteCamerasQueryVariables>(GetSiteCamerasDocument, options);
      }
export function useGetSiteCamerasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteCamerasQuery, GetSiteCamerasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteCamerasQuery, GetSiteCamerasQueryVariables>(GetSiteCamerasDocument, options);
        }
export type GetSiteCamerasQueryHookResult = ReturnType<typeof useGetSiteCamerasQuery>;
export type GetSiteCamerasLazyQueryHookResult = ReturnType<typeof useGetSiteCamerasLazyQuery>;
export type GetSiteCamerasQueryResult = Apollo.QueryResult<GetSiteCamerasQuery, GetSiteCamerasQueryVariables>;
export const GetSiteCameraDocument = gql`
    query GetSiteCamera($id: Int!, $snapshotTime: String) {
  getSiteCamera(id: $id) {
    id
    timezone
    snapshot(time: $snapshotTime)
  }
}
    `;

/**
 * __useGetSiteCameraQuery__
 *
 * To run a query within a React component, call `useGetSiteCameraQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteCameraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteCameraQuery({
 *   variables: {
 *      id: // value for 'id'
 *      snapshotTime: // value for 'snapshotTime'
 *   },
 * });
 */
export function useGetSiteCameraQuery(baseOptions: Apollo.QueryHookOptions<GetSiteCameraQuery, GetSiteCameraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteCameraQuery, GetSiteCameraQueryVariables>(GetSiteCameraDocument, options);
      }
export function useGetSiteCameraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteCameraQuery, GetSiteCameraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteCameraQuery, GetSiteCameraQueryVariables>(GetSiteCameraDocument, options);
        }
export type GetSiteCameraQueryHookResult = ReturnType<typeof useGetSiteCameraQuery>;
export type GetSiteCameraLazyQueryHookResult = ReturnType<typeof useGetSiteCameraLazyQuery>;
export type GetSiteCameraQueryResult = Apollo.QueryResult<GetSiteCameraQuery, GetSiteCameraQueryVariables>;
export const GetFullCameraDocument = gql`
    query GetFullCamera($id: Int!) {
  getSiteCamera(id: $id) {
    id
    name
    address
    enableHealthCheck
    healthCheckEmails
    workingDays
    workingHoursStart
    workingHoursEnd
  }
}
    `;

/**
 * __useGetFullCameraQuery__
 *
 * To run a query within a React component, call `useGetFullCameraQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullCameraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullCameraQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFullCameraQuery(baseOptions: Apollo.QueryHookOptions<GetFullCameraQuery, GetFullCameraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullCameraQuery, GetFullCameraQueryVariables>(GetFullCameraDocument, options);
      }
export function useGetFullCameraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullCameraQuery, GetFullCameraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullCameraQuery, GetFullCameraQueryVariables>(GetFullCameraDocument, options);
        }
export type GetFullCameraQueryHookResult = ReturnType<typeof useGetFullCameraQuery>;
export type GetFullCameraLazyQueryHookResult = ReturnType<typeof useGetFullCameraLazyQuery>;
export type GetFullCameraQueryResult = Apollo.QueryResult<GetFullCameraQuery, GetFullCameraQueryVariables>;
export const GetCompaniesForUserDocument = gql`
    query GetCompaniesForUser($userEmail: String!) {
  constructionCompainesForUser(userEmail: $userEmail) {
    id
    name
    status
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCompaniesForUserQuery__
 *
 * To run a query within a React component, call `useGetCompaniesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesForUserQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useGetCompaniesForUserQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesForUserQuery, GetCompaniesForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesForUserQuery, GetCompaniesForUserQueryVariables>(GetCompaniesForUserDocument, options);
      }
export function useGetCompaniesForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesForUserQuery, GetCompaniesForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesForUserQuery, GetCompaniesForUserQueryVariables>(GetCompaniesForUserDocument, options);
        }
export type GetCompaniesForUserQueryHookResult = ReturnType<typeof useGetCompaniesForUserQuery>;
export type GetCompaniesForUserLazyQueryHookResult = ReturnType<typeof useGetCompaniesForUserLazyQuery>;
export type GetCompaniesForUserQueryResult = Apollo.QueryResult<GetCompaniesForUserQuery, GetCompaniesForUserQueryVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies($filters: CompanyFiltersInput) {
  constructionCompanies(filters: $filters) {
    id
    name
    status
    createdAt
    updatedAt
    activeSites {
      id
      name
      status
      createdAt
      updatedAt
      companyId
    }
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetCompanyDocument = gql`
    query GetCompany($id: Int!) {
  constructionCompany(id: $id) {
    id
    name
    description
    status
    createdAt
    updatedAt
    createdById
    updatedById
    canViewUserManagement
    canViewLiveView
    canViewSiteProgress
    canViewTimelapse
    canViewGateReport
    canViewBIMCompare
    canViewBIMView
    canViewEditFrame
    smtpHost
    smtpPort
    smtpUser
    smtpPass
    smtpFrom
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetForgeAuthTokenDocument = gql`
    query GetForgeAuthToken {
  forgeAuthToken {
    access_token
    expires_in
    refresh_token
    token_type
  }
}
    `;

/**
 * __useGetForgeAuthTokenQuery__
 *
 * To run a query within a React component, call `useGetForgeAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForgeAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForgeAuthTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetForgeAuthTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetForgeAuthTokenQuery, GetForgeAuthTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetForgeAuthTokenQuery, GetForgeAuthTokenQueryVariables>(GetForgeAuthTokenDocument, options);
      }
export function useGetForgeAuthTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetForgeAuthTokenQuery, GetForgeAuthTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetForgeAuthTokenQuery, GetForgeAuthTokenQueryVariables>(GetForgeAuthTokenDocument, options);
        }
export type GetForgeAuthTokenQueryHookResult = ReturnType<typeof useGetForgeAuthTokenQuery>;
export type GetForgeAuthTokenLazyQueryHookResult = ReturnType<typeof useGetForgeAuthTokenLazyQuery>;
export type GetForgeAuthTokenQueryResult = Apollo.QueryResult<GetForgeAuthTokenQuery, GetForgeAuthTokenQueryVariables>;
export const GetGateReportDocument = gql`
    query GetGateReport($id: Int!) {
  gateReport(id: $id) {
    id
    orgId
    siteId
    boxId
    cameraId
    eventTimestamp
    eventSource
    eventObject
    eventType
    snapshot_s3
    payload
  }
}
    `;

/**
 * __useGetGateReportQuery__
 *
 * To run a query within a React component, call `useGetGateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGateReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGateReportQuery(baseOptions: Apollo.QueryHookOptions<GetGateReportQuery, GetGateReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGateReportQuery, GetGateReportQueryVariables>(GetGateReportDocument, options);
      }
export function useGetGateReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGateReportQuery, GetGateReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGateReportQuery, GetGateReportQueryVariables>(GetGateReportDocument, options);
        }
export type GetGateReportQueryHookResult = ReturnType<typeof useGetGateReportQuery>;
export type GetGateReportLazyQueryHookResult = ReturnType<typeof useGetGateReportLazyQuery>;
export type GetGateReportQueryResult = Apollo.QueryResult<GetGateReportQuery, GetGateReportQueryVariables>;
export const GetGateReportsDocument = gql`
    query GetGateReports($filters: GateReportsFilterInput, $page: Int!, $pageSize: Int!) {
  gateReports(filters: $filters, page: $page, pageSize: $pageSize) {
    gateReports {
      id
      orgId
      siteId
      boxId
      cameraId
      eventTimestamp
      eventSource
      eventObject
      eventType
      snapshot_s3
      payload
    }
    count
    hasMore
  }
}
    `;

/**
 * __useGetGateReportsQuery__
 *
 * To run a query within a React component, call `useGetGateReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGateReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGateReportsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetGateReportsQuery(baseOptions: Apollo.QueryHookOptions<GetGateReportsQuery, GetGateReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGateReportsQuery, GetGateReportsQueryVariables>(GetGateReportsDocument, options);
      }
export function useGetGateReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGateReportsQuery, GetGateReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGateReportsQuery, GetGateReportsQueryVariables>(GetGateReportsDocument, options);
        }
export type GetGateReportsQueryHookResult = ReturnType<typeof useGetGateReportsQuery>;
export type GetGateReportsLazyQueryHookResult = ReturnType<typeof useGetGateReportsLazyQuery>;
export type GetGateReportsQueryResult = Apollo.QueryResult<GetGateReportsQuery, GetGateReportsQueryVariables>;
export const GateReportTypesDocument = gql`
    query GateReportTypes {
  gateReportTypes {
    name
  }
}
    `;

/**
 * __useGateReportTypesQuery__
 *
 * To run a query within a React component, call `useGateReportTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGateReportTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGateReportTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGateReportTypesQuery(baseOptions?: Apollo.QueryHookOptions<GateReportTypesQuery, GateReportTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GateReportTypesQuery, GateReportTypesQueryVariables>(GateReportTypesDocument, options);
      }
export function useGateReportTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GateReportTypesQuery, GateReportTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GateReportTypesQuery, GateReportTypesQueryVariables>(GateReportTypesDocument, options);
        }
export type GateReportTypesQueryHookResult = ReturnType<typeof useGateReportTypesQuery>;
export type GateReportTypesLazyQueryHookResult = ReturnType<typeof useGateReportTypesLazyQuery>;
export type GateReportTypesQueryResult = Apollo.QueryResult<GateReportTypesQuery, GateReportTypesQueryVariables>;
export const GateReportObjectsDocument = gql`
    query GateReportObjects {
  gateReportObjects {
    name
  }
}
    `;

/**
 * __useGateReportObjectsQuery__
 *
 * To run a query within a React component, call `useGateReportObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGateReportObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGateReportObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGateReportObjectsQuery(baseOptions?: Apollo.QueryHookOptions<GateReportObjectsQuery, GateReportObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GateReportObjectsQuery, GateReportObjectsQueryVariables>(GateReportObjectsDocument, options);
      }
export function useGateReportObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GateReportObjectsQuery, GateReportObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GateReportObjectsQuery, GateReportObjectsQueryVariables>(GateReportObjectsDocument, options);
        }
export type GateReportObjectsQueryHookResult = ReturnType<typeof useGateReportObjectsQuery>;
export type GateReportObjectsLazyQueryHookResult = ReturnType<typeof useGateReportObjectsLazyQuery>;
export type GateReportObjectsQueryResult = Apollo.QueryResult<GateReportObjectsQuery, GateReportObjectsQueryVariables>;
export const GetMinimalCameraConfigurationsDocument = gql`
    query GetMinimalCameraConfigurations($search: String) {
  getCameraConfigurations(search: $search) {
    id
    name
  }
}
    `;

/**
 * __useGetMinimalCameraConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetMinimalCameraConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMinimalCameraConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMinimalCameraConfigurationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetMinimalCameraConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMinimalCameraConfigurationsQuery, GetMinimalCameraConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMinimalCameraConfigurationsQuery, GetMinimalCameraConfigurationsQueryVariables>(GetMinimalCameraConfigurationsDocument, options);
      }
export function useGetMinimalCameraConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMinimalCameraConfigurationsQuery, GetMinimalCameraConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMinimalCameraConfigurationsQuery, GetMinimalCameraConfigurationsQueryVariables>(GetMinimalCameraConfigurationsDocument, options);
        }
export type GetMinimalCameraConfigurationsQueryHookResult = ReturnType<typeof useGetMinimalCameraConfigurationsQuery>;
export type GetMinimalCameraConfigurationsLazyQueryHookResult = ReturnType<typeof useGetMinimalCameraConfigurationsLazyQuery>;
export type GetMinimalCameraConfigurationsQueryResult = Apollo.QueryResult<GetMinimalCameraConfigurationsQuery, GetMinimalCameraConfigurationsQueryVariables>;
export const GetBaseSiteDocument = gql`
    query GetBaseSite($constructionSiteId: Int!) {
  constructionSite(id: $constructionSiteId) {
    id
    name
    status
    updatedAt
    createdAt
    companyId
    timezone
  }
}
    `;

/**
 * __useGetBaseSiteQuery__
 *
 * To run a query within a React component, call `useGetBaseSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseSiteQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *   },
 * });
 */
export function useGetBaseSiteQuery(baseOptions: Apollo.QueryHookOptions<GetBaseSiteQuery, GetBaseSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseSiteQuery, GetBaseSiteQueryVariables>(GetBaseSiteDocument, options);
      }
export function useGetBaseSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseSiteQuery, GetBaseSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseSiteQuery, GetBaseSiteQueryVariables>(GetBaseSiteDocument, options);
        }
export type GetBaseSiteQueryHookResult = ReturnType<typeof useGetBaseSiteQuery>;
export type GetBaseSiteLazyQueryHookResult = ReturnType<typeof useGetBaseSiteLazyQuery>;
export type GetBaseSiteQueryResult = Apollo.QueryResult<GetBaseSiteQuery, GetBaseSiteQueryVariables>;
export const GetSiteGateReportsDocument = gql`
    query GetSiteGateReports($constructionSiteId: Int!) {
  constructionSite(id: $constructionSiteId) {
    id
    gatereports {
      id
      orgId
      siteId
      boxId
      cameraId
      eventTimestamp
      eventSource
      eventType
      snapshot_s3
      payload
    }
  }
}
    `;

/**
 * __useGetSiteGateReportsQuery__
 *
 * To run a query within a React component, call `useGetSiteGateReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteGateReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteGateReportsQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *   },
 * });
 */
export function useGetSiteGateReportsQuery(baseOptions: Apollo.QueryHookOptions<GetSiteGateReportsQuery, GetSiteGateReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteGateReportsQuery, GetSiteGateReportsQueryVariables>(GetSiteGateReportsDocument, options);
      }
export function useGetSiteGateReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteGateReportsQuery, GetSiteGateReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteGateReportsQuery, GetSiteGateReportsQueryVariables>(GetSiteGateReportsDocument, options);
        }
export type GetSiteGateReportsQueryHookResult = ReturnType<typeof useGetSiteGateReportsQuery>;
export type GetSiteGateReportsLazyQueryHookResult = ReturnType<typeof useGetSiteGateReportsLazyQuery>;
export type GetSiteGateReportsQueryResult = Apollo.QueryResult<GetSiteGateReportsQuery, GetSiteGateReportsQueryVariables>;
export const GetCameraSmtpCredsDocument = gql`
    query GetCameraSmtpCreds($cameraId: Int!) {
  getCameraSmtpCreds(id: $cameraId) {
    email
    password
  }
}
    `;

/**
 * __useGetCameraSmtpCredsQuery__
 *
 * To run a query within a React component, call `useGetCameraSmtpCredsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraSmtpCredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraSmtpCredsQuery({
 *   variables: {
 *      cameraId: // value for 'cameraId'
 *   },
 * });
 */
export function useGetCameraSmtpCredsQuery(baseOptions: Apollo.QueryHookOptions<GetCameraSmtpCredsQuery, GetCameraSmtpCredsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraSmtpCredsQuery, GetCameraSmtpCredsQueryVariables>(GetCameraSmtpCredsDocument, options);
      }
export function useGetCameraSmtpCredsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraSmtpCredsQuery, GetCameraSmtpCredsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraSmtpCredsQuery, GetCameraSmtpCredsQueryVariables>(GetCameraSmtpCredsDocument, options);
        }
export type GetCameraSmtpCredsQueryHookResult = ReturnType<typeof useGetCameraSmtpCredsQuery>;
export type GetCameraSmtpCredsLazyQueryHookResult = ReturnType<typeof useGetCameraSmtpCredsLazyQuery>;
export type GetCameraSmtpCredsQueryResult = Apollo.QueryResult<GetCameraSmtpCredsQuery, GetCameraSmtpCredsQueryVariables>;
export const GetSiteUsersDocument = gql`
    query GetSiteUsers($constructionSiteId: Int!) {
  constructionSite(id: $constructionSiteId) {
    id
    users {
      id
      role
      siteId
      user {
        email
        name
        phone
        status
        joiningAt
        isAdmin
      }
    }
  }
}
    `;

/**
 * __useGetSiteUsersQuery__
 *
 * To run a query within a React component, call `useGetSiteUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteUsersQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *   },
 * });
 */
export function useGetSiteUsersQuery(baseOptions: Apollo.QueryHookOptions<GetSiteUsersQuery, GetSiteUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteUsersQuery, GetSiteUsersQueryVariables>(GetSiteUsersDocument, options);
      }
export function useGetSiteUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteUsersQuery, GetSiteUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteUsersQuery, GetSiteUsersQueryVariables>(GetSiteUsersDocument, options);
        }
export type GetSiteUsersQueryHookResult = ReturnType<typeof useGetSiteUsersQuery>;
export type GetSiteUsersLazyQueryHookResult = ReturnType<typeof useGetSiteUsersLazyQuery>;
export type GetSiteUsersQueryResult = Apollo.QueryResult<GetSiteUsersQuery, GetSiteUsersQueryVariables>;
export const GetSiteDocument = gql`
    query GetSite($constructionSiteId: Int!) {
  constructionSite(id: $constructionSiteId) {
    id
    name
    status
    timezone
    updatedAt
    createdAt
    companyId
    company {
      id
      name
      status
      createdAt
      updatedAt
    }
    timelapse {
      id
      name
      status
      frameFrequency
      createdAt
      updatedAt
      workingHoursStart
      workingHoursEnd
      workingDays
      siteId
      cameraId
      camera {
        id
        name
        siteId
        snapshot
      }
    }
    cameras {
      id
      name
      address
      enableHealthCheck
      healthCheckEmails
      workingDays
      workingHoursStart
      workingHoursEnd
      snapshot
      siteId
      updateFrequency
      configuration {
        id
        name
      }
      bimFile {
        id
        fname
        cameraId
        conversionStatus
        forgeLocation
        forgeObjectId
        sceneState
        opacity
        createdAt
        updatedAt
      }
      reportSchedules {
        id
        cameraId
        days
        time
        status
      }
    }
    procoreConnectionParam {
      companyId
      projectId
    }
  }
}
    `;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions: Apollo.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
      }
export function useGetSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = Apollo.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const GetSitesDocument = gql`
    query GetSites($filters: ConstructionSitesFilterInput) {
  constructionSites(filters: $filters) {
    id
    name
    status
    createdAt
    updatedAt
    companyId
    company {
      id
      name
      status
      createdAt
      updatedAt
      createdById
      updatedById
    }
  }
}
    `;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a React component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
      }
export function useGetSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
        }
export type GetSitesQueryHookResult = ReturnType<typeof useGetSitesQuery>;
export type GetSitesLazyQueryHookResult = ReturnType<typeof useGetSitesLazyQuery>;
export type GetSitesQueryResult = Apollo.QueryResult<GetSitesQuery, GetSitesQueryVariables>;
export const GetSiteTimelapsesDocument = gql`
    query GetSiteTimelapses($constructionSiteId: Int!) {
  constructionSite(id: $constructionSiteId) {
    id
    name
    timelapse {
      id
      name
      status
      createdAt
      updatedAt
      camera {
        id
        bimFile {
          id
          forgeLocation
          fname
          conversionStatus
          cameraId
          createdAt
          updatedAt
          forgeObjectId
          sceneState
          opacity
        }
      }
    }
  }
}
    `;

/**
 * __useGetSiteTimelapsesQuery__
 *
 * To run a query within a React component, call `useGetSiteTimelapsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTimelapsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTimelapsesQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *   },
 * });
 */
export function useGetSiteTimelapsesQuery(baseOptions: Apollo.QueryHookOptions<GetSiteTimelapsesQuery, GetSiteTimelapsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteTimelapsesQuery, GetSiteTimelapsesQueryVariables>(GetSiteTimelapsesDocument, options);
      }
export function useGetSiteTimelapsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteTimelapsesQuery, GetSiteTimelapsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteTimelapsesQuery, GetSiteTimelapsesQueryVariables>(GetSiteTimelapsesDocument, options);
        }
export type GetSiteTimelapsesQueryHookResult = ReturnType<typeof useGetSiteTimelapsesQuery>;
export type GetSiteTimelapsesLazyQueryHookResult = ReturnType<typeof useGetSiteTimelapsesLazyQuery>;
export type GetSiteTimelapsesQueryResult = Apollo.QueryResult<GetSiteTimelapsesQuery, GetSiteTimelapsesQueryVariables>;
export const GetTimelapsePlaylistDocument = gql`
    query getTimelapsePlaylist($timelapseId: Int!) {
  getTimelapsePlaylist(id: $timelapseId)
}
    `;

/**
 * __useGetTimelapsePlaylistQuery__
 *
 * To run a query within a React component, call `useGetTimelapsePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelapsePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelapsePlaylistQuery({
 *   variables: {
 *      timelapseId: // value for 'timelapseId'
 *   },
 * });
 */
export function useGetTimelapsePlaylistQuery(baseOptions: Apollo.QueryHookOptions<GetTimelapsePlaylistQuery, GetTimelapsePlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelapsePlaylistQuery, GetTimelapsePlaylistQueryVariables>(GetTimelapsePlaylistDocument, options);
      }
export function useGetTimelapsePlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelapsePlaylistQuery, GetTimelapsePlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelapsePlaylistQuery, GetTimelapsePlaylistQueryVariables>(GetTimelapsePlaylistDocument, options);
        }
export type GetTimelapsePlaylistQueryHookResult = ReturnType<typeof useGetTimelapsePlaylistQuery>;
export type GetTimelapsePlaylistLazyQueryHookResult = ReturnType<typeof useGetTimelapsePlaylistLazyQuery>;
export type GetTimelapsePlaylistQueryResult = Apollo.QueryResult<GetTimelapsePlaylistQuery, GetTimelapsePlaylistQueryVariables>;
export const GetTimelapseMp4UrlDocument = gql`
    query getTimelapseMp4Url($timelapseId: Int!) {
  getTimelapseMp4Url(id: $timelapseId)
}
    `;

/**
 * __useGetTimelapseMp4UrlQuery__
 *
 * To run a query within a React component, call `useGetTimelapseMp4UrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelapseMp4UrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelapseMp4UrlQuery({
 *   variables: {
 *      timelapseId: // value for 'timelapseId'
 *   },
 * });
 */
export function useGetTimelapseMp4UrlQuery(baseOptions: Apollo.QueryHookOptions<GetTimelapseMp4UrlQuery, GetTimelapseMp4UrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelapseMp4UrlQuery, GetTimelapseMp4UrlQueryVariables>(GetTimelapseMp4UrlDocument, options);
      }
export function useGetTimelapseMp4UrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelapseMp4UrlQuery, GetTimelapseMp4UrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelapseMp4UrlQuery, GetTimelapseMp4UrlQueryVariables>(GetTimelapseMp4UrlDocument, options);
        }
export type GetTimelapseMp4UrlQueryHookResult = ReturnType<typeof useGetTimelapseMp4UrlQuery>;
export type GetTimelapseMp4UrlLazyQueryHookResult = ReturnType<typeof useGetTimelapseMp4UrlLazyQuery>;
export type GetTimelapseMp4UrlQueryResult = Apollo.QueryResult<GetTimelapseMp4UrlQuery, GetTimelapseMp4UrlQueryVariables>;
export const UserMeDocument = gql`
    query UserMe {
  me {
    email
    name
    phone
    status
    isAdmin
    createdAt
    updatedAt
    acceptsExtraEmails
    permissionsFromCompany {
      id
      name
      canViewUserManagement
      canViewLiveView
      canViewSiteProgress
      canViewTimelapse
      canViewGateReport
      canViewBIMCompare
      canViewBIMView
      canViewEditFrame
      logo_s3
    }
    companies {
      id
      companyId
      userId
      createdAt
      updatedAt
      company {
        id
        name
        status
        createdAt
        updatedAt
        sites {
          id
          createdAt
          updatedAt
          companyId
          status
          name
        }
      }
    }
    sites {
      id
      role
      siteId
      user {
        createdAt
        updatedAt
        email
        isAdmin
      }
      userId
      createdAt
      updatedAt
      site {
        id
        createdAt
        updatedAt
        companyId
        status
        name
        company {
          id
          name
          status
          createdAt
          updatedAt
        }
      }
    }
  }
}
    `;

/**
 * __useUserMeQuery__
 *
 * To run a query within a React component, call `useUserMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMeQuery(baseOptions?: Apollo.QueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
      }
export function useUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
        }
export type UserMeQueryHookResult = ReturnType<typeof useUserMeQuery>;
export type UserMeLazyQueryHookResult = ReturnType<typeof useUserMeLazyQuery>;
export type UserMeQueryResult = Apollo.QueryResult<UserMeQuery, UserMeQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($email: String!) {
  user(email: $email) {
    email
    name
    phone
    status
    isAdmin
    updatedAt
    createdAt
    permissionsFromCompany {
      id
      canViewUserManagement
      canViewLiveView
      canViewSiteProgress
      canViewTimelapse
      canViewGateReport
      canViewBIMCompare
      canViewBIMView
      canViewEditFrame
    }
    sites {
      id
      role
      siteId
      userId
      createdAt
      updatedAt
      site {
        id
        name
        companyId
        status
        createdAt
        updatedAt
      }
      user {
        email
        isAdmin
        createdAt
        updatedAt
      }
    }
    companies {
      id
      companyId
      userId
      createdAt
      updatedAt
      company {
        id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    name
    email
    isAdmin
    phone
    status
    createdAt
    updatedAt
    joiningAt
    sites {
      id
      role
      siteId
      user {
        createdAt
        updatedAt
        email
        isAdmin
      }
      userId
      createdAt
      updatedAt
      site {
        id
        createdAt
        updatedAt
        companyId
        status
        name
      }
    }
    companies {
      id
      companyId
      userId
      createdAt
      updatedAt
      company {
        id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;