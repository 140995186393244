import React from "react";
import * as Yup from "yup";

import { Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { isEmpty, pick } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "../../auth/hooks";
import { FormCard } from "../../components/FormCard";
import { Button, Divider, PageTitle } from "../../components/pages/Common";
import {
  SingleSelect,
  TextInput,
  roleChoicesSortFn,
} from "../../components/pages/FormFields";
import {
  defaultHelperText,
  userInputParams,
} from "../../components/pages/inputConstants";
import {
  GetBaseSiteQuery,
  useGetUserQuery,
  useRemoveUserSiteMutation,
  UserRole,
  UserStatus,
  useUpdateUserMutation,
  useUpdateUserSiteMutation,
} from "../../graphql";
import {
  errorUrl,
  getSiteUrl,
  SiteNavigationPath,
} from "../../helpers/navigation";
import { useCurrentSite } from "./context/SiteContext";
import {
  getSiteUserFieldsAllowedForEdit,
  EditableSiteUserField,
} from "../../helpers/permissions";
import { getRoleChoices, getUserStatusChoices } from "../../helpers/getChoices";

const UserEditValidationSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  status: Yup.string()
    .oneOf(Object.values(UserStatus))
    .required(defaultHelperText.required),
  role: Yup.string()
    .oneOf(Object.values(UserRole))
    .required(defaultHelperText.required),
  phone: Yup.string().nullable(),
});

export const SiteUserEditForm: React.FC<{
  site: GetBaseSiteQuery["constructionSite"];
}> = ({ site }) => {
  const navigate = useNavigate();
  const { email: maybeEmail } = useParams();
  const loggedUser = useCurrentUser();
  const email = maybeEmail || "";

  const {
    data: userQuery,
    loading: queryIsLoading,
    refetch: refetchUsers,
  } = useGetUserQuery({
    variables: { email },
  });

  const user = userQuery?.user;

  const siteUser = user?.sites?.find(({ siteId }) => siteId === site.id);

  const [updateUser, { loading: userUpdateRuns }] = useUpdateUserMutation();
  const [updateUserSite, { loading: siteUserUpdateRuns }] =
    useUpdateUserSiteMutation();
  const [deleteUserSite, { loading: siteUserDeleteRuns }] =
    useRemoveUserSiteMutation();

  const loading =
    userUpdateRuns ||
    siteUserUpdateRuns ||
    siteUserDeleteRuns ||
    queryIsLoading;

  const formValues = {
    name: user?.name || "",
    status: user?.status || UserStatus.Invited,
    phone: user?.phone,
    role: siteUser?.role || UserRole.General,
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: UserEditValidationSchema,
    validateOnBlur: true,
    onSubmit: () => {
      null;
    },
  });

  React.useEffect(() => {
    if (user) formik.resetForm({ values: formValues });
  }, [user]);

  const roleChoices = React.useMemo(() => getRoleChoices(), []);

  const statusChoices = React.useMemo(
    () => getUserStatusChoices(formik.values.status),
    [formik.values.status],
  );

  if (!siteUser || !user || !loggedUser) return null;

  const allowedFields = getSiteUserFieldsAllowedForEdit(loggedUser, user, site);

  const disabledFields = {
    email: true,
    isAdmin: true,
    name: !allowedFields.includes(EditableSiteUserField.name),
    phone: !allowedFields.includes(EditableSiteUserField.phone),
    status:
      !allowedFields.includes(EditableSiteUserField.status) ||
      statusChoices.length <= 1,
    role: !allowedFields.includes(EditableSiteUserField.role),
  };

  const allFieldsAreDisabled = Object.values(disabledFields).every(Boolean);

  const showButtons = {
    revokeAccess: allowedFields.includes(EditableSiteUserField.role),
    save: !Object.values(disabledFields).every(Boolean),
  };

  const saveUser = async () => {
    if (allFieldsAreDisabled) return;
    return updateUser({
      variables: {
        ...pick(formik.values, ["name", "phone", "status"]),
        email: user.email,
      },
    });
  };

  const saveSiteUser = async () => {
    if (!allowedFields.includes(EditableSiteUserField.role)) return;
    await updateUserSite({
      variables: {
        role: formik.values.role,
        site: site.id,
        email: user.email,
      },
    });
  };

  const removeSiteUser = async () => {
    if (!allowedFields.includes(EditableSiteUserField.role)) return;
    await deleteUserSite({ variables: { userSiteId: siteUser.id } });
  };

  const Redirect = () =>
    navigate(getSiteUrl(SiteNavigationPath.UserList, site.id));

  const processUpdate = ($mutation: Promise<unknown>) => {
    return formik.validateForm().then(async (errors) => {
      if (!isEmpty(errors)) return;
      await $mutation.catch(() => navigate(errorUrl));
    });
  };

  const saveAndContinue = () => {
    void processUpdate(
      saveSiteUser()
        .then(saveUser)
        .then(() => refetchUsers()),
    );
  };

  const save = () => {
    void processUpdate(saveUser().then(saveSiteUser).then(Redirect));
  };

  const revokeAccess = () => {
    void processUpdate(saveUser().then(removeSiteUser).then(Redirect));
  };

  return (
    <form role="form">
      <Typography variant="h6" gutterBottom mb={8}>
        General info
      </Typography>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...userInputParams.name}
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.name}
            showError={!isEmpty(formik.errors.name) && formik.touched.name}
            disabled={disabledFields.name}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Status"
            placeholder="Select status"
            options={statusChoices}
            value={formik.values.status}
            disabled={disabledFields.status}
            onChange={(value: string) =>
              void formik.setFieldValue("status", value)
            }
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...userInputParams.email}
            value={user.email}
            disabled={disabledFields.email}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...userInputParams.phone}
            value={formik.values.phone}
            name="phone"
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.phone}
            showError={!isEmpty(formik.errors.phone) && formik.touched.phone}
            disabled={disabledFields.phone}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Permission level"
            placeholder="Select Permission level"
            options={[
              { label: "User", value: 0 },
              { label: "Admin", value: 1 },
            ]}
            value={+user.isAdmin}
            disabled={disabledFields.isAdmin}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Role"
            placeholder="Select Role"
            options={roleChoices}
            choicesSortFn={roleChoicesSortFn}
            value={formik.values.role}
            disabled={disabledFields.role}
            onChange={(value: string) =>
              void formik.setFieldValue("role", value)
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={6} justifyContent="right">
        <Grid item>
          {showButtons.revokeAccess && (
            <Button
              color="error"
              variant="contained"
              my={2}
              mr={2}
              onClick={revokeAccess}
              disabled={!formik.isValid || loading}
            >
              Revoke access to this site and save
            </Button>
          )}
          {showButtons.save && (
            <>
              <Button
                color="primary"
                variant="contained"
                my={2}
                mr={2}
                onClick={saveAndContinue}
                disabled={!formik.isValid || loading}
              >
                Save and continue editing
              </Button>
              <Button
                color="primary"
                variant="contained"
                my={2}
                mr={2}
                onClick={save}
                disabled={!formik.isValid || loading}
              >
                Save
              </Button>
            </>
          )}
          <Button color="primary" variant="outlined" my={2} onClick={Redirect}>
            {showButtons.save || showButtons.revokeAccess ? "Cancel" : "Close"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export const SiteUserEditPage: React.FC = () => {
  const site = useCurrentSite();

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: Edit User Profile`} />
      <Divider my={6} />
      <FormCard size="sm">
        <SiteUserEditForm site={site} />
      </FormCard>
    </React.Fragment>
  );
};
