import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton } from "../components/pages/Common";
import { LogOut } from "react-feather";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <IconButton
      color="secondary"
      my={2}
      mr={2}
      onClick={() =>
        void logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      <LogOut />
    </IconButton>
  );
};

export const Auth0LoginRedirect = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    void loginWithRedirect({
      openUrl(url) {
        window.location.replace(url);
      },
    });
  }
  return null;
};
