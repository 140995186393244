import React from "react";

import styled from "@emotion/styled";
import { Button } from "../components/pages/Common";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export const ErrorPage: React.FC<{
  title: string | JSX.Element;
  details: string | JSX.Element;
  redirectTo?: number;
}> = (props) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        {props.title}
      </Typography>

      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {props.details}
      </Typography>

      {props.redirectTo && (
        <Button
          variant="contained"
          color="secondary"
          mt={2}
          onClick={() => navigate(props.redirectTo || -1)}
        >
          Return
        </Button>
      )}
    </Wrapper>
  );
};

export const notFoundPage = (
  <ErrorPage title="404" details="Page not found." redirectTo={-2} />
);

export const errorPage = (
  <ErrorPage
    title="Error"
    details="Oops, it seems something went wrong."
    redirectTo={-1}
  />
);
