import React from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Autocomplete, FormFieldControl, TextInput } from "../pages/FormFields";
import { NewInstanceModal } from "./NewInstanceModal";

import { useGetCompaniesQuery, useCreateSiteMutation } from "../../graphql";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
} from "../../helpers/navigation";
import { defaultHelperText, siteInputParams } from "../pages/inputConstants";
import { isEmpty } from "lodash";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(defaultHelperText.required),
  companyId: Yup.number().min(1).required(defaultHelperText.required),
});

export const NewSiteModal: React.FC<{
  isOpened: boolean;
  close: () => void;
}> = (props) => {
  const navigate = useNavigate();
  const { data: companiesQuery } = useGetCompaniesQuery();
  const [createSite, { data: siteMutation, loading, error }] =
    useCreateSiteMutation();
  const initialValues = {
    name: "",
    companyId: 0,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit: () => {
      null;
    },
  });

  React.useEffect(() => {
    const newSiteId = siteMutation?.createConstructionSite.id;
    if (error) navigate(errorUrl);
    if (newSiteId) {
      navigate(getAdminUrl(AdminNavigationPath.SiteEdit, newSiteId));
    }
  }, [siteMutation, error]);

  const companyOptions = React.useMemo(
    () =>
      (companiesQuery?.constructionCompanies || []).map((company) => ({
        label: company.name,
        value: company.id,
      })),
    [companiesQuery?.constructionCompanies],
  );

  const close = () => {
    formik.resetForm({ values: initialValues });
    props.close();
  };

  const onSave = () => {
    void createSite({
      variables: {
        name: formik.values.name,
        companyId: formik.values.companyId,
      },
    });
    close();
  };

  return (
    <NewInstanceModal
      title="Add New Construction Site"
      canBeSaved={formik.isValid && !isEmpty(formik.touched) && !loading}
      onSave={onSave}
      onCancel={close}
      isOpened={props.isOpened}
    >
      <FormFieldControl mt={2}>
        <Autocomplete<number>
          name="companyId"
          options={companyOptions}
          onChange={(value?: number | null) => {
            void formik.setFieldValue("companyId", value || 0);
          }}
          value={formik.values.companyId}
          {...siteInputParams.company}
        />
      </FormFieldControl>
      <TextInput
        name="name"
        margin="dense"
        value={formik.values.name}
        onChange={(...args) => {
          formik.handleChange(...args);
          formik.handleBlur(...args);
        }}
        onBlur={formik.handleBlur}
        helperText={formik.errors.name}
        showError={!!formik.errors.name && formik.touched.name}
        {...siteInputParams.siteName}
      />
    </NewInstanceModal>
  );
};
