import React from "react";
import styled from "@emotion/styled";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetSiteCameraLazyQuery,
  GetSiteCameraQuery,
  GetSiteCameraQueryVariables,
} from "../../graphql/generated";
import { getClosestDateByInterval, getServerUri } from "./helpers";
import {
  getZonedNow,
  getSecondsTotal,
  toUtcDateTimeString,
} from "../../helpers/date";
import { dateTimeFormat } from "../../constants";
import { iframeParamsError, iframeTokenError } from "./Error";

type Camera = GetSiteCameraQuery["getSiteCamera"];

export const IframeLiveView: React.FC = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const cameraId = parseInt(params.cameraId || "");
  const interval = parseInt(searchParams.get("interval") || "");
  const token = params.token || "";
  const [initialized, setInitialized] = React.useState(false);
  const [snapshotUrl, setSnapshotUrl] = React.useState<string | null>();

  const [getCamera, { error }] = useGetSiteCameraLazyQuery({
    context: { uri: getServerUri(token) },
  });

  const [camera, setCamera] = React.useState<Camera>();
  const { timezone } = camera || {};
  const variables: GetSiteCameraQueryVariables = { id: cameraId };

  React.useEffect(() => {
    document.body.style.margin = "unset";
    document.body.style.padding = "unset";
    loadCamera();
  }, []);

  React.useEffect(() => {
    if (!timezone || initialized) return;
    initPolling(true);
  }, [timezone]);

  const loadCamera = () => {
    void getCamera({ variables }).then(({ data }) =>
      setCamera(data?.getSiteCamera),
    );
  };

  const loadSnapshot = (date: Date, timezone: string) => {
    const snapshotTime = toUtcDateTimeString(date, timezone, dateTimeFormat);
    void getCamera({ variables: { ...variables, snapshotTime } }).then(
      ({ data }) => setSnapshotUrl(data?.getSiteCamera?.snapshot),
    );
  };

  const initPolling = (firstLoad?: boolean) => {
    if (!timezone || Number.isNaN(interval)) return;
    setInitialized(true);

    const normalizedInterval = Math.max(interval, 10);
    const closestTime = getClosestDateByInterval(timezone, normalizedInterval);

    // Gets snapshot in delayed mode to be sure server already got it.
    setTimeout(() => loadSnapshot(closestTime, timezone), 30000);

    // But if that is first load, user also should get snapshot instantly.
    if (firstLoad) loadSnapshot(closestTime, timezone);

    const localEpoch = getSecondsTotal(getZonedNow(timezone));
    const timeout =
      normalizedInterval - (localEpoch - getSecondsTotal(closestTime));

    setTimeout(initPolling, timeout * 1000);
  };

  if (Number.isNaN(interval) || Number.isNaN(cameraId))
    return iframeParamsError;
  if (error) return iframeTokenError;

  return (
    <SnapshotContainer>
      <img src={snapshotUrl || ""} />
    </SnapshotContainer>
  );
};

const SnapshotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  img {
    object-fit: contain;
    max-height: 100vh;
    max-width: 100vw;
  }
`;
