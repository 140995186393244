import React from "react";
import * as Yup from "yup";

import { Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { isEmpty, pick } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { FormCard } from "../../components/FormCard";
import {
  AddEditSiteUserModal,
  AddEditSiteUserModalProps,
} from "../../components/modals/AddEditSiteUserModal";
import {
  Button,
  Divider,
  PageTitle,
  PlusButton,
} from "../../components/pages/Common";
import { SingleSelect, TextInput } from "../../components/pages/FormFields";
import {
  defaultHelperText,
  userInputParams,
} from "../../components/pages/inputConstants";
import { emDash, UserRoleLabel } from "../../constants";
import {
  AddUpdateUserSiteInput,
  GetUserQuery,
  useAddUserSiteMutation,
  useGetCompaniesForUserQuery,
  useGetCompaniesQuery,
  useGetSitesQuery,
  useGetUserQuery,
  useRemoveCompanyAdminMutation,
  useRemoveUserSiteMutation,
  UserRole,
  UserStatus,
  useUpdateUserMutation,
  useUpdateUserSiteMutation,
} from "../../graphql";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
  notFoundUrl,
} from "../../helpers/navigation";
import { getUserStatusChoices } from "../../helpers/getChoices";
import { useCurrentUser } from "../../auth/hooks";
import { HeadCell } from "../../components/tables/types";
import { sortRows } from "../../components/tables/sorting";
import { SimpleTable } from "../../components/tables/SimpleTable";
import { AddUserCompanyModal } from "../../components/modals/AddUserCompanyModal";
import {
  canAddRemoveUserCompany,
  getSiteUserFieldsAllowedForEdit,
  EditableSiteUserField,
  EditableUserField,
  getUserFieldsAllowedForEdit,
} from "../../helpers/permissions";

export const UserEditAdmin: React.FC = () => {
  const { email } = useParams();
  const navigate = useNavigate();

  const { data, refetch, error } = useGetUserQuery({
    variables: { email: email || "" },
  });

  React.useEffect(() => {
    if (error) navigate(notFoundUrl);
  }, [error]);

  const refetchUser = () => void refetch();

  const user = data?.user;
  if (!user) {
    navigate(notFoundUrl);
    return null;
  }

  return (
    <React.Fragment>
      <PageTitle text="Edit User Profile" />
      <Divider my={6} />
      <FormCard size="sm">
        <UserEditForm user={user} refetchUser={refetchUser} />
      </FormCard>
      {!user.isAdmin && (
        <>
          <FormCard size="sm">
            <UserCompaniesTable user={user} refetchUser={refetchUser} />
          </FormCard>
          <FormCard size="sm">
            <UserSitesTable user={user} refetchUser={refetchUser} />
          </FormCard>
        </>
      )}
    </React.Fragment>
  );
};

const UserEditValidationSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  status: Yup.string().required(defaultHelperText.required),
  phone: Yup.string().nullable(),
  isAdmin: Yup.boolean().required(defaultHelperText.required),
  permissionsFromCompany: Yup.string().nullable(),
});

export const UserEditForm: React.FC<{
  user: GetUserQuery["user"];
  refetchUser: () => void;
}> = ({ user, refetchUser }) => {
  const [updateUser, { loading }] = useUpdateUserMutation();
  const loggedUser = useCurrentUser();
  const navigate = useNavigate();

  const initialValues = {
    name: user.name || "",
    status: user.status || UserStatus.Invited,
    isAdmin: +user.isAdmin,
    phone: user.phone,
    permissionsFromCompany: user.permissionsFromCompany?.id ?? undefined,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UserEditValidationSchema,
    validateOnBlur: true,
    onSubmit: () => {
      null;
    },
  });

  const { data } = useGetCompaniesForUserQuery({
    variables: { userEmail: user.email },
  });

  const companies = React.useMemo(
    () => [
      { label: "---", value: undefined },
      ...(data?.constructionCompainesForUser || []).map((company) => ({
        label: company.name,
        value: company.id,
      })),
    ],
    [data?.constructionCompainesForUser],
  );

  const statusChoices = React.useMemo(
    () => getUserStatusChoices(formik.values.status),
    [formik.values.status],
  );

  const Save = async () => {
    if (allFieldsAreDisabled) return;
    await updateUser({
      variables: {
        ...pick(formik.values, [
          "name",
          "phone",
          "status",
          "permissionsFromCompany",
        ]),
        email: user.email,
        isAdmin: !!formik.values.isAdmin,
      },
    })
      .then(refetchUser)
      .catch(() => navigate(errorUrl));
  };

  if (!loggedUser) return null;

  const Redirect = () => {
    navigate(getAdminUrl(AdminNavigationPath.UserList));
    setTimeout(() => window.location.reload(), 300);
  };

  const SaveAndRedirect = () => {
    void Save().then(Redirect);
  };

  const SaveAndContinue = () => {
    void Save().then(() => window.location.reload());
  };

  const allowedFields = getUserFieldsAllowedForEdit(loggedUser, user);

  const disabledFields = {
    email: true,
    name: !allowedFields.includes(EditableUserField.name),
    status:
      !allowedFields.includes(EditableUserField.status) ||
      statusChoices.length <= 1,
    isAdmin: !allowedFields.includes(EditableUserField.isAdmin),
    phone: !allowedFields.includes(EditableUserField.phone),
    permissionsFromCompany: !allowedFields.includes(
      EditableUserField.permissionsFromCompany,
    ),
  };
  const allFieldsAreDisabled = Object.values(disabledFields).every(Boolean);

  return (
    <form>
      <Typography variant="h6" gutterBottom mb={8}>
        General info
      </Typography>
      <Grid container spacing={2} justifyContent="right">
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...userInputParams.name}
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.name}
            showError={!isEmpty(formik.errors.name) && formik.touched.name}
            disabled={disabledFields.name}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Status"
            placeholder="Select status"
            options={statusChoices}
            value={formik.values.status}
            disabled={disabledFields.status}
            onChange={(value: string) =>
              void formik.setFieldValue("status", value)
            }
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...userInputParams.email}
            value={user.email}
            disabled={disabledFields.email}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...userInputParams.phone}
            value={formik.values.phone}
            name="phone"
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.phone}
            disabled={disabledFields.phone}
            showError={!isEmpty(formik.errors.phone) && formik.touched.phone}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Permission by company"
            placeholder="Select company to apply permissions from"
            options={companies}
            value={formik.values.permissionsFromCompany}
            disabled={disabledFields.permissionsFromCompany}
            onChange={(value: number | undefined) =>
              void formik.setFieldValue("permissionsFromCompany", value ?? null)
            }
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <SingleSelect
            label="Permission level"
            placeholder="Select Permission level"
            options={[
              { label: "Admin", value: 1 },
              { label: "User", value: 0 },
            ]}
            value={formik.values.isAdmin}
            disabled={disabledFields.isAdmin}
            onChange={(value: number) =>
              void formik.setFieldValue("isAdmin", value)
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={6} justifyContent="right">
        <Grid item>
          {!allFieldsAreDisabled && (
            <>
              <Button
                color="primary"
                variant="contained"
                my={2}
                mr={2}
                onClick={() => void SaveAndContinue()}
                disabled={!isEmpty(formik.errors) || loading}
              >
                Save and continue editing
              </Button>
              <Button
                color="primary"
                variant="contained"
                my={2}
                mr={2}
                onClick={SaveAndRedirect}
                disabled={!isEmpty(formik.errors) || loading}
              >
                Save
              </Button>
            </>
          )}
          <Button color="primary" variant="outlined" my={2} onClick={Redirect}>
            {allFieldsAreDisabled ? "Close" : "Cancel"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

type CompanyRow = {
  id: number;
  companyId: number;
  name?: string;
  role: string;
};

const UserCompaniesTable: React.FC<{
  user: GetUserQuery["user"];
  refetchUser: () => void;
}> = ({ user, refetchUser }) => {
  const navigate = useNavigate();
  const loggedUser = useCurrentUser();

  const [modalIsOpened, setModalIsOpened] = React.useState(false);
  const [removeCompanyAdmin, { error }] = useRemoveCompanyAdminMutation();
  const { data: companiesQuery } = useGetCompaniesQuery();

  const userCompanyIds = React.useMemo(
    () => (user.companies || []).map(({ companyId }) => companyId),
    [user.companies],
  );

  const companyOptions = React.useMemo(() => {
    if (!loggedUser) return [];
    const companies = companiesQuery?.constructionCompanies || [];
    return companies.filter(
      ({ id }) =>
        !userCompanyIds.includes(id) &&
        canAddRemoveUserCompany(loggedUser, user, id),
    );
  }, [companiesQuery?.constructionCompanies, userCompanyIds, loggedUser]);

  const rows: CompanyRow[] = React.useMemo(() => {
    if (!loggedUser) return [];
    const companies = (user.companies || []).map((row) => ({
      id: row.id,
      name: row.company?.name || emDash,
      companyId: row.companyId,
      role: "Compamy Admin",
    }));
    return sortRows(companies, {
      key: "name",
      order: "asc",
    });
  }, [user.companies, loggedUser]);

  React.useEffect(() => {
    if (error) navigate(errorUrl);
  }, [error]);

  const headCells: Array<HeadCell<CompanyRow>> = [
    {
      key: "name",
      label: "Company",
      alignment: "left",
      width: "50%",
      render: (row) => row.name || emDash,
    },
    {
      key: "role",
      label: "Role",
      alignment: "center",
      width: "25%",
      render: (row) => row.role,
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "25%",
      render: (row) => {
        if (!loggedUser) return emDash;
        if (!canAddRemoveUserCompany(loggedUser, user, row.companyId))
          return emDash;
        return <RemoveButton onClick={() => removeCompany(row.id)} />;
      },
    },
  ];

  const removeCompany = (id: number) =>
    void removeCompanyAdmin({ variables: { id } }).then(refetchUser);

  if (!loggedUser) return null;

  return (
    <>
      <Grid container my={6} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" gutterBottom mb={2} mt={2}>
            Managed companies
          </Typography>
        </Grid>
        <Grid item>
          {companyOptions.length > 0 && (
            <PlusButton
              tooltip="Add a Construction Companies to the User's list"
              onClick={() => setModalIsOpened(true)}
            />
          )}
        </Grid>
      </Grid>
      <AddUserCompanyModal
        email={user.email}
        companies={companyOptions}
        isOpened={modalIsOpened}
        close={() => setModalIsOpened(false)}
        onSave={refetchUser}
      />
      {rows.length > 0 && <SimpleTable headCells={headCells} rows={rows} />}
    </>
  );
};

type ModalProps = Pick<
  AddEditSiteUserModalProps,
  "save" | "isOpened" | "initialValue" | "close"
>;

type SiteRow = {
  id: number;
  name: string;
  siteId: number;
  companyId: number;
  role: UserRole;
};

export const UserSitesTable: React.FC<{
  user: GetUserQuery["user"];
  refetchUser: () => void;
}> = ({ user, refetchUser }) => {
  const navigate = useNavigate();
  const loggedUser = useCurrentUser();
  const { data: sitesQuery } = useGetSitesQuery();
  const [addUserSite] = useAddUserSiteMutation();
  const [updateUserSite] = useUpdateUserSiteMutation();
  const [deleteUserSite] = useRemoveUserSiteMutation();

  const processMutation = ($mutation: Promise<unknown>) => {
    $mutation
      .then(() => {
        refetchUser();
        closeModal();
      })
      .catch(() => navigate(errorUrl));
  };

  const canManageUserSite = (site: { id: number; companyId: number }) => {
    if (!loggedUser) return [];
    return getSiteUserFieldsAllowedForEdit(loggedUser, user, site).includes(
      EditableSiteUserField.role,
    );
  };

  const siteOptions = React.useMemo(() => {
    if (!loggedUser) return [];
    const sites = sitesQuery?.constructionSites || [];
    const userSiteIds = (user.sites || []).map((row) => row.site.id);
    return sites.filter(
      (site) => !userSiteIds.includes(site.id) && canManageUserSite(site),
    );
  }, [sitesQuery?.constructionSites, user.sites, loggedUser]);

  const addUpdateUserSite = (
    userSite: AddUpdateUserSiteInput,
    action: "add" | "update",
  ) => {
    const mutate = action === "add" ? addUserSite : updateUserSite;
    processMutation(mutate({ variables: userSite }));
  };

  const removeUserSite = (userSiteId: number) =>
    processMutation(deleteUserSite({ variables: { userSiteId } }));

  const closeModal = () => setModalProps({ isOpened: false });

  const modalInitialProps: ModalProps = {
    isOpened: false,
    save: (userSite) => addUpdateUserSite(userSite, "add"),
    initialValue: null,
    close: closeModal,
  };

  const modalPropsReducer = (
    state: ModalProps,
    update: Partial<ModalProps>,
  ): ModalProps => {
    return { ...state, ...update };
  };

  const [modalProps, setModalProps] = React.useReducer(
    modalPropsReducer,
    modalInitialProps,
  );

  const rows: SiteRow[] = React.useMemo(() => {
    const sites = (user.sites || []).map((row) => ({
      id: row.id,
      role: row.role,
      siteId: row.site.id,
      ...pick(row.site, ["name", "companyId"]),
    }));
    return sortRows(sites, {
      key: "name",
      order: "asc",
    });
  }, [user?.sites]);

  if (!loggedUser) return null;

  const headCells: Array<HeadCell<SiteRow>> = [
    {
      key: "name",
      label: "Site",
      alignment: "left",
      width: "50%",
      render: (row) => row.name,
    },
    {
      key: "role",
      label: "Role",
      alignment: "center",
      width: "25%",
      render: (row) => UserRoleLabel[row.role],
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "25%",
      render: (row) => {
        if (!canManageUserSite(row)) return emDash;
        return (
          <div style={{ minWidth: "200px" }}>
            <EditButton
              onClick={() =>
                setModalProps({
                  isOpened: true,
                  initialValue: row,
                  save: (userSite) => addUpdateUserSite(userSite, "update"),
                })
              }
            />
            <RemoveButton onClick={() => removeUserSite(row.id)} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container my={6} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" gutterBottom mb={2} mt={2}>
            Available sites
          </Typography>
        </Grid>
        <Grid item>
          {siteOptions.length > 0 && (
            <PlusButton
              tooltip="Add a Construction Site to the User's list"
              onClick={() =>
                setModalProps({
                  isOpened: true,
                  initialValue: null,
                  save: (userSite) => addUpdateUserSite(userSite, "add"),
                })
              }
            />
          )}
        </Grid>
      </Grid>
      <AddEditSiteUserModal {...modalProps} sites={siteOptions} user={user} />
      {rows.length > 0 && <SimpleTable rows={rows} headCells={headCells} />}
    </>
  );
};

const EditButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button mr={2} variant="outlined" onClick={onClick}>
    Edit
  </Button>
);

const RemoveButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button variant="outlined" onClick={onClick} margin="0">
    Remove
  </Button>
);
