import React from "react";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { NewInstanceModal } from "./NewInstanceModal";

import { Grid } from "@mui/material";
import { addDays } from "date-fns";
import { useFormik } from "formik";
import { dateFormat, dateTimeFormat } from "../../constants";
import { useRestoreTimelapseMutation } from "../../graphql";
import {
  getUtcNow,
  offsetDateByTz,
  setTime,
  toUtcDateTimeString,
} from "../../helpers/date";
import { errorUrl } from "../../helpers/navigation";
import { DatePicker } from "../pages/FormFields";
import { defaultHelperText } from "../pages/inputConstants";

interface AddMissingSnapshotsrModalProps {
  timelapseId: number;
  siteTimezone: string;
  isOpened: boolean;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  dateFrom: Yup.date().required(defaultHelperText.required),
  dateTo: Yup.date()
    .required(defaultHelperText.required)
    .min(Yup.ref("dateFrom"), "End date can't be before start date"),
});

export const AddMissingSnapshotsrModal: React.FC<
  AddMissingSnapshotsrModalProps
> = (props) => {
  const navigate = useNavigate();
  const [restoreTimelapse, { error, loading }] = useRestoreTimelapseMutation();
  const maxDate = offsetDateByTz(addDays(getUtcNow(), -1), props.siteTimezone);

  const initialValues = {
    dateFrom: null,
    dateTo: null,
  };

  const formik = useFormik<{
    dateFrom: null | Date;
    dateTo: null | Date;
  }>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      null;
    },
  });

  React.useEffect(() => {
    if (error) navigate(errorUrl);
  }, [error]);

  const close = () => {
    void formik.setFieldValue("dateFrom", null, true);
    void formik.setFieldValue("dateTo", null, true);
    props.close();
  };

  const onSave = () => {
    if (loading) return;
    const form = formik.values;
    if (!form.dateFrom || !form.dateTo) return;
    const dateFrom = setTime(form.dateFrom, "00:00:00");
    const dateTo = setTime(form.dateTo, "23:59:59");

    const [utcDateFrom, utcDateTo] = [dateFrom, dateTo].map((date) => {
      return toUtcDateTimeString(date, props.siteTimezone, dateTimeFormat);
    });

    const input = {
      id: props.timelapseId,
      utcDateFrom,
      utcDateTo,
    };

    void restoreTimelapse({ variables: { input } }).then(close);
  };

  return (
    <NewInstanceModal
      title="Add missing snapshots"
      saveButtonLabel="Add"
      canBeSaved={formik.isValid || loading}
      onSave={onSave}
      onCancel={close}
      isOpened={props.isOpened}
    >
      <Grid container>
        <Grid item my={2} xs={12}>
          <DatePicker
            value={formik.values.dateFrom}
            label="Date from"
            inputFormat={dateFormat}
            mask="____-__-__"
            maxDate={maxDate}
            onChange={(value: Date | null) => {
              void formik.setFieldValue("dateFrom", value, true);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            value={formik.values.dateTo}
            label="Date to"
            inputFormat={dateFormat}
            mask="____-__-__"
            maxDate={maxDate}
            onChange={(value: Date | null) => {
              void formik.setFieldValue("dateTo", value, true);
            }}
          />
        </Grid>
      </Grid>
    </NewInstanceModal>
  );
};
