import React, { useCallback, useEffect, useMemo } from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import { SingleSelect, Switcher, TextInput } from "../../pages/FormFields";
import {
  CameraAuthenticationMethod,
  CameraTimeFormat,
  CameraTimezoneSource,
  useCreateUpdateCameraConfigurationMutation,
  useGetCameraConfigurationLazyQuery,
  useTestCameraConfigurationMutation,
} from "../../../graphql";
import { isNil, pick } from "lodash";
import { Button } from "../../pages/Common";
import {
  AUTHENTICATION_METHOD_CHOICES,
  CAMERA_TIME_FORMAT_CHOICES,
  DATE_TOKEN,
  DEFAULT_TIMEZONE_CHOICES,
  EMPTY_CAMERA_CONFIGURATION_FORM,
  EMPTY_CONFIGURATION_TEST_FORM,
  ISO_TIMEZONE_SOURCE,
  MODAL_ID,
  PartialPatternChoices,
  REQUIRED_CONFIGURATION_TEST_FIELDS,
} from "./constants";
import {
  AddEditCameraConfigurationModalProps,
  CameraConfigurationForm,
} from "./types";
import {
  CAMERA_CONFIUGURATION_FORM_VALIDATION_SCHEMA,
  CONFIGURATION_TEST_FORM_VALIDATION_SCHEMA,
  CONFIGURATION_TEST_MAIN_FORM_VALIDATION_SCHEMA,
} from "./validators";
import { ValidationError } from "yup";
import { convertConfigurationFormToAPIData } from "./helpers";
import { RenderConfigurationTestingResponse } from "./components/RenderConfigurationTestingResponse";

export default function AddEditCameraConfigurationModal({
  id,
  isOpened,
  onClose,
}: AddEditCameraConfigurationModalProps) {
  const [handleCameraConfigurationFetch] = useGetCameraConfigurationLazyQuery();
  const [handleCameraConfigurationCreateUpdate, { error: createUpdateError }] =
    useCreateUpdateCameraConfigurationMutation();
  const [handleCameraConfigurationTest, cameraConfigurationTestResponse] =
    useTestCameraConfigurationMutation();

  const cameraConfigurationForm = useFormik<CameraConfigurationForm>({
    initialValues: EMPTY_CAMERA_CONFIGURATION_FORM,
    validationSchema: CAMERA_CONFIUGURATION_FORM_VALIDATION_SCHEMA,
    validateOnBlur: true,
    onSubmit: (values, formikHelpers) => {
      const data = convertConfigurationFormToAPIData(values);
      handleCameraConfigurationCreateUpdate({
        variables: {
          cameraId: id,
          input: data,
        },
      })
        .then(() => {
          formikHelpers.resetForm({ values: EMPTY_CAMERA_CONFIGURATION_FORM });
          onClose(true);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
  });

  const cameraConfigurationTestingForm = useFormik({
    initialValues: EMPTY_CONFIGURATION_TEST_FORM,
    validationSchema: CONFIGURATION_TEST_FORM_VALIDATION_SCHEMA,
    validateOnBlur: true,
    onSubmit: (values) => {
      try {
        CONFIGURATION_TEST_MAIN_FORM_VALIDATION_SCHEMA.validateSync(
          cameraConfigurationForm.values,
          { abortEarly: false },
        );
      } catch (e) {
        const error = e as ValidationError;
        void cameraConfigurationForm.setErrors(
          Object.fromEntries(
            error.inner.map(({ errors, path }) => [path, errors[0]]),
          ) as typeof cameraConfigurationForm.errors,
        );
        void cameraConfigurationForm.setTouched(
          Object.fromEntries(
            error.inner.map(({ path }) => [path, true]),
          ) as typeof cameraConfigurationForm.touched,
        );
        return;
      }

      void handleCameraConfigurationTest({
        variables: {
          credentials: values,
          configuration: pick(
            convertConfigurationFormToAPIData(cameraConfigurationForm.values),
            REQUIRED_CONFIGURATION_TEST_FIELDS,
          ),
        },
      });
    },
  });

  const timezoneChoices = useMemo(
    function getTimezoneChoices() {
      return (
        cameraConfigurationForm.values.timeFormat === CameraTimeFormat.Iso
          ? [
              { label: "ISO Value", value: ISO_TIMEZONE_SOURCE },
              ...DEFAULT_TIMEZONE_CHOICES,
            ]
          : DEFAULT_TIMEZONE_CHOICES
      ) as {
        label: string;
        value: CameraTimezoneSource | typeof ISO_TIMEZONE_SOURCE;
      }[];
    },
    [cameraConfigurationForm.values.timeFormat],
  );

  const handleAuthenticationMethodChange = useCallback(
    function createAuthenticationMethodChangeHandler(
      value: CameraAuthenticationMethod,
    ) {
      void cameraConfigurationForm.setFieldValue("authenticationMethod", value);
    },
    [cameraConfigurationForm.setFieldValue],
  );

  const handleTimeFormatChange = useCallback(
    function creatTimeFormatChangeHandler(value: CameraTimeFormat) {
      void cameraConfigurationForm.setFieldValue("timeFormat", value);
    },
    [cameraConfigurationForm.setFieldValue],
  );

  const handleTimezoneChange = useCallback(
    function creatTimezoneChangeHandler(
      value: CameraTimezoneSource | typeof ISO_TIMEZONE_SOURCE,
    ) {
      void cameraConfigurationForm.setFieldValue("timezone", value);
    },
    [cameraConfigurationForm.setFieldValue],
  );

  const handleUserAvailabilityChange = useCallback(
    function handleUserAvailabilityChange(value: boolean) {
      void cameraConfigurationForm.setFieldValue("isAvailableForUsers", value);
    },
    [cameraConfigurationForm.setFieldValue],
  );

  const handleClose = useCallback(
    function handleClose() {
      cameraConfigurationForm.resetForm({
        values: EMPTY_CAMERA_CONFIGURATION_FORM,
      });
      cameraConfigurationTestingForm.resetForm({
        values: EMPTY_CONFIGURATION_TEST_FORM,
      });
      cameraConfigurationTestResponse.reset();
      onClose(false);
    },
    [
      cameraConfigurationForm.resetForm,
      cameraConfigurationTestingForm.resetForm,
      // Only used for signature testing, no harm here.
      // eslint-disable-next-line @typescript-eslint/unbound-method
      cameraConfigurationTestResponse.reset,
      onClose,
    ],
  );

  const handleTestFormSubmit = useCallback(
    function handleTestFormSubmit() {
      cameraConfigurationTestingForm.submitForm().catch(() => {
        void cameraConfigurationTestingForm.setTouched(
          Object.fromEntries(
            Object.keys(EMPTY_CONFIGURATION_TEST_FORM).map((key) => [
              key,
              true,
            ]),
          ),
        );
      });
    },
    [
      cameraConfigurationTestingForm.submitForm,
      cameraConfigurationTestingForm.setFieldTouched,
    ],
  );

  useEffect(
    function fetchCameraConfiguration() {
      if (!id)
        cameraConfigurationForm.resetForm({
          values: EMPTY_CAMERA_CONFIGURATION_FORM,
        });
      else
        handleCameraConfigurationFetch({
          variables: {
            id,
          },
        })
          .then((response) => {
            const cameraConfiguration = response.data?.getCameraConfiguration;
            if (!cameraConfiguration) return;
            const form: CameraConfigurationForm = {
              ...EMPTY_CAMERA_CONFIGURATION_FORM,
              ...pick(
                cameraConfiguration,
                Object.keys(EMPTY_CAMERA_CONFIGURATION_FORM),
              ),
              timezone: isNil(cameraConfiguration.timezone)
                ? ISO_TIMEZONE_SOURCE
                : cameraConfiguration.timezone,
              pattern: cameraConfiguration.timeParsingRules.pattern ?? "",
              customTimeFormat:
                cameraConfiguration.timeParsingRules.customTimeFormat ?? "",
              partialPatterns: {
                ...EMPTY_CAMERA_CONFIGURATION_FORM.partialPatterns,
                ...cameraConfiguration.timeParsingRules.partialPatterns,
              },
            };

            cameraConfigurationForm.resetForm({
              values: form,
            });
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
    },
    [id],
  );

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby={MODAL_ID}
      maxWidth="md"
      fullWidth
    >
      {typeof createUpdateError !== "undefined" && (
        <Alert severity="error">{createUpdateError.message}</Alert>
      )}
      <form onSubmit={cameraConfigurationForm.handleSubmit}>
        <DialogTitle id={MODAL_ID}>
          <Grid container direction="row" flexWrap="nowrap">
            <Grid item width="calc(50% - 8px)" mr={2}>
              {id ? "Edit" : "Create"} Camera Configuration
            </Grid>
            <Grid item width="calc(50% - 8px)" ml={2}>
              Test Camera Configuration
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" flexWrap="nowrap" mt={2}>
            <Grid item width="calc(50% - 8px)" mr={2}>
              <Grid container justifyContent="space-between">
                <Grid item mb={2} xs={12}>
                  <TextInput
                    label="Name"
                    name="name"
                    value={cameraConfigurationForm.values.name}
                    onChange={cameraConfigurationForm.handleChange}
                    onBlur={cameraConfigurationForm.handleBlur}
                    showError={
                      cameraConfigurationForm.touched.name &&
                      Boolean(cameraConfigurationForm.errors.name?.length)
                    }
                    helperText={
                      cameraConfigurationForm.touched.name
                        ? (cameraConfigurationForm.errors.name as string)
                        : ""
                    }
                  />
                </Grid>
                <Grid item mb={2} xs={12}>
                  <TextInput
                    label="Timestamp URI"
                    name="statusUri"
                    value={cameraConfigurationForm.values.statusUri}
                    onChange={cameraConfigurationForm.handleChange}
                    onBlur={cameraConfigurationForm.handleBlur}
                    showError={
                      cameraConfigurationForm.touched.statusUri &&
                      Boolean(cameraConfigurationForm.errors.statusUri?.length)
                    }
                    helperText={
                      cameraConfigurationForm.touched.statusUri
                        ? (cameraConfigurationForm.errors.statusUri as string)
                        : ""
                    }
                  />
                </Grid>
                <Grid item mb={2} xs={12}>
                  <TextInput
                    label="Snapshot URI"
                    name="snapshotUri"
                    value={cameraConfigurationForm.values.snapshotUri}
                    onChange={cameraConfigurationForm.handleChange}
                    onBlur={cameraConfigurationForm.handleBlur}
                    showError={
                      cameraConfigurationForm.touched.snapshotUri &&
                      Boolean(
                        cameraConfigurationForm.errors.snapshotUri?.length,
                      )
                    }
                    helperText={
                      cameraConfigurationForm.touched.snapshotUri
                        ? (cameraConfigurationForm.errors.snapshotUri as string)
                        : ""
                    }
                  />
                </Grid>
                <Grid item mb={2} xs={12}>
                  <SingleSelect
                    label="Authentication Method"
                    name="authenticationMethod"
                    options={AUTHENTICATION_METHOD_CHOICES}
                    value={cameraConfigurationForm.values.authenticationMethod}
                    onChange={handleAuthenticationMethodChange}
                    onBlur={cameraConfigurationForm.handleBlur}
                  />
                </Grid>
                <Grid item mb={2} xs={12}>
                  <SingleSelect
                    label="Time Format"
                    name="timeFormat"
                    options={CAMERA_TIME_FORMAT_CHOICES}
                    value={cameraConfigurationForm.values.timeFormat}
                    onChange={handleTimeFormatChange}
                    onBlur={cameraConfigurationForm.handleBlur}
                  />
                </Grid>
                <Grid item mb={2} xs={12}>
                  <SingleSelect
                    label="Timezone"
                    name="timezone"
                    options={timezoneChoices}
                    value={cameraConfigurationForm.values.timezone}
                    onChange={handleTimezoneChange}
                    onBlur={cameraConfigurationForm.handleBlur}
                  />
                </Grid>
                {cameraConfigurationForm.values.timeFormat ===
                CameraTimeFormat.Partial ? (
                  Object.entries(PartialPatternChoices).map(
                    ([label, value]) => (
                      <Grid item mb={2} xs={12} key={value}>
                        <TextInput
                          label={`${label} Pattern`}
                          name={`partialPatterns.${value}`}
                          placeholder={`UTCTime = ${DATE_TOKEN}`}
                          value={
                            cameraConfigurationForm.values.partialPatterns?.[
                              value
                            ]
                          }
                          onChange={cameraConfigurationForm.handleChange}
                          onBlur={cameraConfigurationForm.handleBlur}
                          showError={
                            cameraConfigurationForm.touched.partialPatterns?.[
                              value
                            ] &&
                            Boolean(
                              cameraConfigurationForm.errors.partialPatterns?.[
                                value
                              ]?.length,
                            )
                          }
                          helperText={
                            cameraConfigurationForm.touched.partialPatterns?.[
                              value
                            ]
                              ? cameraConfigurationForm.errors
                                  .partialPatterns?.[value]
                              : ""
                          }
                        />
                      </Grid>
                    ),
                  )
                ) : (
                  <Grid item mb={2} xs={12}>
                    <TextInput
                      label="Pattern"
                      name="pattern"
                      placeholder={`UTCTime = ${DATE_TOKEN}`}
                      value={cameraConfigurationForm.values.pattern}
                      onChange={cameraConfigurationForm.handleChange}
                      onBlur={cameraConfigurationForm.handleBlur}
                      showError={
                        cameraConfigurationForm.touched.pattern &&
                        Boolean(cameraConfigurationForm.errors.pattern?.length)
                      }
                      helperText={
                        cameraConfigurationForm.touched.pattern
                          ? (cameraConfigurationForm.errors.pattern as string)
                          : ""
                      }
                    />
                  </Grid>
                )}
                {cameraConfigurationForm.values.timeFormat ===
                  CameraTimeFormat.Custom && (
                  <Grid item mb={2} xs={12}>
                    <TextInput
                      label="Time Format"
                      name="customTimeFormat"
                      placeholder="mm/dd/yyyy hh:mm"
                      value={cameraConfigurationForm.values.customTimeFormat}
                      onChange={cameraConfigurationForm.handleChange}
                      onBlur={cameraConfigurationForm.handleBlur}
                      showError={
                        cameraConfigurationForm.touched.customTimeFormat &&
                        Boolean(
                          cameraConfigurationForm.errors.customTimeFormat
                            ?.length,
                        )
                      }
                      helperText={
                        cameraConfigurationForm.touched.customTimeFormat
                          ? (cameraConfigurationForm.errors
                              .customTimeFormat as string)
                          : ""
                      }
                    />
                  </Grid>
                )}
                <Grid item mb={2} xs={12}>
                  <Switcher
                    label="Is visible for all users"
                    name="isAvailableForUsers"
                    checked={cameraConfigurationForm.values.isAvailableForUsers}
                    labelPlacement="end"
                    onChange={handleUserAvailabilityChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item width="calc(50% - 8px)" ml={2}>
              <form onSubmit={cameraConfigurationTestingForm.handleSubmit}>
                <Grid container justifyContent="space-between">
                  <Grid item mb={2} xs={12}>
                    <TextInput
                      label="IP address"
                      name="address"
                      value={cameraConfigurationTestingForm.values.address}
                      showError={
                        cameraConfigurationTestingForm.touched.address &&
                        Boolean(
                          cameraConfigurationTestingForm.errors.address?.length,
                        )
                      }
                      helperText={
                        cameraConfigurationTestingForm.touched.address
                          ? (cameraConfigurationTestingForm.errors
                              .address as string)
                          : ""
                      }
                      onChange={cameraConfigurationTestingForm.handleChange}
                      onBlur={cameraConfigurationTestingForm.handleBlur}
                    />
                  </Grid>
                  <Grid item mb={2} xs={12}>
                    <TextInput
                      label="Username"
                      name="username"
                      value={cameraConfigurationTestingForm.values.username}
                      showError={
                        cameraConfigurationTestingForm.touched.username &&
                        Boolean(
                          cameraConfigurationTestingForm.errors.username
                            ?.length,
                        )
                      }
                      helperText={
                        cameraConfigurationTestingForm.touched.username
                          ? (cameraConfigurationTestingForm.errors
                              .username as string)
                          : ""
                      }
                      onChange={cameraConfigurationTestingForm.handleChange}
                      onBlur={cameraConfigurationTestingForm.handleBlur}
                    />
                  </Grid>
                  <Grid item mb={2} xs={12}>
                    <TextInput
                      label="Password"
                      name="password"
                      type="password"
                      value={cameraConfigurationTestingForm.values.password}
                      showError={
                        cameraConfigurationTestingForm.touched.password &&
                        Boolean(
                          cameraConfigurationTestingForm.errors.password
                            ?.length,
                        )
                      }
                      helperText={
                        cameraConfigurationTestingForm.touched.password
                          ? (cameraConfigurationTestingForm.errors
                              .password as string)
                          : ""
                      }
                      onChange={cameraConfigurationTestingForm.handleChange}
                      onBlur={cameraConfigurationTestingForm.handleBlur}
                    />
                  </Grid>
                </Grid>
              </form>
              <Box>
                <RenderConfigurationTestingResponse
                  testingResponse={cameraConfigurationTestResponse}
                ></RenderConfigurationTestingResponse>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" flexWrap="nowrap">
            <Grid
              item
              container
              flexDirection="row-reverse"
              width="calc(50% - 8px)"
              mr={2}
            >
              <Button type="submit" color="primary" variant="contained">
                Save
              </Button>
              <Button
                color="primary"
                variant="outlined"
                mr={2}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              container
              flexDirection="row-reverse"
              width="calc(50% - 8px)"
              ml={2}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleTestFormSubmit}
              >
                Test configuration
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}
