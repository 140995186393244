import React from "react";
import styled from "@emotion/styled";
import { generatePath, useNavigate } from "react-router-dom";
import { SidebarItemsType } from "../../types/sidebar";
import { Camera, Edit, Layers, Sidebar, Users } from "react-feather";
import BusinessIcon from "@mui/icons-material/Business";
import LockIcon from "@mui/icons-material/Lock";
import { Grid } from "@mui/material";
import { orderBy } from "lodash";
import {
  AdminNavigationPath,
  adminPathPrefix,
  getAdminUrl,
  getSiteUrl,
  SiteNavigationPath,
  sitePathPrefix,
} from "../../helpers/navigation";
import { ConstructionCompany, User } from "@ocilex/api";
import {
  canAdminCompany,
  canAdminSite,
  getAllowedAdminPages,
  getSitePagesForShowInSidebar,
} from "../../helpers/permissions";
import { NavSectionProps } from "./SidebarNav";

import { UpgradePlanIcon } from "../pages/Common";
import { CompareFramesComparisonTool } from "../../pages/site/CompareFrames";

export function getMenuItems(
  loggedUser: User,
  companies: ConstructionCompany[],
) {
  const sidebarMenu: NavSectionProps[] = [];
  const adminPages = getAdminMenuForUser(loggedUser);

  if (adminPages.length > 0)
    sidebarMenu.push({
      title: "Admin panel",
      pages: adminPages,
      uniqKey: "admin",
    });

  orderBy(companies, [(row) => row.name.toLowerCase()])
    .filter((company) => (company.activeSites || []).length > 0)
    .map((company) => {
      const sitePages = orderBy(company.activeSites, [
        (row) => row.name.toLowerCase(),
      ]).map((site) => {
        return {
          icon: <SiteIcon loggedUser={loggedUser} siteId={site.id} />,
          title: site.name,
          children: getSiteMenuForUser(loggedUser, site.id),
          getIsActive: (pathname: string) =>
            pathname.includes(`/${sitePathPrefix}/${site.id}`),
        };
      });

      sidebarMenu.push({
        title: <CompanyTitleSection company={company} user={loggedUser} />,
        pages: sitePages as SidebarItemsType[],
        uniqKey: company.name,
      });
    });

  return sidebarMenu;
}

type AdminMenuPage = Extract<
  AdminNavigationPath,
  "CompanyList" | "CompanyEdit" | "SiteList" | "SiteEdit"
>;

const getAdminMenuForUser = (user: User): SidebarItemsType[] => {
  const adminMenu = {
    [AdminNavigationPath.CompanyList]: {
      href: getAdminUrl(AdminNavigationPath.CompanyList),
      icon: <Layers />,
      title: "Companies",
      getIsActive: (pathname: string) =>
        pathname.includes(`${adminPathPrefix}/company`),
    },
    [AdminNavigationPath.SiteList]: {
      href: getAdminUrl(AdminNavigationPath.SiteList),
      icon: <Sidebar />,
      title: "Construction Sites",
      getIsActive: (pathname: string) =>
        pathname.includes(`${adminPathPrefix}/site`),
    },
    [AdminNavigationPath.UserList]: {
      href: getAdminUrl(AdminNavigationPath.UserList),
      icon: <Users />,
      title: "User Management",
      getIsActive: (pathname: string) =>
        pathname.includes(`${adminPathPrefix}/user`),
    },
    [AdminNavigationPath.CameraConfigurationsList]: {
      href: getAdminUrl(AdminNavigationPath.CameraConfigurationsList),
      icon: <Camera />,
      title: "Camera Configurations",
      getIsActive: (pathname: string) =>
        pathname.includes(`${adminPathPrefix}/camera-configuration`),
    },
  };

  const allowedPages = getAllowedAdminPages(user);
  const pages = Object.keys(adminMenu).filter((key) =>
    allowedPages.includes(key as AdminMenuPage),
  ) as AdminMenuPage[];
  return pages.map((key) => adminMenu[key]);
};

const getSiteMenuForUser = (
  loggedUser: User,
  siteId: number,
): SidebarItemsType[] => {
  const siteMenu = [
    [
      SiteNavigationPath.UserList,
      {
        href: getSiteUrl(SiteNavigationPath.UserList, siteId),
        title: "User Management",
        getIsActive: (pathname: string) =>
          pathname.includes(`/${sitePathPrefix}/${siteId}/user`),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewUserManagement,
    ],
    [
      [SiteNavigationPath.LiveViewList],
      {
        href: getSiteUrl(SiteNavigationPath.LiveViewList, siteId),
        title: "Live View",
        getIsActive: (pathname: string) =>
          pathname.startsWith(
            getSiteUrl(SiteNavigationPath.LiveViewList, siteId),
          ),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewLiveView,
    ],
    [
      SiteNavigationPath.CompareSnapshots,
      {
        href: generatePath(
          getSiteUrl(SiteNavigationPath.CompareSnapshots, siteId),
          { comparisonTool: CompareFramesComparisonTool.SideBySide },
        ),
        title: "Site Progress",
        getIsActive: (pathname: string) =>
          pathname.startsWith(
            `${getSiteUrl(SiteNavigationPath.CompareSnapshotsBaseURI, siteId)}${
              CompareFramesComparisonTool.SideBySide
            }`,
          ),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewSiteProgress,
    ],
    [
      SiteNavigationPath.CompareSnapshots,
      {
        href: generatePath(
          getSiteUrl(SiteNavigationPath.CompareSnapshots, siteId),
          { comparisonTool: CompareFramesComparisonTool.Magnifier },
        ),
        title: "X-Ray",
        getIsActive: (pathname: string) =>
          pathname.startsWith(
            `${getSiteUrl(SiteNavigationPath.CompareSnapshotsBaseURI, siteId)}${
              CompareFramesComparisonTool.Magnifier
            }`,
          ),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewSiteProgress,
    ],
    [
      SiteNavigationPath.Timelapse,
      {
        href: getSiteUrl(SiteNavigationPath.Timelapse, siteId),
        title: "Timelapse",
        getIsActive: (pathname: string) =>
          pathname === getSiteUrl(SiteNavigationPath.Timelapse, siteId),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewTimelapse,
    ],
    [
      SiteNavigationPath.GateReport,
      {
        href: getSiteUrl(SiteNavigationPath.GateReport, siteId),
        title: "Gate Report",
        getIsActive: (pathname: string) =>
          pathname === getSiteUrl(SiteNavigationPath.GateReport, siteId),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewGateReport,
    ],
    [
      SiteNavigationPath.BimCompare,
      {
        href: getSiteUrl(SiteNavigationPath.BimCompare, siteId),
        title: "BIM Compare",
        getIsActive: (pathname: string) =>
          pathname === getSiteUrl(SiteNavigationPath.BimCompare, siteId),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewBIMCompare,
    ],
    // [
    //   SiteNavigationPath.BimView,
    //   {
    //     href: getSiteUrl(SiteNavigationPath.BimView, siteId),
    //     title: "BIM View",
    //     getIsActive: (pathname: string) =>
    //       pathname === getSiteUrl(SiteNavigationPath.BimView, siteId),
    //   },
    //   (user: User) =>
    //     user.isAdmin ||
    //     !user.permissionsFromCompany ||
    //     user.permissionsFromCompany.canViewBIMView,
    // ],
    [
      SiteNavigationPath.EditFrame,
      {
        href: getSiteUrl(SiteNavigationPath.EditFrame, siteId),
        title: "Edit Frame",
        getIsActive: (pathname: string) =>
          pathname === getSiteUrl(SiteNavigationPath.EditFrame, siteId),
      },
      (user: User) =>
        user.isAdmin ||
        !user.permissionsFromCompany ||
        user.permissionsFromCompany.canViewEditFrame,
    ],
  ] as Array<[SiteNavigationPath, SidebarItemsType, (user: User) => boolean]>;

  const pagesForShow = getSitePagesForShowInSidebar(loggedUser, siteId);

  return siteMenu
    .filter(([, , predicate]) => predicate(loggedUser))
    .map(([path, menuItem]) => {
      const page = pagesForShow.find((page) => page.path == path);

      if (!page) {
        return;
      }

      return {
        ...menuItem,
        title: page.isAllowed ? (
          menuItem.title
        ) : (
          <ForbiddenSitePageTitle title={menuItem.title} />
        ),
        disabled: !page.isAllowed,
        href: page.isAllowed ? menuItem.href : "#",
      };
    })
    .filter((menuItem) => !!menuItem) as SidebarItemsType[];
};

const EditIcon = styled(Edit)`
  cursor: pointer;
`;

const CompanyEditIcon = styled(EditIcon)`
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

const SiteEditIcon = styled(EditIcon)`
  opacity: 1 !important;
`;

const ForbiddenSitePageTitle: React.FC<{
  title: React.ReactNode;
}> = ({ title }) => {
  return (
    <div>
      <div style={{ display: "inline-block" }}>{title}</div>
      <div style={{ display: "inline-block" }}>
        <UpgradePlanIcon
          iconElement={
            <LockIcon
              style={{
                marginTop: "-15px",
                marginLeft: "5px",
                position: "absolute",
              }}
            />
          }
        />
      </div>
    </div>
  );
};

const SiteIcon: React.FC<{
  loggedUser: User;
  siteId: number;
}> = ({ loggedUser, siteId }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = React.useState(false);

  const siteUrl = React.useMemo(
    () => getAdminUrl(AdminNavigationPath.SiteEdit, siteId),
    [siteId],
  );

  const canEdit = React.useMemo(
    () => canAdminSite(loggedUser, siteId),
    [loggedUser, siteId],
  );

  const onEditClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    navigate(siteUrl);
  };

  const icon =
    isHovered && canEdit ? (
      <SiteEditIcon onClick={onEditClick} />
    ) : (
      <BusinessIcon />
    );

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon}
    </div>
  );
};

const CompanyTitleSection: React.FC<{
  user: User;
  company: ConstructionCompany;
}> = ({ user, company }) => {
  const navigate = useNavigate();
  const navigateCompanyEdit = React.useCallback(
    () => navigate(getAdminUrl(AdminNavigationPath.CompanyEdit, company.id)),
    [company.id],
  );

  const canEdit = React.useMemo(
    () => canAdminCompany(user, company.id),
    [user, company.id],
  );

  return (
    <Grid container justifyContent="space-between">
      <div>{company.name}</div>
      {canEdit && <CompanyEditIcon onClick={navigateCompanyEdit} size="20" />}
    </Grid>
  );
};
