import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useProject } from "../../constants";
import { Link } from "react-router-dom";

import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import { SidebarFooter } from "./SidebarFooter";
import { NavSectionProps, SidebarNav } from "./SidebarNav";
import logoUrl from "../../vendor/logo100.png";
import { useCurrentUser } from "../../auth/hooks";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const LogoIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

export interface SidebarProps {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: NavSectionProps[];
  showFooter?: boolean;
  showMenu?: boolean;
}

export const Sidebar = ({
  items,
  showFooter = true,
  showMenu = true,
  ...rest
}: SidebarProps) => {
  const user = useCurrentUser();
  const { name } = useProject();
  return (
    <Drawer variant="permanent" {...rest}>
      <Link to="/">
        <Brand>
          <LogoIcon src={user?.permissionsFromCompany?.logo_s3 || logoUrl} />
          <Box ml={1}>{name}</Box>
        </Brand>
      </Link>
      <SidebarNav {...{ showMenu, items }} />
      {!!showFooter && <SidebarFooter />}
    </Drawer>
  );
};
