import React, { useCallback, useMemo } from "react";
import * as Yup from "yup";

import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { Button, Divider, PageTitle } from "../../components/pages/Common";
import {
  companyInputParams,
  defaultHelperText,
} from "../../components/pages/inputConstants";

import { ConstructionCompanyWithPerms } from "@ocilex/api";

import { FormCard } from "../../components/FormCard";

import {
  CompanyStatus,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
  useRequestUpdateCompanyLogoMutation,
  useUpdateCompanyMutation,
} from "../../graphql";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
  notFoundUrl,
} from "../../helpers/navigation";

import {
  FileInput,
  SingleSelect,
  Switcher,
  TextArea,
  TextInput,
} from "../../components/pages/FormFields";
import { useFormik } from "formik";
import { isEmpty, pick } from "lodash";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { useCurrentUser } from "../../auth/hooks";
import { getCompanyStatusChoices } from "../../helpers/getChoices";
import { CompanyStatusLabel } from "../../constants";
import DeletionCard from "../../components/DeletionCard";

export const CompanyEditAdmin: React.FC = () => {
  const { companyId: id } = useParams();
  const companyId = parseInt(id || "");
  const navigate = useNavigate();
  const user = useCurrentUser();

  const { data, refetch, error } = useGetCompanyQuery({
    variables: { id: companyId },
  });
  const [dispatchCompanyDeletion] = useDeleteCompanyMutation({
    variables: {
      id: companyId,
    },
  });

  React.useEffect(() => {
    if (error) navigate(notFoundUrl);
  }, [error]);

  const handleDeletionConfirmation = useCallback(
    function handleDeletionConfirmation() {
      void dispatchCompanyDeletion().then(() =>
        navigate(getAdminUrl(AdminNavigationPath.CompanyList)),
      );
    },
    [dispatchCompanyDeletion, navigate],
  );

  const company = data?.constructionCompany || null;
  const refetchCompany = () => void refetch();

  if (!company) return null;

  return (
    <>
      <PageTitle text="Edit Construction Company" />
      <Divider my={6} />
      <FormCard size="sm">
        <CompanyEditForm company={company} refetchCompany={refetchCompany} />
      </FormCard>
      {Boolean(user?.isAdmin) && (
        <DeletionCard
          title="Delete Company"
          size="sm"
          confirmationMessage={`Are you sure you want to delete Company ${company.name}?
            Please keep in mind that all data, including construction sites, cameras, and timelapses will be permanently deleted.
            Consider disabling the company by changing status to Inactive to hide it from users without data loss.`}
          onConfirmation={handleDeletionConfirmation}
        >
          <Typography variant="body1">
            You can delete company by clicking the button below.
          </Typography>
          <Typography variant="body1">
            Please keep in mind that all data, including construction sites,
            cameras, and timelapses will be permanently deleted. Consider
            disabling the company by setting status to DISABLED to hide it from
            users without data loss.
          </Typography>
        </DeletionCard>
      )}
    </>
  );
};

interface FormProps {
  company: ConstructionCompanyWithPerms;
  refetchCompany: () => void;
}

enum ModalAction {
  save = "save",
  saveAndContinue = "save_n_continue",
}

const SiteValidationSchema = Yup.object().shape({
  name: Yup.string().required(defaultHelperText.required),
  status: Yup.string().oneOf(Object.keys(CompanyStatusLabel)),
  description: Yup.string().nullable(),
  smtpHost: Yup.string().nullable(),
  smtpPort: Yup.number().integer().nullable(),
  smtpUser: Yup.string().nullable(),
  smtpPass: Yup.string().nullable(),
  smtpFrom: Yup.string().nullable(),
  canViewUserManagement: Yup.bool().default(false),
  canViewLiveView: Yup.bool().default(false),
  canViewSiteProgress: Yup.bool().default(false),
  canViewTimelapse: Yup.bool().default(false),
  canViewGateReport: Yup.bool().default(false),
  canViewBIMCompare: Yup.bool().default(false),
  canViewBIMView: Yup.bool().default(false),
  canViewEditFrame: Yup.bool().default(false),
});

const permissionsConfig: {
  name:
    | "canViewUserManagement"
    | "canViewLiveView"
    | "canViewSiteProgress"
    | "canViewTimelapse"
    | "canViewGateReport"
    | "canViewBIMCompare"
    | "canViewBIMView"
    | "canViewEditFrame";
  label: string;
}[] = [
  { name: "canViewUserManagement", label: "Can manage users" },
  { name: "canViewLiveView", label: "Can see live view" },
  { name: "canViewSiteProgress", label: "Can view site progress" },
  { name: "canViewTimelapse", label: "Can view timelapse" },
  { name: "canViewGateReport", label: "Can view gate report" },
  { name: "canViewBIMCompare", label: "Can compare BIM" },
  { name: "canViewBIMView", label: "Can view BIM" },
  { name: "canViewEditFrame", label: "Can edit frame" },
];

const CompanyEditForm: React.FC<FormProps> = ({ company, refetchCompany }) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [updateCompany, { loading }] = useUpdateCompanyMutation();
  const [modalAction, setModalAction] = React.useState<ModalAction | null>(
    null,
  );
  const [file, setFile] = React.useState<File>();

  const initialValues = pick(company, [
    "name",
    "description",
    "status",
    "smtpHost",
    "smtpPort",
    "smtpUser",
    "smtpPass",
    "smtpFrom",
    "canViewUserManagement",
    "canViewLiveView",
    "canViewSiteProgress",
    "canViewTimelapse",
    "canViewGateReport",
    "canViewBIMCompare",
    "canViewBIMView",
    "canViewEditFrame",
    "logo_s3",
  ]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SiteValidationSchema,
    validateOnBlur: true,
    onSubmit: () => {
      null;
    },
  });

  const statusSelectProps = React.useMemo(() => {
    return {
      options: getCompanyStatusChoices(company.status),
      onChange: (value: string) => {
        void formik.setFieldValue("status", value);
      },
    };
  }, [company.status]);

  React.useEffect(
    () =>
      formik.resetForm({
        values: pick(company, [
          "name",
          "description",
          "status",
          "smtpHost",
          "smtpPort",
          "smtpUser",
          "smtpPass",
          "smtpFrom",
          "canViewUserManagement",
          "canViewLiveView",
          "canViewSiteProgress",
          "canViewTimelapse",
          "canViewGateReport",
          "canViewBIMCompare",
          "canViewBIMView",
          "canViewEditFrame",
        ]),
      }),
    [company],
  );

  const redirect = () => {
    navigate(-1);
    setTimeout(() => window.location.reload(), 300);
  };

  const saveChanges = async () => {
    return formik.validateForm().then(async (errors) => {
      if (!isEmpty(errors)) return;
      setModalAction(null);
      let withLogo = false;
      if (file) {
        const uploadFile = async () => {
          const { data } = await requestUpdateCompanyLogo();
          if (data?.requestUpdateCompanyLogo?.uploadUrl) {
            const uploadUrl = data.requestUpdateCompanyLogo.uploadUrl;
            await fetch(uploadUrl, {
              method: "PUT",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              body: file,
            });
            withLogo = true;
          }
        };
        await uploadFile();
      }
      await updateCompany({
        variables: {
          input: {
            ...formik.values,
            id: company.id,
            smtpPort:
              formik.values.smtpPort &&
              !isNaN(parseInt(formik.values.smtpPort as unknown as string))
                ? parseInt(formik.values.smtpPort as unknown as string)
                : undefined,
            status: formik.values.status as CompanyStatus,
            withLogo,
          },
        },
      })
        .then(() => refetchCompany())
        .catch(() => navigate(errorUrl));
    });
  };
  const save = () => void saveChanges().then(redirect);
  const saveAndContinue = () =>
    void saveChanges().then(() => window.location.reload());

  const handleSave = (getBack: boolean) => {
    const beenDeactivated =
      company.status !== CompanyStatus.Inactive &&
      formik.values.status === CompanyStatus.Inactive;

    if (beenDeactivated)
      setModalAction(getBack ? ModalAction.save : ModalAction.saveAndContinue);
    else getBack ? save() : saveAndContinue();
  };

  const onModalConfirm = () =>
    modalAction === ModalAction.save ? save() : saveAndContinue();

  const [requestUpdateCompanyLogo] = useRequestUpdateCompanyLogoMutation({
    variables: { companyId: company.id },
  });

  const imageUrl = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }
    if (user?.permissionsFromCompany?.logo_s3) {
      return user?.permissionsFromCompany?.logo_s3;
    }
    return;
  }, [file, user?.permissionsFromCompany?.logo_s3]);

  return (
    <form>
      <ConfirmationModal
        isOpened={!!modalAction}
        close={() => setModalAction(null)}
        onConfirm={onModalConfirm}
      >
        Are you sure you want to deactivate this company? All construction sites
        will also be deactivated, and all the timelapses for company's sites
        will be stopped.
      </ConfirmationModal>

      <Grid container spacing={2}>
        <Grid item md={9} xs={12} mb={2}>
          <TextInput
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.name}
            showError={!isEmpty(formik.errors.name) && formik.touched.name}
            {...companyInputParams.name}
          />
        </Grid>
        <Grid item md={3} xs={12} mb={2}>
          <SingleSelect
            {...statusSelectProps}
            {...companyInputParams.status}
            value={formik.values.status as string}
          />
        </Grid>
        <Grid item md={12} xs={12} mb={2}>
          <TextArea
            name="description"
            rows={4}
            value={formik.values.description || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...companyInputParams.description}
          />
        </Grid>
        <Grid item md={4} xs={12} mb={2}>
          <TextInput
            label="SMTP host"
            value={formik.values.smtpHost}
            name="smtpHost"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.smtpHost}
            showError={
              !isEmpty(formik.errors.smtpHost) && formik.touched.smtpHost
            }
          />
        </Grid>
        <Grid item md={4} xs={12} mb={2}>
          <TextInput
            label="SMTP port"
            value={formik.values.smtpPort?.toString()}
            name="smtpPort"
            onChange={(e) => {
              const intValue = parseInt(e.target.value);
              // I am not proud of it
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              e.target.value = !isNaN(intValue) ? intValue : "";
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            helperText={formik.errors.smtpPort}
            showError={
              !isEmpty(formik.errors.smtpPort) && formik.touched.smtpPort
            }
          />
        </Grid>
        <Grid item md={4} xs={12} mb={2}>
          <TextInput
            label="SMTP user"
            value={formik.values.smtpUser}
            name="smtpUser"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.smtpUser}
            showError={
              !isEmpty(formik.errors.smtpUser) && formik.touched.smtpUser
            }
          />
        </Grid>
        <Grid item md={4} xs={12} mb={2}>
          <TextInput
            label="SMTP password"
            value={formik.values.smtpPass}
            type="password"
            name="smtpPass"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.smtpPass}
            showError={
              !isEmpty(formik.errors.smtpPass) && formik.touched.smtpPass
            }
          />
        </Grid>
        <Grid item md={4} xs={12} mb={2}>
          <TextInput
            label="SMTP sender"
            value={formik.values.smtpFrom}
            name="smtpFrom"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.smtpFrom}
            showError={
              !isEmpty(formik.errors.smtpFrom) && formik.touched.smtpFrom
            }
          />
        </Grid>
        <Grid item md={12} xs={12} mb={2}>
          <Grid container direction="row">
            <Grid item>
              <FileInput
                label="Upload logo"
                // fileName={formik.values.file?.name}
                onChange={(files: FileList | null) => setFile(files?.[0])}
                accept="image/*"
              />
            </Grid>
            {imageUrl && (
              <Grid item>
                <img
                  alt="preview image"
                  src={imageUrl}
                  style={{
                    height: "auto",
                    width: "auto",
                    maxWidth: "32px",
                    maxHeight: "32px",
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} mb={2}>
          {permissionsConfig.map(({ name, label }) => (
            <Switcher
              checked={formik.values[name] ?? false}
              disabled={!user?.isAdmin}
              onChange={(value) => void formik.setFieldValue(name, value)}
              label={label}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={6} justifyContent="right">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            my={2}
            mr={2}
            onClick={() => handleSave(false)}
            disabled={!isEmpty(formik.errors) || loading}
          >
            Save and continue editing
          </Button>
          <Button
            color="primary"
            variant="contained"
            my={2}
            mr={2}
            onClick={() => handleSave(true)}
            disabled={!isEmpty(formik.errors) || loading}
          >
            Save
          </Button>
          <Button color="primary" variant="outlined" my={2} onClick={redirect}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
