export const downloadFile = (data: string, fileName: string): void => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.setAttribute("style", "display: none");
  a.href = data;
  a.download = fileName;
  a.click();
  a.remove();
};

export const getFileExtension = (file: File): string =>
  file.name.split(".").pop() || "";
