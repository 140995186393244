/* eslint-disable no-console */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGetCameraSnapshotsQuery } from "../../../graphql";
import { Button } from "../../../components/pages/Common";
import { DatetimePicker } from "../../../components/pages/FormFields";
import { dateFormat } from "../../../constants";
import isToday from "date-fns/isToday";
import { addDays } from "date-fns";

interface Snapshot {
  snapshotUrl: string;
  id: number;
  createdAt: Date;
}

const DEFAULT_RESULT: { results: Snapshot[] } = { results: [] };

export const useSnapshotPlayback = (cameraId?: number) => {
  const maxDate = useMemo(() => new Date(), []);
  const [creationDay, setCreationDay] = useState(() => new Date());
  const [snapshots, setSnapshots] = useState(DEFAULT_RESULT);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data, refetch } = useGetCameraSnapshotsQuery({
    variables: {
      filters: {
        cameraId,
        creationDay: creationDay.toISOString(),
      },
    },
  });
  const isChangingDay = useRef(false);

  useEffect(() => {
    if (data?.getCameraSnapshots && snapshots === DEFAULT_RESULT) {
      setSnapshots({
        results: data.getCameraSnapshots.results.map((snapshot) => ({
          ...snapshot,
          createdAt: new Date(snapshot.createdAt),
        })),
      });
    } else {
      setSnapshots(DEFAULT_RESULT);
    }
    if (isChangingDay.current && data?.getCameraSnapshots.results.length) {
      setCurrentIndex(
        (data?.getCameraSnapshots.currentItemIndex as number) ??
          data?.getCameraSnapshots.results.length - 1,
      );
      isChangingDay.current = false;
    }
  }, [data]);

  const currentSnapshot = useMemo(
    () => snapshots.results[currentIndex],
    [snapshots, currentIndex],
  );

  console.log({
    isToday: isToday(creationDay),
    creationDay,
  });

  const forwardButton = (
    <Button
      className="forwardPlayButton"
      variant="contained"
      color="primary"
      disabled={currentIndex === 0 && isToday(creationDay)}
      onClick={() => {
        if (currentIndex === 0) {
          console.log("forward if");
          const newCreationDay = addDays(creationDay, 1);
          setCreationDay(newCreationDay);
          void refetch({
            filters: {
              cameraId,
              creationDay: newCreationDay.toISOString(),
              // .substring(0, 10),
            },
          }).then(
            (res) =>
              res.data.getCameraSnapshots.results.length &&
              setCurrentIndex(res.data.getCameraSnapshots.results.length - 1),
          );
        } else {
          console.log("forward else");
          setCurrentIndex(currentIndex - 1);
        }
      }}
    >
      <ChevronRightIcon style={{ fontSize: "xx-large" }} />
    </Button>
  );

  const backButton = (
    <Button
      className="backPlayButton"
      variant="contained"
      color="primary"
      onClick={() => {
        if (currentIndex === snapshots.results.length - 1) {
          console.log("back if");
          const newCreationDay = addDays(creationDay, -1);
          setCreationDay(newCreationDay);
          void refetch({
            filters: {
              cameraId,
              creationDay: newCreationDay.toISOString(),
              // .substring(0, 10),
            },
          }).then(() => setCurrentIndex(0));
        } else {
          console.log("back else");
          setCurrentIndex(currentIndex + 1);
        }
      }}
    >
      <ChevronLeftIcon style={{ fontSize: "xx-large" }} />
    </Button>
  );

  const datePicker = (
    <DatetimePicker
      value={creationDay}
      label="Snapshots for date"
      inputFormat={dateFormat}
      mask="____-__-__"
      maxDate={maxDate}
      onChange={(value: Date | null) => {
        if (value) {
          isChangingDay.current = true;
          setCreationDay(value);
        }
      }}
    />
  );

  return {
    forwardButton,
    backButton,
    currentSnapshot,
    datePicker,
  };
};
