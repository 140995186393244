import React from "react";

import { Grid, TableContainer, TablePagination } from "@mui/material";
import { SimpleTable } from "./SimpleTable";
import { useLocalStorage } from "../../helpers/localStorage";
import { PaginatedTableProps } from "./types";
import { omit } from "lodash";
import { Paper } from "../pages/Common";

const rowsPerPageOptions = [5, 10, 25, 100];

export const DEFAULT_ROWS_PER_PAGE = rowsPerPageOptions[1];

interface ServerPaginatedTableProps<T> extends PaginatedTableProps<T> {
  // page?: number;
  // rowsPerPage?: number;
  onPageChange?: (newPage: number) => void;
  onRowsPerPageChange?: (newRowsCount: number) => void;
  count?: number;
}

export const PaginatedTable = <T extends object>(
  props: ServerPaginatedTableProps<T>,
) => {
  const {
    rows,
    count,
    settingsLocalStorageKey,
    onPageChange,
    onRowsPerPageChange,
  } = props;
  const [page, setPage] = React.useState(0);
  // React.useEffect(() => setPage(propPage), [propPage])

  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>(
    `${settingsLocalStorageKey}-rows-per-page`,
    DEFAULT_ROWS_PER_PAGE,
  );

  React.useEffect(() => {
    onRowsPerPageChange?.(rowsPerPage);
    onPageChange?.(page);
  }, [rowsPerPage, page]);

  // const openedPage = React.useMemo(() => {
  //   const pagesTotal = Math.ceil(rows.length / rowsPerPage);
  //   const maxPageIndex = Math.max(pagesTotal - 1, 0);
  //   const newVal = page <= maxPageIndex ? page : maxPageIndex;
  //   if (newVal !== page) setPage(newVal);
  //   return newVal;
  // }, [page, props.rows, rowsPerPage]);

  const rowsToShow = React.useMemo(() => {
    if (onPageChange && onRowsPerPageChange) {
      return rows;
    }
    const from = page * rowsPerPage;
    const to = page * rowsPerPage + rowsPerPage;
    return rows.slice(from, to);
  }, [rows, rowsPerPage, page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseInt(event.target.value, rowsPerPageOptions[1]);
    setRowsPerPage(value);
    if (onRowsPerPageChange) {
      onRowsPerPageChange(value);
    }
    setPage(value);
    if (onPageChange) {
      onPageChange(value);
    }
  };

  const emptyRows = rowsPerPage - rowsToShow.length;

  return (
    <>
      <SimpleTable
        {...omit(props, ["rows"])}
        rows={rowsToShow}
        emptyRowsN={emptyRows}
      />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TableContainer>
            <Paper>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count ?? rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
