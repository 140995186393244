import React from "react";
import { GetForgeAuthTokenQuery } from "../graphql";
import { ForgeViewerLoader } from "./Loader";

interface ForgeViewerProps {
  objectId: string;
  token?: GetForgeAuthTokenQuery["forgeAuthToken"];
  edit?: boolean;
  show?: boolean;
  opacity?: number;
  sceneState?: string;
  hideLoader?: boolean;
  onload?: () => void;
  onCameraChange?: (sceneState: string) => void;
}

interface State {
  [key: string]: unknown;
  cutplanes: unknown;
  renderOptions: unknown;
  objectSet: unknown;
  seedURN: unknown;
  viewport: {
    name: unknown;
    worldUpVector: unknown;
    pivotPoint: unknown;
    distanceToOrbit: unknown;
    aspectRatio: unknown;
  };
}

interface CameraChangeEvent {
  camera: unknown;
  target: {
    getState: () => State;
  };
}

const forgeViewerSrc =
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.69/viewer3D.min.js";

export const ForgeViewer: React.FC<ForgeViewerProps> = (props) => {
  const [documentLoaded, setDocumentLoaded] = React.useState<boolean>(false);
  const [viewer, setViewer] = React.useState<Autodesk.Viewing.Viewer3D | null>(
    null,
  );

  React.useEffect(() => {
    if (viewer) {
      const opacity = typeof props.opacity === "number" ? props.opacity : 50;
      viewer.container.style.opacity = `${opacity / 100}`;
      viewer.container.style.display =
        props.show || props.edit ? "block" : "none";
      viewer.resize();
    }
  }, [viewer, props.opacity, props.show, props.edit]);

  React.useEffect(() => {
    if (viewer) {
      const onDocumentLoadSuccess = (
        viewerDocument: Autodesk.Viewing.Document,
      ) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        void viewer.loadDocumentNode(viewerDocument, defaultModel).then(() => {
          viewer.resize();
          if (props.sceneState) {
            viewer.restoreState(
              getCleanedState(JSON.parse(props.sceneState) as State),
              null,
              true,
            );
          } else {
            viewer.restoreState(
              getCleanedState(viewer.getState() as State),
              null,
              true,
            );
          }
          setDocumentLoaded(true);
        });
      };

      const onDocumentLoadFailure = () => {
        // eslint-disable-next-line no-console
        console.error("Failed fetching Forge manifest");
      };

      Autodesk.Viewing.Document.load(
        `urn:${btoa(props.objectId)}`,
        onDocumentLoadSuccess,
        onDocumentLoadFailure,
      );
      return () => {
        viewer.finish();
        setViewer(null);
        Autodesk.Viewing.shutdown();
      };
    }
  }, [viewer]);

  React.useEffect(() => {
    if (props.token && (props.show || props.edit)) {
      const scriptId = "forgeViewerScript";
      const existingScript = document.getElementById(scriptId);
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = forgeViewerSrc;
        script.id = scriptId;
        document.body.appendChild(script);
        script.onload = handleScriptLoad;
        script.onerror = handleViewerError;
      } else {
        handleScriptLoad();
      }
    }
  }, [props.token, props.show, props.edit]);

  const handleScriptLoad = () => {
    if (viewer) {
      return;
    }
    const options = {
      env: "AutodeskProduction2",
      api: "streamingV2", // for models uploaded to EMEA change this option to 'streamingV2_EU'
      getAccessToken: function (
        onTokenReady: (accessToken: string, expires: number) => void,
      ) {
        onTokenReady(
          props.token?.access_token || "",
          props.token?.expires_in || 3600,
        );
      },
    };

    Autodesk.Viewing.Initializer(options, () => {
      const htmlDiv = document.getElementById("forgeViewer");
      if (!htmlDiv) {
        return;
      }

      const viewer3D = new Autodesk.Viewing.Viewer3D(htmlDiv);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const startedCode = viewer3D.start(null, null, null, null, {
        webglInitParams: {
          alpha: true,
        },
      }) as number;
      if (startedCode > 0) {
        // eslint-disable-next-line no-console
        console.error("Failed to create a Viewer: WebGL not supported.");
        return;
      }

      // TODO: update types @types/forge-viewer
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      viewer3D.impl.renderer().setClearAlpha(0); //clear alpha channel
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      viewer3D.impl.glrenderer().setClearColor(0xffffff, 0); //set transparent background, color code does not matter
      viewer3D.impl.invalidate(true); //trigger rendering

      viewer3D.navigation.toPerspective();

      let containerStyle =
        "width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 100; display: none";
      containerStyle += props.edit ? "" : ";pointer-events: none";

      viewer3D.container.setAttribute("style", containerStyle);

      if (props.edit && props.onCameraChange) {
        viewer3D.addEventListener(
          Autodesk.Viewing.CAMERA_CHANGE_EVENT,
          (event: CameraChangeEvent) => {
            props.onCameraChange?.(
              JSON.stringify(getCleanedState(event.target.getState())),
            );
          },
        );
      }

      if (props.onload) {
        viewer3D.addEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          props.onload,
        );
      }

      setViewer(viewer3D);
    });
  };
  const handleViewerError = (error: unknown) => {
    // eslint-disable-next-line no-console
    console.log("handleViewerError: ", error);
  };
  return (
    <div
      id="forgeViewer"
      style={{
        width: "100%",
        height: "auto",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {(props.show || props.edit) && !documentLoaded && !props.hideLoader && (
        <ForgeViewerLoader />
      )}
      <div style={{ display: "block" }}>{props.children}</div>
    </div>
  );
};

const getCleanedState = (sceneState: State) => {
  return {
    ...sceneState,
    cutplanes: undefined,
    renderOptions: undefined,
    objectSet: undefined,
    seedURN: undefined,
    viewport: {
      ...sceneState.viewport,
      name: undefined,
      projection: "perspective",
      isOrthographic: false,
      fieldOfView: 45,
    },
  };
};
