import styled from "@emotion/styled";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { VerifiedUser, Person, Group } from "@mui/icons-material";
import { Card } from "../pages/Common";
import { darken } from "polished";
import { InviteSiteUserModal } from "./InviteSiteUserModal";
import { InviteAdminModal } from "./InviteAdminModal";
import { InviteCompAdminModal } from "./InviteCompAdminModal";
import { useCurrentUser } from "../../auth/hooks";

const StyledCard = styled(Card)`
  width: 150px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 0 2px ${(props) => props.theme.palette.action.selected};
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 4px 2px ${(props) => props.theme.sidebar.background};
  }

  svg {
    color: ${(props) => darken(0.03, props.theme.sidebar.background)};
    margin-top: 30px;
    width: 80px;
    height: 80px;
  }
`;

const UserTypeCard: React.FC<{
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}> = (props) => {
  return (
    <StyledCard mt={4} onClick={props.onClick}>
      {props.icon}
      <Typography mt={4} variant="h5">
        {props.label}
      </Typography>
    </StyledCard>
  );
};

export enum AddUserModal {
  Select = "select",
  AddAdmin = "addAdmin",
  AddUser = "addUser",
  AddCompAdmin = "addCompAdmin",
}

interface ModalProps {
  openedModal: AddUserModal | null;
  setOpenedModal: (modal: AddUserModal | null) => void;
}

export const InviteUserModal: React.FC<ModalProps> = ({
  openedModal,
  setOpenedModal,
}) => {
  const closeModal = () => setOpenedModal(null);
  const user = useCurrentUser();

  return (
    <>
      <InviteAdminModal
        isOpened={openedModal === AddUserModal.AddAdmin}
        close={closeModal}
      />
      <InviteCompAdminModal
        isOpened={openedModal === AddUserModal.AddCompAdmin}
        close={closeModal}
      />
      <InviteSiteUserModal
        isOpened={openedModal === AddUserModal.AddUser}
        close={closeModal}
      />
      <Dialog
        open={openedModal === AddUserModal.Select}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Select User Type</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" spacing={6}>
            {!!user?.isAdmin && (
              <Grid item>
                <UserTypeCard
                  label="Admin"
                  onClick={() => setOpenedModal(AddUserModal.AddAdmin)}
                  icon={<VerifiedUser />}
                />
              </Grid>
            )}
            <Grid item>
              <UserTypeCard
                label="Company Admin"
                onClick={() => setOpenedModal(AddUserModal.AddCompAdmin)}
                icon={<Group />}
              />
            </Grid>
            <Grid item>
              <UserTypeCard
                label="User"
                onClick={() => setOpenedModal(AddUserModal.AddUser)}
                icon={<Person />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
