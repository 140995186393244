import { ConfirmationModal } from "./modals/ConfirmationModal";
import { CardActions, CardContent, styled, Typography } from "@mui/material";
import { Button } from "./pages/Common";
import React, { ReactNode, useCallback, useState } from "react";
import { StyledCard } from "./FormCard";

const DangerCard = styled(StyledCard)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  borderWidth: "2px",
}));

const DangerTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

interface DeletionCardProps {
  title: string;
  children: ReactNode;
  onConfirmation: () => void;
  buttonCaption?: string;
  size?: "sm" | "md";
  confirmationMessage?: string;
}

export default function DeletionCard({
  title,
  children,
  onConfirmation,
  size,
  buttonCaption,
  confirmationMessage = "Are you sure you want to delete this object? All related data\n" +
    "will be permanently deleted from the database!",
}: DeletionCardProps) {
  const [isDeletionModalVisible, setDeletionModalStatus] = useState(false);

  const handleDeletionModalClose = useCallback(
    function handleDeletionModalClose() {
      setDeletionModalStatus(false);
    },
    [setDeletionModalStatus],
  );

  const handleDeletionModalOpen = useCallback(
    function handleDeletionModalOpen() {
      setDeletionModalStatus(true);
    },
    [setDeletionModalStatus],
  );

  const handleDeletionModalConfirmation = useCallback(
    function handleDeletionModalConfirmation() {
      handleDeletionModalClose();
      onConfirmation();
    },
    [handleDeletionModalClose, onConfirmation],
  );

  return (
    <>
      <ConfirmationModal
        isOpened={isDeletionModalVisible}
        close={handleDeletionModalClose}
        onConfirm={handleDeletionModalConfirmation}
      >
        {confirmationMessage.split("\n").map((paragraph) => (
          <Typography>{paragraph}</Typography>
        ))}
      </ConfirmationModal>
      <DangerCard variant="outlined" size={size}>
        <CardContent>
          <DangerTypography textAlign="center" variant="h4" mb="20px">
            {title}
          </DangerTypography>
          {children}
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button
            color="error"
            variant="contained"
            onClick={handleDeletionModalOpen}
          >
            {buttonCaption ?? title}
          </Button>
        </CardActions>
      </DangerCard>
    </>
  );
}
