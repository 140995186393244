class Auth {
  private idToken = "";

  setIdToken(token: string) {
    this.idToken = token;
  }

  getIdToken() {
    return this.idToken;
  }
}

export const auth = new Auth();
