import React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { defaultHelperText } from "../pages/inputConstants";
import {
  SingleSelect,
  Autocomplete,
  roleChoicesSortFn,
} from "../pages/FormFields";
import { useFormik } from "formik";

import * as Yup from "yup";
import { UserRoleLabel } from "../../constants";
import { Button } from "../pages/Common";
import { AddUpdateUserSiteInput, UserRole } from "../../graphql";
import { isEmpty } from "lodash";

export interface AddEditSiteUserModalProps {
  user: { email: string };
  sites: { name: string; id: number }[];
  initialValue?: null | {
    name: string;
    siteId: number;
    role: UserRole;
  };
  save: (value: AddUpdateUserSiteInput) => void;
  close: () => void;
  isOpened: boolean;
}

const TimelapseValidationSchema = Yup.object().shape({
  site: Yup.number().required(defaultHelperText.required),
  role: Yup.string().required(defaultHelperText.required),
});

export const AddEditSiteUserModal: React.FC<AddEditSiteUserModalProps> = (
  props,
) => {
  const siteChoices = React.useMemo(() => {
    const choices = props.sites.map((site) => {
      return { label: site.name, value: site.id };
    });

    if (props.initialValue)
      choices.push({
        label: props.initialValue.name,
        value: props.initialValue.siteId,
      });

    return choices;
  }, [props.initialValue, props.sites]);

  const initialValues = props.initialValue
    ? {
        site: props.initialValue.siteId,
        role: props.initialValue.role,
      }
    : {
        site: null,
        role: UserRole.General,
      };

  const formik = useFormik({
    initialValues,
    validationSchema: TimelapseValidationSchema,
    validateOnBlur: true,
    onSubmit: () => {
      null;
    },
  });

  React.useEffect(
    () => formik.resetForm({ values: initialValues }),
    [props.isOpened, props.initialValue],
  );

  const canSave =
    formik.isValid && (!isEmpty(formik.touched) || !!props.initialValue);

  const save = () => {
    props.save({
      email: props.user.email,
      ...formik.values,
    } as AddUpdateUserSiteInput);
  };

  const siteSelectProps = React.useMemo(() => {
    return {
      name: "site",
      label: "Site",
      placeholder: "Select Site",
      initialValue: formik.values.site,
      options: siteChoices,
      onChange: (value?: number) => {
        void formik
          .setFieldValue("site", value)
          .then(() => void formik.setFieldTouched("site", true));
      },
      onBlur: formik.handleBlur,
    };
  }, [siteChoices]);

  const statusSelectProps = React.useMemo(() => {
    return {
      name: "role",
      label: "Role",
      placeholder: "Select Role",
      initialValue: formik.values.role,
      options: Object.entries(UserRoleLabel).map(([value, label]) => {
        return { value, label };
      }),
      onChange: (value: string) => {
        void formik
          .setFieldValue("role", value)
          .then(() => void formik.setFieldTouched("role", true));
      },
      onBlur: formik.handleBlur,
    };
  }, []);

  return (
    <Dialog
      open={props.isOpened}
      onClose={props.close}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        {props.initialValue ? "Edit " : "Add "}
        Construction Site
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid
            container
            mt={6}
            columnSpacing={2}
            justifyContent="space-between"
          >
            <Grid item mb={2} xs={12}>
              <Autocomplete
                {...siteSelectProps}
                value={formik.values.site}
                onBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item mb={2} xs={12}>
              <SingleSelect
                {...statusSelectProps}
                choicesSortFn={roleChoicesSortFn}
                value={formik.values.role}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!canSave}
          onClick={save}
        >
          Save
        </Button>
        <Button color="primary" variant="outlined" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
