import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

import { auth } from "../auth/auth";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_APOLLO_SERVER_URI });

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: auth.getIdToken(),
    },
  });

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_SERVER_URI,
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: ["email"],
      },
    },
  }),
  link: authLink.concat(httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-and-network",
    },
  },
});
