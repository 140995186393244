import styled from "@emotion/styled";
import timezones from "timezones-list";
import {
  Avatar,
  Box,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import ReactDOM from "react-dom";
import { isEmpty, orderBy, pick } from "lodash";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddEditTimelapseModal } from "../../components/modals/AddEditTimelapseModal";
import {
  Alert,
  Button,
  Divider,
  PageTitle,
  PlusButton,
} from "../../components/pages/Common";
import {
  Autocomplete,
  SingleSelect,
  TextInput,
} from "../../components/pages/FormFields";
import {
  defaultHelperText,
  siteInputParams,
} from "../../components/pages/inputConstants";
import {
  emDash,
  SiteStatusLabel,
  TimelapseFrequencyLabel,
  TimelapseStatusLabel,
  WorkingDayLabel,
} from "../../constants";

import * as Yup from "yup";
import { FormCard } from "../../components/FormCard";
import {
  Camera,
  CameraConfiguration as CameraConfigurationType,
  CompanyStatus,
  ConstructionSiteStatus,
  ConversionStatus,
  CreateTimelapseSettingMutationVariables,
  GetSiteQuery,
  Maybe,
  MutationUpdateCameraArgs,
  TimelapseSetting,
  TimelapseStatus,
  UpdateTimelapseSettingMutationVariables,
  useCreateCameraMutation,
  useCreateTimelapseSettingMutation,
  useDeleteCameraMutation,
  useDeleteConstructionSiteMutation,
  useGetSiteQuery,
  useUpdateCameraMutation,
  useUpdateSiteMutation,
  useUpdateTimelapseSettingMutation,
  useUpdateTimelapseStatusMutation,
  WorkingDay,
} from "../../graphql";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
  getGateReportIframeUrl,
  getLiveViewIframeUrl,
  notFoundUrl,
} from "../../helpers/navigation";
import { TimelapseDisactivationModal } from "../../components/modals/TimelapseDisactivationModal";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { getSiteStatusChoices } from "../../helpers/getChoices";
import { AddEditBimModal } from "../../components/modals/AddEditBimModal";
import { AddMissingSnapshotsrModal } from "../../components/modals/AddMissingSnapshotsModal";
import { CameraConfiguration } from "../../components/modals/CameraConfiguration";

import { HeadCell } from "../../components/tables/types";
import { SimpleTable } from "../../components/tables/SimpleTable";
import { blue, green, red } from "@mui/material/colors";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  HighlightOff as ErrorIcon,
  HourglassTop as PendingIcon,
} from "@mui/icons-material";
import {
  MutationCreateCameraArgs,
  useCreateAccessTokenMutation,
  useGetCameraSmtpCredsQuery,
  useRegenerateCameraSmtpCredsMutation,
} from "../../graphql/generated";
import { ObjectType } from "@ocilex/api";
import { AddLiveViewIframeModal } from "../../components/modals/AddLiveViewIframeModal";
import { useCurrentUser } from "../../auth/hooks";
import DeletionCard from "../../components/DeletionCard";

export const SiteEditAdmin: React.FC = () => {
  const { siteId: id } = useParams();
  const siteId = parseInt(id || "");
  const navigate = useNavigate();
  const user = useCurrentUser();

  const { data, refetch, error } = useGetSiteQuery({
    variables: { constructionSiteId: siteId },
    pollInterval: 60000,
  });
  const [dispatchConstructionSiteDeletion] = useDeleteConstructionSiteMutation({
    variables: {
      id: Number(id || 0),
    },
  });

  React.useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      navigate(notFoundUrl);
    }
  }, [error]);

  const handleDeletionConfirmation = useCallback(
    function handleDeletionConfirmation() {
      void dispatchConstructionSiteDeletion().then(() =>
        navigate(getAdminUrl(AdminNavigationPath.CompanyList)),
      );
    },
    [dispatchConstructionSiteDeletion, navigate],
  );

  const site = data?.constructionSite || null;
  const cameras = site?.cameras || [];
  const refetchSite = () => void refetch();

  if (!site) return null;

  // const isConnectedToProcore =
  //   !!site.procoreConnectionParam?.companyId &&
  //   !!site.procoreConnectionParam?.projectId;

  return (
    <React.Fragment>
      <PageTitle text="Edit Construction Site" />
      <Divider my={6} />

      <FormCard>
        <SiteEditForm site={site} refetchSite={refetchSite} />
      </FormCard>
      {cameras.length > 0 && (
        <FormCard>
          <SiteIframesTable site={site} />
        </FormCard>
      )}
      <FormCard>
        <CameraTable
          siteId={site.id}
          cameras={cameras}
          refetchSite={refetchSite}
        />
      </FormCard>

      {cameras.length > 0 && (
        <FormCard>
          <TimelapseTable site={site} refetchSite={refetchSite} />
        </FormCard>
      )}
      {/* {isConnectedToProcore && (
        <FormCard>
          <ProcoreDailyLogsTable site={site} refetchSite={refetchSite} />
        </FormCard>
      )} */}
      {Boolean(user?.isAdmin) && (
        <DeletionCard
          title="Site Deletion"
          confirmationMessage={`Are you sure you want to delete Site ${site.name}?
            Please keep in mind that all data, including cameras and timelapses, will be permanently deleted!
            Consider disabling the site by setting status to Inactive to hide it from users without data loss.`}
          onConfirmation={handleDeletionConfirmation}
        >
          <Typography variant="body1">
            You can delete site by clicking the button below.
          </Typography>
          <Typography variant="body1">
            Please keep in mind that all data, including cameras, and timelapses
            will be permanently deleted.
          </Typography>
        </DeletionCard>
      )}
    </React.Fragment>
  );
};

const SiteValidationSchema = Yup.object().shape({
  name: Yup.string().required(defaultHelperText.required).nullable(),
  status: Yup.string().oneOf(Object.keys(SiteStatusLabel)),
  timezone: Yup.string().required(defaultHelperText.required).nullable(),
  // procoreConnectionParam: Yup.object().nullable().shape({
  //   companyId: Yup.string().nullable(),
  //   projectId: Yup.string().nullable(),
  // }),
});

enum ModalAction {
  save = "save",
  saveAndContinue = "save_n_continue",
}

export const SiteEditForm: React.FC<{
  site: GetSiteQuery["constructionSite"];
  refetchSite: () => void;
}> = ({ site, refetchSite }) => {
  const [modalAction, setModalAction] = React.useState<ModalAction | null>(
    null,
  );

  const initialValues = {
    ...pick(site, ["id", "name", "status"]),
    timezone: site.timezone || "",
    //procoreConnectionParam: omit(site.procoreConnectionParam, ["__typename"]),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SiteValidationSchema,
    validateOnBlur: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const [mutateSite, { loading }] = useUpdateSiteMutation();
  const navigate = useNavigate();

  const beenDeactivated =
    site.status !== ConstructionSiteStatus.Inactive &&
    formik.values.status === ConstructionSiteStatus.Inactive;

  const updateSite = (onSuccess?: () => void) => {
    formik.handleSubmit();
    void formik.validateForm().then((errors) => {
      if (!isEmpty(errors)) return;
      void mutateSite({ variables: formik.values })
        .then(() => {
          refetchSite();
          if (onSuccess) onSuccess();
        })
        .catch(() => navigate(errorUrl));
    });
  };

  const SaveAndContinue = () => {
    if (beenDeactivated) {
      setModalAction(ModalAction.saveAndContinue);
      return;
    }
    updateSite();
  };

  const Redirect = () => navigate(getAdminUrl(AdminNavigationPath.SiteList));

  const Save = () => {
    if (beenDeactivated) {
      setModalAction(ModalAction.save);
      return;
    }
    SaveAndContinue();
    Redirect();
  };

  const companyIsInactive = site.company?.status != CompanyStatus.Active;

  const statusSelectProps = React.useMemo(() => {
    return {
      label: "Status",
      placeholder: "Select status",
      options: getSiteStatusChoices(site.status),
      disabled: companyIsInactive,
      onChange: (value: string) => {
        void formik.setFieldValue("status", value);
      },
    };
  }, [site.status, companyIsInactive]);

  const confirmStatus = () => {
    if (modalAction === ModalAction.save) {
      updateSite(Redirect);
    } else {
      updateSite();
    }
    setModalAction(null);
  };

  const timezoneOptions = React.useMemo(
    () => timezones.map(({ label, tzCode: value }) => ({ label, value })),
    [],
  );

  return (
    <form>
      {companyIsInactive && (
        <Alert severity="warning" mb={4}>
          This site’s company is not active
        </Alert>
      )}
      <Grid container spacing={{ md: 6 }}>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...siteInputParams.siteName}
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            helperText={formik.errors.name}
            showError={!!formik.errors.name}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item md={3} xs={12} mb={2}>
          <Autocomplete
            label="Timezone"
            placeholder="Select timezone"
            options={timezoneOptions}
            value={formik.values.timezone || ""}
            error={formik.touched.timezone ? formik.errors.timezone : ""}
            onChange={(value?: string) => {
              void formik.setFieldValue("timezone", value);
            }}
          />
        </Grid>

        <Grid item md={3} xs={12} mb={2}>
          <SingleSelect {...statusSelectProps} value={formik.values.status} />
        </Grid>
      </Grid>

      {/* <Typography variant="h6" gutterBottom my={4}>
        Procore connection params
      </Typography>

      <Grid container spacing={{ md: 6 }}>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...siteInputParams.procoreCompanyId}
            value={formik.values.procoreConnectionParam?.companyId}
            name="procoreConnectionParam.companyId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={2}>
          <TextInput
            {...siteInputParams.procoreProjectId}
            value={formik.values.procoreConnectionParam?.projectId}
            name="procoreConnectionParam.projectId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid> */}

      <Grid container spacing={6} justifyContent="right">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            my={2}
            mr={2}
            onClick={SaveAndContinue}
            disabled={!isEmpty(formik.errors) || loading}
          >
            Save and continue editing
          </Button>
          <Button
            color="primary"
            variant="contained"
            my={2}
            mr={2}
            onClick={Save}
            disabled={!isEmpty(formik.errors) || loading}
          >
            Save
          </Button>
          <Button color="primary" variant="outlined" my={2} onClick={Redirect}>
            Cancel
          </Button>
          <ConfirmationModal
            isOpened={!!modalAction}
            close={() => setModalAction(null)}
            onConfirm={confirmStatus}
          >
            Are you sure you want to deactivate this construction site?
            Timelapse video recordings will be stopped, and users will lose
            access to it.
          </ConfirmationModal>
        </Grid>
      </Grid>
    </form>
  );
};

type IframesTableRow = {
  label: string;
  onClick: () => void;
};

interface SnackbarProps {
  open: boolean;
  url: string;
  close: () => void;
}

const updateSnackbarProps = (
  prev: SnackbarProps,
  next: Partial<SnackbarProps>,
) => ({ ...prev, ...next });

const SiteIframesTable: React.FC<{
  site: GetSiteQuery["constructionSite"];
}> = ({ site }) => {
  const [modalIsOpened, setModalIsOpened] = React.useState(false);
  const [createTokenMutation] = useCreateAccessTokenMutation();
  const [snackbarProps, setSnackbarProps] = React.useReducer(
    updateSnackbarProps,
    {
      url: "",
      open: false,
      close: () => setSnackbarProps({ open: false }),
    },
  );

  const getToken = (input: { objectId: number; objectType: ObjectType }) => {
    // @ts-expect-error: wrong auto-typing;
    return createTokenMutation({ variables: { input } }).then(({ data }) => {
      const token = data?.createTokenBasedAccess?.token;
      return token || "";
    });
  };

  const generateGateReportUrl = React.useCallback(() => {
    void getToken({ objectType: ObjectType.Site, objectId: site.id }).then(
      (token) => {
        const url = getGateReportIframeUrl(token, site.id);
        void navigator.clipboard.writeText(url);
        setSnackbarProps({ open: true, url });
      },
    );
  }, []);

  const generateLiveViewUrl = React.useCallback(
    (cameraId: number, interval: number) => {
      void getToken({ objectType: ObjectType.Camera, objectId: cameraId }).then(
        (token) => {
          const url = getLiveViewIframeUrl(token, cameraId, interval);
          void navigator.clipboard.writeText(url);
          setSnackbarProps({ open: true, url });
        },
      );
    },
    [],
  );

  const rows: IframesTableRow[] = [
    { label: "Live View", onClick: () => setModalIsOpened(true) },
    { label: "Gate Report", onClick: generateGateReportUrl },
  ];

  const headCells: Array<HeadCell<IframesTableRow>> = [
    {
      key: "description",
      label: "",
      alignment: "left",
      width: "80%",
      render: (row) => row.label,
    },
    {
      key: "action",
      label: "",
      alignment: "right",
      width: "20%",
      render: (row) => (
        <div style={{ minWidth: "120px" }}>
          <Button mb={2} variant="outlined" onClick={row.onClick}>
            Generate URL
          </Button>
        </div>
      ),
    },
  ];

  const label = (
    <Typography variant="h6" gutterBottom my={8}>
      Embeddable components/iframes
    </Typography>
  );

  const snackbar = ReactDOM.createPortal(
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={snackbarProps.open}
      autoHideDuration={7000}
      onClose={snackbarProps.close}
      message={
        <div>
          <h3>
            <p>Done!</p>
            <p>URL copied to the clipboard.</p>
            <p>
              <a
                style={{
                  color: "white",
                  textDecoration: "underline",
                }}
                target="_blank"
                href={snackbarProps.url}
              >
                Click to open the iframe
              </a>
            </p>
          </h3>
        </div>
      }
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={snackbarProps.close}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />,
    document.body,
  );

  return (
    <>
      {label}
      {snackbar}
      <AddLiveViewIframeModal
        isOpened={modalIsOpened}
        cameras={site.cameras || []}
        close={() => setModalIsOpened(false)}
        onSave={generateLiveViewUrl}
      />
      <SimpleTable headCells={headCells} rows={rows} />
    </>
  );
};

enum CameraTableModals {
  "3dCamera" = "3dCamera",
  cameraConfiguration = "cameraConfiguration",
  cameraDeletion = "cameraDeletion",
}

const CameraTable: React.FC<{
  cameras: (Omit<Camera, "configuration"> & {
    configuration?: Maybe<Pick<CameraConfigurationType, "id" | "name">>;
  })[];
  siteId: number;
  refetchSite: () => void;
}> = ({ cameras, siteId, refetchSite }) => {
  type camera = (typeof cameras)[number];
  const navigate = useNavigate();
  const user = useCurrentUser();

  const [modalAction, setModalAction] =
    React.useState<CameraTableModals | null>(null);
  const [selectedCamera, setSelectedCamera] = React.useState<camera | null>(
    null,
  );
  const [createCamera] = useCreateCameraMutation();
  const [updateCamera] = useUpdateCameraMutation();
  const [deleteCamera] = useDeleteCameraMutation();

  const rows = React.useMemo(
    () => orderBy(cameras, (row) => row.name.toLowerCase()),
    [cameras],
  );

  const on3DModelButtonClick = (camera: camera) => {
    setSelectedCamera(camera);
    setModalAction(CameraTableModals["3dCamera"]);
  };

  const label = (
    <Grid container my={6} justifyContent="space-between">
      <Grid item>
        <Typography variant="h6" gutterBottom my={8}>
          Camera settings
        </Typography>
      </Grid>
      <Grid item>
        <PlusButton
          tooltip="Add Timelapse"
          onClick={() => {
            setSelectedCamera(null);
            setModalAction(CameraTableModals.cameraConfiguration);
          }}
        />
      </Grid>
    </Grid>
  );

  const headCells: Array<HeadCell<camera>> = [
    {
      key: "name",
      label: "Camera Name",
      alignment: "center",
      width: "20%",
      render: (row) => row.name,
    },
    {
      key: "preview",
      label: "Camera preview",
      alignment: "center",
      width: "20%",
      render: (row) => <CameraPreview image={row.snapshot || ""} />,
    },
    {
      key: "3d",
      label: "3D Model",
      alignment: "center",
      width: "40%",
      render: (row) => {
        switch (row.bimFile?.conversionStatus) {
          case ConversionStatus.Converted:
            return successStatusIcon;
          case ConversionStatus.NotConverted:
            return pendingStatusIcon;
          case ConversionStatus.Error:
            return errorStatusIcon;
          default:
            return emDash;
        }
      },
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "20%",
      render: (row) => (
        <div>
          <Button
            mb={2}
            variant="outlined"
            margin="2"
            onClick={() => {
              setModalAction(CameraTableModals.cameraConfiguration);
              setSelectedCamera(row);
            }}
          >
            Edit Camera Configuration
          </Button>
          <br />
          {user?.isAdmin && process.env.REACT_APP_GATE_REPORT_ENABLED && (
            <Box marginBottom="8px">
              <ViewCredsButton cameraId={row.id} />
            </Box>
          )}
          <Button
            mb={2}
            variant="outlined"
            onClick={() => on3DModelButtonClick(row)}
            margin="2"
          >
            3D Model
          </Button>
          <br />

          <Button
            mb={2}
            variant="contained"
            color="error"
            onClick={() => {
              setModalAction(CameraTableModals.cameraDeletion);
              setSelectedCamera(row);
            }}
            margin="0"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleCameraCreate = useCallback(
    function handleCreate(input: MutationCreateCameraArgs["input"]) {
      createCamera({
        variables: {
          siteId,
          input,
        },
      })
        .then(() => {
          void refetchSite();
          setModalAction(null);
        })
        .catch(() => navigate(errorUrl));
    },
    [siteId, createCamera],
  );

  const handleCameraUpdate = useCallback(
    function handleUpdate(input: MutationUpdateCameraArgs["input"]) {
      if (!selectedCamera) return;
      updateCamera({
        variables: {
          id: selectedCamera.id,
          input,
        },
      })
        .then(() => {
          void refetchSite();
          setModalAction(null);
        })
        .catch(() => navigate(errorUrl));
    },
    [selectedCamera?.id, createCamera],
  );

  const handleModalClose = useCallback(
    function handleModalClose() {
      setModalAction(null);
      setSelectedCamera(null);
    },
    [setModalAction, setSelectedCamera],
  );
  const handleCameraDeletion = useCallback(
    function handleCameraDeletion() {
      handleModalClose();

      if (!selectedCamera) return;

      void deleteCamera({
        variables: {
          id: selectedCamera?.id,
        },
      }).then(() => {
        refetchSite();
      });
    },
    [deleteCamera, selectedCamera?.id, handleModalClose, refetchSite],
  );

  return (
    <>
      {label}
      <SimpleTable headCells={headCells} rows={rows} rowHeight={150} />
      {selectedCamera && (
        <AddEditBimModal
          isOpened={modalAction === CameraTableModals["3dCamera"]}
          close={handleModalClose}
          onSave={refetchSite}
          camera={selectedCamera}
        />
      )}
      {modalAction === CameraTableModals.cameraConfiguration && (
        <CameraConfiguration
          isOpened={modalAction === CameraTableModals.cameraConfiguration}
          camera={selectedCamera}
          close={handleModalClose}
          create={handleCameraCreate}
          update={handleCameraUpdate}
        />
      )}
      {modalAction === CameraTableModals.cameraDeletion && (
        <ConfirmationModal
          isOpened
          onConfirm={handleCameraDeletion}
          close={handleModalClose}
        >
          Are you sure you want to delete this camera? This action will
          permanently delete this camera along with all related timelapses!
        </ConfirmationModal>
      )}
    </>
  );
};

enum Modals {
  timelapseRestore = "restoration",
  disactivation = "disactivation",
  timelapseAddEdit = "timelapseAddEdit",
  cameraSettingsCreateEdit = "cameraSettingsCreateEdit",
  cameraSettingsDelete = "cameraSettingsDelete",
}

export const TimelapseTable: React.FC<{
  site: GetSiteQuery["constructionSite"];
  refetchSite: () => void;
}> = ({ site, refetchSite }) => {
  const [modalAction, setModalAction] = React.useState<Modals | null>(null);
  const [selectedTimelapseId, setSelectedTimelapseId] = React.useState<
    number | null
  >(null);

  const [updateTimelapseStatus] = useUpdateTimelapseStatusMutation();
  const [createTimelapseSetting] = useCreateTimelapseSettingMutation();
  const [updateTimelapseSetting] = useUpdateTimelapseSettingMutation();

  const navigate = useNavigate();

  const timelapses = React.useMemo(
    () =>
      orderBy(site.timelapse || [], [(row) => row.name.toLowerCase(), "id"]),
    [site.timelapse],
  );

  const selectedTimelapse = React.useMemo(() => {
    if (!site.timelapse || !selectedTimelapseId) return null;
    return site.timelapse.find(({ id }) => id === selectedTimelapseId);
  }, [site.timelapse, selectedTimelapseId]);

  const saveTimelapse = (args: CreateTimelapseSettingMutationVariables) => {
    void createTimelapseSetting({ variables: { ...args } })
      .then(() => {
        setModalAction(null);
        void refetchSite();
      })
      .catch(() => navigate(errorUrl));
  };

  const updateTimelapse = (args: UpdateTimelapseSettingMutationVariables) => {
    void updateTimelapseSetting({ variables: { ...args } })
      .then(() => {
        setModalAction(null);
        void refetchSite();
      })
      .catch(() => navigate(errorUrl));
  };

  const setTimelapseStatus = (
    timelapse: TimelapseSetting | null,
    status: TimelapseStatus,
  ) => {
    if (!timelapse) return;
    if (timelapse.status === TimelapseStatus.NoConnection) return;
    void updateTimelapseStatus({
      variables: { changeTimelapseStatusId: timelapse.id, status },
    })
      .then(() => {
        void refetchSite();
        setModalAction(null);
      })
      .catch(() => navigate(errorUrl));
  };

  const deactivateTimelapse = (timelapse: TimelapseSetting | null) =>
    setTimelapseStatus(timelapse, TimelapseStatus.Inactive);

  const onSetStatusButtonClick = (timelapse: TimelapseSetting) => {
    setSelectedTimelapseId(timelapse.id);

    if (timelapse.status === TimelapseStatus.Inactive)
      setTimelapseStatus(timelapse, TimelapseStatus.Active);

    if (timelapse.status === TimelapseStatus.Active)
      setModalAction(Modals.disactivation);
  };

  const onEditTimelapseButtonClick = (timelapse: TimelapseSetting) => {
    setSelectedTimelapseId(timelapse.id);
    setModalAction(Modals.timelapseAddEdit);
  };

  const onRestoreTimelapseButtonClick = (timelapse: TimelapseSetting) => {
    setSelectedTimelapseId(timelapse.id);
    setModalAction(Modals.timelapseRestore);
  };

  const label = (
    <Grid container my={6} justifyContent="space-between">
      <Grid item>
        <Typography variant="h6" gutterBottom mb={2} mt={2}>
          Timelapse settings
        </Typography>
      </Grid>
      <Grid item>
        {(site.cameras || []).length > 0 && (
          <PlusButton
            tooltip="Add Timelapse"
            onClick={() => {
              setSelectedTimelapseId(null);
              setModalAction(Modals.timelapseAddEdit);
            }}
          />
        )}
      </Grid>
    </Grid>
  );

  const modals = [];

  if (selectedTimelapse && site.timezone)
    modals.push(
      <TimelapseDisactivationModal
        key={Modals.disactivation}
        isOpened={modalAction === Modals.disactivation}
        close={() => setModalAction(null)}
        onConfirm={deactivateTimelapse}
        timelapse={selectedTimelapse}
      />,

      <AddMissingSnapshotsrModal
        key={Modals.timelapseRestore}
        isOpened={modalAction === Modals.timelapseRestore}
        timelapseId={selectedTimelapse.id}
        siteTimezone={site.timezone}
        close={() => setModalAction(null)}
      />,
    );

  if ((site.cameras || []).length > 0) {
    modals.push(
      <AddEditTimelapseModal
        key={Modals.timelapseAddEdit}
        isOpened={modalAction === Modals.timelapseAddEdit}
        close={() => {
          setSelectedTimelapseId(null);
          setModalAction(null);
        }}
        siteId={site.id}
        timelapse={selectedTimelapse}
        save={saveTimelapse}
        update={updateTimelapse}
        cameras={site.cameras || []}
      />,
    );
  }

  const headCells: Array<HeadCell<TimelapseSetting>> = [
    {
      key: "name",
      label: "Timelapse Name",
      alignment: "center",
      width: "20%",
      render: (row) => row.name,
    },
    {
      key: "preview",
      label: "Camera preview",
      alignment: "center",
      width: "20%",
      render: (row) => <CameraPreview image={row.camera?.snapshot || ""} />,
    },
    {
      key: "freq",
      label: "Frame Frequency",
      alignment: "center",
      width: "10%",
      render: (row) => TimelapseFrequencyLabel[row.frameFrequency],
    },
    {
      key: "wdays",
      label: "Working days",
      alignment: "center",
      width: "20%",
      render: (row) => getWorkingDaysAsString(row.workingDays),
    },
    {
      key: "whours",
      label: "Working hours",
      alignment: "center",
      width: "10%",
      render: (row) =>
        getWorkingTimeString(row.workingHoursStart, row.workingHoursEnd),
    },
    {
      key: "status",
      label: "Status",
      alignment: "center",
      width: "10%",
      render: (row) =>
        TimelapseStatusLabel[row.status || TimelapseStatus.Inactive],
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "10%",
      render: (row) => (
        <div style={{ minWidth: "120px" }}>
          <Button
            mb={2}
            variant="outlined"
            onClick={() => onEditTimelapseButtonClick(row)}
          >
            Edit
          </Button>
          <br />
          <Button
            mb={2}
            variant="outlined"
            onClick={() => onSetStatusButtonClick(row)}
            disabled={row.status === TimelapseStatus.NoConnection}
          >
            {row.status === TimelapseStatus.Active ? "Deactivate" : "Activate"}
          </Button>
          <br />
          <Button
            variant="outlined"
            margin="0"
            onClick={() => onRestoreTimelapseButtonClick(row)}
          >
            Add snapshots
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {label}
      {timelapses.length > 0 && (
        <SimpleTable headCells={headCells} rows={timelapses} rowHeight={150} />
      )}
      {modals}
    </>
  );
};

// type CameraReportScheduleRow = {
//   name: string;
//   days: WorkingDay[];
// } & CameraReportSchedule;

// const ProcoreDailyLogsTable: React.FC<{
//   site: GetSiteQuery["constructionSite"];
//   refetchSite: () => void;
// }> = ({ site, refetchSite }) => {
//   const navigate = useNavigate();
//   const [modalIsOpened, setModalIsOpened] = React.useState(false);
//   const [updateDailyLog, { error }] = useUpdateCameraReportScheduleMutation();
//   const [selectedSchedule, setSelectedSchedule] =
//     React.useState<CameraReportScheduleRow>();

//   React.useEffect(() => {
//     if (error) navigate(errorUrl);
//   }, [error]);

//   const cameras = site.cameras || [];

//   const rows: CameraReportScheduleRow[] = React.useMemo(() => {
//     const schedules = flatten(
//       cameras.map((camera) =>
//         (camera.reportSchedules || []).map((schedule) => ({
//           ...schedule,
//           name: camera.name,
//           camera,
//         }))
//       )
//     );
//     return sortRows(sortRows(schedules, { key: "id", order: "asc" }), {
//       key: "name",
//       order: "asc",
//     });
//   }, [site.cameras]);

//   const toggleScheduleStatus = async (row: CameraReportScheduleRow) => {
//     if (!row.camera) return;
//     const newStatus =
//       row.status === CameraReportScheduleStatus.Active
//         ? CameraReportScheduleStatus.Inactive
//         : CameraReportScheduleStatus.Active;

//     await updateDailyLog({
//       variables: {
//         input: {
//           // @ts-expect-error compiler goes crazy here
//           status: newStatus,
//           id: row.id,
//           camera: row.camera.id,
//         },
//       },
//     });
//     refetchSite();
//   };

//   const headCells: Array<HeadCell<CameraReportScheduleRow>> = [
//     {
//       key: "name",
//       label: "Camera",
//       alignment: "center",
//       width: "10%",
//       render: (row) => row.name || emDash,
//     },
//     {
//       key: "preview",
//       label: "Camera preview",
//       alignment: "center",
//       width: "20%",
//       render: (row) => <CameraPreview image={row.camera?.snapshot || ""} />,
//     },
//     {
//       key: "days",
//       label: "Working days",
//       alignment: "center",
//       width: "25%",
//       render: (row) => getWorkingDaysAsString(row.days as WorkingDay[]),
//     },
//     {
//       key: "time",
//       label: "Snapshot Creation Time",
//       alignment: "center",
//       width: "15%",
//       render: (row) => (
//         <>
//           {row.time.map((tpoint, index) => (
//             <div key={index}>{tpoint}</div>
//           ))}
//         </>
//       ),
//     },
//     {
//       key: "status",
//       label: "Daily Log Status",
//       alignment: "center",
//       width: "15%",
//       render: (row) => CameraReportScheduleStatusLabel[row.status],
//     },
//     {
//       key: "actions",
//       label: "Daily Log Action",
//       alignment: "right",
//       width: "15%",
//       render: (row) => {
//         const onEditClick = () => {
//           setSelectedSchedule(row);
//           setModalIsOpened(true);
//         };
//         const onActivationClick = () => void toggleScheduleStatus(row);
//         return (
//           <div style={{ minWidth: "120px" }}>
//             <Button mb={2} variant="outlined" onClick={onEditClick}>
//               Edit
//             </Button>
//             <br />
//             <Button variant="outlined" onClick={onActivationClick} margin="0">
//               {row.status === CameraReportScheduleStatus.Active
//                 ? "Deactivate"
//                 : "Activate"}
//             </Button>
//           </div>
//         );
//       },
//     },
//   ];

//   const label = (
//     <Grid container my={6} justifyContent="space-between">
//       <Grid item>
//         <Typography variant="h6" gutterBottom mb={2} mt={2}>
//           Procore Daily Logs
//         </Typography>
//       </Grid>
//       <Grid item>
//         {cameras.length > 0 && (
//           <PlusButton
//             tooltip="Add Daily Log"
//             onClick={() => setModalIsOpened(true)}
//           />
//         )}
//       </Grid>
//     </Grid>
//   );

//   return (
//     <>
//       {label}
//       {rows.length > 0 && (
//         <SimpleTable headCells={headCells} rows={rows} rowHeight={150} />
//       )}
//       {cameras.length > 0 && (
//         <AddEditProcoreLogModal
//           isOpened={modalIsOpened}
//           cameras={cameras}
//           initialValue={selectedSchedule}
//           close={() => setModalIsOpened(false)}
//           onSave={refetchSite}
//         />
//       )}
//     </>
//   );
// };

const getWorkingDaysAsString = (value?: WorkingDay[] | null) => {
  const workingDays = value || [];
  if (!workingDays) return "";
  if (workingDays.length === 7) return "Entire week";
  return workingDays.map((day) => WorkingDayLabel[day]).join(", ");
};

const getWorkingTimeString = (
  workingHoursStart?: string | null,
  workingHoursEnd?: string | null,
) => {
  if (workingHoursStart === workingHoursEnd) return "Entire day";
  return `From ${workingHoursStart || "-"} to ${workingHoursEnd || "-"}`;
};

const CameraPreview = styled(CardMedia)`
  height: 150px;
  width: auto;
  min-width: 170px;
`;

const StatusIconContainer = styled(Avatar)<{ bgcolor: string }>`
  background-color: ${(props) => props.bgcolor};
  margin: auto;
`;

const successStatusIcon = (
  <StatusIconContainer bgcolor={green[500]}>
    <CheckIcon />
  </StatusIconContainer>
);

const pendingStatusIcon = (
  <StatusIconContainer bgcolor={blue[500]}>
    <PendingIcon />
  </StatusIconContainer>
);

const errorStatusIcon = (
  <StatusIconContainer bgcolor={red[500]}>
    <ErrorIcon />
  </StatusIconContainer>
);

const RegenerateCredsButton: React.FC<{
  cameraId: number;
  onSuccess: () => void;
}> = ({ cameraId, onSuccess }) => {
  const [renenerateCameraSmtpCreds, { loading }] =
    useRegenerateCameraSmtpCredsMutation();

  return (
    <>
      <Button
        mr={2}
        variant="outlined"
        onClick={() => {
          const regenerate = async () => {
            if (
              confirm(
                "Are you sure you want to recreate creds? This will archive old email address and create a new one.",
              )
            ) {
              await renenerateCameraSmtpCreds({
                variables: { cameraId },
              });
              onSuccess();
            }
          };
          void regenerate();
        }}
        color="warning"
        margin="0"
      >
        Regenerate {loading && <CircularProgress size="15px" />}
      </Button>
    </>
  );
};

const ViewCredsButton: React.FC<{ cameraId: number }> = ({ cameraId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        mr={2}
        variant="outlined"
        onClick={() => setIsOpen(true)}
        margin="0"
      >
        SMTP creds
      </Button>
      {isOpen && (
        <ViewCredsModal cameraId={cameraId} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const ViewCredsModal: React.FC<{ cameraId: number; onClose: () => void }> = ({
  cameraId,
  onClose,
}) => {
  const { data, refetch } = useGetCameraSmtpCredsQuery({
    variables: { cameraId },
  });
  return (
    <Dialog open fullWidth maxWidth={"sm"} onClose={onClose}>
      <DialogContent>
        <Grid container direction="column" gap={2}>
          <Grid item>{data?.getCameraSmtpCreds?.email}</Grid>
          <Grid item>{data?.getCameraSmtpCreds?.password}</Grid>
          <Grid item>
            <RegenerateCredsButton
              cameraId={cameraId}
              onSuccess={() => {
                void refetch();
              }}
            />
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
