import React, { useMemo } from "react";

import { Grid } from "@mui/material";
import { FormCard } from "../../../components/FormCard";
import {
  Alert,
  Divider,
  PageTitle,
  ZonedClock,
} from "../../../components/pages/Common";
import { SingleSelect, Switcher } from "../../../components/pages/FormFields";
import {
  CameraStatus as CameraStatusChoices,
  GetCameraChoicesQuery,
  useGetCameraChoicesQuery,
} from "../../../graphql";
import { useCurrentSite } from "../context/SiteContext";
import { useDebounce } from "use-debounce";
import { useImage } from "../../../hooks/use-image";
import { CameraStatus } from "../../../components/CameraStatus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNil, trimStart } from "lodash";
import { LiveViewImage } from "./LiveViewImage";
import { useSnapshotPlayback } from "./useSnapshotPlayback";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { format } from "date-fns";

// minute interval should be short enough to receive new image which is updated once in 10 minutes.
// We could increase it, but in this case user could wait more than a minute to get current snapshot.
const DATA_POLL_INTERVAL = 60 * 1000;

type CameraChoice = NonNullable<
  GetCameraChoicesQuery["constructionSite"]["cameras"]
>[number];

const EMPTY_CAMERAS: CameraChoice[] = [];
Object.freeze(EMPTY_CAMERAS);

// export const LiveViewDetailPage: React.FC = () => {
//   const navigate = useNavigate();
//   const { cameraId } = useParams();
//   const location = useLocation();

//   if (!cameraId) {}
// }

export const LiveViewDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { cameraId } = useParams();
  const location = useLocation();

  const [_isZoomed, setIsZoomed] = React.useState(false);
  const [isZoomed] = useDebounce(_isZoomed, 5000);

  const site = useCurrentSite();
  // Preserve old data on network error
  const { data: cameraQueryResponse, previousData: prevCameraQueryResponse } =
    useGetCameraChoicesQuery({
      nextFetchPolicy: "cache-and-network",
      variables: { constructionSiteId: site.id },
      pollInterval: isZoomed ? 0 : DATA_POLL_INTERVAL,
      skip: isZoomed,
      errorPolicy: "ignore",
    });

  const cameras =
    (cameraQueryResponse ?? prevCameraQueryResponse)?.constructionSite
      .cameras ?? EMPTY_CAMERAS;
  const camera: CameraChoice | undefined = useMemo(
    function findCamera() {
      return cameras.find(({ id }) => id === Number(cameraId));
    },
    [cameraId, cameras],
  );

  const [isZoomEnabled, setZoomStatus] = React.useState(false);

  const options = React.useMemo(
    () =>
      cameras
        ?.map((currentCamera) => ({
          value: currentCamera.id,
          label: currentCamera.name || "",
        }))
        .filter((currentCamera) => !!currentCamera.value),
    [site, cameras],
  );

  const handleChange = (value: number) => {
    const separator = "/";
    navigate(
      separator +
        trimStart(location.pathname, separator)
          .split(separator)
          .slice(0, -1)
          .concat(String(value))
          .join(separator),
    );
  };

  const handleZoomToggle = React.useCallback(
    function handleZoomToggle() {
      setZoomStatus((prevState) => !prevState);
    },
    [setZoomStatus],
  );

  const { currentSnapshot, forwardButton, backButton, datePicker } =
    useSnapshotPlayback(camera?.id);

  const { src: snapshotSrc, status: snapshotStatus } = useImage(
    currentSnapshot?.snapshotUrl || "",
  );

  const currentSnapshotDisplayDate = !isNil(currentSnapshot?.createdAt)
    ? format(
        utcToZonedTime(currentSnapshot?.createdAt, site.timezone ?? "GMT"),
        "yyyy-MM-dd HH:mm",
      )
    : null;

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: Live view`}>
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>
      <Divider my={6} />
      <FormCard>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          mb={6}
        >
          <Grid item container xs={12} md={12} alignItems="center">
            <Grid item md={3} xs={3} mr={3}>
              <SingleSelect
                label="Camera"
                placeholder="Select Camera"
                value={Number(cameraId) || 0}
                allowEmpty={true}
                options={options || []}
                onChange={(value: number) => handleChange(value)}
              />
            </Grid>
            <Grid item md={3} xs={3} mr={3}>
              {datePicker}
            </Grid>

            <Grid item md={1} xs={1}>
              <Switcher
                checked={isZoomEnabled}
                label="Enable zoom"
                labelPlacement="end"
                onChange={handleZoomToggle}
              />
            </Grid>
            <Grid item md={2} xs={2} marginLeft={4}>
              {currentSnapshotDisplayDate ? (
                <>Snapshot date: {currentSnapshotDisplayDate}</>
              ) : null}
            </Grid>
            <Grid item md={2} xs={2} marginLeft="auto">
              {camera?.health ? (
                <CameraStatus
                  status={
                    camera.health?.status ?? CameraStatusChoices.Unavailable
                  }
                  lastSeen={camera.health?.lastSeen ?? undefined}
                  timezone={site.timezone ?? undefined}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justifyContent="space-around"
            marginTop={2}
          >
            <Grid item xs={1}>
              {backButton}
            </Grid>
            <Grid item xs={10}>
              <LiveViewImage
                snapshotSrc={snapshotSrc}
                snapshotStatus={snapshotStatus}
                isZoomEnabled={isZoomEnabled}
                onIsZoomedChanged={setIsZoomed}
              />
            </Grid>
            <Grid item xs={1}>
              {forwardButton}
            </Grid>
          </Grid>
        </Grid>

        {!cameraId && (
          <Alert my={2} severity="warning">
            Please select a camera to see the live stream.
          </Alert>
        )}
      </FormCard>
    </React.Fragment>
  );
};
