import {
  UserStatus,
  ConstructionSiteStatus,
  CompanyStatus,
  Frequency,
} from "@ocilex/api";
import { TimelapseFrequencyLabel, UserRoleLabel } from "../constants";
import { enumToChoices } from "./enumToChoices";

export const getRoleChoices = () => {
  return enumToChoices(UserRoleLabel, { reversed: true });
};

export const getUserStatusChoices = (currentStatus: UserStatus) => {
  const isInvited = currentStatus === UserStatus.Invited;
  return enumToChoices(UserStatus).filter(({ value }) =>
    isInvited ? value === UserStatus.Invited : value != UserStatus.Invited,
  );
};

export const getSiteStatusChoices = (currentStatus: ConstructionSiteStatus) => {
  // Forbidden Status change: Active/Inactive -> Draft
  return enumToChoices(ConstructionSiteStatus).filter(
    ({ value }) =>
      value != ConstructionSiteStatus.Draft || value === currentStatus,
  );
};

export const getCompanyStatusChoices = (currentStatus: CompanyStatus) => {
  // Forbidden Status change: Active/Inactive -> Draft
  return enumToChoices(CompanyStatus).filter(
    ({ value }) => value != CompanyStatus.Draft || value === currentStatus,
  );
};

export const frequencyChoices = enumToChoices(TimelapseFrequencyLabel, {
  reversed: true,
}).map((choice) =>
  choice.value == Frequency.M15
    ? { value: choice.value, label: "15m - recommended" }
    : choice,
);
