import React from "react";
import { useParams, Outlet } from "react-router-dom";
import {
  GetBaseSiteQuery,
  ConstructionSiteStatus,
  useGetBaseSiteQuery,
} from "../../../graphql";
import { ErrorPage } from "../../ErrorPage";

type Site = GetBaseSiteQuery["constructionSite"];

const initialContextValue: Site = {
  id: NaN,
  name: "",
  status: ConstructionSiteStatus.Draft,
  createdAt: "",
  updatedAt: "",
  companyId: NaN,
};

const SiteContext = React.createContext<Site>(initialContextValue);

export function useCurrentSite(): Site {
  return React.useContext(SiteContext);
}

const page404 = (
  <ErrorPage title="404" details="Site not found." redirectTo={-1} />
);

export const SiteContextProvider: React.FC = () => {
  const { siteId: maybeSiteId } = useParams();
  const siteId = parseInt(maybeSiteId || "");

  if (!siteId) {
    return page404;
  }

  const { data, loading } = useGetBaseSiteQuery({
    variables: { constructionSiteId: siteId },
  });
  const site = data?.constructionSite;

  if (loading) return null;
  if (!site) return page404;

  return (
    <SiteContext.Provider value={site}>
      <Outlet />
    </SiteContext.Provider>
  );
};
