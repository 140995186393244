import React from "react";

import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useCreateAdminMutation, UserRole } from "../../graphql";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
} from "../../helpers/navigation";
import { TextInput } from "../pages/FormFields";
import { defaultHelperText, userInputParams } from "../pages/inputConstants";
import { NewInstanceModal } from "./NewInstanceModal";
import { isEmpty } from "lodash";

const initialValues = {
  name: "",
  email: "",
  isAdmin: false,
  role: UserRole.General,
  site: 0,
};

export const InviteAdminModal: React.FC<{
  isOpened: boolean;
  close: () => void;
}> = (props) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable(),
    email: Yup.string()
      .email(defaultHelperText.invalidEmail)
      .required(defaultHelperText.required),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const navigate = useNavigate();
  const [createAdmin] = useCreateAdminMutation();

  const onSave = () => {
    void formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        createAdmin({
          variables: {
            input: {
              name: formik.values.name,
              email: formik.values.email,
            },
          },
        })
          .then((result) => {
            const email = result.data?.createAdmin.email || "";
            close();
            navigate(getAdminUrl(AdminNavigationPath.UserEdit, email));
          })
          .catch(() => navigate(errorUrl));

        close();
      }
    });
  };

  const close = () => {
    formik.resetForm({ values: initialValues });
    props.close();
  };

  return (
    <NewInstanceModal
      isOpened={props.isOpened}
      title="Invite an Admin"
      canBeSaved={formik.isValid && !isEmpty(formik.touched)}
      onSave={onSave}
      onCancel={close}
      saveButtonLabel="Invite"
    >
      <form>
        <Grid container spacing={6} mb={2}>
          <Grid item xs={12}>
            <TextInput
              {...userInputParams.name}
              value={formik.values.name}
              margin={"dense"}
              name="name"
              onChange={formik.handleChange}
              helperText={formik.errors.name}
              showError={!!formik.errors.name && formik.touched.name}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6} mb={2}>
          <Grid item xs={12}>
            <TextInput
              {...userInputParams.email}
              value={formik.values.email}
              name="email"
              onChange={formik.handleChange}
              helperText={formik.errors.email}
              showError={!!formik.errors.email && formik.touched.email}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>
      </form>
    </NewInstanceModal>
  );
};
