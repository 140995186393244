import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, Slider, Typography } from "@mui/material";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { FormCard } from "../../components/FormCard";
import {
  DatetimePicker,
  SingleSelect,
  Switcher,
} from "../../components/pages/FormFields";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import {
  Alert,
  Divider,
  PageTitle,
  ZonedClock,
} from "../../components/pages/Common";

import {
  GetCameraChoicesQuery,
  useGetCameraChoicesQuery,
  useGetSnapshotQuery,
} from "../../graphql";
import {
  getUtcNow,
  isToday,
  offsetDateByTz,
  roundMinutes,
} from "../../helpers/date";
import { useCurrentSite } from "./context/SiteContext";
import { getTime as getMSeconds } from "date-fns";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import {
  errorUrl,
  getSiteUrl,
  SiteNavigationPath,
} from "../../helpers/navigation";
import Magnifier from "../../components/ImageMagnifier";
import useResizeObserver from "use-resize-observer";

const dayMSeconds = 86400000;

const sliderStyle: React.CSSProperties = {
  display: "inline-block",
};

const imageStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  objectFit: "cover",
};

export enum CompareFramesComparisonTool {
  SideBySide = "side-by-side",
  Magnifier = "magnifier",
}

type CameraChoice = NonNullable<
  GetCameraChoicesQuery["constructionSite"]["cameras"]
>[number];

const EMPTY_CAMERAS: CameraChoice[] = [];
Object.freeze(EMPTY_CAMERAS);

const FRAME_COMPARISON_TOOL_CHOICES = [
  { value: CompareFramesComparisonTool.SideBySide, label: "Side-by-Side" },
  {
    value: CompareFramesComparisonTool.Magnifier,
    label: "X-Ray",
  },
];

const MIN_MAGNIFIER_SIZE_COEFFICIENT = 10;
const DEFAULT_MAGNIFIER_SIZE_COEFFICIENT = 15;
const MAX_MAGNIFIER_SIZE_COEFFICIENT = 25;

function formatMagnifierSizeCoefficientLabel(value: number) {
  return String(value) + "%";
}

export const CompareFramesPage: React.FC = () => {
  const { comparisonTool: rawComparisonTool, cameraId } = useParams() as {
    comparisonTool: string;
    cameraId: string;
  };
  const navigate = useNavigate();

  const site = useCurrentSite();
  const { data: cameraQueryResponse, error } = useGetCameraChoicesQuery({
    variables: { constructionSiteId: site.id },
  });
  const cameras =
    cameraQueryResponse?.constructionSite.cameras ?? EMPTY_CAMERAS;

  const [isZoomEnabled, setZoomStatus] = useState(false);
  const [magnifierSizeCoefficient, setMagnifierSizeCoefficient] = useState(
    DEFAULT_MAGNIFIER_SIZE_COEFFICIENT,
  );

  const siteTz = site.timezone || "";
  const nowUtc = getUtcNow();
  const nowLocal = offsetDateByTz(nowUtc, siteTz);
  const localDayBefore = new Date(getMSeconds(nowLocal) - dayMSeconds);

  const [firstDate, setFirstDate] = useState<Date>(
    roundMinutes(localDayBefore),
  );
  const [secondDate, setSecondDate] = useState<Date>(roundMinutes(nowLocal));

  const { data: firstSnapshotQuery } = useGetSnapshotQuery({
    variables: {
      cameraId: Number(cameraId ?? 0),
      snapshotTime: firstDate.toISOString(),
    },
    skip: typeof cameraId === "undefined",
  });
  const firstSnapshot = firstSnapshotQuery?.getSiteCamera.snapshot;
  const { data: secondSnapshotQuery } = useGetSnapshotQuery({
    variables: {
      cameraId: Number(cameraId ?? 0),
      snapshotTime: secondDate.toISOString(),
    },
    skip: typeof cameraId === "undefined",
  });
  const secondSnapshot = secondSnapshotQuery?.getSiteCamera.snapshot;
  const comparisonToolContainer = useRef<HTMLDivElement | null>(null);

  const { width: comparisonToolWidth } = useResizeObserver({
    ref: comparisonToolContainer,
  });

  useEffect(() => {
    if (error) navigate(errorUrl);
  }, [error]);

  const cameraChoices = useMemo(
    () =>
      cameras.map((camera) => ({
        value: camera.id,
        label: camera?.name || "",
      })),
    [cameras],
  );

  const toggleZoom = useCallback(
    function toggleZoom() {
      setZoomStatus((prevState) => !prevState);
    },
    [setZoomStatus],
  );

  const handleMagnifierSizeCoefficientChange = useCallback(
    function handleMagnifierSizeCoefficientChange(
      event: Event,
      value: number | number[],
    ) {
      setMagnifierSizeCoefficient(Array.isArray(value) ? value[0] : value);
    },
    [setMagnifierSizeCoefficient],
  );

  const generateLocalPath = useCallback(
    function generateLocalPath(
      cameraId: string,
      comparisonTool: string | CompareFramesComparisonTool,
    ) {
      return generatePath(
        getSiteUrl(SiteNavigationPath.CompareSnapshots, site.id),
        { cameraId, comparisonTool },
      );
    },
    [site.id],
  );

  const setCameraId = useCallback(
    function setCameraId(value: number) {
      navigate(generateLocalPath(String(value), rawComparisonTool));
    },
    [generateLocalPath, navigate, rawComparisonTool],
  );

  const setComparisonTool = useCallback(
    function setComparisonTool(value: CompareFramesComparisonTool) {
      navigate(generateLocalPath(cameraId, value));
    },
    [generateLocalPath, navigate, cameraId],
  );

  if (
    !rawComparisonTool ||
    !(Object.values(CompareFramesComparisonTool) as string[]).includes(
      rawComparisonTool,
    )
  ) {
    navigate(getSiteUrl(SiteNavigationPath.LiveViewList, site.id), {
      replace: true,
    });
    return null;
  }

  // Value verified above
  const comparisonTool = rawComparisonTool as CompareFramesComparisonTool;

  const magnifierSize = Math.round(
    ((comparisonToolWidth ?? 0) * magnifierSizeCoefficient) / 100,
  );
  const hasEmptyFiels = ![cameraId, firstDate, firstDate].every((val) => !!val);
  const showImageComparison = !!firstSnapshot && !!secondSnapshot;

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: Compare Images`}>
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>
      <Divider my={6} />
      <FormCard>
        <Grid
          container
          mb={6}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            alignItems={{ md: "center" }}
            spacing={2}
          >
            <Grid item md={3} xs={12}>
              <SingleSelect
                label="Camera"
                placeholder="Select Camera"
                value={Number(cameraId)}
                options={cameraChoices}
                onChange={setCameraId}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <DatetimePicker
                value={firstDate}
                label="First frame"
                inputFormat="yyyy-MM-dd HH:mm"
                maxDate={nowLocal}
                maxTime={
                  firstDate && isToday(firstDate, siteTz) ? nowLocal : undefined
                }
                mask="____-__-__ __:__"
                onChange={(value: Date | null) => {
                  if (value) setFirstDate(value);
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <DatetimePicker
                value={secondDate}
                maxDate={nowLocal}
                maxTime={
                  secondDate && isToday(secondDate, siteTz)
                    ? nowLocal
                    : undefined
                }
                label="Second frame"
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                onChange={(value: Date | null) => {
                  if (value) setSecondDate(value);
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <SingleSelect
                label="Comparison Tool"
                placeholder="Select Comparison Tool"
                value={comparisonTool}
                options={FRAME_COMPARISON_TOOL_CHOICES}
                onChange={setComparisonTool}
              />
            </Grid>
          </Grid>
          {comparisonTool === CompareFramesComparisonTool.Magnifier && (
            <Grid
              container
              mt={6}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ md: "center" }}
              spacing={2}
            >
              <Grid item md={3} xs={6}>
                <Switcher
                  checked={isZoomEnabled}
                  label="Enable zoom"
                  labelPlacement="end"
                  onChange={toggleZoom}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography gutterBottom>Magnifier size</Typography>
                <Slider
                  value={magnifierSizeCoefficient}
                  min={MIN_MAGNIFIER_SIZE_COEFFICIENT}
                  max={MAX_MAGNIFIER_SIZE_COEFFICIENT}
                  onChange={handleMagnifierSizeCoefficientChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={formatMagnifierSizeCoefficientLabel}
                  marks
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <div ref={comparisonToolContainer}>
          {showImageComparison &&
            (comparisonTool === CompareFramesComparisonTool.SideBySide ? (
              <ReactCompareSlider
                style={sliderStyle}
                itemOne={
                  <ReactCompareSliderImage
                    src={firstSnapshot}
                    style={imageStyle}
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src={secondSnapshot}
                    style={imageStyle}
                  />
                }
              />
            ) : (
              <Magnifier
                src={secondSnapshot}
                zoomImgSrc={firstSnapshot}
                zoomFactor={isZoomEnabled ? 3 : 1}
                mgWidth={magnifierSize}
                mgHeight={magnifierSize}
              />
            ))}
        </div>
        {hasEmptyFiels && (
          <Alert my={2} severity="warning">
            Please select a camera and specify date and time of each frame
          </Alert>
        )}
      </FormCard>
    </React.Fragment>
  );
};
