// Admin routes
export const adminPathPrefix = "admin";
export enum AdminNavigationPath {
  CompanyEdit = "company-edit",
  CompanyList = "company-list",
  SiteEdit = "site-edit",
  SiteList = "sites-list",
  UserEdit = "user-edit",
  UserList = "users-list",
  CameraConfigurationsList = "camera-configuration-list",
}

export const getAdminUrl = (path: AdminNavigationPath, id?: number | string) =>
  `/${adminPathPrefix}/${path}/${id || ""}`;

// Site routes
export const sitePathPrefix = "site";
export enum SiteNavigationPath {
  UserEdit = "user-edit",
  UserList = "users-list",
  LiveViewList = "live-view",
  LiveViewDetail = `${SiteNavigationPath.LiveViewList}/:cameraId`,
  GateReport = "gate-report",
  GateReportActiveItem = `${SiteNavigationPath.GateReport}/:gateReportId`,
  Timelapse = "timelapse",
  EditFrame = "edit-frame",
  CompareSnapshotsBaseURI = "compare",
  CompareSnapshots = `${SiteNavigationPath.CompareSnapshotsBaseURI}/:comparisonTool/:cameraId?`,
  CompareFrames = "compare-frames",
  BimView = "bim-view",
  BimCompare = "bim-compare",
}

export const isSiteMenuPage = (path: string) =>
  path.startsWith(`/${sitePathPrefix}/`);

export const getSiteUrl = (
  path: SiteNavigationPath,
  siteId: number,
  childId?: number | string,
) => `/${sitePathPrefix}/${siteId}/${path}/${childId || ""}`;

// Profile routes
export const profilePathPrefix = "profile";
export enum ProfileNavigationPath {
  Edit = "edit",
}

export const getProfileUrl = (path: ProfileNavigationPath) =>
  `/${profilePathPrefix}/${path}/`;

// Iframe routes
export const iframePathPrefix = "iframe";
export enum IframeNavigationPath {
  LiveView = "live-view",
  GateReport = "gate-report",
}

const getIframeRootUrl = (token: string) =>
  `${window.location.origin}/${iframePathPrefix}/${token}`;

export const getLiveViewIframeUrl = (
  token: string,
  cameraId: number,
  interval: number,
) => {
  const urlBase = getIframeRootUrl(token);
  return `${urlBase}/${IframeNavigationPath.LiveView}/${cameraId}?interval=${interval}`;
};

export const getGateReportIframeUrl = (token: string, siteId: number) => {
  const urlBase = getIframeRootUrl(token);
  return `${urlBase}/${IframeNavigationPath.GateReport}/${siteId}`;
};

export const errorUrl = "/error";
export const notFoundUrl = "/not-found";
