import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { format as dateToString, startOfDay, endOfDay } from "date-fns";

export const isToday = (date: Date, tzCode: string): boolean => {
  const now = offsetDateByTz(getUtcNow(), tzCode);

  return (
    date.getDate() == now.getDate() &&
    date.getMonth() == now.getMonth() &&
    date.getFullYear() == now.getFullYear()
  );
};

const getTzOffset = (tzCode: string): number => {
  // Test tz code is valid.
  const tzOffset = getTimezoneOffset(tzCode);
  if (Number.isNaN(tzOffset)) throw Error(`Invalid tz code: ${tzCode}`);
  return tzOffset;
};

// Offsets timezoned Date to Utc Date
export const getUtcDate = (timezonedDate: Date, tzCode: string) => {
  const msecondsTzOffset = getTzOffset(tzCode);
  return new Date(timezonedDate.getTime() - msecondsTzOffset);
};

export const offsetDateByTz = (utcDate: Date, tzCode: string) => {
  const msecondsTzOffset = getTimezoneOffset(tzCode, new Date());
  return new Date(utcDate.getTime() + msecondsTzOffset);
};

export const roundMinutes = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(date.getHours() + Math.floor(date.getMinutes() / 60));
  newDate.setMinutes(0, 0, 0);
  return newDate;
};

export const getUtcFromLocalDate = (date: Date) =>
  new Date(date.getTime() + date.getTimezoneOffset() * 60000);

export const getUtcNow = () => getUtcFromLocalDate(new Date());
export const getZonedNow = (tzCode: string) =>
  offsetDateByTz(getUtcNow(), tzCode);

export const toUtcDateTimeString = (
  zonedDate: Date,
  tz: string,
  format: string,
) => {
  const utcDate = getUtcDate(zonedDate, tz);
  const utcString = dateToString(utcDate, format);
  return `${utcString}Z`;
};

export const setTime = (date: Date, time: string) => {
  const dateStr = dateToString(date, "yyyy-MM-dd");
  return new Date(`${dateStr} ${time}Z`);
};

export const getSecondsTotal = (date: Date): number => date.getTime() / 1000;

export const getDateFromWorkingHours = (
  workingHours: string | undefined | null,
  defaultHours: string,
) => {
  return new Date(
    0,
    0,
    0,
    parseInt(workingHours?.split(":").shift() || defaultHours, 10),
    parseInt(workingHours?.split(":").pop() || "00", 10),
    0,
  );
};

export function applyClientTimezoneOffset(
  date: Date | number,
  direction: 1 | -1 = 1,
) {
  return new Date(
    Number(date) + new Date().getTimezoneOffset() * direction * 60 * 1000,
  );
}

export function dateUtcToLocal(utcDate: Date, tzCode: string): Date {
  const isoDate = utcDate.toISOString();
  return utcToZonedTime(isoDate, tzCode);
}

export function calendarDateToLocal(localDate: Date, tzCode: string): Date {
  const isoDate = localDate.toISOString();
  return zonedTimeToUtc(isoDate, tzCode);
}

export function dateUtcToLocalStartOfDay(utcDate: Date, tzCode: string): Date {
  return calendarDateToLocal(
    startOfDay(dateUtcToLocal(utcDate, tzCode)),
    tzCode,
  );
}

export function dateUtcToLocalEndOfDay(utcDate: Date, tzCode: string): Date {
  return calendarDateToLocal(endOfDay(dateUtcToLocal(utcDate, tzCode)), tzCode);
}
