import React from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Button } from "../pages/Common";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  CircularProgress,
} from "@mui/material";
import { ForgeClient, tokenExprationPeriod } from "../../forge-client/client";
import {
  Camera,
  ConversionStatus,
  useCreateUpdateBimFileMutation,
  useGetForgeAuthTokenQuery,
  useUpdateBimFileSceneStateMutation,
} from "../../graphql";
import { useNavigate } from "react-router-dom";
import { errorUrl } from "../../helpers/navigation";
import { ForgeViewer } from "../ForgeViewer";
import { getBadUserInputs } from "../../apollo-client/parseErrors";
import { useFormik } from "formik";
import { defaultHelperText } from "../pages/inputConstants";
import { isEmpty } from "lodash";
import { FileInput } from "../pages/FormFields";

const invalidFile = "Invalid file or extension.";
const tokenPollingInterval = (tokenExprationPeriod - 60) * 1000;

interface ModalProps {
  isOpened: boolean;
  camera: Omit<Camera, "configuration">;
  close: () => void;
  onSave: () => void;
}

const SnapshotContainer = styled.img`
  width: 100%;
  height: auto;
`;

const Snapshot: React.FC<{ url: string }> = ({ url }) => (
  <SnapshotContainer src={url} />
);

export const AddEditBimModal: React.FC<ModalProps> = (props) => {
  const navigate = useNavigate();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File | null>();
  const [allowedFormats, setAllowedFormats] = React.useState("");
  const [uploading, setIsUploading] = React.useState(false);
  const [saveCameraBim] = useCreateUpdateBimFileMutation({
    errorPolicy: "all",
  });
  const [updateSceneState] = useUpdateBimFileSceneStateMutation();
  const { bimFile, id: cameraId } = props.camera;

  const { data: tokenData } = useGetForgeAuthTokenQuery({
    pollInterval: tokenPollingInterval,
  });
  const token = tokenData?.forgeAuthToken;

  const validationSchema = () => {
    const schema = {
      fname: Yup.string().required(defaultHelperText.required),
      sceneState: bimFile
        ? Yup.string().required(defaultHelperText.required)
        : Yup.string(),
      opacity: Yup.number().required(defaultHelperText.required),
    };

    return Yup.object().shape(schema);
  };

  const initialValues = {
    fname: bimFile?.fname,
    sceneState: bimFile?.sceneState || "",
    opacity: bimFile?.opacity || 50,
  };

  const formik = useFormik<{
    fname: string | undefined;
    sceneState: string;
    opacity: number;
  }>({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  React.useEffect(() => {
    if (!fileInputRef.current) return;
    fileInputRef.current.value = "";
    fileInputRef.current.files = null;
    setFile(null);
  }, [props.isOpened]);

  React.useEffect(() => {
    if (!token || allowedFormats) return;
    const forgeClient = new ForgeClient(token);
    void forgeClient
      .getAllowedFormats({ withDot: true })
      .then((formats) => setAllowedFormats(formats.join(",")));
  }, [token]);

  const saveBimFile = async () => {
    if (!file) return;
    setIsUploading(true);

    if (!token) return;
    const forgeClient = new ForgeClient(token);
    return forgeClient.uploadFile(file).then((upload) =>
      saveCameraBim({
        variables: {
          input: {
            camera: cameraId,
            fname: file.name,
            forgeLocation: upload.location,
            forgeObjectId: upload.objectId,
          },
        },
      }),
    );
  };

  const save = () => {
    void saveBimFile()
      .then((response) => {
        // no file to upload
        if (!response) {
          props.onSave();
          close();
        }
        // file uploaded
        else if (!response?.errors) {
          props.onSave();
          close();
          return true;
        }
        // wrong file
        else if (getBadUserInputs(response?.errors || []).length) {
          formik.setFieldError("fname", invalidFile);
        } else {
          close();
          navigate(errorUrl);
        }
      })
      .then((resetState) => {
        if (formik.touched && props.camera.bimFile?.id) {
          const input = resetState
            ? {
                id: props.camera.bimFile?.id,
                sceneState: "",
                opacity: 50,
              }
            : {
                id: props.camera.bimFile?.id,
                sceneState: formik.values.sceneState,
                opacity: formik.values.opacity,
              };
          return updateSceneState({ variables: { input } }).then(() => {
            props.onSave();
            close();
          });
        }
      })
      .finally(() => setIsUploading(false));
  };

  const close = (cancel?: boolean) => {
    const values = cancel
      ? { ...initialValues }
      : {
          fname: initialValues.fname,
          sceneState: formik.values.sceneState,
          opacity: formik.values.opacity,
        };
    formik.resetForm({
      values,
    });
    props.close();
  };

  const onFileChange = (files: FileList | null) => {
    const file = files ? files[0] : null;
    formik.resetForm({
      values: {
        fname: file?.name,
        sceneState: "",
        opacity: bimFile?.opacity || 50,
      },
    });
    setFile(file);
  };

  const onCameraChange = (sceneState: string) => {
    void formik.setFieldValue("sceneState", sceneState, true);
  };

  const onTransparencyChange = (opacity: number | number[]) => {
    if (typeof opacity === "number") {
      void formik.setFieldValue("opacity", opacity, true);
    }
  };

  const conversionIsRuning =
    bimFile?.conversionStatus === ConversionStatus.NotConverted;
  const bimIsPending = uploading || conversionIsRuning;
  const canBeSaved =
    (!!file || !!bimFile) && !bimIsPending && isEmpty(formik.errors);
  const canBeRendered =
    bimFile?.conversionStatus === ConversionStatus.Converted;
  const canBeUploaded = !bimIsPending && !!allowedFormats;

  const cancelButtonLabel = conversionIsRuning ? "Close" : "Cancel";
  let saveButtonLabel = "Save";
  if (uploading) saveButtonLabel = "Uploading";
  if (conversionIsRuning) saveButtonLabel = "Processing";

  return (
    <Dialog
      open={props.isOpened}
      onClose={() => close(true)}
      fullWidth={false}
      maxWidth="sm"
    >
      <DialogTitle>3D Model Configuration</DialogTitle>
      <DialogContent>
        <FileInput
          label="Upload 3D model"
          fileName={file?.name || bimFile?.fname}
          onChange={onFileChange}
          disabled={!canBeUploaded}
          ref={fileInputRef}
          accept={allowedFormats}
          error={formik.errors.fname}
        />
        <Grid
          container
          justifyContent="center"
          direction="column"
          spacing={4}
          my={2}
        >
          <Grid item>
            {!canBeRendered && <Snapshot url={props.camera.snapshot || ""} />}
            {canBeRendered && (
              <ForgeViewer
                objectId={bimFile?.forgeObjectId || ""}
                token={token}
                sceneState={bimFile?.sceneState || ""}
                opacity={formik.values.opacity}
                edit={true}
                onCameraChange={onCameraChange}
              >
                <Snapshot url={props.camera.snapshot || ""} />
              </ForgeViewer>
            )}
          </Grid>
          <Grid item mt={2}>
            Opacity
            <Slider
              value={formik.values.opacity}
              onChange={(e, val) => onTransparencyChange(val)}
              valueLabelDisplay="auto"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={save}
          disabled={!canBeSaved}
        >
          {bimIsPending && (
            <CircularProgress
              color="secondary"
              size="18px"
              style={{ marginRight: "5px" }}
            />
          )}
          {saveButtonLabel}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => close(true)}
          disabled={uploading}
        >
          {cancelButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
