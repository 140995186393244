import styled from "@emotion/styled";

const MessageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IframeError: React.FC<{ message: string }> = ({ message }) => {
  return (
    <MessageContainer>
      <h1>{message}</h1>
    </MessageContainer>
  );
};

export const iframeTokenError = (
  <IframeError message="Token not provided or is invalid." />
);

export const iframeParamsError = (
  <IframeError message="Invalid parameters provided." />
);
