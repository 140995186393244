import { format as dateToString } from "date-fns";
import tuiImageEditor from "tui-image-editor";

type ImageEditorRef = {
  imageEditorInst: tuiImageEditor;
};

export const addOrUpdateWatermark = (
  imageEditor: ImageEditorRef,
  result: Date,
  format: string,
) => {
  const dateTime = dateToString(result, format);
  const canvasSize = imageEditor.imageEditorInst.getCanvasSize();
  const fontSize = 48;
  const ml = 22;
  const widthInPx = dateTime.split("").length * ml;
  return imageEditor.imageEditorInst.addText(dateTime, {
    styles: {
      fill: "rgb(255, 101, 24)",
      fontSize: fontSize,
      fontWeight: "bold",
    },
    position: {
      x: canvasSize.width - widthInPx - 15,
      y: canvasSize.height - fontSize - 15,
    },
  });
};
