import React from "react";
import {
  Button,
  Divider,
  PageTitle,
  PlusButton,
} from "../../components/pages/Common";
import { useDebounce } from "use-debounce";
import { filterObjectsByString } from "../../components/tables/filterByString";
import { dateToString } from "../../helpers/toString";
import { useNavigate } from "react-router-dom";
import {
  useGetUsersQuery,
  GetUsersQuery,
  UserStatus,
  useGetSitesQuery,
  useGetCompaniesQuery,
} from "../../graphql";
import { emDash, UserStatusLabel } from "../../constants";
import { uniqBy, flatten } from "lodash";
import { getAdminUrl, AdminNavigationPath } from "../../helpers/navigation";
import { enumToChoices } from "../../helpers/enumToChoices";
import {
  SingleSelect,
  Autocomplete,
  FormFieldControl,
} from "../../components/pages/FormFields";
import { PaginatedTable } from "../../components/tables/PaginatedTable";
import { HeadCell, Sorting } from "../../components/tables/types";
import { sortReducer, sortRows } from "../../components/tables/sorting";
import { useLocalStorage } from "../../helpers/localStorage";
import { TableFilterToolbar } from "../../components/tables/components/Toolbar";
import { SearchInput } from "../../components/tables/components/SearchInput";
import {
  InviteUserModal,
  AddUserModal,
} from "../../components/modals/InviteUserModal";

const EditButton: React.FC<{ email: string }> = ({ email }) => {
  const navigate = useNavigate();

  return (
    <Button
      mr={2}
      variant="outlined"
      onClick={() => navigate(getAdminUrl(AdminNavigationPath.UserEdit, email))}
      margin="0"
    >
      Edit
    </Button>
  );
};

type User = GetUsersQuery["users"][number];

const defaultSorting: Sorting = {
  key: "joiningAt",
  order: "desc",
};

const filterByCompany = (user: User, companyId: number | null) => {
  if (!companyId) return true;
  return !!user.companies?.find(({ companyId: id }) => id === companyId);
};

const filterBySite = (user: User, siteId: number | null) => {
  if (!siteId) return true;
  return !!user.sites?.find(({ siteId: id }) => id === siteId);
};

const filterByStatus = (user: User, status: UserStatus | "") => {
  if (status === "") return true;
  return user.status === status;
};

const filterByPermission = (user: User, permission: string) => {
  if (permission === "user") return !user.isAdmin;
  if (permission === "admin") return user.isAdmin;
  return true;
};

type FiltersType = {
  permissionLevel: string;
  status: string;
  siteId: number | null;
  companyId: number | null;
};

const initialFilters: FiltersType = {
  permissionLevel: "",
  status: "",
  siteId: null,
  companyId: null,
};

const filtersReducer = (
  state: FiltersType,
  action: Partial<FiltersType>,
): FiltersType => {
  return { ...state, ...action };
};

const searchUsers = (users: User[], pattern: string): User[] => {
  const byName = filterObjectsByString(users, "name", pattern);
  const byEmail = filterObjectsByString(users, "email", pattern);
  return uniqBy(flatten([byName, byEmail]), "email");
};

const Page: React.FC = () => {
  const { data } = useGetUsersQuery();
  const sitesQuery = useGetSitesQuery();
  const companiesQuery = useGetCompaniesQuery();

  const [openedModal, setOpenedModal] = React.useState<AddUserModal | null>(
    null,
  );
  const [searchPattern, setSearchPattern] = React.useState<string>("");
  const [filtersState, setFiltersState] = React.useReducer(
    filtersReducer,
    initialFilters,
  );
  const [debouncedSearchPattern] = useDebounce(searchPattern, 200);
  const users = data?.users || [];
  const settingsLocalStorageKey = "admin-users-list";
  const [initialSorting, saveSorting] = useLocalStorage<Sorting>(
    `${settingsLocalStorageKey}-sorting`,
    defaultSorting,
  );
  const [sorting, setSortingKey] = React.useReducer(
    sortReducer,
    initialSorting,
  );

  const siteChoices = React.useMemo(() => {
    const sites = sitesQuery.data?.constructionSites || [];
    return sites.map(({ id: value, name: label }) => ({ label, value }));
  }, [sitesQuery.data]);

  const companyChoices = React.useMemo(() => {
    const companies = companiesQuery.data?.constructionCompanies || [];
    return companies.map(({ id: value, name: label }) => ({ label, value }));
  }, [companiesQuery.data]);

  const matchedUsers = React.useMemo(() => {
    return searchUsers(users, debouncedSearchPattern);
  }, [debouncedSearchPattern, users]);

  const filteredUsers = React.useMemo(() => {
    return matchedUsers.filter((user) => {
      return (
        filterByStatus(user, filtersState.status as UserStatus) &&
        filterByCompany(user, filtersState.companyId) &&
        filterBySite(user, filtersState.siteId) &&
        filterByPermission(user, filtersState.permissionLevel)
      );
    });
  }, [matchedUsers, filtersState]);

  const sortedRows = React.useMemo(() => {
    return sortRows(filteredUsers, sorting);
  }, [sorting, filteredUsers]);

  React.useEffect(() => saveSorting(sorting), [sorting]);

  const headCells: Array<HeadCell<User>> = [
    {
      key: "name",
      label: "Name",
      alignment: "left",
      width: "10%",
      handleSort: setSortingKey,
      render: (user) => user.name || emDash,
    },
    {
      key: "email",
      label: "Email",
      alignment: "left",
      width: "10%",
      handleSort: setSortingKey,
      render: (user) => user.email,
    },
    {
      key: "phone",
      label: "Phone",
      alignment: "center",
      width: "10%",
      render: (user) => user.phone || emDash,
    },
    {
      key: "isAdmin",
      label: "Permissions level",
      alignment: "center",
      width: "10%",
      render: (user) => (user.isAdmin ? "Admin" : "User"),
    },
    {
      key: "companies",
      label: "Companies",
      alignment: "center",
      width: "15%",
      render: (user) => {
        const companies = user.companies || [];
        if (!companies.length) return emDash;
        return companies
          .filter(({ company }) => !!company)
          .map((row) => row.company?.name)
          .join(", ");
      },
    },
    {
      key: "sites",
      label: "Sites",
      alignment: "center",
      width: "15%",
      render: (user) => {
        const sites = user.sites || [];
        if (!sites.length) return emDash;
        return sites.map((row) => row.site.name).join(", ");
      },
    },
    {
      key: "status",
      label: "Status",
      alignment: "center",
      width: "10%",
      handleSort: setSortingKey,
      render: (user) => UserStatusLabel[user.status || UserStatus.Inactive],
    },
    {
      key: "joiningAt",
      label: "Joining Date",
      alignment: "center",
      width: "10%",
      handleSort: setSortingKey,
      render: (user) =>
        user.joiningAt ? dateToString(new Date(user.joiningAt)) : emDash,
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "10%",
      render: (user) => <EditButton email={user.email} />,
    },
  ];

  const filters = [
    <SingleSelect
      label="Permission level"
      placeholder="Select Permissions level"
      value={filtersState.permissionLevel}
      allowEmpty={true}
      minWidth="150px"
      options={[
        { label: "Admin", value: "admin" },
        { label: "User", value: "user" },
      ]}
      onChange={(value: string) => setFiltersState({ permissionLevel: value })}
    />,
    <FormFieldControl minwidth="180px">
      <Autocomplete
        label="Construction Company"
        placeholder="Select company"
        value={filtersState.companyId}
        allowEmpty={true}
        options={companyChoices}
        onChange={(value?: number) => setFiltersState({ companyId: value })}
      />
    </FormFieldControl>,
    <FormFieldControl minwidth="150px">
      <Autocomplete
        label="Construction Site"
        placeholder="Select site"
        value={filtersState.siteId}
        allowEmpty={true}
        options={siteChoices}
        onChange={(value?: number) => setFiltersState({ siteId: value })}
      />
    </FormFieldControl>,
    <SingleSelect
      label="Status"
      placeholder="Select status"
      value={filtersState.status}
      allowEmpty={true}
      options={enumToChoices(UserStatus)}
      onChange={(value: string) => setFiltersState({ status: value })}
    />,
  ];

  const search = (
    <SearchInput
      placeholder="Search by name or email"
      onChange={setSearchPattern}
    />
  );
  const toolbar = <TableFilterToolbar search={search} filters={filters} />;

  return (
    <>
      <PageTitle text="User Management">
        <PlusButton
          tooltip="Invite a User"
          onClick={() => setOpenedModal(AddUserModal.Select)}
        />
        <InviteUserModal
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
        />
      </PageTitle>
      <Divider my={6} />
      <PaginatedTable
        headCells={headCells}
        rows={sortedRows}
        settingsLocalStorageKey={settingsLocalStorageKey}
        sorting={sorting}
        toolbar={toolbar}
      />
    </>
  );
};

export const UsersListAdmin = React.memo(Page);
