import { createSingleSelectChoicesFromEnum } from "../../../utils/forms";
import {
  CameraAuthenticationMethod,
  CameraTimeFormat,
  CameraTimezoneSource,
} from "../../../graphql";
import { startCase, upperCase } from "lodash";

import { CameraConfigurationForm } from "./types";

export const MODAL_ID = "ADD_EDIT_CAMERA_CONFIGURATION_MODAL";

export const ISO_TIMEZONE_SOURCE = "ISO";

export const UNNESTED_FIELDS_SOURCE = "timeParsingRules";

export const UNNESTED_FIELDS: [
  "pattern",
  "partialPatterns",
  "customTimeFormat",
] = ["pattern", "partialPatterns", "customTimeFormat"];

export const REQUIRED_CONFIGURATION_TEST_FIELDS: [
  "statusUri",
  "snapshotUri",
  "authenticationMethod",
  "timeFormat",
  "timezone",
  typeof UNNESTED_FIELDS_SOURCE,
] = [
  "statusUri",
  "snapshotUri",
  "authenticationMethod",
  "timeFormat",
  "timezone",
  UNNESTED_FIELDS_SOURCE,
];
export const DATE_TOKEN = "<<date>>";

export enum PartialPatternChoices {
  Year = "year",
  Month = "month",
  Day = "day",
  Hour = "hour",
  Minute = "minute",
}

export const EMPTY_CAMERA_CONFIGURATION_FORM: CameraConfigurationForm = {
  name: "",
  statusUri: "",
  snapshotUri: "",
  authenticationMethod: CameraAuthenticationMethod.Basic,
  timeFormat: CameraTimeFormat.Iso,
  timezone: CameraTimezoneSource.Utc,
  // Nested under timeParsingRules in API.
  // Flattened for better compatibility with Formik types
  pattern: "",
  partialPatterns: {
    year: "",
    month: "",
    day: "",
    hour: "",
    minute: "",
  },
  customTimeFormat: "",
  isAvailableForUsers: true,
};

Object.freeze(EMPTY_CAMERA_CONFIGURATION_FORM);
export const EMPTY_CONFIGURATION_TEST_FORM = {
  address: "",
  username: "",
  password: "",
};

Object.freeze(EMPTY_CONFIGURATION_TEST_FORM);

export const AUTHENTICATION_METHOD_CHOICES = createSingleSelectChoicesFromEnum(
  CameraAuthenticationMethod,
);
export const CAMERA_TIME_FORMAT_CHOICES = createSingleSelectChoicesFromEnum(
  CameraTimeFormat,
  startCase,
);

export const DEFAULT_TIMEZONE_CHOICES = createSingleSelectChoicesFromEnum(
  CameraTimezoneSource,
  upperCase,
);
