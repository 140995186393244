import React from "react";
import styled from "@emotion/styled";

import { Grid, Typography } from "@mui/material";
import { LogoutButton } from "../../auth/components";
import { useCurrentUser } from "../../auth/hooks";
import { getProfileUrl, ProfileNavigationPath } from "../../helpers/navigation";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "../pages/Common";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  white-space: nowrap;
  overflow: hidden;
  max-width: 169px;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const SidebarFooter = ({ ...rest }) => {
  const user = useCurrentUser();
  const { isAuthenticated, user: auth0User } = useAuth0();
  const navigate = useNavigate();

  const onClick = () => navigate(getProfileUrl(ProfileNavigationPath.Edit));

  return (
    <Footer {...rest}>
      <Grid container>
        <Grid item>
          {!!user?.email && (
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Tooltip title={user.email} placement="top-start">
                  <FooterText variant="body2" onClick={onClick}>
                    {user.email}
                  </FooterText>
                </Tooltip>
              </Grid>
              <Grid item>
                <LogoutButton />
              </Grid>
            </Grid>
          )}
          {!user?.email && isAuthenticated && (
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Tooltip title={auth0User?.email || ""} placement="top-start">
                  <FooterText variant="body2">{auth0User?.email}</FooterText>
                </Tooltip>
              </Grid>
              <Grid item>
                <LogoutButton />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Footer>
  );
};
