export const defaultMaxLeght = 1000;
export const defaultHelperText = {
  required: "Field is required",
  invalidEmail: "Invalid email",
  notUniqueEmail: "User with this email already exists",
  invalidUrl: "URL is not valid",
  invalidNumber: "Is not a number",
  invalidFile: "Invalid file format",
};

export const siteInputParams = {
  siteName: {
    label: "Site Name",
    placeholder: "Enter Construction Site Name",
  },
  company: {
    label: "Company",
    placeholder: "Select company",
  },
  procoreCompanyId: {
    label: "Company ID",
    placeholder: "12345",
  },
  procoreProjectId: {
    label: "Project ID",
    placeholder: "12345",
  },
};

export const userInputParams = {
  name: {
    label: "Name",
    placeholder: "Enter Name",
  },
  email: {
    label: "Email",
    placeholder: "Enter Email",
  },
  isAdmin: {
    label: "Permissions Level",
    placeholder: "Select Permissions Level",
  },
  site: {
    label: "Site",
    placeholder: "Select Site",
  },
  role: {
    label: "Role",
    placeholder: "Select Role",
  },
  phone: {
    label: "Phone",
  },
};

export const timelapseInputParams = {
  name: { label: "Timelapse name" },
};

export const companyInputParams = {
  name: {
    label: "Company name",
    placeholder: "Enter Construction Company Name",
  },
  status: {
    label: "Status",
    placeholder: "Select Status",
  },
  description: {
    label: "Description",
    placeholder: "Enter Construction Company Description",
  },
};
