import { orderBy, toString } from "lodash";
import { Sorting } from "./types";

export const sortReducer = (state: Sorting, key: string): Sorting => {
  const isAsc = state.key === key && state.order === "asc";
  return { key, order: isAsc ? "desc" : "asc" };
};

export const sortRows = <T extends { [key: string]: unknown }>(
  rows: Array<T>,
  sorting: Sorting,
) => orderBy(rows, (row: T) => getSortValue(row, sorting.key), sorting.order);

const getSortValue = <T extends { [key: string]: unknown }>(
  row: T,
  key: keyof T,
) => {
  if (!(key in row)) return;
  const value = row[key];
  if (!value) return null;
  if (typeof value === "number") return value;
  return toString(value).toLocaleLowerCase();
};
