import React from "react";
import styled from "@emotion/styled";
import TuiImageEditor from "@toast-ui/react-image-editor";
import tuiImageEditor from "tui-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";

export type ImageEditorRef = {
  imageEditorInst: tuiImageEditor;
};

type MenuOption = "crop" | "draw" | "shape" | "text" | "icon" | "filter";

export const DefaultEditorContainer = styled.div`
  .tui-image-editor-align-wrap {
    vertical-align: top !important;
  }
  .tui-image-editor {
    top: 0 !important;
  }
  .tui-image-editor-submenu-style {
    opacity: 1 !important;
  }
`;

const editorTheme = {
  // Theme object to extends default dark theme.
  "common.backgroundColor": "#fff",
};

interface ImageEditorProps {
  EditorContainer?: React.FC;
  editorRef: React.RefObject<ImageEditorRef>;
  show: boolean;
  menu: MenuOption[];
  image: {
    path: string;
    name: string;
  };
  editorSize: {
    width: string;
    height: string;
  };
  imageSize: {
    width: number;
    height: number;
  };
}

export const ImageEditor: React.FC<ImageEditorProps> = ({
  EditorContainer = DefaultEditorContainer,
  ...props
}) => {
  return (
    <EditorContainer>
      {props.show && (
        <TuiImageEditor
          includeUI={{
            loadImage: props.image,
            theme: editorTheme,
            menu: props.menu,
            uiSize: props.editorSize,
            menuBarPosition: "top",
          }}
          ref={props.editorRef}
          cssMaxHeight={props.imageSize.height}
          cssMaxWidth={props.imageSize.width}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={false}
        />
      )}
    </EditorContainer>
  );
};
