import React, { useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import {
  AppBar,
  Box,
  CssBaseline,
  Paper as MuiPaper,
  Toolbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import { GlobalStyle } from "../components/GlobalStyle";
import { getMenuItems } from "../components/sidebar/sidebarItems";
import { Sidebar } from "../components/sidebar/Sidebar";
import { Footer } from "../components/Footer";
import { CompanyStatus, useGetCompaniesQuery } from "../graphql";
import { IconButton } from "../components/pages/Common";
import MenuIcon from "@mui/icons-material/Menu";
import { useCurrentUser } from "../auth/hooks";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: auto;
`;

const Paper = styled(MuiPaper)(spacing);

const StyledAppBar = styled(AppBar)`
  padding-left: 10px;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export const DashboardLayout: React.FC = () => {
  const user = useCurrentUser();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { data: companiesQuery } = useGetCompaniesQuery({
    variables: {
      filters: {
        status: CompanyStatus.Active,
      },
    },
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const sidedarItems = React.useMemo(() => {
    if (!user || !companiesQuery) return null;
    return getMenuItems(user, companiesQuery.constructionCompanies);
  }, [companiesQuery?.constructionCompanies, user]);

  const drawler = (
    <Box sx={{ display: { xs: "block", lg: "none" } }}>
      <Sidebar
        PaperProps={{ style: { width: drawerWidth } }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        showMenu={sidedarItems != null}
        items={sidedarItems || []}
      />
    </Box>
  );

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle theme={theme} />
      <Drawer>
        {drawler}
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            showMenu={sidedarItems != null}
            items={sidedarItems || []}
          />
        </Box>
      </Drawer>
      <AppContent>
        <StyledAppBar
          position="relative"
          sx={{
            width: { sm: `100%` },
            display: { xs: "block", md: "none" },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xs: "block", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </StyledAppBar>
        <MainContent p={isLgUp ? 12 : 5}>
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};
