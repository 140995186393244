import styled from "@emotion/styled";
import React from "react";
import { Card, CardContent } from "./pages/Common";

type Size = "sm" | "md";

interface FormCardProps {
  size?: Size;
}

interface CardContentProps {
  nopadding?: number;
}

const StyledCardContent = styled(CardContent)<CardContentProps>`
  ${(props) =>
    props.nopadding &&
    `
    padding: 0 !important;
    padding-bottom: 0 !important;
  `}
`;

export const StyledCard = styled(Card)<FormCardProps>`
  min-width: 400px;
  max-width: ${(props) => (props.size === "sm" ? "1000" : "1500")}px;
`;

export const FormCard: React.FC<{
  size?: Size;
  nopadding?: boolean;
}> = (props) => {
  return (
    <StyledCard mb={6} size={props.size}>
      <StyledCardContent nopadding={+(props.nopadding || false)}>
        {props.children}
      </StyledCardContent>
    </StyledCard>
  );
};
