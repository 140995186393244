import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Divider,
  PageTitle,
  ZonedClock,
} from "../../../components/pages/Common";
import { useCurrentSite } from "../context/SiteContext";
import { SimpleTable } from "../../../components/tables/SimpleTable";
import { HeadCell } from "../../../components/tables/types";
import {
  CameraStatus,
  GetCameraChoicesQuery,
  useGetCameraChoicesQuery,
} from "../../../graphql";
import { generatePath, Link } from "react-router-dom";
import {
  SiteNavigationPath,
  sitePathPrefix,
} from "../../../helpers/navigation";
import styled from "@emotion/styled";
import {
  Box,
  CardMedia,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";

const DATA_POLL_INTERVAL = 5 * 60 * 1000;

const LinkContainer = styled("div")({
  "> a": {
    display: "block",
    "&:not(:last-child)": {
      marginBottom: "5px",
    },
  },
});

type CameraChoice = NonNullable<
  GetCameraChoicesQuery["constructionSite"]["cameras"]
>[number];

type ItemsPerRow = 2 | 4;

const EMPTY_CAMERAS_LIST: CameraChoice[] = [];
Object.freeze(EMPTY_CAMERAS_LIST);

export const CameraPreview = styled(CardMedia)`
  min-width: 170px;
  max-width: 250px;
  padding-bottom: 45%;
  margin: 0 auto;
`;

export function LiveViewListPage() {
  const site = useCurrentSite();

  const [tab, setTab] = useState<"table" | "gallery">("gallery");
  const [itemsPerRow, setItemsPerRow] = useState<ItemsPerRow>(2);

  useEffect(() => {
    setItemsPerRow(2);
  }, [tab]);

  const headCells: Array<HeadCell<CameraChoice>> = [
    {
      key: "name",
      label: "Camera Name",
      alignment: "left",
      width: "200px",
      render: (row) => row.name,
    },
    {
      key: "preview",
      label: "Camera preview",
      alignment: "center",
      width: "170px",
      render: (row) => <CameraPreview image={row.snapshot || ""} />,
    },
    {
      key: "status",
      label: "Is Available",
      alignment: "center",
      width: "100px",
      render: (row) =>
        row.health?.status === CameraStatus.Available ? (
          <CheckCircleOutline fontSize="large" color="success" />
        ) : (
          <ErrorOutline fontSize="large" color="error" />
        ),
    },
    {
      key: "actions",
      label: "Actions",
      alignment: "right",
      width: "100px",
      render: (row) => (
        <LinkContainer>
          <Link
            to={generatePath(
              `/${sitePathPrefix}/:siteId/${SiteNavigationPath.LiveViewDetail}`,
              { siteId: String(site.id), cameraId: String(row.id) },
            )}
          >
            <Button variant="outlined" margin="0">
              Live View
            </Button>
          </Link>
        </LinkContainer>
      ),
    },
  ];

  const { data: cameraQueryResponse } = useGetCameraChoicesQuery({
    variables: { constructionSiteId: site.id },
    pollInterval: DATA_POLL_INTERVAL,
  });
  const cameras = useMemo(
    () =>
      (cameraQueryResponse?.constructionSite.cameras ?? EMPTY_CAMERAS_LIST).map(
        (camera) => ({
          ...camera,
          detailUrl: generatePath(
            `/${sitePathPrefix}/:siteId/${SiteNavigationPath.LiveViewDetail}`,
            { siteId: String(site.id), cameraId: String(camera.id) },
          ),
        }),
      ),
    [cameraQueryResponse?.constructionSite.cameras, site.id],
  );

  return (
    <>
      <PageTitle
        text={`${site.name}: Cameras List`}
        paddingTop="0px !important"
      >
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>
      <Divider />
      <Grid
        container
        direction="row"
        spacing={4}
        marginTop={0.5}
        columnSpacing={2}
      >
        <Grid item paddingY={0}>
          <Button
            color="primary"
            variant={tab === "gallery" ? "contained" : "outlined"}
            onClick={() => setTab("gallery")}
          >
            Gallery
          </Button>
        </Grid>
        <Grid item paddingY={0}>
          <Button
            color="primary"
            variant={tab === "table" ? "contained" : "outlined"}
            onClick={() => setTab("table")}
          >
            Table
          </Button>
        </Grid>
        {tab === "gallery" && (
          <Grid item marginLeft="auto">
            <Grid container direction="row" spacing={4} columnSpacing={2}>
              <Grid item paddingY={0}>
                <Button
                  color="primary"
                  variant={itemsPerRow === 2 ? "contained" : "outlined"}
                  onClick={() => setItemsPerRow(2)}
                >
                  2 per row
                </Button>
              </Grid>
              <Grid item paddingY={0}>
                <Button
                  color="primary"
                  variant={itemsPerRow === 4 ? "contained" : "outlined"}
                  onClick={() => setItemsPerRow(4)}
                >
                  4 per row
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {tab === "table" ? (
        <Box marginTop="3px">
          <SimpleTable headCells={headCells} rows={cameras} rowHeight={150} />
        </Box>
      ) : (
        <LiveViewGallery cameras={cameras} itemsPerRow={itemsPerRow} />
      )}
    </>
  );
}

interface CameraChoiceWithDetailUrl extends CameraChoice {
  detailUrl: string;
}

interface LiveViewGalleryProps {
  cameras: CameraChoiceWithDetailUrl[];
  itemsPerRow: ItemsPerRow;
}

const ITEM_SX = {
  alignItems: "center",
  justifyContent: "center",
  transition: "transform .5s, box-shadow 1s",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0 0 2px 3px rgba(0,0,0,0.4)",
  },
};

const LIST_SX = { paddingX: "10px", paddingTop: "3px", overflowY: "visible" };

const TITLE_SX = {};

const LiveViewGallery: FC<LiveViewGalleryProps> = ({
  cameras,
  itemsPerRow,
}) => {
  const itemSX = useMemo(
    () => ({
      ...ITEM_SX,
      width: `${itemsPerRow === 2 ? "35vw" : "18vw"} !important`,
      height: `${itemsPerRow === 2 ? "40vh" : "30vh"} !important`,
    }),
    [itemsPerRow],
  );
  const titleSX = useMemo(
    () => ({ ...TITLE_SX, height: itemsPerRow === 4 ? "25%" : undefined }),
    [itemsPerRow],
  );
  return (
    <ImageList cols={itemsPerRow} gap={5} sx={LIST_SX}>
      {cameras.map((camera) => (
        <ImageListItem key={camera.id} sx={itemSX}>
          <Link
            to={camera.detailUrl}
            style={{
              height: `${itemsPerRow === 2 ? "40vh" : "30vh"} !important`,
            }}
          >
            <ImageWithFallback
              camera={camera}
              width={itemsPerRow === 2 ? "35vw" : "18vw"}
              height={itemsPerRow === 2 ? "40vh" : "30vh"}
            />
            <ImageListItemBar title={camera.name} sx={titleSX} />
          </Link>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

interface ImageWithFallbackProps {
  camera: CameraChoice;
  height: string;
  width?: string;
}

const ERROR_STYLE = { fontSize: "70px", alignSelf: "center" };

const ImageWithFallback: FC<ImageWithFallbackProps> = memo(
  ({ camera, height, width = "auto" }) => {
    const [hasError, setHasError] = useState<boolean>(false);

    const handleError = useCallback(() => setHasError(true), []);

    const imgStyle = useMemo(() => ({ width, height }), [height]);

    useEffect(() => {
      if (!camera.snapshot) {
        setHasError(true);
      }
    }, [camera.snapshot]);

    return !hasError ? (
      <img
        srcSet={camera.snapshot!}
        // srcSet={`${camera.snapshot}?w=248&fit=crop&auto=format&dpr=2 2x`}
        // src={`${camera.snapshot}?w=248&fit=crop&auto=format`}
        src={camera.snapshot!}
        alt={camera.name}
        loading="lazy"
        onError={handleError}
        style={imgStyle}
      />
    ) : (
      <ErrorOutline color="error" sx={ERROR_STYLE} />
    );
  },
);
