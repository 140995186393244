import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";
import { FormCard } from "../../components/FormCard";
import {
  Alert,
  Divider,
  PageTitle,
  ZonedClock,
} from "../../components/pages/Common";
import {
  DatetimePicker,
  SingleSelect,
} from "../../components/pages/FormFields";

import styled from "@emotion/styled";
import { ReactCompareSlider } from "react-compare-slider";
import { ForgeViewer } from "../../components/ForgeViewer";
import { dateTimeFormat } from "../../constants";
import { tokenExprationPeriod } from "../../forge-client/client";
import {
  Camera,
  useGetForgeAuthTokenQuery,
  useGetSiteCamerasLazyQuery,
} from "../../graphql";
import {
  getUtcNow,
  isToday,
  offsetDateByTz,
  roundMinutes,
  toUtcDateTimeString,
} from "../../helpers/date";
import { errorUrl } from "../../helpers/navigation";
import { useCurrentSite } from "./context/SiteContext";

const tokenPollingInterval = (tokenExprationPeriod - 60) * 1000;

const Slider = styled(ReactCompareSlider)<{ loading: number }>`
  width: 100%;
  height: auto;
  
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  [data-rcs="handle-container"] {
    ${(props) => (props.loading ? "display: none;" : "z-index: 101;")}
`;

const Overlay = styled.div<{ loading: number }>`
  position: relative;
  width: 100%;
  min-height: 60vh;
  text-align: center;

  ${(props) =>
    props.loading &&
    `
      pointer-events: none;
      cursor: wait !important;
      z-index: 102;
    `}
`;

const Loader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 103;
`;

const Container: React.FC<{ loading: boolean }> = ({ loading, children }) => (
  <Overlay loading={+loading}>
    {loading && <Loader color="primary" size="100px" />}
    <div style={loading ? { filter: "blur(7px)" } : {}}>{children}</div>
  </Overlay>
);

enum PageState {
  noCameras,
  paramsNotSet,
  readyToRender,
  loading,
}

export const BimComparePage: React.FC = () => {
  const site = useCurrentSite();

  const siteTz = site.timezone || "";
  const nowUtc = getUtcNow();
  const nowLocal = offsetDateByTz(nowUtc, siteTz);

  const navigate = useNavigate();
  const [getLazySiteCameras, { error }] = useGetSiteCamerasLazyQuery();
  const [cameras, setCameras] = React.useState<Camera[]>();
  const [cameraId, setCameraId] = React.useState<number>();
  const [snapshotUrl, setSnapshotUrl] = React.useState("");
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
  const [show3DViewer, setShow3DViewer] = React.useState(false);
  const [viewerLoaded, setViewerLoaded] = React.useState(false);

  const { data: tokenData } = useGetForgeAuthTokenQuery({
    pollInterval: tokenPollingInterval,
  });
  const token = tokenData?.forgeAuthToken;

  const [snapshotTime, setSnapshotTime] = React.useState<Date | null>(
    roundMinutes(nowLocal),
  );

  const getCameras = async (time?: Date) => {
    const { data } = await getLazySiteCameras({
      variables: {
        constructionSiteId: site.id,
        snapshotTime: time
          ? toUtcDateTimeString(time, siteTz, dateTimeFormat)
          : "",
      },
    });
    const cameras = data?.constructionSite.cameras || [];
    return cameras.filter((camera) => !!camera.bimFile);
  };

  const getSnapshot = async (time?: Date) => {
    const cameras = await getCameras(time);
    const camera = cameras.find((camera) => camera.id === cameraId);
    return camera?.snapshot || "";
  };

  React.useEffect(() => {
    setCameraId(undefined);
    void getCameras().then((cameras) => setCameras(cameras));
  }, [site.id]);

  const [camera, bimFile] = React.useMemo(() => {
    const camera = (cameras || []).find((cam) => cam.id === cameraId);
    return [camera, camera?.bimFile];
  }, [cameraId]);

  React.useEffect(() => {
    setImageLoaded(false);
    void getSnapshot(snapshotTime || undefined).then((url) => {
      setSnapshotUrl(url);
    });
  }, [cameraId, snapshotTime]);

  React.useEffect(() => {
    setShow3DViewer(false);
    setViewerLoaded(false);
  }, [cameraId]);

  React.useEffect(() => {
    if (!imageLoaded || show3DViewer) return;
    setTimeout(() => setShow3DViewer(true));
  }, [imageLoaded, show3DViewer]);

  React.useEffect(() => {
    if (error) navigate(errorUrl);
  }, [error]);

  const cameraChoices = React.useMemo(() => {
    if (!cameras) return [];
    return cameras.map((camera) => ({
      value: camera.id,
      label: camera?.name || "",
    }));
  }, [cameras]);

  let pageState = PageState.readyToRender;
  if (Array.isArray(cameras) && !cameras.length) {
    pageState = PageState.noCameras;
  } else if (!camera || !snapshotTime) {
    pageState = PageState.paramsNotSet;
  } else if (!show3DViewer || !imageLoaded || !viewerLoaded) {
    pageState = PageState.loading;
  }

  const snapshot = (
    <img src={snapshotUrl} onLoad={() => setImageLoaded(true)} />
  );

  const loading = pageState == PageState.loading;

  return (
    <React.Fragment>
      <PageTitle text={`${site.name}: BIM Compare`}>
        {site.timezone && <ZonedClock timezone={site.timezone} />}
      </PageTitle>
      <Divider my={6} />
      <FormCard>
        <Grid
          container
          mb={6}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={9}>
            <Grid
              container
              direction={{ xs: "column", md: "row" }}
              alignItems={{ md: "center" }}
              spacing={2}
            >
              <Grid item md={3} xs={12}>
                <SingleSelect
                  label="Camera"
                  placeholder="Select Camera"
                  value={cameraId || 0}
                  options={cameraChoices}
                  onChange={(value: number) => setCameraId(value)}
                  disabled={[PageState.noCameras, PageState.loading].includes(
                    pageState,
                  )}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <DatetimePicker
                  value={snapshotTime}
                  label="Date"
                  inputFormat="yyyy-MM-dd HH:mm"
                  maxDate={nowLocal}
                  maxTime={
                    snapshotTime && isToday(snapshotTime, siteTz)
                      ? nowLocal
                      : undefined
                  }
                  mask="____-__-__ __:__"
                  onChange={setSnapshotTime}
                  disabled={[PageState.noCameras, PageState.loading].includes(
                    pageState,
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {[PageState.readyToRender, PageState.loading].includes(pageState) && (
          <Container loading={loading}>
            <Slider
              loading={+loading}
              itemOne={
                show3DViewer ? (
                  <ForgeViewer
                    objectId={bimFile?.forgeObjectId || ""}
                    token={token}
                    sceneState={bimFile?.sceneState || ""}
                    opacity={100}
                    edit={false}
                    show={true}
                    hideLoader={true}
                    onload={() => setViewerLoaded(true)}
                  >
                    {snapshot}
                  </ForgeViewer>
                ) : null
              }
              itemTwo={snapshot}
            />
          </Container>
        )}

        {pageState === PageState.paramsNotSet && (
          <Alert my={2} severity="warning">
            Please select a camera and specify date and time of the frame
          </Alert>
        )}

        {pageState === PageState.noCameras && (
          <Alert my={2} severity="error">
            We're sorry, but there are no 3D files uploaded for the cameras on
            this site. Please contact admins.
          </Alert>
        )}
      </FormCard>
    </React.Fragment>
  );
};
