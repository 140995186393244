import * as ForgeSDK from "forge-apis";
import { ForgeAuthToken } from "../graphql";
import { v4 as uuid } from "uuid";
import { getFileExtension } from "../helpers/file";

const fakeAuth = new ForgeSDK.AuthClientTwoLegged("", "", [], false);
const ObjectsApi = new ForgeSDK.ObjectsApi();
const DerivativesApi = new ForgeSDK.DerivativesApi();
export const tokenExprationPeriod = 3600; //seconds

type UploadResponseBody = {
  location: string;
  objectId: string;
};

type GetFormatsResponseBody = {
  formats: {
    svf: string[];
    svf2: string[];
  };
};

export class ForgeClient {
  private token: ForgeSDK.AuthToken;

  constructor(token: ForgeAuthToken) {
    this.token = {
      ...token,
      refresh_token: token.refresh_token || undefined,
    };
  }

  async uploadFile(file: File): Promise<UploadResponseBody> {
    const bucketKey = process.env.REACT_APP_FORGE_BUCKET_KEY;
    if (!bucketKey) throw new Error("Forge bucket key is not set.");
    const extension = getFileExtension(file);
    const fname = `${uuid()}.${extension}`;

    return ObjectsApi.uploadObject(
      bucketKey,
      fname,
      file.size,
      // @ts-expect-error: SDK wrong type
      file,
      {},
      fakeAuth,
      this.token,
    ).then(({ body }) => {
      const { location, objectId } = body as UploadResponseBody;
      return { location, objectId };
    });
  }

  /* Returns file formats allowed for SVF2 conversion/translate */
  async getAllowedFormats(options?: { withDot?: boolean }): Promise<string[]> {
    const { withDot } = options || {};
    const formats = await DerivativesApi.getFormats(
      {},
      fakeAuth,
      this.token,
    ).then(({ body }) => {
      const { formats } = body as GetFormatsResponseBody;
      return formats.svf2;
    });
    if (!withDot) return formats;
    return formats.map((f) => `.${f}`);
  }
}
