import * as Yup from "yup";
import {
  DATE_TOKEN,
  ISO_TIMEZONE_SOURCE,
  PartialPatternChoices,
} from "./constants";
import { escapeRegExp, inRange } from "lodash";
import {
  CameraAuthenticationMethod,
  CameraTimeFormat,
  CameraTimezoneSource,
} from "../../../graphql";
import { addressFieldValidator } from "../../../helpers/validation";
import { defaultHelperText } from "../../pages/inputConstants";

const DATE_TOKEN_REG_EXP = new RegExp(escapeRegExp(DATE_TOKEN), "g");

const CUSTOM_DATE_FORMAT_VALIDATION_REGEX_SET = [
  { regex: /y{1,4}/g, isRequired: true },
  { regex: /M{1,2}/g, isRequired: true },
  { regex: /d{1,2}/g, isRequired: true },
  { regex: /h{1,2}/g, isRequired: true },
  { regex: /m{1,2}/g, isRequired: true },
  { regex: /s{1,2}/g, isRequired: false },
];

const AUTHENTICATION_METHOD_VALIDATOR = Yup.string().oneOf(
  Object.values(CameraAuthenticationMethod),
);

const TIME_FORMAT_VALIDATOR = Yup.string().oneOf(
  Object.values(CameraTimeFormat),
);

const TIMEZONE_VALIDATOR = Yup.string().when("timeFormat", {
  is: CameraTimeFormat.Iso,
  then: (schema) =>
    schema.oneOf([ISO_TIMEZONE_SOURCE, ...Object.values(CameraTimezoneSource)]),
  otherwise: (schema) => schema.oneOf(Object.values(CameraTimezoneSource)),
});

function getTimePatternValidator(schema: Yup.StringSchema<string | undefined>) {
  return schema.test(
    "test_date_token",
    `Pattern should contain ${DATE_TOKEN} token exactly once!`,
    (value) => (value ? value.match(DATE_TOKEN_REG_EXP)?.length === 1 : true),
  );
}

function getPartialPatternsDetailedValidator(
  schema: Yup.ObjectSchema<object>,
  isRequired = true,
) {
  return schema.shape(
    Object.fromEntries(
      Object.entries(PartialPatternChoices).map(([label, value]) => {
        const baseStringValidator = Yup.string().test(
          "test_date_token",
          `Pattern should contain ${DATE_TOKEN} token exactly once!`,
          (value) =>
            value ? value.match(DATE_TOKEN_REG_EXP)?.length === 1 : true,
        );
        return [
          value,
          isRequired
            ? baseStringValidator.required(`${label} field is required!`)
            : baseStringValidator,
        ];
      }),
    ),
  );
}

function getPartialDefaultValidator(schema: Yup.ObjectSchema<object>) {
  return schema.shape(
    Object.fromEntries(
      Object.values(PartialPatternChoices).map((value) => [
        value,
        Yup.string(),
      ]),
    ),
  );
}

function getCustomTimeFormatValidator(
  schema: Yup.StringSchema<string | undefined>,
) {
  return schema.test(
    "test_date_time_tokens_integrity",
    "Field should contain year, month, day, hour, and minute tokens",
    (value) =>
      value
        ? CUSTOM_DATE_FORMAT_VALIDATION_REGEX_SET.every(
            ({ regex, isRequired }) =>
              inRange(value.match(regex)?.length ?? 0, Number(isRequired), 2),
          )
        : true,
  );
}

export const CAMERA_CONFIUGURATION_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Name field is required!"),
  statusUri: Yup.string().required("Status URI field is required!"),
  snapshotUri: Yup.string().required("Snapshot URI field is required!"),
  authenticationMethod: AUTHENTICATION_METHOD_VALIDATOR.required(
    "Authentication Method field is required!",
  ),
  timeFormat: TIME_FORMAT_VALIDATOR.required("Time Format field is required!"),
  timezone: TIMEZONE_VALIDATOR.required("Timezone field is required!"),
  pattern: Yup.string().when("timeFormat", {
    is: CameraTimeFormat.Partial,
    otherwise: (schema) =>
      getTimePatternValidator(schema).required("Pattern field is required!"),
  }),
  partialPatterns: Yup.object().when("timeFormat", {
    is: CameraTimeFormat.Partial,
    then: (schema) => getPartialPatternsDetailedValidator(schema, true),
    otherwise: getPartialDefaultValidator,
  }),
  customTimeFormat: Yup.string().when("timeFormat", {
    is: CameraTimeFormat.Custom,
    then: (schema) =>
      getCustomTimeFormatValidator(schema).required(
        "Time Format field is required!",
      ),
  }),
  isAvailableForUsers: Yup.boolean().default(true),
});

export const CONFIGURATION_TEST_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  address: addressFieldValidator.required(defaultHelperText.required),
  username: Yup.string().required(),
  password: Yup.string().required(),
});

export const CONFIGURATION_TEST_MAIN_FORM_VALIDATION_SCHEMA =
  Yup.object().shape({
    statusUri: Yup.string().required(
      "Timestamp URI field is required for configuration testing!",
    ),
    authenticationMethod: AUTHENTICATION_METHOD_VALIDATOR.required(
      "Authentication Method field is required for configuration testing!",
    ),
    timeFormat: TIME_FORMAT_VALIDATOR,
    timezone: TIMEZONE_VALIDATOR,
    pattern: Yup.string().when("timeFormat", {
      is: CameraTimeFormat.Partial,
      otherwise: getTimePatternValidator,
    }),
    partialPatterns: Yup.object().when("timeFormat", {
      is: CameraTimeFormat.Partial,
      then: (schema) => getPartialPatternsDetailedValidator(schema, false),
      otherwise: getPartialDefaultValidator,
    }),
    customTimeFormat: Yup.string().when("timeFormat", {
      is: CameraTimeFormat.Custom,
      then: getCustomTimeFormatValidator,
    }),
  });
