import {
  Grid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Card, CardContent, Paper } from "../pages/Common";
import { HeadCell, Sorting, TableProps } from "./types";

interface TableHeadProps<T> {
  headCells: Array<HeadCell<T>>;
  sorting?: Sorting;
}

const TableHead = <T extends object>(props: TableHeadProps<T>) => {
  const { headCells, sorting } = props;

  const onSortChange = (headCell: HeadCell<T>) => {
    if (!headCell.handleSort) return;
    headCell.handleSort(headCell.key);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            align={headCell.alignment}
            padding={"normal"}
            sortDirection={
              sorting?.key === headCell.key ? sorting.order : false
            }
            width={headCell.width}
          >
            {headCell.handleSort ? (
              <TableSortLabel
                active={sorting?.key === headCell.key}
                direction={sorting?.order}
                onClick={() => onSortChange(headCell)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

const Table = <T extends object>({
  rows,
  headCells,
  id,
  rowHeight = defaultRowHeight,
  ...rest
}: TableProps<T>) => {
  const emptyRowsN = rest.emptyRowsN || 0;

  return (
    <Paper>
      {rest.toolbar}
      {rows.length > 0 && (
        <TableContainer>
          <MuiTable id={id} size={rest.size}>
            <TableHead headCells={headCells} sorting={rest.sorting} />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    style={{ height: `${rowHeight}px` }}
                  >
                    {headCells.map((headCell) => (
                      <TableCell align={headCell.alignment} key={headCell.key}>
                        {headCell.render(row)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRowsN > 0 && (
                <TableRow style={{ height: rowHeight * emptyRowsN }}>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
      {!rows.length && noResultEl}
    </Paper>
  );
};

export const SimpleTable = <T extends object>(props: TableProps<T>) => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Table {...props} />
      </Grid>
    </Grid>
  );
};

const noResultMessage =
  "No results matching your query found. Try changing the applied filters or search query.";

const noResultEl = (
  <Card>
    <CardContent>
      <em>{noResultMessage}</em>
    </CardContent>
  </Card>
);

const defaultRowHeight = 53;
