import { getSecondsTotal, getZonedNow, setTime } from "../../helpers/date";

export const getServerUri = (authToken: string) => {
  const url = new URL(process.env.REACT_APP_APOLLO_SERVER_URI || "");
  url.searchParams.append("auth_token", authToken);
  return url.toString();
};

export const getClosestDateByInterval = (tzcode: string, interval: number) => {
  const localNow = getZonedNow(tzcode);
  const localNowEpoch = getSecondsTotal(localNow);

  let lastLocalEpoch = getSecondsTotal(setTime(localNow, "00:00:00"));

  while (lastLocalEpoch + interval < localNowEpoch) {
    lastLocalEpoch += interval;
  }

  return new Date(lastLocalEpoch * 1000);
};
