import React from "react";

import { reduceChildRoutes } from "./reduceChildRoutes";

import { SidebarItemsType } from "../../types/sidebar";

interface SidebarNavListProps {
  depth: number;
  pages: SidebarItemsType[];
}

export const SidebarNavList = (props: SidebarNavListProps) => {
  const { pages, depth } = props;

  const childRoutes = pages.reduce(
    (items, page, index) => reduceChildRoutes({ items, page, depth, index }),
    [] as JSX.Element[],
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};
