import React from "react";
import styled from "@emotion/styled";
import { rgba, darken } from "polished";

import { Chip, Collapse, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useScrollbarContext } from "./SidebarNav";

interface ItemProps {
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: React.FC;
  depth: number;
}

const Item = styled(ListItemButton)<ItemProps>`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

interface TitleInterface {
  depth: number;
}

const Title = styled(ListItemText)<TitleInterface>`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1,
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Container = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed !important;
      opacity: 0.6;
    `};
`;

interface SidebarNavListItemProps {
  className?: string;
  depth: number;
  href: string;
  icon?: JSX.Element;
  badge?: string;
  open?: boolean;
  title: React.ReactNode;
  disabled?: boolean;
  getIsActive: (pathname: string) => boolean;
}

export const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (
  props,
) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    disabled,
    getIsActive,
    open: openProp = false,
  } = props;
  const { updateScrollbar } = useScrollbarContext();
  const [open, setOpen] = React.useState(openProp);
  const { pathname } = useLocation();
  const handleToggle = () => setOpen((state) => !state);
  React.useEffect(updateScrollbar, [open]);

  return (
    <Container disabled={disabled}>
      {children ? (
        <React.Fragment>
          <Item depth={depth} onClick={handleToggle}>
            {Icon}
            <Title depth={depth}>
              {title}
              {badge && <Badge label={badge} />}
            </Title>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Item>
          <Collapse in={open}>{children}</Collapse>
        </React.Fragment>
      ) : (
        <Link to={href}>
          <Item
            depth={depth}
            className={getIsActive(pathname) ? "active" : ""}
            activeclassname="active"
          >
            {Icon}
            <Title depth={depth}>
              {title}
              {badge && <Badge label={badge} />}
            </Title>
          </Item>
        </Link>
      )}
    </Container>
  );
};
