import React from "react";

import { format } from "date-fns";
import styled from "@emotion/styled";
import { CameraStatus as CameraStatusChoices } from "../graphql";
import { utcToZonedTime } from "date-fns-tz";
import { isNil } from "lodash";

const Circle = styled.div`
  display: flex;
  align-items: center;

  ::before {
    content: "";
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
`;

const LastSeen = styled(Circle)`
  ::before {
    background-color: red;
  }
`;

const Online = styled(Circle)`
  ::before {
    background-color: green;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const CameraStatus: React.FC<{
  status: CameraStatusChoices;
  lastSeen?: Date | string;
  timezone?: string;
}> = (props) => {
  const isActive = props.status === CameraStatusChoices.Available;

  const lastSeen = !isNil(props.lastSeen)
    ? utcToZonedTime(props.lastSeen, props.timezone ?? "GMT")
    : props.lastSeen;

  return (
    <Container>
      {!isActive ? (
        <>
          {lastSeen ? (
            <LastSeen>
              Last seen at: {format(lastSeen, "yyyy-MM-dd HH:mm")}
            </LastSeen>
          ) : (
            <LastSeen>Offline</LastSeen>
          )}

          <div></div>
        </>
      ) : (
        <>
          <div></div>
          <Online>Online</Online>
        </>
      )}
    </Container>
  );
};
