import React, { ReactNode } from "react";
import { styled, Typography, TypographyProps } from "@mui/material";

interface MUIFieldSetProps {
  legend: string;
  legendProps?: TypographyProps;
  children: ReactNode;
}

const StyledFieldSet = styled("fieldset")(({ theme }) => ({
  borderColor: theme.palette.grey.A400,
  borderRadius: theme.shape.borderRadius,
  borderWidth: "1px",
}));

const StyledLegend = styled("legend")(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 400,
  padding: "0 6px 6px",
}));

export default function MUIFieldSet({
  legend,
  legendProps,
  children,
}: MUIFieldSetProps) {
  return (
    <StyledFieldSet>
      <StyledLegend>
        <Typography variant="caption" {...legendProps}>
          {legend}
        </Typography>
      </StyledLegend>
      {children}
    </StyledFieldSet>
  );
}
