import React from "react";
import { Grid, Toolbar } from "@mui/material";

export const TableFilterToolbar: React.FC<{
  search: React.ReactNode;
  filters: React.ReactNode[];
}> = (props) => {
  const filters = props.filters.map((filter, index) => (
    <Grid item key={index}>
      {filter}
    </Grid>
  ));

  return (
    <Toolbar>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "space-between" }}
      >
        <Grid item xs={12} md="auto" my={2}>
          {props.search}
        </Grid>
        <Grid item xs={12} md="auto">
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            justifyContent="flex-end"
            spacing={2}
          >
            {filters}
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};
