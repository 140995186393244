import { memo, useEffect, useReducer, useMemo } from "react";
import { PaginatedTable } from "../../../components/tables/PaginatedTable";
import { useLocalStorage } from "../../../helpers/localStorage";
import { sortReducer } from "../../../components/tables/sorting";
import { Sorting } from "../../../components/tables/types";
import { GateReport } from "./types";
import { getHeadCells } from "./head-cells";

const defaultSorting: Sorting = {
  key: "eventTimestamp",
  order: "desc",
};

export interface GateReportTableProps {
  siteId: number;
  gateReports: GateReport[];
  count?: number;
  onPageChange?: (newPage: number) => void;
  onRowsPerPageChange?: (newRowsCount: number) => void;
}

const GateReportTableRaw = ({
  count,
  siteId,
  gateReports,
  onPageChange,
  onRowsPerPageChange,
}: GateReportTableProps) => {
  const settingsLocalStorageKey = `gate-reports-table-${siteId}`;

  const [initialSorting, saveSorting] = useLocalStorage<Sorting>(
    `${settingsLocalStorageKey}-sorting`,
    defaultSorting,
  );
  const [sorting, setSortingKey] = useReducer(sortReducer, initialSorting);

  const headCells = useMemo(
    () => getHeadCells({ handleSort: setSortingKey }),
    [],
  );

  // const sortedRows: GateReport[] = useMemo(() => {
  //   return sortRows(gateReports, sorting);
  // }, [sorting, gateReports]);

  useEffect(() => saveSorting(sorting), [sorting]);

  return (
    <>
      <PaginatedTable
        headCells={headCells}
        rows={gateReports}
        sorting={sorting}
        settingsLocalStorageKey={settingsLocalStorageKey}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        count={count}
        // toolbar={toolbar}
      />
    </>
  );
};

export const GateReportTable = memo(GateReportTableRaw);
