import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserMeQuery, useUserMeLazyQuery } from "../graphql";
import { auth } from "./auth";
import { useNavigate } from "react-router-dom";
import { errorUrl } from "../helpers/navigation";

interface AuthError {
  networkError?: {
    result?: {
      errors?: {
        extensions?: {
          code?: string;
        };
      }[];
    };
  };
}

export const useCurrentUser = () => {
  const [user, setUser] = useState<UserMeQuery["me"]>();

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [loadData] = useUserMeLazyQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      void getAccessTokenSilently({ detailedResponse: true })
        .then((tokenData) => {
          auth.setIdToken(tokenData.id_token);
          return loadData();
        })
        .then((result) => {
          setUser(result.data?.me);
        })
        .catch((error: AuthError) => {
          const errors = error?.networkError?.result?.errors;
          if (errors && errors[0]?.extensions?.code === "USER_DEACTIVATED") {
            navigate(errorUrl);
          }
          // eslint-disable-next-line no-console
          console.log("Auth0 hook error:", error);
        });
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, loadData]);

  return user;
};
