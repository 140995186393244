import React from "react";

import { SidebarItemsType } from "../../types/sidebar";
import { SidebarNavListItem } from "./SidebarNavListItem";
import { SidebarNavList } from "./SidebarNavList";

interface ReduceChildRoutesProps {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[];
  index: number;
}

export const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const { index, items, page, depth } = props;

  if (page.children) {
    items.push(
      <SidebarNavListItem depth={depth} key={index} {...page}>
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>,
    );
  } else {
    items.push(<SidebarNavListItem depth={depth} key={index} {...page} />);
  }

  return items;
};
