import React from "react";

import { SingleSelect, TextInput } from "../pages/FormFields";
import { NewInstanceModal } from "./NewInstanceModal";
import styled from "@emotion/styled";
import { FormHelperText, Grid } from "@mui/material";

const timeMultipliers = [
  { label: "Seconds", value: 1 },
  { label: "Minutes", value: 60 },
  { label: "Hours", value: 3600 },
];

export const AddLiveViewIframeModal: React.FC<{
  isOpened: boolean;
  cameras: { id: number; name: string; snapshot?: string | null }[];
  close: () => void;
  onSave: (cameraId: number, interval: number) => void;
}> = (props) => {
  const initialMultiplier = timeMultipliers[2].value;
  const initialCameraId = props.cameras[0]?.id || 0;
  const initialTime = 1;

  const [time, setTime] = React.useState<number | null>(initialTime);
  const [timeMultiplier, setTimeMultiplier] = React.useState(initialMultiplier);
  const [cameraId, setCameraId] = React.useState(initialCameraId);

  const cameraChoices = React.useMemo(
    () =>
      props.cameras.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [],
  );

  const snapshotUrl = React.useMemo(() => {
    const selectedCamera = props.cameras.find(({ id }) => id == cameraId);
    return selectedCamera?.snapshot || "";
  }, [cameraId]);

  const interval = (time || 0) * timeMultiplier;
  const intervalIsValid = interval >= 10 && interval < 86400;
  const isValid = !!cameraId && intervalIsValid;

  const close = () => {
    props.close();
    setTimeMultiplier(initialMultiplier);
    setTime(initialTime);
    setCameraId(initialCameraId);
  };

  const onSave = () => {
    close();
    props.onSave(cameraId, interval);
  };

  return (
    <NewInstanceModal
      title="Generate Live View iframe URL"
      canBeSaved={isValid}
      onSave={onSave}
      onCancel={close}
      isOpened={props.isOpened}
      saveButtonLabel="Generate URL"
    >
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} mt={2}>
          <SingleSelect
            label="Camera"
            placeholder="Select Camera"
            value={cameraId}
            options={cameraChoices}
            onChange={setCameraId}
          />
        </Grid>

        <Grid item xs={12}>
          <SingleSelect
            label="Interval type"
            placeholder="Select type"
            value={timeMultiplier}
            options={timeMultipliers}
            onChange={setTimeMultiplier}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Interval value"
            value={time ? time.toString() : ""}
            onChange={(event) => {
              const value: string = event.target.value;
              setTime(parseInt(value) || null);
            }}
          />
          {!isValid && (
            <FormHelperText error={true}>
              Interval may be between 10 seconds and 24 hours.
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} mt={1}>
          <Snapshot src={snapshotUrl} />
        </Grid>
      </Grid>
    </NewInstanceModal>
  );
};

const Snapshot = styled.img`
  object-fit: contain;
  max-width: 100%;
  height: auto;
  border-radius: 5px;
`;
