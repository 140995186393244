import { useState } from "react";

export const useLocalStorage = <T>(
  storageKey: string,
  defaultValue: T,
): [value: T, saveFn: (value: T) => void] => {
  const initialValue = loadFromLocalStorage<T>(storageKey, defaultValue);
  const [value, setValue] = useState(initialValue);
  const saveValue = (value: T) => {
    setValue(value);
    saveToLocalStorage(storageKey, value);
  };
  return [value, saveValue];
};

export const saveToLocalStorage = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const loadFromLocalStorage = <T>(key: string, defaultValue: T) => {
  const stored = localStorage.getItem(key);
  if (stored) return JSON.parse(stored) as T;
  return defaultValue;
};
