import { Global, css } from "@emotion/react";
import { Theme } from "@mui/material";

export const GlobalStyle: React.FC<{
  theme: Theme;
}> = ({ theme }) => (
  <Global
    styles={css`
      html,
      body,
      #__next {
        height: 100%;
      }

      body {
        margin: 0;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      .tui-image-editor-container .tui-image-editor-controls {
        background-color: ${theme.palette.primary.main};
      }

      .tui-image-editor-submenu-style {
        background-color: ${theme.palette.primary.main}!important;
      }

      .tui-image-editor-controls-logo,
      .tui-image-editor-controls-buttons {
        display: none !important;
      }

      .tui-image-editor-container.top.tui-image-editor-top-optimization
        .tui-image-editor-controls
        ul {
        text-align: center;
      }
    `}
  />
);
