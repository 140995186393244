import React from "react";
import * as Yup from "yup";
import { NewInstanceModal } from "./NewInstanceModal";

import { useNavigate } from "react-router-dom";
import { defaultHelperText, userInputParams } from "../pages/inputConstants";
import {
  useCreateUserMutation,
  useGetSitesQuery,
  UserRole,
} from "../../graphql";

import { useFormik } from "formik";
import {
  SingleSelect,
  TextInput,
  Autocomplete,
  roleChoicesSortFn,
} from "../pages/FormFields";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import {
  AdminNavigationPath,
  errorUrl,
  getAdminUrl,
  getSiteUrl,
  SiteNavigationPath,
} from "../../helpers/navigation";
import { isEmpty } from "lodash";
import { getRoleChoices } from "../../helpers/getChoices";

export const InviteSiteUserModal: React.FC<{
  siteId?: number;
  isOpened: boolean;
  close: () => void;
}> = (props) => {
  const navigate = useNavigate();
  const [createUser] = useCreateUserMutation();
  const { data: sitesData } = useGetSitesQuery();
  const sites = sitesData?.constructionSites || [];
  const siteIds = React.useMemo(() => sites.map(({ id }) => id), [sites]);

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string()
      .email(defaultHelperText.invalidEmail)
      .required(defaultHelperText.required),
    role: Yup.string().oneOf(Object.values(UserRole)),
    site: Yup.number().oneOf(siteIds, defaultHelperText.required),
  });

  const initialValues = {
    name: "",
    email: "",
    role: UserRole.General,
    site: props.siteId || 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      null;
    },
  });

  const redirect = (email: string) => {
    const url = props.siteId
      ? getSiteUrl(SiteNavigationPath.UserEdit, formik.values.site, email)
      : getAdminUrl(AdminNavigationPath.UserEdit, email);
    navigate(url);
  };

  const onSave = () => {
    void formik.validateForm().then((errors) => {
      if (!isEmpty(errors)) return;
      createUser({
        variables: {
          input: formik.values,
        },
      })
        .then((result) => {
          const email = result.data?.createUser.email || "";
          close();
          redirect(email);
        })
        .catch(() => navigate(errorUrl));
    });
  };

  const close = () => {
    formik.resetForm({ values: initialValues });
    props.close();
  };

  const roleChoices = React.useMemo(() => getRoleChoices(), []);

  const siteSelectProps = React.useMemo(() => {
    return {
      label: userInputParams.site.label,
      placeholder: userInputParams.site.placeholder,
      placeholderValue: "0",
      name: "site",
      initialValue: formik.values.site,
      options: sites.map((site) => ({
        value: site.id,
        label: site.name,
      })),
      onChange: (value?: number) => formik.setFieldValue("site", value),
      onBlur: async (e: React.SyntheticEvent) => {
        formik.handleBlur(e);
        await formik.setFieldTouched("site");
      },
    };
  }, [sites]);

  const roleSelectProps = {
    label: userInputParams.role.label,
    placeholder: userInputParams.role.placeholder,
    name: "role",
    initialValue: formik.values.role,
    options: roleChoices,
    onChange: (value: string) => formik.setFieldValue("role", value),
    onBlur: formik.handleBlur,
  };

  return (
    <NewInstanceModal
      title="Invite a User"
      canBeSaved={formik.isValid && !isEmpty(formik.touched)}
      onSave={onSave}
      onCancel={close}
      saveButtonLabel="Invite"
      isOpened={props.isOpened}
    >
      <form>
        <Grid item xs={12} my={2}>
          <TextInput
            {...userInputParams.name}
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            helperText={formik.errors.name}
            showError={!!formik.errors.name && formik.touched.name}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={12} mb={2}>
          <TextInput
            {...userInputParams.email}
            value={formik.values.email}
            name="email"
            onChange={formik.handleChange}
            helperText={formik.errors.email}
            showError={!!formik.errors.email && formik.touched.email}
            onBlur={formik.handleBlur}
          />
        </Grid>

        {!props.siteId && (
          <Grid container spacing={6} mb={2}>
            <Grid item xs={12}>
              <FormControl
                fullWidth={true}
                error={!!formik.errors.site && formik.touched.site}
              >
                <Autocomplete {...siteSelectProps} value={formik.values.site} />
                {!!formik.errors.site && formik.touched.site && (
                  <FormHelperText>{formik.errors.site}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} mb={2}>
          <SingleSelect
            {...roleSelectProps}
            value={formik.values.role}
            choicesSortFn={roleChoicesSortFn}
          />
        </Grid>
      </form>
    </NewInstanceModal>
  );
};
