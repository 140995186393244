import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import "./i18n";
import "./vendor/perfect-scrollbar.css";

import { DashboardLayout } from "./layouts/SidebarLayout";
import { SiteEditAdmin } from "./pages/admin/SiteEdit";
import { SitesListAdmin } from "./pages/admin/SitesList";
import { UserEditAdmin } from "./pages/admin/UserEdit";
import { UsersListAdmin } from "./pages/admin/UsersList";
import { EditFramePage } from "./pages/site/EditFrame";
import { SiteUserEditPage } from "./pages/site/SiteUserEdit";
import { SiteUsersListPage } from "./pages/site/SiteUsersList";

import { errorPage, notFoundPage } from "./pages/ErrorPage";
import { GateReportPage } from "./pages/site/GateReport/GateReportPage";
import { LiveViewDetailPage } from "./pages/site/LiveView/LiveViewDetail";
import { TimelapsePage } from "./pages/site/Timelapse";

import { auth } from "./auth/auth";
import { Auth0LoginRedirect } from "./auth/components";
import {
  AdminNavigationPath,
  adminPathPrefix,
  IframeNavigationPath,
  iframePathPrefix,
  ProfileNavigationPath,
  profilePathPrefix,
  SiteNavigationPath,
  sitePathPrefix,
} from "./helpers/navigation";
import { CompaniesListAdmin } from "./pages/admin/CompaniesList";
import { CompanyEditAdmin } from "./pages/admin/CompanyEdit";
import { IframeLiveView } from "./pages/iframe/LiveView";
import { IndexPage } from "./pages/IndexPage";
import { LoaderPage } from "./pages/LoaderPage";
import { EditProfile } from "./pages/profile/EditProfile";
import { BimComparePage } from "./pages/site/BimCompare";
import { BimViewPage } from "./pages/site/BimView";
import { CompareFramesPage } from "./pages/site/CompareFrames";
import { SiteContextProvider } from "./pages/site/context/SiteContext";
import { RoutesGuard } from "./permissions/RoutesGuard";
import { IframeGateReport } from "./pages/iframe/GateReport";
import CameraConfigurationList from "./pages/admin/CameraConfiguration/CameraConfigurationList";
import { LiveViewListPage } from "./pages/site/LiveView/LiveViewList";
import { GateReportActiveItemPage } from "./pages/site/GateReport/GateReportActiveItemPage";

export const getProjectRoutes = () => {
  return window.location.href.includes(`/${iframePathPrefix}/`) ? (
    <IframeRoutes />
  ) : (
    <SiteRoutes />
  );
};

export const IframeRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={`${iframePathPrefix}/:token`}>
          <Route
            path={`${IframeNavigationPath.LiveView}/:cameraId`}
            element={<IframeLiveView />}
          />
          <Route
            path={`${IframeNavigationPath.GateReport}/:siteId`}
            element={<IframeGateReport />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export const SiteRoutes: React.FC = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = React.useState<string>();

  React.useEffect(
    () =>
      void getAccessTokenSilently({ detailedResponse: true }).then(
        (tokenData) => {
          auth.setIdToken(tokenData.id_token);
          setToken(auth.getIdToken());
        },
      ),
    [],
  );

  const loading = isLoading || (isAuthenticated && !token);

  if (loading) return <LoaderPage />;

  if (!isAuthenticated) return <Auth0LoginRedirect />;

  return (
    <Router>
      <Routes>
        <Route element={<DashboardLayout />}>
          <Route path="/" element={<IndexPage />}></Route>
          <Route element={<RoutesGuard />}>
            {adminRoutes}
            {siteRoutes}
          </Route>
          {profileRoutes}
          <Route path="error" element={errorPage} />
          <Route path="*" element={notFoundPage} />
        </Route>
      </Routes>
    </Router>
  );
};

const adminRoutes = (
  <Route path={adminPathPrefix}>
    <Route path={AdminNavigationPath.SiteList} element={<SitesListAdmin />} />
    <Route
      path={`${AdminNavigationPath.SiteEdit}/:siteId`}
      element={<SiteEditAdmin />}
    />
    <Route path={AdminNavigationPath.UserList} element={<UsersListAdmin />} />
    <Route
      path={`${AdminNavigationPath.UserEdit}/:email`}
      element={<UserEditAdmin />}
    />
    <Route
      path={AdminNavigationPath.CompanyList}
      element={<CompaniesListAdmin />}
    />
    <Route
      path={`${AdminNavigationPath.CompanyEdit}/:companyId`}
      element={<CompanyEditAdmin />}
    />
    <Route
      path={AdminNavigationPath.CameraConfigurationsList}
      element={<CameraConfigurationList />}
    />
  </Route>
);

const siteRoutes = (
  <Route path={`${sitePathPrefix}/:siteId`} element={<SiteContextProvider />}>
    <Route
      path={SiteNavigationPath.LiveViewList}
      element={<LiveViewListPage />}
    />
    <Route
      path={SiteNavigationPath.LiveViewDetail}
      element={<LiveViewDetailPage />}
    />
    <Route path={SiteNavigationPath.GateReport} element={<GateReportPage />} />
    <Route
      path={SiteNavigationPath.GateReportActiveItem}
      element={<GateReportActiveItemPage />}
    />
    <Route path={SiteNavigationPath.Timelapse} element={<TimelapsePage />} />
    <Route path={SiteNavigationPath.UserList} element={<SiteUsersListPage />} />
    <Route
      path={`${SiteNavigationPath.UserEdit}/:email`}
      element={<SiteUserEditPage />}
    />
    <Route path={SiteNavigationPath.EditFrame} element={<EditFramePage />} />
    <Route
      path={SiteNavigationPath.CompareSnapshots}
      element={<CompareFramesPage />}
    />
    <Route path={SiteNavigationPath.BimView} element={<BimViewPage />} />
    <Route path={SiteNavigationPath.BimCompare} element={<BimComparePage />} />
  </Route>
);

const profileRoutes = (
  <Route path={profilePathPrefix}>
    <Route path={ProfileNavigationPath.Edit} element={<EditProfile />} />
  </Route>
);
