export const filterObjectsByString = <T>(
  array: Array<T>,
  key: keyof T,
  pattern: string,
  options?: {
    exact?: boolean;
    caseSensitive?: boolean;
  },
) => {
  if (!pattern) return array;
  const _options = options || { caseSensitive: false, exact: false };
  const _pattern = !_options.caseSensitive
    ? pattern.toLocaleLowerCase()
    : pattern;
  const matchingFn = !_options.exact ? intersectionComparer : exactComparer;

  return array.filter((value) =>
    matchingFn(
      _options.caseSensitive
        ? getStringFromObject(value[key])
        : getStringFromObject(value[key]).toLocaleLowerCase(),
      _pattern,
    ),
  );
};

const exactComparer = (value: string, pattern: string) => value === pattern;
const intersectionComparer = (value: string, pattern: string) =>
  value.includes(pattern);

const getStringFromObject = (value: unknown): string => {
  if (typeof value === "string") return value;
  if (typeof value === "number") return value.toString();
  return JSON.stringify(value);
};
