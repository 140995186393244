import { Button } from "../pages/Common";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const SaveButton: React.FC<ButtonProps & { label: string }> = (props) => (
  <Button color="primary" variant="contained" {...props}>
    {props.label}
  </Button>
);

const CancelButton: React.FC<ButtonProps> = (props) => (
  <Button color="primary" variant="outlined" {...props}>
    Cancel
  </Button>
);

interface ModalProps {
  title: string;
  isOpened: boolean;
  canBeSaved: boolean;
  saveButtonLabel?: string;
  onSave: () => void;
  onCancel: () => void;
}

export const NewInstanceModal: React.FC<ModalProps> = (props) => {
  return (
    <Dialog
      open={props.isOpened}
      onClose={props.onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <SaveButton
          onClick={props.onSave}
          disabled={!props.canBeSaved}
          label={props.saveButtonLabel || "Save"}
        />
        <CancelButton onClick={props.onCancel} />
      </DialogActions>
    </Dialog>
  );
};
