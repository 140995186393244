import { GraphQLError } from "graphql";

type Errors = readonly GraphQLError[];

export const getBadUserInputs = (errors: Errors) =>
  errors
    .filter(({ extensions }) => extensions.code === BadRequest)
    .map(({ extensions }) => extensions.invalidArgs);

const BadRequest = "BAD_USER_INPUT";
