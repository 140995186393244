import React, { useCallback, useMemo } from "react";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";

import {
  MultipleSelect,
  SingleSelect,
  Switcher,
  TextInput,
  TimePicker,
} from "../pages/FormFields";

import { Button } from "../pages/Common";
import { LiveViewUpdateFrequency, WorkingDayLabel } from "../../constants";
import { defaultHelperText } from "../pages/inputConstants";
import { getDateFromWorkingHours } from "../../helpers/date";
import { addressFieldValidator } from "../../helpers/validation";
import { Camera } from "@ocilex/api";
import {
  CameraConfiguration as CameraConfigurationType,
  Maybe,
  MutationCreateCameraArgs,
  MutationUpdateCameraArgs,
  useGetMinimalCameraConfigurationsQuery,
  WorkingDay,
} from "../../graphql";
import { dateTo24HoursString } from "../../helpers/toString";

interface ModalProps {
  close: () => void;
  camera:
    | (Omit<Camera, "configuration"> & {
        configuration?: Maybe<Pick<CameraConfigurationType, "id" | "name">>;
      })
    | null;
  isOpened: boolean;
  update: (input: MutationUpdateCameraArgs["input"]) => void;
  create: (input: MutationCreateCameraArgs["input"]) => void;
}

const WORKING_DAYS_OPTIONS = Object.entries(WorkingDayLabel).map(
  ([value, label]) => {
    return { value, label };
  },
);

const UPDATE_FREQUENCY_OPTIONS = Object.entries(LiveViewUpdateFrequency).map(
  ([value, label]) => {
    return { value, label };
  },
);

const UPDATE_FREQUENCY_DEFAULT = UPDATE_FREQUENCY_OPTIONS[2]
  .value as LiveViewUpdateFrequency;

export const CameraConfiguration: React.FC<ModalProps> = (props) => {
  const { data: cameraConfigurations } =
    useGetMinimalCameraConfigurationsQuery();
  const cameraConfigurationChoices = useMemo(
    function generateCameraConfigurationChoices() {
      return (
        cameraConfigurations?.getCameraConfigurations?.map(({ id, name }) => ({
          id,
          label: name,
        })) ?? []
      );
    },
    [cameraConfigurations],
  );
  const modalId = React.useMemo(() => uuid(), []);

  const cameraConfigurationValidationSchema = useMemo(
    function getCameraConfigurationValidationSchema() {
      return Yup.object().shape({
        configuration: Yup.object()
          .shape({
            id: Yup.number().positive().required(),
            label: Yup.string(),
          })
          .required(),
        name: Yup.string().required(defaultHelperText.required),
        address: addressFieldValidator.required(defaultHelperText.required),
        username:
          props.camera === null
            ? Yup.string().required(defaultHelperText.required)
            : Yup.string(),
        password:
          props.camera === null
            ? Yup.string().required(defaultHelperText.required)
            : Yup.string(),
        workingDays: Yup.array()
          .of(Yup.string().oneOf(Object.keys(WorkingDayLabel)))
          .min(1, defaultHelperText.required),
        updateFrequency: Yup.string().oneOf(
          Object.keys(LiveViewUpdateFrequency),
        ),
        workingHoursStart: Yup.date().required(defaultHelperText.required),
        workingHoursEnd: Yup.date().required(defaultHelperText.required),
        enableHealthCheck: Yup.bool(),
        healthCheckEmails: Yup.array()
          .of(Yup.string().email("Invalid email format"))
          .when("enableHealthCheck", {
            is: true,
            then: (schema) => schema.required("At least one email is required"),
          }),
      });
    },
    [props.camera === null],
  );

  const initialValues = {
    configuration: props.camera?.configuration
      ? {
          id: props.camera.configuration.id,
          label: props.camera.configuration.name,
        }
      : undefined,
    name: props.camera?.name ?? "",
    address: props.camera?.address ?? "",
    username: "",
    password: "",
    workingDays: (props.camera?.workingDays?.filter(Boolean) ??
      []) as WorkingDay[],
    updateFrequency: (props.camera?.updateFrequency ??
      UPDATE_FREQUENCY_DEFAULT) as LiveViewUpdateFrequency,
    workingHoursStart: getDateFromWorkingHours(
      props.camera?.workingHoursStart,
      "10",
    ),
    workingHoursEnd: getDateFromWorkingHours(
      props.camera?.workingHoursEnd,
      "19",
    ),
    enableHealthCheck: props.camera?.enableHealthCheck ?? true,
    healthCheckEmails: (props.camera?.healthCheckEmails?.filter(Boolean) ??
      []) as string[],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: cameraConfigurationValidationSchema,
    validateOnBlur: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const enableHealthCheckProps = React.useMemo(() => {
    return {
      name: "enableHealthCheck",
      label: "Health Notifications",
      onChange: (value: boolean) =>
        formik.setFieldValue("enableHealthCheck", value),
    };
  }, []);

  function save() {
    const data:
      | MutationCreateCameraArgs["input"]
      | MutationUpdateCameraArgs["input"] = {
      // Validated by Yup
      configuration: formik.values.configuration!.id,
      name: formik.values.name,
      address: formik.values.address,
      username: formik.values.username,
      password: formik.values.password,
      workingDays: formik.values.workingDays,
      updateFrequency: formik.values.updateFrequency,
      workingHoursStart: dateTo24HoursString(formik.values.workingHoursStart),
      workingHoursEnd: dateTo24HoursString(formik.values.workingHoursEnd),
      enableHealthCheck: formik.values.enableHealthCheck,
      healthCheckEmails: formik.values.healthCheckEmails,
    };
    if (!props.camera) props.create(data as MutationCreateCameraArgs["input"]);
    else {
      if (!data.password) delete data.password;
      if (!data.username) delete data.username;

      props.update(data);
    }
  }

  const hanleConfigurationChange = useCallback(
    function handleConfigurationChange(
      _,
      value: { id: number; label: string } | null,
    ) {
      void formik.setFieldValue("configuration", value);
    },
    [formik.setFieldValue],
  );

  const handleWorkingDaysChange = useCallback(
    function handleWorkingDaysChange(value: string[]) {
      void formik.setFieldValue("workingDays", value);
    },
    [formik.setFieldValue],
  );

  const handleUpdateFrequency = useCallback(
    (value: string) => {
      void formik.setFieldValue("updateFrequency", value);
    },
    [formik.setFieldValue],
  );

  const handleWorkingTimeFromChange = useCallback(
    function handleWorkingTimeFromChange(value: Date | null) {
      void formik.setFieldValue("workingHoursStart", value);
    },
    [formik.setFieldValue],
  );

  const handleWorkingTimeToChange = useCallback(
    function handleWorkingTimeToChange(value: Date | null) {
      void formik.setFieldValue("workingHoursEnd", value);
    },
    [formik.setFieldValue],
  );

  return (
    <Dialog
      open={props.isOpened}
      onClose={props.close}
      aria-labelledby={modalId}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id={modalId}>Edit Camera Configuration</DialogTitle>
      <DialogContent>
        <form>
          <Grid
            container
            mt={6}
            columnSpacing={2}
            justifyContent="space-between"
          >
            <Grid item mb={2} xs={12}>
              <Autocomplete
                disablePortal
                options={cameraConfigurationChoices}
                onChange={hanleConfigurationChange}
                value={formik.values.configuration}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="configuration"
                    label="Camera type"
                  />
                )}
              />
            </Grid>
            <Grid item mb={2} xs={12}>
              <TextInput
                name="name"
                value={formik.values.name}
                label="Name"
                showError={
                  formik.touched.name && Boolean(formik.errors.name?.length)
                }
                helperText={
                  formik.touched.name ? (formik.errors.name as string) : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item mb={2} xs={12}>
              <TextInput
                name="address"
                value={formik.values.address}
                label="IP Address"
                showError={
                  formik.touched.address &&
                  Boolean(formik.errors.address?.length)
                }
                helperText={
                  formik.touched.address
                    ? (formik.errors.address as string)
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item mb={2} xs={12}>
              <TextInput
                name="username"
                autoComplete="off"
                value={formik.values.username}
                label="Username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item mb={2} xs={12}>
              <TextInput
                type="password"
                autoComplete="off"
                name="password"
                value={formik.values.password}
                label="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item mb={2} xs={12}>
              <MultipleSelect
                label="Working Days"
                placeholder="Select Working Days"
                name="workingDays"
                options={WORKING_DAYS_OPTIONS}
                value={formik.values.workingDays}
                errors={
                  formik.touched.workingDays ? formik.errors.workingDays : []
                }
                onChange={handleWorkingDaysChange}
                onBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item mb={2} xs={12}>
              <SingleSelect
                label="Update Frequency"
                placeholder="Live View Update Frequency"
                name="updateFrequency"
                options={UPDATE_FREQUENCY_OPTIONS}
                value={formik.values.updateFrequency}
                onChange={handleUpdateFrequency}
                onBlur={formik.handleBlur}
                // skip sotring to avoid "alphabetic" ordering of "numeric" string
                choicesSortFn={() => 1}
              />
            </Grid>

            <Grid item mb={2} xs={6}>
              <TimePicker
                label="Working hours, from"
                value={formik.values.workingHoursStart}
                onChange={handleWorkingTimeFromChange}
              />
            </Grid>

            <Grid item mb={2} xs={6}>
              <TimePicker
                label="Working hours, to"
                value={formik.values.workingHoursEnd}
                onChange={handleWorkingTimeToChange}
              />
            </Grid>
            <Grid item mb={2} xs={12}>
              <Switcher
                checked={formik.values.enableHealthCheck}
                labelPlacement="end"
                {...enableHealthCheckProps}
              />
            </Grid>
            {formik.values.enableHealthCheck ? (
              <Grid item mb={2} xs={12}>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  value={formik.values.healthCheckEmails}
                  onChange={(_, value) =>
                    void formik.setFieldValue("healthCheckEmails", value)
                  }
                  onBlur={formik.handleBlur}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="healthCheckEmails"
                      label="Health Notification Emails"
                      placeholder="Health Notification Emails"
                      onBlur={formik.handleBlur("healthCheckEmails")}
                      error={
                        formik.touched.healthCheckEmails &&
                        Boolean(formik.errors.healthCheckEmails)
                      }
                      helperText={
                        formik.touched.healthCheckEmails
                          ? formik.errors.healthCheckEmails ||
                            "Press Enter to save email"
                          : "Press Enter to save email"
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={
            !formik.isValid ||
            (formik.values.enableHealthCheck &&
              formik.values.healthCheckEmails.length === 0)
          }
          onClick={save}
        >
          Save
        </Button>
        <Button color="primary" variant="outlined" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
